import { Box, Button, Typography } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import "../Register.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerCompany } from "../../../store/actions/RegisterCompanyAction";
import { toast } from "react-toastify";

const DigitalSignature = ({
  setNextPhase,
  setOpenAccordion,
  setCompanyDetails,
  companyDetails,
}) => {
  const [isSignatureDrawn, setIsSignatureDrawn] = useState(false);
  const [errors, setError] = useState("");
  const signatureRef = useRef(null);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.companyRegister.loading);
  const success = useSelector((state) => state.companyRegister.registerdata);
  const error = useSelector((state) => state.companyRegister.error);

  const clearSignature = () => {
    signatureRef.current.clear();
    setIsSignatureDrawn(false);
  };
  const saveSignature = () => {
    if (isSignatureDrawn) {
      const imageData = signatureRef.current.toDataURL();
      setCompanyDetails({ ...companyDetails, contract_sign: imageData });
    } else {
      setError("Please draw your signature before saving.");
    }
  };


  useEffect(() => {
    if (companyDetails.contract_sign !== "") {
      dispatch(registerCompany(companyDetails));
    }
  }, [companyDetails, companyDetails.contract_sign, dispatch]);

  useEffect(()=>{
    if(success && success?.data ){
      navigation("/company-register/success-register");
    }
    if(error){
      toast.error('Something went wrong,Please try again')
    }
  },[success?.data, success, navigation,error])

  const handleDraw = () => {
    const pixelData = signatureRef.current.getTrimmedCanvas().toDataURL();
    setIsSignatureDrawn(pixelData !== signatureRef.current.toDataURL());
  };
  return (
    <Box>
      <Box my={{ xs: "16px", sm: "24px" }}>
        <Typography
          sx={{
            color: "#212121",
            fontSize: { xs: "12px", sm: "14px" },
            fontWeight: 500,
            fontFamily: "Montserrat",
            textAlign: "center",
          }}
        >
          Signing a contract with our platform
        </Typography>
      </Box>
      <Box
        style={{
          position: "relative",
          mx: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SignatureCanvas
          penColor="black"
          canvasProps={{
            className: "sigCanvas",
            textAlign: "center",
            style: {
              backgroundColor: "#F2F2F7",
              maxWidth: "384px",
              width: "100%",
              height: 180,
              margin: "auto",
            },
            onMouseUp: handleDraw,
            onTouchEnd: handleDraw,
          }}
          ref={signatureRef}
        />
        <button
          onClick={clearSignature}
          style={{
            position: "absolute",
            top: "3%",
            right: "3%",
            border: "none",
          }}
        >
          X
        </button>
      </Box>
      <Box sx={{ fontSize: "12px", color: "red" }}>{errors}</Box>
      <Typography
        sx={{
          color: "#AEAEB2",
          fontSize: "12px",
          maxWidth: "384px",
          mx: "auto",
        }}
        mt="8px"
      >
        By signing up, you are agree to our{" "}
        <span
          style={{
            color: "#212121",
            textDecoration: "underline",
            fontSize: "12px",
          }}
        >
          Terms and Conditions
        </span>{" "}
        and{" "}
        <span
          style={{
            color: "#212121",
            textDecoration: "underline",
            fontSize: "12px",
          }}
        >
          Privacy
        </span>{" "}
        Policy
      </Typography>
      <Box my="32px" display="flex" gap="8px">
        <Button
          sx={{
            width: "50%",
            borderRadius: "0px 30px",
            background: "#D9DCF9",
            color: "#212121",
            fontSize: { xs: "14px", sm: "18px" },
            fontWeight: 700,
            py: "10px",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#D9DCF9",
            },
            "&:active": {
              backgroundColor: "#D9DCF9",
            },
            textTransform: "none",
          }}
          variant="contained"
          startIcon={<KeyboardDoubleArrowLeftIcon />}
          onClick={() => {
            setNextPhase({ step1: false, step2: true, step3: false });
            setOpenAccordion([1, 2]);
          }}
        >
          Back
        </Button>
        <Button
          sx={{
            width: "50%",
            borderRadius: "0px 30px",
            background: "#4254EA",
            color: "#fff",
            fontSize: { xs: "14px", sm: "18px" },
            fontWeight: 700,
            py: "10px",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#4254EA",
            },
            "&:active": {
              backgroundColor: "#4254EA",
            },
            textTransform: "none",
          }}
          variant="contained"
          onClick={saveSignature}
          disabled = {loading}
        >
          { loading ? 'Loading...' : 'Continue' }

        </Button>
      </Box>
    </Box>
  );
};

export default DigitalSignature;
