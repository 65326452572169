import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import {
  CUSTOMER_FEEDBACK_FAILURE,
  CUSTOMER_FEEDBACK_REQUEST,
  CUSTOMER_FEEDBACK_SUCCESS,
} from "../types/Feedback";
import { toast } from "react-toastify";

function* UserFeedbackSaga(action) {
  try {
    const { data, token } = action.payload;
    const url = `/users/feedback`;

    const response = yield call(baseURL.post, url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({
      type: CUSTOMER_FEEDBACK_SUCCESS,
      payload: response.data,
    });
    toast.success("Feedback submitted successfully!");
  } catch (error) {
    yield put({ type: CUSTOMER_FEEDBACK_FAILURE, payload: error });
    toast.error("Failed to submit feedback. Please try again!");
  }
}

export default function* watchUserFeedbackSaga() {
  yield takeLatest(CUSTOMER_FEEDBACK_REQUEST, UserFeedbackSaga);
}
