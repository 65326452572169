import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import { toast } from "react-toastify";
import { EMAIL_EXIST_STATUS, EMAIL_EXIST_STATUS_ERROR, EMAIL_EXIST_STATUS_SUCCESS } from "../types/ExistEmailCheck";

function* emailCheckStatus(action) {
  try {
    const { data } = action.payload;
    const url = `/users/check-email`;

    const response = yield call(baseURL.post, url, data);
    const result = {
      status : response.status,
      message : response.data.message
    }
    yield put({
      type: EMAIL_EXIST_STATUS_SUCCESS,
      payload: result,
    });
  } catch (error) {
    const errorPayload = {
      message :error.response.data.message,
      status:error.response.status
    }
    yield put({ type: EMAIL_EXIST_STATUS_ERROR, payload: errorPayload });
    if(errorPayload.status === 400 && errorPayload.message === "User with this email already exists in db"){
      toast.error("User with this email address already exists, please try with a different email");
    }
  }
}

export default function* watchEmailCheckStatus() {
  yield takeLatest(EMAIL_EXIST_STATUS, emailCheckStatus);
}
