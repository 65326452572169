import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../global/CustomButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import CartCard from "./CartCard";
import CommonBreadcrumb from "../global/CommonBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { valueAry } from "../../utils/Constant";
import { sendCheckout } from "../../store/actions/AddToCartAction";
import {
  CleanGiftCart,
  EditAddTOGiftCart,
  getGiftCart,
} from "../../store/actions/AddToGiftCartAction";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const AddCart = () => {
  const { t } = useTranslation();
  const path = [
    { path: "/", label: `${t("giftcardpage.main")}` },
    { path: "/gift-card", label: ` Lahjakamu ${t("navbar.card")}` },
    { path: "/add-cart", label: `${t("checkoutpage.cart")}` },
  ];
  const token = localStorage.getItem("authToken");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.getUser.user);
  const cartData = useSelector((state) => state.GetGiftCartReducer?.data);
  // const successEdit = useSelector((state) => state.EditAddToCartReducer.data);
  const [cart, setCart] = useState([]);
  const [editCardData, setEditCardData] = useState([]);
  const navigation = useNavigate();
  const finalTotal = cart.reduce((acc, item) => {
    // Convert nominal and amount to numbers
    const nominal = parseInt(item.nominal);
    const amount = parseInt(item.amount);

    if (!isNaN(nominal) && !isNaN(amount)) {
      if (item.type_of_card === "paid") {
        // Add 2 + nominal for each paid object
        acc += (nominal + 2) * amount;
      } else {
        // Add nominal * amount for non-paid objects
        acc += nominal * amount;
      }
    }

    return acc;
  }, 0);

  const handleIncrease = (index) => {
    setCart((prevCart) =>
      prevCart.map((item, i) =>
        i === index
          ? {
              ...item,
              amount: Math.max(parseInt(item.amount) + 1, 0),
              total: item.nominal * Math.max(parseInt(item.amount) + 1, 0),
            }
          : item
      )
    );
  };

  const handleDecrease = (index) => {
    setCart((prevCart) =>
      prevCart.map((item, i) =>
        i === index
          ? {
              ...item,
              amount: Math.max(item.amount - 1, 1),
              total: item.nominal * Math.max(item.amount - 1, 1),
            }
          : item
      )
    );
  };

  const handleInputChange = (event, index) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value)) {
      setCart((prevCart) =>
        prevCart.map((item, i) =>
          i === index
            ? { ...item, amount: value, total: item.nominal * value }
            : item
        )
      );
    }
  };

  const handleChange = (event, index) => {
    const value = event.target.value;
    setCart((prevCart) =>
      prevCart.map((item, i) =>
        i === index ? { ...item, delivery_type: value } : item
      )
    );
  };

  const handleChangeNominal = (event, index) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value)) {
      setCart((prevCart) =>
        prevCart.map((item, i) =>
          i === index
            ? { ...item, nominal: value, total: value * item.amount }
            : item
        )
      );
    }
  };

  const handleRemoveItem = (index) => {
    const id = userData.data[0]._id;
    const editData = {
      _id: cart[index]._id,
      isDelete: true,
    };
    const payloadEdit = {
      cards: [editData],
    };
    dispatch(EditAddTOGiftCart(payloadEdit, token, id));
    setCart((prevCart) => prevCart.filter((_, i) => i !== index));
  };

  const updatedEditState = editCardData.map((editItem) => {
    const cartItem = cart.find((cartItem) => cartItem._id === editItem._id);

    if (cartItem) {
      // If the object is found in the cart state, update the values
      editItem = {
        _id: editItem._id,
        isDelete: false,
        // Note: You can customize this part based on the keys you want to include
        ...(cartItem.delivery_type && {
          delivery_type: cartItem.delivery_type,
        }),
        ...(cartItem.nominal && { nominal: cartItem.nominal }),
        ...(cartItem.amount && { amount: cartItem.amount }),
      };
    } else {
      // If the object is not found in the cart state, set isDelete to true
      const { _id } = editItem; // Extract _id before modifying the object
      editItem = { _id, isDelete: true };
    }

    return editItem;
  });

  cart.forEach((cartItem) => {
    const existingItem = updatedEditState.find(
      (updatedItem) => updatedItem._id === cartItem._id
    );

    if (!existingItem) {
      updatedEditState.push({
        ...cartItem,
        isDelete: false,
      });
    }
  });

  const handleButtonClick = () => {
    const payload = {
      item: cart,
      finalTotal: finalTotal,
    };
    const payloadEdit = {
      cards: updatedEditState,
    };
    const id = userData.data[0]._id;

    if (cart && cart?.length > 0) {
      dispatch(sendCheckout(payload));
      dispatch(EditAddTOGiftCart(payloadEdit, token, id));
      navigation("/checkout");
    } else {
      toast.info(
        "Cart is Empty!!! ,Please add items to your cart before proceeding to checkout."
      );
    }
  };

  useEffect(() => {
    if (cartData && cartData?.length > 0) {
      setCart(cartData);
      setEditCardData(cartData);
    }
  }, [cartData]);

  useEffect(() => {
    if (token && userData && userData.data && userData.data.length > 0) {
      const userId = userData.data[0]._id;
      dispatch(getGiftCart(userId, token));
    }
  }, [token, userData, dispatch]);

  useEffect(() => {
    dispatch(CleanGiftCart());
  }, [dispatch]);

  return (
    <Box mx={"7%"} pb={"200px"}>
      <Box>
        <CommonBreadcrumb path={path} />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        gap="1rem"
      >
        <Typography
          sx={{
            color: "#212121",
            fontWeight: "700",
            fontSize: { xs: "30px", md: "40px" },
            lineHeight: { xs: "36px", md: "56px" },
          }}
        >
          {t("checkoutpage.cart")}
        </Typography>
        <Box display="flex" gap={{ xs: "1rem", sm: "2rem" }}>
          <Typography
            sx={{
              background: "#D9DCF9",
              display: "inline-block",
              padding: { md: "10px 80px", tab: "10px 40px", xs: "10px 14px" },
              color: "#4254EA",
              fontWeight: 700,
              borderRadius: "16px",
              margin: "auto",
              fontSize: { xs: "14px", tab: "16px" },
            }}
          >
            {t("checkoutpage.total")}: ${finalTotal}
          </Typography>
          <CustomButton onClick={handleButtonClick} pd="10px 80px">
            {t("checkoutpage.checkout")}
          </CustomButton>
        </Box>
      </Box>
      {cart && cart?.length > 0 ? (
        cart?.map((item, index) => (
          <Grid
            container
            sx={{ background: "#fff" }}
            borderRadius="16px"
            padding={{ xs: "15px", md: "30px" }}
            display="flex"
            gap="1rem"
            mt={"2rem"}
            alignItems="center"
            justifyContent={{ xs: "center", tab: "none" }}
            key={index}
          >
            <>
              <Grid item xs={12} sm={12} tab={2.5} md={1.9} lg={1.5}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box
                    sx={{
                      background: "#D8F1FD",
                      borderRadius: "16px",
                      height: { tab: "130px", md: "105px" },
                      width: "130px",
                      p: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CartCard
                      product={item?.image}
                      message={item?.greeting_text}
                      price={item?.nominal}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} tab={9} md={9} lg={10}>
                {" "}
                <Box>
                  <Typography
                    sx={{
                      color: "#212121",
                      fontSize: { xs: "24px", md: "30px" },
                      fontWeight: 600,
                      textAlign: { xs: "center", sm: "left" },
                    }}
                  >
                    Lahjakamu {t("giftcardpage.giftcard")}
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection={{ xs: "column", sm: "row" }}
                    gap="1rem"
                  >
                    <Box
                      display="flex"
                      gap={{ xs: ".5rem", lg: "2rem" }}
                      pt="14px"
                      flexWrap="wrap"
                      justifyContent={{ xs: "center", sm: "flex-start" }}
                      sx={{
                        maxWidth: { tab: "450px", lg: "none" },
                        width: { xs: "100%", lg: "auto" },
                      }}
                    >
                      <Select
                        sx={{
                          maxWidth: { xs: "100px", lg: "none" },
                          width: { lg: "176px", xs: "100%" },
                          background: "#fff",
                          borderRadius: "8px",
                          border: "1px solid rgba(66, 84, 234, 0.20)",
                          fontWeight: "600",
                          "& .MuiSelect-select": {
                            padding: "10px 14px", // Adjust padding here
                          },
                        }}
                        value={
                          item?.delivery_type ? item?.delivery_type : "email"
                        }
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(e) => handleChange(e, index)}
                        IconComponent={ExpandMoreIcon}
                        placeholder="enter amount"
                      >
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="sms">SMS</MenuItem>
                      </Select>
                      <Select
                        sx={{
                          maxWidth: { xs: "100px", lg: "none" },
                          width: { lg: "176px", xs: "100%" },
                          background: "#fff",
                          borderRadius: "8px",
                          border: "1px solid rgba(66, 84, 234, 0.20)",
                          fontWeight: "600",
                          "& .MuiSelect-select": {
                            padding: "10px 14px", // Adjust padding here
                          },
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                        value={item?.nominal}
                        displayEmpty
                        onChange={(e) => handleChangeNominal(e, index)}
                        IconComponent={ExpandMoreIcon}
                        placeholder="enter amount"
                      >
                        {valueAry?.map((item, ind) => (
                          <MenuItem key={ind} value={item}>
                            ${item}
                          </MenuItem>
                        ))}{" "}
                      </Select>
                      <Stack
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: ".5rem",
                        }}
                        variant="outlined"
                      >
                        <button
                          onClick={() => handleIncrease(index)}
                          style={{
                            background: "#D9DCF9",
                            border: "none",
                            fontWeight: "bold",
                            fontSize: "20px",
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          +
                        </button>
                        <OutlinedInput
                          inputProps={{
                            "aria-label": "Without label",
                            style: { textAlign: "center" },
                          }}
                          sx={{
                            width: "87px",
                            background: "#fff",
                            borderRadius: "8px",
                            border: "none",
                            textAlign: "center",
                            fontWeight: "600",
                            "& input": {
                              padding: "10px 14px", // Adjust input padding here
                            },
                          }}
                          value={item?.amount}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                        <button
                          onClick={() => handleDecrease(index)}
                          style={{
                            background: "#D9DCF9",
                            border: "none",
                            fontWeight: "bold",
                            fontSize: "20px",
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          -
                        </button>
                      </Stack>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Typography>{t("checkoutpage.cost")}</Typography>
                      <Typography
                        pl={"5px"}
                        pr={{ md: "2rem", xs: "1rem" }}
                        sx={{ color: "#212121", fontWeight: 600 }}
                        width={"100px"}
                      >
                        {item?.type_of_card === "paid"
                          ? (parseInt(item?.nominal) + 2) *
                            parseInt(item?.amount)
                          : parseInt(item?.nominal) * parseInt(item?.amount)}
                      </Typography>
                      <IconButton
                        onClick={() => handleRemoveItem(index)}
                        sx={{ color: "#BABABA" }}
                      >
                        <ClearIcon />
                      </IconButton>{" "}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </>
          </Grid>
        ))
      ) : (
        <Box sx={{ color: "#BABABB" }}> {t("checkoutpage.emptyCart")}</Box>
      )}
    </Box>
  );
};

export default AddCart;
