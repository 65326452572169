import { CHANGE_PASSWORD_COMPANY, FORGOT_PASSWORD_COMPANY, FORGOT_PASSWORD_SAVE_INFO } from "../types/ForgetPassword";

export const forgetPasswordCompany =(data)=>({
    type: FORGOT_PASSWORD_COMPANY,
    payload: data
})

export const forgetPasswordSaveInfo =(data)=>({
    type: FORGOT_PASSWORD_SAVE_INFO,
    payload: data
})

export const changePasswordInfo =(data)=>({
    type: CHANGE_PASSWORD_COMPANY,
    payload: data
})