import {
  SEARCH_GIPHY_STICKERS_FAILURE,
  SEARCH_GIPHY_STICKERS_REQUEST,
  SEARCH_GIPHY_STICKERS_SUCCESS,
} from "../types/GifiSearchType";

// src/reducers/giftCardReducer.js
const initialState = {
  data: [],
  loading: false,
  error: null,
};

const GifiSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_GIPHY_STICKERS_REQUEST:
      return { ...state, loading: true, error: null };
    case SEARCH_GIPHY_STICKERS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case SEARCH_GIPHY_STICKERS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default GifiSearchReducer;
