import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clip from "../../assets/copyboard.svg";
import { personPromocodeAction } from "../../store/actions/PersonPromocodeAction";
import { useDispatch, useSelector } from "react-redux";
import Loadingbar from "../../utils/Loadingbar";
import { useTranslation } from "react-i18next";



const Promocodes = () => {
  const headtxt = { color: "#BABABA", fontSize: { xs: "12px", sm: "14px" } };
  const subtxt = {
    fontSize: { xs: "12px", sm: "14px" },
    color: "#212121",
    fontWeight: 500,
  };
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const [messTooltip, setMessTooltip] = React.useState("Click to Copy");
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const userData = useSelector((state) => state?.getUser?.user);
  const promocode = useSelector(
    (state) => state?.promocode?.data?.getPromoCode
  );
  const loadingData = useSelector((state) => state?.promocode?.loading);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setMessTooltip("Copied to clipboard:", text);
      })
      .catch((error) => {
        setMessTooltip("Error copying to clipboard:", error);
      });
  };

  useEffect(() => {
    if (token) {
      dispatch(personPromocodeAction(token, userData?.data[0]?._id));
    }
  }, []);


  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            color: "#212121",
            fontWeight: 600,
            mb: "24px",
          }}
        >
          {t("sidebarView.yourCode")}
        </Typography>
      </Box>
      {loadingData ? (
        <Loadingbar />
      ) : (
        <Box>
          {promocode && promocode?.length > 0 ? (
            promocode?.map((data, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                sx={{
                  marginBottom: "8px",
                  borderBottom: "none",
                  boxShadow: "none",
                  padding: "20px 10px",
                  borderRadius: "8px",
                  border: "none",
                  "&:before": {
                    display: "none",
                  },
                  "&.MuiAccordion-root:first-of-type": {
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  },
                  "&.MuiAccordion-root:last-of-type": {
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{
                    "& .css-i4bv87-MuiSvgIcon-root": {
                      marginTop: {
                        xs: expanded === `panel${index}` ? "30px" : "-30px",
                        sm: "auto",
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      maxWidth: "400px",
                      width: "100%",
                      alignItems: { sm: "center" },
                      justifyContent: "space-between",
                      flexDirection: { sm: "row", xs: "column" },
                      gap: "10px",
                    }}
                  >
                    <Box display="flex" alignItems="center" gap="12px">
                      <Typography sx={headtxt}>{t("sidebarView.partName")}</Typography>
                      <Typography sx={subtxt}>{data.partner_name}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap="12px">
                      <Typography sx={headtxt}>{t("sidebarView.activeNumber")}</Typography>
                      <Typography sx={subtxt}>{data.nominal}</Typography>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Divider />
                    <Box
                      display="flex"
                      alignItems="center"
                      mt="24px"
                      gap="12px"
                    >
                      <Typography sx={headtxt}>{t("sidebarView.ActiveDate")}</Typography>
                      <Typography sx={subtxt}>
                        {data.promo_code_issue_date}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems={{ lg: "center" }}
                      justifyContent={{ lg: "space-between" }}
                      mt="10px"
                      flexDirection={{ lg: "row", xs: "column" }}
                      gap="10px"
                    >
                      <Box display="flex" alignItems="center" gap="12px">
                        <Typography sx={headtxt}>
                        {t("sidebarView.endPromo")}
                        </Typography>
                        <Typography sx={subtxt}>
                          {data.promo_code_expiry_date}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" gap="12px">
                        <Typography sx={headtxt}>{t("sidebarView.PromoName")}</Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          gap="8px"
                          sx={{
                            background: "#F2F2F7",
                            padding: "8px 12px",
                            borderRadius: "8px",
                            color: "#4254EA",
                            fontWeight: "600",
                            fontSize: { sm: "14px", xs: "12px" },
                          }}
                        >
                          <p>{data.promo_code}</p>
                          <p>
                            <Tooltip title={messTooltip} arrow placement="top">
                              <img
                                src={clip}
                                alt="copy"
                                onClick={() => copyToClipboard(data.promo_code)}
                              />
                            </Tooltip>
                          </p>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Box sx={{ color: "gray", fontSize: "16px", textAlign: "left" }}>
              {t("sidebarView.promHistory")}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Promocodes;
