import { Box } from "@mui/material";
import React from "react";
import FaqAccordion from "./FaqAccordion";

const FAQsection = () => {
  return (
    <Box mx={"7%"} my="80px">
      <FaqAccordion title="Purchase" />
      <Box my="60px">
        <FaqAccordion title="Delivery" />
      </Box>
      <FaqAccordion title="Other" />
    </Box>
  );
};

export default FAQsection;
