import {
  HISTORY_PURCHASE_INFO,
  HISTORY_PURCHASE_INFO_ERROR,
  HISTORY_PURCHASE_INFO_SUCCESS,
} from "../types/HistoryPurchase";

// reducers.js
const initialState = {
  history: null,
  loading: false,
  error: null,
};

const HistoryPurchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case HISTORY_PURCHASE_INFO:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case HISTORY_PURCHASE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        history: action.payload,
        error: null,
      };

    case HISTORY_PURCHASE_INFO_ERROR:
      return {
        ...state,
        loading: false,
        history: null,
        error: action.error,
      };

    default:
      return state;
  }
};

export default HistoryPurchaseReducer;
