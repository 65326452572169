import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect } from "react";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const CategorySection = ({
  category,
  selectedValues,
  setSelectedValues,
  categories,
  onCategoryChange,
  onWhomChange,
  onCityChange,
  validationErrors,
  filteredData,
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    switch (category) {
      case "category":
        onCategoryChange(value);
        break;
      case "whom":
        onWhomChange(value);
        break;
      case "city":
        onCityChange(value);
        break;
      default:
        break;
    }

    setSelectedValues((prevSelected) => ({
      ...prevSelected,
      [category]: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const { label, options } = categories[category];

  useEffect(() => {
    if (filteredData.length > 0) {
      const data = filteredData[0];
      setSelectedValues((prevSelected) => ({
        ...prevSelected,
        [category]: data[category], // Assuming category is the name of the property like "category", "whom", "city"
      }));
    }
  }, [filteredData]);

  return (
    <Box>
      <Typography
        sx={{
          color: "#64646D",
          fontSize: "12px",
          fontWeight: 500,
          fontFamily: "Montserrat",
          opacity: ".8",
          ml: "12px",
        }}
      >
        {label}*
      </Typography>
      <Select
        sx={{
          width: "100%",
          background: "#F8F8F8",
          borderRadius: "8px",
          "& fieldset": { border: "1px solid #4254ea33" },
        }}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={ExpandMoreIcon}
        placeholder={`Select ${label}`}
        multiple
        value={selectedValues[category] || []}
        onChange={handleChange}
        renderValue={(selected) => <p sx={{ fontSize: "14px" }}>Select</p>}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox
              checked={(selectedValues[category] || []).indexOf(option) > -1}
            />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
      {validationErrors[category] && (
        <Typography
          sx={{
            color: "red",
            fontSize: "12px",
            ml: "12px",
          }}
        >
          {validationErrors[category]}
        </Typography>
      )}
      <Box
        display="flex"
        alignItems="center"
        gap="12px"
        flexWrap="wrap"
        sx={{ maxWidth: "440px" }}
        mt="8px"
      >
        {(selectedValues[category] || []).map((item) => (
          <p
            key={item}
            style={{
              background: "#D9DCF9",
              color: "#4254EA",
              display: "flex",
              alignItems: "center",
              padding: "8px 12px",
              borderRadius: "8px",
            }}
          >
            {item}
            <CheckIcon fontSize="small" />
          </p>
        ))}
      </Box>
    </Box>
  );
};

export default CategorySection;
