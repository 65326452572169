import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import { toast } from "react-toastify";
import {
  ACTIVE_CARD_FAILURE,
  ACTIVE_CARD_REQUEST,
  ACTIVE_CARD_SUCCESS,
  CHECK_CARD_FAILURE,
  CHECK_CARD_REQUEST,
  CHECK_CARD_SUCCESS,
} from "../types/ActiveCheckCard";
import { BALANCE_REQUEST } from "../types/GetBalance";

function* activeCardSaga(action) {
  try {
    const { data, token, id } = action.payload;
    const url = `/gift-card/activate-card`;
    const userId = id;
    const response = yield call(baseURL.put, url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    yield put({
      type: ACTIVE_CARD_SUCCESS,
      payload: { data: response.data, status: response.status },
    });
    yield put({ type: BALANCE_REQUEST, payload: { userId, token } });
  } catch (error) {
    const errorPayload = {
      message: error.response.data.message,
      name: error.name,
      code: error.response.status,
    };
    yield put({ type: ACTIVE_CARD_FAILURE, payload: errorPayload });
    if (error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Invalid Code. Please try again!");
    }
  }
}

function* CheckCardSaga(action) {
  try {
    const { data, token } = action.payload;
    const url = `/gift-card/check-card`;

    const response = yield call(baseURL.post, url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({
      type: CHECK_CARD_SUCCESS,
      payload: { data: response.data, status: response.status },
    });
  } catch (error) {
    const errorPayload = {
      message: error.message,
      name: error.name,
      code: error.code,
      // Include any other properties you want from the error object
    };
    yield put({ type: CHECK_CARD_FAILURE, payload: errorPayload });
    toast.error("Please try again!");
  }
}

export default function* watchActiveCheckSaga() {
  yield takeLatest(ACTIVE_CARD_REQUEST, activeCardSaga);
  yield takeLatest(CHECK_CARD_REQUEST, CheckCardSaga);
}
