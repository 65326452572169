import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
const ItemCard = ({ title, display, describe }) => {
  const navigate = useNavigate(); // useNavigate instead of useHistory

  const navigateToPartnersCard = () => {
    navigate('/partners-card');
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrolls to the top of the page
  };
  return (
    <Box
      sx={{
        boxShadow: "2px 2px 40px 0px rgba(66, 84, 234, 0.08)",
        width: { xs: "300px",md: "384px" },
        hight: "auto",
        padding: {sm:"16px 32px",xs:"16px 16px"},
      }}
      onClick={navigateToPartnersCard}
    >
      <img
        src={display}
        alt={title}
        style={{
          maxWidth: "100%",
          width: "100%",
          height: "auto",
          objectFit: "cover",
          aspectRatio: "5/3",
        }}      />
      <Box>
        <Typography
          sx={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}
          py={1}
        >
          {title}
        </Typography>
        <Typography
          sx={{ textAlign: "center", fontSize: "14px", color: "#64646D" }}
        >
          {`${describe.slice(0, 150)}...`}
        </Typography>
      </Box>
    </Box>
  );
};

export default ItemCard;
