import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CustomButton from "../global/CustomButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import BasicModal from "../global/BasicModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { filterRequest } from "../../store/actions/PartnerFilterAction";
import { valueAry } from "../../utils/Constant";
import { generatePromocode } from "../../store/actions/GeneratePromoCodeAction";
import { toast } from "react-toastify";
import { balanceRequest } from "../../store/actions/GetBalanceAction";

const path = [
  { path: "/", label: "Main" },
  { path: "/gift-card", label: " Lahjakamu Сard" },
];

const CardView = () => {
  const [showMore, setShowMore] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [isChecked, setIsChecked] = useState(true); // You may set the initial value based on your needs
  const [error, setError] = useState({});
  const userData = useSelector((state) => state.getUser.user);
  const [balance, setBalance] = React.useState();
  const location = useLocation();
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const allData = useSelector((state) => state?.filterData?.data?.data);
  const getUser = allData && allData?.find((item) => item._id === id);
  const codeSuccess = useSelector((state) => state?.GeneratePromo?.code);
  const codeSuccessStatus = useSelector((state) => state?.GeneratePromo?.status);
  const codeLoader = useSelector((state) => state?.GeneratePromo?.loading);
  const token = localStorage.getItem("authToken");
  const bal = useSelector((state) => state?.balance?.balance?.data?.balance);
  const handleShowMoreClick = () => {
    setShowMore(!showMore);
  };

  const handleButtonClick = () => {};
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleCodeGenerate = async () => {
    const newError = {};

    if (!selectedValue) {
      newError.select = t("validation.selectValue");
    }

    if (!isChecked) {
      newError.checkbox = t("validation.agreeTermsT");
    }

    setError(newError);

    const nominalAmount = parseFloat(selectedValue);
    const numericUserBalance = parseFloat(balance);

    if (nominalAmount > numericUserBalance) {
      newError.balance = "Balance is not sufficient";
      toast.error("You don't have sufficient balance");
    }

    // If there are no errors, proceed with your logic
    if (Object.keys(newError).length === 0) {
      try {
        const payload = {
          partner_name: userData.data[0].user_name,
          partner_id: userData.data[0]._id,
          nominal: `${selectedValue}`,
          activation_amount: `${balance}`,
        };
        const userId = userData.data[0]._id;

        // Assuming generatePromocode is an async action
        dispatch(generatePromocode(payload, token, userId));

        // Update balance after successful generation
        const newBalance = numericUserBalance - nominalAmount;
        setBalance(newBalance.toString());

        // Optionally, you can also reset the selected value and checkbox
        setSelectedValue("");
        setIsChecked(true);
      } catch (error) {
        // Handle error if needed
        toast.error("Error generating promo code");
      }
    }
  };

  useEffect(() => {
    dispatch(filterRequest(""));
  }, [dispatch]);

  useEffect(() => {
    if (codeSuccessStatus && codeSuccessStatus === 200) {
      setOpen(true);
    }
  }, [codeSuccess]);

  useEffect(() => {
    if (token && userData) {
      const userId = userData.data[0]._id;
      dispatch(balanceRequest(userId, token));
    }
  }, [token,userData,dispatch]);

  useEffect(() => {
    if (bal) {
      setBalance(bal);
    }
  }, [bal]);

  return (
    <Box mx={"7%"} mb={"40px"}>
      <Box>
        <Breadcrumbs
          separator={<ArrowForwardIosIcon fontSize="8px" />}
          aria-label="breadcrumb"
          sx={{ color: "#BABABA" }}
          py={2}
        >
          {path.map((item, index) => (
            <Typography key={index}>
              <Link
                to={item.path}
                style={{
                  color: location.pathname === item.path ? "black" : "#BABABA",
                }}
              >
                {item.label}
              </Link>
            </Typography>
          ))}
        </Breadcrumbs>
      </Box>
      <Box>
        <Typography
          sx={{
            color: "#212121",
            fontWeight: "700",
            fontSize: { xs: "30px", md: "40px" },
            lineHeight: { xs: "36px", md: "56px" },
          }}
          py={2}
        >
          {getUser?.company_name}
        </Typography>
        <Box>
          <Grid
            container
            display={{ lg: "flex" }}
            gap={{ lg: "10px" }}
            maxWidth="1250px"
          >
            <Grid item xs={12} md={7} lg={6}>
              <Box>
                <Grid container>
                  <Grid
                    item
                    sm={12}
                    display="flex"
                    gap={{ xs: "5px", sm: "12px" }}
                    justifyContent={{ xs: "center", sm: "flex-start" }}
                    alignItems={{ xs: "center", sm: "flex-start" }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        maxWidth: "470px",
                        width: "100%",
                        background: "#fff",
                        borderRadius: "16px",
                        minWidth: "200px",
                        height: { xs: "210px", sm: "340px" },
                      }}
                    >
                      <Box
                        position="relative"
                        width="100%"
                        height="100%"
                        borderRadius="16px"
                        overflow="hidden"
                      >
                        <img
                          src={getUser?.product_images[0]}
                          alt="Company"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "16px",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection={{ xs: "column" }}
                      gap={{ xs: "5px", sm: "12px" }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          width: { xs: "83px", sm: "126px" },
                          height: { xs: "69px", sm: "105px" },
                          background: "#fff",
                          borderRadius: "16px",
                        }}
                      >
                        <Box
                          position="relative"
                          width="100%"
                          height="100%"
                          borderRadius="16px"
                          overflow="hidden"
                        >
                          <img
                            src={getUser?.product_images[1]}
                            alt="Company"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          position: "relative",
                          width: { xs: "83px", sm: "126px" },
                          height: { xs: "69px", sm: "105px" },
                          background: "#fff",
                          borderRadius: "16px",
                        }}
                      >
                        <Box
                          position="relative"
                          width="100%"
                          height="100%"
                          borderRadius="16px"
                          overflow="hidden"
                        >
                          <img
                            src={getUser?.product_images[2]}
                            alt="Company"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          position: "relative",
                          width: { xs: "83px", sm: "126px" },
                          height: { xs: "69px", sm: "105px" },
                          background: "#fff",
                          borderRadius: "16px",
                        }}
                      >
                        <Box
                          position="relative"
                          width="100%"
                          height="100%"
                          borderRadius="16px"
                          overflow="hidden"
                        >
                          <img
                            src={getUser?.product_images[3]}
                            alt="Company"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    display={{ sm: "flex" }}
                    justifyContent={{ xs: "flex-start", sm: "space-between" }}
                    gap={{ xs: "5px", sm: "12px" }}
                    mt={"20px"}
                  >
                    <Box maxWidth="440px" width="100%">
                      <Typography
                        style={{
                          color: "#212121",
                          fontSize: "14px",
                          fontWeight: 500,
                          padding: "0px 11px 0px 0px",
                        }}
                      >
                        {showMore
                          ? getUser?.about_company
                          : `${getUser?.about_company.slice(0, 170)}...`}
                      </Typography>
                      <Box my={{ xs: 1, sm: 0 }}>
                        <Button
                          sx={{
                            color: "#4254EA",
                            textTransform: "none",
                            fontSize: "12px",
                            padding: "0px",
                          }}
                          endIcon={<KeyboardDoubleArrowDownIcon />}
                          onClick={handleShowMoreClick}
                        >
                          {showMore ? t("showless") : t("showmore")}
                        </Button>
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        variant="outlined"
                        sx={{
                          maxWidth: "140px",
                          width: { xs: "140px", md: "120px", lg: "140px" },
                          borderRadius: "16px",
                          fontSize: "14px",
                          fontWeight: 600,
                          textTransform: "none",
                          padding: "09px",
                        }}
                        onClick={() => {
                          window.open(getUser?.site_link, "_blank");
                        }}
                      >
                        {t("partnercardpage.visitsite")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              mt={{ xs: 5, md: 0 }}
              xs={12}
              md={5}
              lg={4}
              px={{ md: 3, lg: 0 }}
            >
              <Box>
                {" "}
                <CustomButton
                  onClick={handleButtonClick}
                  bg="#4254ea1a"
                  color="#4254EA"
                  width="100%"
                  fs="18px"
                >
                  {t("partnercardpage.balance")}: ${balance}
                </CustomButton>
              </Box>
              <FormControl
                sx={{
                  maxWidth: { xs: "100%" },
                  mt: { xs: "20px", md: "40px" },
                }}
                fullWidth
              >
                <Typography
                  sx={{
                    color: "#212121",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  py={1}
                >
                  {t("partnercardpage.typofnominal")}
                </Typography>
                <Select
                  sx={{
                    background: "#fff",
                    border: "none",
                    fontWeight: "600",
                    "& .MuiSelect-select": {
                      padding: "10px 14px", // Adjust padding here
                    },
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={ExpandMoreIcon}
                  placeholder="enter amount"
                  value={selectedValue}
                  onChange={handleSelectChange}
                >
                  {valueAry.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      ${item}
                    </MenuItem>
                  ))}
                </Select>
                <p style={{ color: "red", fontSize: "12px" }}>{error.select}</p>
              </FormControl>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyItems="self-start"
                  gap="5px"
                  mt="20px"
                >
                  <Checkbox
                    defaultChecked={isChecked}
                    sx={{
                      padding: "0px",
                      color: "#4254EA",
                      "&.Mui-checked": {
                        color: "#4254EA",
                      },
                    }}
                    onChange={handleCheckboxChange}
                  />
                  <Typography
                    sx={{ color: "#212121", fontSize: "14px", fontWeight: 500 }}
                  >
                    {t("partnercardpage.agreewith")}{" "}
                    <span style={{ textDecoration: "underline" }}>
                      {t("partnercardpage.tandc")}
                    </span>
                  </Typography>
                </Box>
                <p style={{ color: "red", fontSize: "12px" }}>
                  {error.checkbox}
                </p>
              </Box>

              <Box mt={{ xs: "30px", md: "70px" }}>
                <BasicModal
                  handleCodeGenerate={handleCodeGenerate}
                  setOpen={setOpen}
                  open={open}
                  codeSuccess={codeSuccess?.addPromoCode}
                  siteLink={getUser?.site_link}
                  codeLoader={codeLoader}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CardView;
