import { Box, Button, OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

const SearchBar = ({ handleSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleButtonClick = () => {
    // Call the handleSearch function with the current searchTerm
    handleSearch(searchTerm);
  };

  const handleEnterKeyPress = (event) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.key === "Enter") {
      // Call the handleSearch function with the current searchTerm
      handleSearch(searchTerm);
    }
  };

  return (
    <Box sx={{ position: "relative" }} mb={1}>
      <OutlinedInput
        inputProps={{
          "aria-label": "Without label",
        }}
        sx={{
          background: "#fff",
          borderRadius: "8px 0px 0px 8px",
          border: "none",
          fontWeight: "600",
          width: { xs: "80%", md: "92%" },
          "& input": {
            padding: "10px 14px", // Adjust input padding here
          },
        }}
        placeholder={t("giftcardpage.srcimg")}
        value={searchTerm}
        onChange={handleInputChange}
        onKeyDown={handleEnterKeyPress} // Handle "Enter" key press
      />
      <Button
        aria-label="delete"
        sx={{
          background: "#4254EA",
          borderRadius: "0px",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#3141D8", // Adjust hover color as needed
          },
          padding: "9px 9px",
          position: "absolute",
          top: "1px",
        }}
        onClick={handleButtonClick}
      >
        <SearchIcon />
      </Button>
    </Box>
  );
};

export default SearchBar;
