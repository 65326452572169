import React, { useEffect, useState } from "react";
import { Box, OutlinedInput, Typography } from "@mui/material";
import TabViewModal from "./TabViewModal";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCard,
  cleanCheckModal,
} from "../../../store/actions/ActiveCheckAction";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const txt = {
  color: "#64646D",
  fontSize: "12px",
  fontWeight: 500,
  fontFamily: "Montserrat",
  opacity: ".8",
};
const CheckPage = ({ setLogintype }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    giftCardNumber: "",
    secretCode: "",
  });
  const [modalInfo, setModalInfo] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const checkCardData = useSelector((state) => state?.checkCardData?.data);
  const navigation = useNavigate();
  const { t } = useTranslation();

  const handleChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const validate = () => {
    const errors = {};

    if (!formData.giftCardNumber.trim()) {
      errors.giftCardNumber = "Gift card number is required";
    }

    if (!formData.secretCode.trim()) {
      errors.secretCode = "Secret code is required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleActivate = () => {
    if (validate()) {
      if (token) {
        const payload = {
          promo_code: formData.giftCardNumber,
          secret_code: formData.secretCode,
        };
        dispatch(CheckCard(payload, token));
      }
    }
  };

  useEffect(() => {
    if (checkCardData && checkCardData.status === 200) {
      setOpen(true);

      setModalInfo(checkCardData.data.getCard);
    }
  }, [checkCardData]);

  const handleCloseModal = () => {
    setOpen(false);
    dispatch(cleanCheckModal());
  };
  const handleNavigate = () => {
    setOpen(true);
    dispatch(cleanCheckModal());
    setLogintype("Activate");
  };

  return (
    <Box>
      <Box my="16px">
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          gap="8px"
        >
          <Box>
            <Typography sx={txt}>{t("sidebarView.gfNum")}</Typography>
            <OutlinedInput
              fullWidth
              sx={{
                maxWidth: { sm: "240px" },
                width: "100%",
                background: "#F8F8F8",
                "& fieldset": { border: "1px solid #4254ea33" },
                borderRadius: "8px",
              }}
              placeholder="Enter Gift card number*"
              value={formData.giftCardNumber}
              onChange={(e) => handleChange("giftCardNumber", e.target.value)}
            />
            {validationErrors.giftCardNumber && (
              <Typography sx={{ color: "red", fontSize: "12px" }}>
                {validationErrors.giftCardNumber}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography sx={txt}>{t("sidebarView.secretCode")}</Typography>
            <OutlinedInput
              fullWidth
              sx={{
                maxWidth: { sm: "240px" },
                width: "100%",
                background: "#F8F8F8",
                "& fieldset": { border: "1px solid #4254ea33" },
                borderRadius: "8px",
              }}
              placeholder="Enter Secret code*"
              value={formData.secretCode}
              onChange={(e) => handleChange("secretCode", e.target.value)}
            />
            {validationErrors.secretCode && (
              <Typography sx={{ color: "red", fontSize: "12px" }}>
                {validationErrors.secretCode}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Box mt="35px" sx={{ maxWidth: { sm: "240px" } }}>
        <TabViewModal
          btntxt={t("sidebarView.checkCard")}
          headingtxt={t("sidebarView.checkGF")}
          subtxt={t("sidebarView.balCheck")}
          balanceamt="Balance: $1000"
          modalbtn={t("sidebarView.Activate")}
          fs="18px"
          width={{ xs: "100%" }}
          setOpen={setOpen}
          open={open}
          handleStatus={handleActivate}
          modalInfo={modalInfo}
          handleCloseModal={handleCloseModal}
          handleNavigate={handleNavigate}
        />
      </Box>
    </Box>
  );
};

export default CheckPage;
