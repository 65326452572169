import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box, IconButton, ImageListItemBar } from "@mui/material";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import Loadingbar from "../../utils/Loadingbar";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function ImgList({
  setSelectedImg,
  itemData,
  load,
  updateImageInCardData,
  index,
}) {
  const [selectedPic, setSelectedPic] = React.useState(null);

  const handleBookmarkClick = (img) => {
    setSelectedPic(img === selectedPic ? null : img);
    setSelectedImg(img === selectedPic ? null : img);
    updateImageInCardData(img == selectedPic ? null : img, index);
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: 200,
        background: load ? "white" : "#F2F2F7",
      }}
    >
      {load ? (
        <Loadingbar />
      ) : (
        <ImageList
          sx={{
            width: "100%",
            height: 200,
            transform: "translateZ(0)",
            borderRadius: "8px",
            overflowY: "auto", // Add overflowY: auto to ImageList styles
            scrollbarWidth: "none", // Hide the scrollbar for Firefox
            msOverflowStyle: "none", // Hide the scrollbar for IE/Edge
            "&::-webkit-scrollbar": {
              width: "5px", // Set the width of the scrollbar
              background: "#fff",
              padding: "2rem",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.2)", // Set the color of the scrollbar thumb
              borderRadius: "4px", // Optional: Set the border radius of the thumb
            },
          }}
          variant="quilted"
          cols={4}
          rowHeight={121}
        >
          {itemData.map((item, ind) => (
            <ImageListItem
              key={ind}
              cols={item.cols || 1}
              rows={item.rows || 1}
            >
              <img
                {...srcset(item.url, 121, item.rows, item.cols)}
                alt="search image"
                loading="lazy"
                style={{ objectFit: "contain" }}
              />
              <ImageListItemBar
                sx={{
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                }}
                position="top"
                actionIcon={
                  <IconButton
                    sx={{
                      color: selectedPic === item.url ? "#4254EA" : "white",
                    }}
                    aria-label={`star ${item.url}`}
                    onClick={() => handleBookmarkClick(item.url)}
                  >
                    {selectedPic === item.url ? (
                      <RadioButtonCheckedIcon />
                    ) : (
                      <PanoramaFishEyeIcon />
                    )}
                  </IconButton>
                }
                actionPosition="left"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </Box>
  );
}
