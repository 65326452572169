import React from "react";
import { Container } from "@mui/material";
import Checkout from "../../components/Checkout/Checkout";
const CheckOut = () => {
  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: 0, paddingRight: 0, background: "#F2F2F7" }}
    >
      <Checkout />
    </Container>
  );
};

export default CheckOut;
