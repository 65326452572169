import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CustomButton from "../global/CustomButton";
import { OutlinedInput } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { Userfeedback } from "../../store/actions/FeedbackAction";
import { isAuthenticated } from "../../utils/authService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  width: "100%",
  bgcolor: "background.paper",
  border: { xs: "40px solid #EBF8FF", md: "60px solid #EBF8FF" },
  boxShadow: 24,
  p: { xs: "20px 20px", md: "50px 80px" },
  m: "2px",
  borderRadius: "16px",
};

const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(String(email).toLowerCase());
};
const validatePhoneNumber = (phoneNumber) => {
  // Regular expression to match a phone number with optional country code
  const phoneRegex = /^\+?\d{0,3}?\d{9}$/; // Modify the regex based on your requirements
  return phoneRegex.test(phoneNumber);
};
export default function FeedbackModal() {
  const [open, setOpen] = React.useState(false);
  const navigation = useNavigate()
  const handleOpen = () => {
    if (isAuthenticated()) {
      setOpen(true);
    } else {
      // toast.error("Please Login!");
      navigation('/login');  // Replace '/login' with the actual login route
    }
  };
  
  const handleClose = () => setOpen(false);
  const [formData, setFormData] = React.useState({
    firstName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = React.useState({
    firstName: false,
    email: false,
    phone: false,
    message: false,
  });
  const text = {
    fontSize: "12px",
    color: "#64646D",
    fontWeight: "500",
  };
  const dispatch = useDispatch();
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    if (field === "email") {
      const isValidEmail = validateEmail(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: !isValidEmail,
      }));
    } else if (field === "phone") {
      const isValidPhoneNumber = validatePhoneNumber(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: !isValidPhoneNumber,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: false,
      }));
    }
  };

  const handleFeedbackSubmission = () => {
    let formErrors = {};

    // Check for empty fields and invalid email/phone
    if (!formData.firstName) {
      formErrors = { ...formErrors, firstName: true };
    }
    if (!formData.email || !validateEmail(formData.email)) {
      formErrors = { ...formErrors, email: true };
    }
    if (!formData.phone || !validatePhoneNumber(formData.phone)) {
      formErrors = { ...formErrors, phone: true };
    }
    if (!formData.message) {
      formErrors = { ...formErrors, message: true };
    }

    setErrors(formErrors);

    if (Object.keys(formErrors).length > 0) {
      return;
    }

    // Handle successful submission
    // ...
    const payload = {
      phone_number: formData.phone,
      email: formData.email,
      user_name: formData.firstName,
      message: formData.message,
    };
    const token = localStorage.getItem("authToken");
    dispatch(Userfeedback(payload, token));
    setFormData({
      firstName: "",
      email: "",
      phone: "",
      message: "",
    });

    setErrors({
      firstName: false,
      email: false,
      phone: false,
      message: false,
    });

    handleClose();
  };

  return (
    <div>
      <CustomButton onClick={handleOpen} pd="10px 80px">
        Leave feedback
      </CustomButton>{" "}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              position: "absolute",
              top: { xs: "-7%", md: "-9%" },
              right: { xs: "-12px", md: "-32px" },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>

          <Typography
            sx={{
              color: "#212121",
              fontSize: { xs: "25px", sm: "28px", lg: "32px" },
              lineHeight: "30px",
              fontWeight: 700,
              padding: "0px 0px 16px 0px",
              textAlign: "center",
            }}
          >
            Leave feedback
          </Typography>
          <Box display="flex" flexDirection="column" gap="12px">
            <Box>
              <Typography style={text} mb={"4px"} ml={{ md: "12px" }}>
                First Name*
              </Typography>
              <OutlinedInput
                placeholder="First Name"
                value={formData.firstName}
                onChange={(e) => handleInputChange("firstName", e.target.value)}
                sx={{
                  width: { xs: "100%" },
                  background: "#F8F8F8",
                  "& fieldset": {
                    border: errors.firstName
                      ? "2px solid red"
                      : "1px solid #4254ea33",
                  },
                  borderRadius: "8px",
                }}
                size="small"
                inputProps={{
                  style: { fontSize: "0.8rem" },
                }}
                error={errors.firstName}
              />
              {errors.firstName && (
                <Typography
                  color="error"
                  ml={{ md: "12px" }}
                  sx={{ fontSize: "12px" }}
                >
                  First Name is required
                </Typography>
              )}
            </Box>{" "}
            <Box>
              <Typography style={text} mb={"4px"} ml={{ md: "12px" }}>
                Email*
              </Typography>
              <OutlinedInput
                placeholder="Email"
                value={formData.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                sx={{
                  width: { xs: "100%" },
                  background: "#F8F8F8",
                  "& fieldset": {
                    border: errors.email
                      ? "2px solid red"
                      : "1px solid #4254ea33",
                  },
                  borderRadius: "8px",
                }}
                size="small"
                inputProps={{
                  style: { fontSize: "0.8rem" },
                }}
                error={errors.email}
              />
              {errors.email && (
                <Typography
                  color="error"
                  ml={{ md: "12px" }}
                  sx={{ fontSize: "12px" }}
                >
                  Please enter a valid email address"
                </Typography>
              )}
            </Box>{" "}
            <Box>
              <Typography style={text} mb={"4px"} ml={{ md: "12px" }}>
                Phone*
              </Typography>
              <OutlinedInput
                placeholder="Phone"
                value={formData.phone}
                onChange={(e) => handleInputChange("phone", e.target.value)}
                sx={{
                  width: { xs: "100%" },
                  background: "#F8F8F8",
                  "& fieldset": {
                    border: errors.phone
                      ? "2px solid red"
                      : "1px solid #4254ea33",
                  },
                  borderRadius: "8px",
                }}
                size="small"
                inputProps={{
                  style: { fontSize: "0.8rem" },
                }}
              />
              {errors.phone && (
                <Typography
                  color="error"
                  ml={{ md: "12px" }}
                  sx={{ fontSize: "12px" }}
                >
                  Please enter a valid phone number
                </Typography>
              )}
            </Box>
            <Box>
              <Typography style={text} mb={"4px"} ml={{ md: "12px" }}>
                Your message*
              </Typography>
              <OutlinedInput
                placeholder="Your message"
                value={formData.message}
                onChange={(e) => handleInputChange("message", e.target.value)}
                sx={{
                  width: { xs: "100%" },
                  background: "#F8F8F8",
                  "& fieldset": {
                    border: errors.message
                      ? "2px solid red"
                      : "1px solid #4254ea33",
                  },
                  borderRadius: "8px",
                }}
                size="small"
                inputProps={{
                  style: { fontSize: "0.8rem" },
                }}
                multiline
                rows={2}
              />
              {errors.message && (
                <Typography
                  color="error"
                  ml={{ md: "12px" }}
                  sx={{ fontSize: "12px" }}
                >
                  message is required
                </Typography>
              )}
            </Box>
            <Box mt="25px">
              <CustomButton
                onClick={handleFeedbackSubmission}
                width="100%"
                fs="18px"
              >
                Send feedback
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
