import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.svg";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { Box, Button, Typography, Container } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { getGiftCart } from "../../store/actions/AddToGiftCartAction";
import { isAuthenticated } from "../../utils/authService";
import { toast } from "react-toastify";
const ITEM_HEIGHT = 48;

const Navbar = ({ locale, handleChange }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isTableMode, setIsTableMode] = useState(false);
  const baseOptions = [
    { title: t("navbar.aboutUs"), path: "/about-us" },
    { title: `FAQ`, path: "/faq-section" },
    { title: t("navbar.contacts"), path: "/contact-us" },
  ];
  const [updatedOptions, setUpdatedOptions] = useState([...baseOptions]);
  const userType = localStorage.getItem("userType");
  const mobileOptions = [
    { title: `Lahjakamu ${t("navbar.card")}`, path: "/gift-card" },
    { title: t("navbar.partners"), path: "/all-partners" },
    { title: t("navbar.hws"), path: "/how-it-works" },
    { title: t("navbar.aboutUs"), path: "/about-us" },
    { title: `FAQ`, path: "/faq-section" },
    { title: t("navbar.contacts"), path: "/contact-us" },
    userType === "PM"
      ? { title: t("navbar.btntxt"), path: "/person-account/gift-card" }
      : null,
  ].filter(Boolean);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const storedLanguage = localStorage.getItem("i18nextLng");
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const userData = useSelector((state) => state.getUser.user);
  const cartData = useSelector((state) => state?.GetGiftCartReducer?.data);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setLanguageOpen(false);
  };
  const handleLanguageClick = () => {
    setLanguageOpen(!languageOpen);
  };
  const updateTableMode = () => {
    const screenWidth = window.innerWidth;
    const tableMode = screenWidth <= 1279;
    setIsTableMode(tableMode);

    setUpdatedOptions(
      tableMode
        ? [
            { title: `Lahjakamu ${t("navbar.card")}`, path: "/about-us" },
            { title: t("navbar.partners"), path: "/all-partners" },
            { title: t("navbar.hws"), path: "/how-it-works" },
            ...baseOptions,
          ]
        : baseOptions
    );
  };

  useEffect(() => {
    updateTableMode();
    window.addEventListener("resize", updateTableMode);
    return () => {
      window.removeEventListener("resize", updateTableMode);
    };
  }, []);

  const handleLanguageChange = (language) => {
    handleChange({ target: { value: language } });
    window.location.reload();
  };

  const handleNavigate = () => {
    if (userType === "PR") {
      isAuthenticated() ? navigate("/person-account") : navigate("/login");
    } else if (userType === "CM") {
      isAuthenticated() ? navigate("/company-account") : navigate("/login");
    }
  };

  useEffect(() => {
    if (token && userData && userData.data && userData.data.length > 0) {
      const userId = userData.data[0]._id;
      dispatch(getGiftCart(userId, token));
    }
  }, [token, userData, dispatch]);

  const sumOfAmounts =
    cartData && cartData?.length > 0
      ? cartData.reduce((accumulator, currentObject) => {
          const amount = parseInt(currentObject?.amount);
          return accumulator + amount;
        }, 0)
      : 0;

  return (
    <Box>
      <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          height="60px"
          backgroundColor="#fff"
          color="black"
          // position="fixed"
          // top="0"
          // left="0"
          zIndex="1"
          py={2}
          mb={3}
        >
          <Box
            width={{ tab: "88%", xs: "95%" }}
            margin="auto"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              onClick={() => navigate("/")}
              sx={{ "&:hover": { cursor: "pointer" } }}
              width={{ minWidth: "100px" }}
            >
              {/* logo */}
              <img
                src={logo}
                alt="logo"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              columnGap="30px"
              alignItems="center"
              zIndex="2"
              sx={{ display: { tab: "flex", xs: "none" }, cursor: "pointer" }}
            >
              {!isTableMode && (
                <>
                  <Typography
                    onClick={() =>
                      isAuthenticated()
                        ? navigate("/gift-card")
                        : toast.warn("Need to login, for access this page!")
                    }
                  >
                    Lahjakamu {t("navbar.card")}
                  </Typography>
                  <Typography
                    onClick={() =>
                      isAuthenticated()
                        ? navigate("/all-partners")
                        : toast.warn("Need to login, for access this page!")
                    }
                  >
                    {t("navbar.partners")}
                  </Typography>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/how-it-works")}
                  >
                    {t("navbar.hws")}
                  </Typography>
                </>
              )}
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  {!isTableMode && <MoreHorizIcon />}
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "auto",
                      borderRadius: "8px",
                    },
                  }}
                >
                  {updatedOptions.map((option) => (
                    <MenuItem
                      key={option.title}
                      selected={option.title === "Pyxis"}
                      onClick={handleClose}
                    >
                      <Link style={{ color: "#212121" }} to={option.path}>
                        {option.title}
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              columnGap={{ tab: "30px", xs: "10px" }}
            >
              <Box sx={{ display: { tab: "block", xs: "none" } }}>
                {/* button */}
                {userType === "PR" && (
                  <Button
                    variant="outlined"
                    onClick={() =>
                      isAuthenticated() && navigate("/person-account/gift-card")
                    }
                    sx={{
                      borderRadius: "0px 30px",
                      p: { lg: "8px 32px", md: "10px 20px", tab: "10px 10px" },
                      color: "#4254EA",
                      border: "1px solid #4254EA",
                      fontSize: {
                        md: "16px",
                        tab: storedLanguage === "fi" ? "11px" : "12px",
                      },
                    }}
                  >
                    {t("navbar.btntxt")}
                  </Button>
                )}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ display: { tab: "flex", xs: "none" }, cursor: "pointer" }}
              >
                {/* language */}
                <Typography
                  onClick={() => handleLanguageChange("en")}
                  sx={{
                    color:
                      storedLanguage === "en-US" || storedLanguage === "en"
                        ? "#212121"
                        : "#BABABA",
                  }}
                >
                  En
                </Typography>
                <Typography px={1}>|</Typography>
                <Typography
                  onClick={() => handleLanguageChange("fi")}
                  sx={{
                    color: storedLanguage === "fi" ? "#212121" : "#BABABA",
                  }}
                >
                  Fn
                </Typography>
              </Box>
              {/*  */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {/* logo and cart */}
                <Box px={2} sx={{ height: "24px" }}>
                  <AccountCircleOutlinedIcon onClick={handleNavigate} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderRadius: "6px",
                    background: "#F2F2F7",
                    alignItems: "center",
                    padding: "8px",
                  }}
                >
                  {sumOfAmounts === 0 || sumOfAmounts == null ? (
                    <ShoppingBagOutlinedIcon
                      onClick={() => isAuthenticated() && navigate("/add-cart")}
                      sx={{ cursor: "pointer" }}
                    />
                  ) : (
                    <ShoppingBagIcon
                      onClick={() => isAuthenticated() && navigate("/add-cart")}
                      sx={{ cursor: "pointer", color: "blue" }}
                    />
                  )}

                  <Typography px={1}>{sumOfAmounts}</Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                columnGap="30px"
                alignItems="center"
                zIndex="2"
                sx={{ display: { tab: "flex", xs: "none" } }}
              >
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    {isTableMode && <MenuIcon />}
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "auto",
                        borderRadius: "8px",
                      },
                    }}
                  >
                    {updatedOptions.map((option) => (
                      <MenuItem
                        key={option.title}
                        selected={option.title === "Pyxis"}
                        onClick={handleClose}
                      >
                        <Link style={{ color: "#212121" }} to={option.path}>
                          {option.title}
                        </Link>
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                columnGap="30px"
                alignItems="center"
                zIndex="2"
                sx={{ display: { tab: "none", xs: "block" } }}
              >
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "auto",
                        borderRadius: "8px",
                      },
                    }}
                    sx={{ display: { tab: "none", xs: "block" } }}
                  >
                    {mobileOptions.map((option) => (
                      <MenuItem
                        key={option.title}
                        selected={option.title === "Pyxis"}
                        onClick={handleClose}
                      >
                        <Link style={{ color: "#212121" }} to={option.path}>
                          {option.title}
                        </Link>
                      </MenuItem>
                    ))}
                    <MenuItem
                      onClick={handleLanguageClick}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>{t("navbar.lng")}</span>
                      <span>
                        {" "}
                        {languageOpen ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </span>
                    </MenuItem>
                    <Collapse in={languageOpen} timeout="auto" unmountOnExit>
                      <div>
                        <MenuItem onClick={handleClose}>
                          <Box
                            sx={{
                              color:
                                storedLanguage === "en-US" ||
                                storedLanguage === "en"
                                  ? "#212121"
                                  : "#BABABA",
                            }}
                            onClick={() => handleLanguageChange("en")}
                          >
                            English
                          </Box>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                          <Box
                            sx={{
                              color:
                                storedLanguage === "fi" ? "#212121" : "#BABABA",
                            }}
                            onClick={() => handleLanguageChange("fi")}
                          >
                            Finnish
                          </Box>
                        </MenuItem>
                      </div>
                    </Collapse>
                  </Menu>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Navbar;
