import { Box, Typography } from "@mui/material";
import React from "react";
import banner from "../../assets/howItWorks/header.svg";
import { useTranslation } from "react-i18next";
const Header = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection={{ md: "row", xs: "column" }}>
      <Box
        width={{ md: "45%", xs: "100%" }}
        sx={{
          padding: { lg: "150px 112px", sm: "100px 80px", xs: "30px 20px" },
          backgroundColor: "#F2F2F7",
        }}
      >
        <Typography
          sx={{
            color: "#212121",
            fontWeight: 700,
            fontSize: { xs: "28px", md: "40px" },
            maxWidth: { sm: "354px" },
            lineHeight: { xs: "32px", md: "56px" },
          }}
        >
          {t("howitworks.heading")}
        </Typography>
        <Typography
          py={1}
          sx={{
            color: "#212121",
            fontSize: { xs: "16px", lg: "18px" },
            fontWeight: 500,
            maxWidth: { xs: "auto", md: "384px" },
            fontFamily: "Montserrat",
          }}
        >
          {t("howitworks.subtxt")}
        </Typography>
      </Box>
      <Box
        width={{ md: "55%", xs: "100%" }}
        sx={{
          backgroundImage: `url(${banner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: { xs: "250px", tab: "300px", md: "auto" }, // Adjust minimum height for mobile view
        }}
      ></Box>
    </Box>
  );
};

export default Header;
