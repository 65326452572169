import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import { toast } from "react-toastify";
import {
  LOGIN_NUMBER_VARIFICATION,
  LOGIN_NUMBER_VARIFICATION_ERROR,
  LOGIN_NUMBER_VARIFICATION_SUCCESS,
} from "../types/NumberVarification";

function* numberVarificationSaga(action) {
  try {
    // Extract data from the action payload
    const { phone_number } = action.payload;

    // Construct the URL for number verification
    const url = `/auth/check-number/${phone_number}`;
    const response = yield call(baseURL.get, url);

    // Extract necessary information from the response
    const result = {
      status: response.data.statusCode,
      message: response.data.message,
    };

    // Dispatch success action with the result
    yield put({ type: LOGIN_NUMBER_VARIFICATION_SUCCESS, payload: result });
  } catch (error) {
    const errorMessage =
      error.response.data.message || "An unknown error occurred";
    const statusCode = error.response.status || "Unknown status";

    // Dispatch error action with the error payload
    yield put({
      type: LOGIN_NUMBER_VARIFICATION_ERROR,
      payload: { message: errorMessage, status: statusCode },
    });
    if (statusCode === 404 && errorMessage === "User not found") {
      toast.error(
        "This number is not registered. Please try logging in with a registered phone number."
      );
    }
  }
}

export default function* watchNumberVarificationSaga() {
  // Start the saga and listen for LOGIN_NUMBER_VARIFICATION actions
  yield takeLatest(LOGIN_NUMBER_VARIFICATION, numberVarificationSaga);
}
