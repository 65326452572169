import { Box, Container, OutlinedInput, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../global/CustomButton";
import CommonBreadcrumb from "../../global/CommonBreadcrumbs";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import OTPValidation from "./OTPValidation";
import ResetPassword from "./ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  forgetPasswordCompany,
  forgetPasswordSaveInfo,
} from "../../../store/actions/ForgotPasswordAction";
import { forgetpasswordsuccess } from "../../../utils/Constant";
import { useTranslation } from "react-i18next";

const PhoneValidation = () => {
  const txt = {
    color: "#64646D",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  };
  const navigation = useNavigate();
  const { t } = useTranslation();

  const path = [
    { path: "/login", label: t("giftcardpage.main") },
    { path: "/forgot-pwd", label: t("forgotPassword.fgPwd") },
  ];
  const [phoneNumber, setPhoneNumber] = useState({
    phone: "",
  });
  const [phoneError, setPhoneError] = useState("");
  const [stepChangePass, setStepChangePass] = useState({
    step1: true,
    step2: false,
    step3: false,
  });
  const dispatch = useDispatch();

  const otpsendsuccess = useSelector((state) => state.ForgetPasswordReducer);

  const handleFieldChange = (fieldName, value) => {
    if (fieldName === "phone") {
      value = `+${value}`;
    }

    setPhoneNumber({
      ...phoneNumber,
      [fieldName]: value,
    });
  };

  const handleNext = () => {
    let isValid = true;
    if (!phoneNumber.phone) {
      setPhoneError("Phone number cannot be empty");
      isValid = false;
    } else if (phoneNumber.phone.trim().length <= 8) {
      setPhoneError("Invalid Phone Number");
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (isValid) {
      const payload1 = {
        phone_number: phoneNumber.phone,
      };

      dispatch(forgetPasswordSaveInfo(payload1));
      dispatch(forgetPasswordCompany(payload1));
    }
  };

  useEffect(() => {
    if (otpsendsuccess?.data?.message === forgetpasswordsuccess) {
      toast.success("OTP send Successfully");
      setStepChangePass({
        step1: false,
        step2: true,
        step3: false,
      });
    }
  }, [otpsendsuccess]);

  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: 0, paddingRight: 0, background: "#F2F2F7" }}
    >
      <Box mx={"7%"}>
        <Box>
          <CommonBreadcrumb path={path} />
        </Box>
        <Box py={{ xs: "30px", tab: "60px" }}>
          <Box
            sx={{
              bgcolor: "#fff",
              maxWidth: "592px",
              width: "100%",
              padding: { xs: "30px 20px", tab: "40px 116px" },
              borderRadius: "16px",
              mx: "auto",
              mb: "24px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                color: "#212121",
                textAlign: "center",
                mb: "24px",
              }}
            >
              {t("forgotPassword.headingforgetpwd")}{" "}
            </Typography>
            {stepChangePass.step1 && (
              <Box>
                <Box>
                  <Box mb="24px">
                    <Typography
                      sx={{
                        color: "#212121",
                        textAlign: "center",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {t("forgotPassword.enterNum")}{" "}
                    </Typography>
                  </Box>
                  {/* <Box my="16px">
                    <Typography sx={txt}>Email</Typography>
                    <OutlinedInput
                      fullWidth
                      value={phoneNumber.email}
                      onChange={(e) => handleFieldChange("email", e)}
                      sx={{
                        width: { xs: "100%", tab: "355px" },
                        background: "#F8F8F8",
                        "& fieldset": {
                          border:
                            phoneError !== ""
                              ? "1px solid red"
                              : "1px solid #4254ea33",
                        },
                        borderRadius: "8px",
                      }}
                      placeholder="Enter Input"
                    />
                    <Typography color="error" sx={{ fontSize: "12px" }}>
                      {phoneError}
                    </Typography>
                  </Box> */}
                  <Box my="16px">
                    <Typography sx={txt}>
                      {t("loginRegister.enterPhnNumber")}
                    </Typography>
                    <Box display="flex" gap="8px">
                      <PhoneInput
                        country={"fi"}
                        enableSearch={true}
                        value={phoneNumber.phone}
                        inputStyle={{
                          width: "100%",
                          borderRadius: "8px",
                          border: phoneError
                            ? "1px solid red"
                            : "1px solid #4254ea33",
                          background: "#F8F8F8",
                        }}
                        onChange={(value) => handleFieldChange("phone", value)}
                      />
                    </Box>
                    <Box>
                      <Typography color="error" sx={{ fontSize: "12px" }}>
                        {phoneError}
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt="60px">
                    <CustomButton onClick={handleNext} width="100%" fs="18px">
                      {t("formLable.continue")}
                    </CustomButton>
                  </Box>
                </Box>
              </Box>
            )}
            {stepChangePass.step2 && (
              <OTPValidation
                stepChangePass={stepChangePass}
                setStepChangePass={setStepChangePass}
              />
            )}
            {stepChangePass.step3 && (
              <ResetPassword
                stepChangePass={stepChangePass}
                setStepChangePass={setStepChangePass}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default PhoneValidation;
