// TextList.js
import React from "react";
import { Grid, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";

const TextList = ({ handleTextSelection, index, cardDataInfo }) => {
  const { t } = useTranslation();
  const texts = [
    `${t("giftcardpage.message.hbt")}`,
    `${t("giftcardpage.message.yourareawesome")}`,
    `${t("giftcardpage.message.merryXmas")}`,
    `${t("giftcardpage.message.haveANiceDay")}`,
    `${t("giftcardpage.message.loveYou")}`,
    `${t("giftcardpage.message.justSmile")}`,
  ]; // Your list of texts

  const handleItemClick = (text) => {
    handleTextSelection(text, index);
  };

  return (
    <>
      <Typography
        sx={{ fontSize: "14px", fontWeight: "600" }}
        mt={"24px"}
        pb={1}
      >
        Text Greetings
      </Typography>
      <Grid
        container
        display="flex"
        flexWrap="wrap"
        gap=".5rem"
        alignItems="center"
      >
        {texts.map((text, inx) => (
          <Grid
            key={inx}
            item
            onClick={() => handleItemClick(text)}
            sx={{ display: "block" }}
          >
            <Typography
              sx={{
                display: "flex",
                color:
                  cardDataInfo[index].greeting_text === text
                    ? "#FFF"
                    : "#4254EA",
                background:
                  cardDataInfo[index].greeting_text === text
                    ? "#5AC8FA"
                    : "#fff",
                fontSize: "12px",
                padding: "4px 14px",
                alignItems: "center",
                borderRadius: "8px",
                cursor:"pointer"
              }}
            >
              <span>{text}</span>
              <span>
                {cardDataInfo[index].greeting_text === text && <CheckIcon />}
              </span>
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default TextList;
