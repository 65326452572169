import { INFO_DETAILS_COMPANY } from "../types/AfterLoginPartnerInfo";

  export const partnerDetailsInfo = (data,token) => {
      return {
        type: INFO_DETAILS_COMPANY,
        payload: {data,token},
      };
    };
  
    
  
  