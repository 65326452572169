import React, { useEffect, useState } from "react";
import { Box, Checkbox, OutlinedInput, Typography } from "@mui/material";
import CustomButton from "../global/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { companyInfoEdit } from "../../store/actions/EditCompanyInfoAction";
import { useTranslation } from "react-i18next";
const txt = {
  color: "#64646D",
  fontSize: "12px",
  fontWeight: 500,
  fontFamily: "Montserrat",
  opacity: ".8",
};

const CompanyAccount = () => {
  const [contactInfo, setContactInfo] = useState({
    user_name: "",
    email: "",
    phone_number: "",
    y_tunnus: "",
    terms_and_condition: true,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const userData = useSelector((state) => state?.getUser?.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const token = localStorage.getItem("authToken");
  const handleChange = (field, value) => {
    setContactInfo((prevContactInfo) => ({
      ...prevContactInfo,
      [field]: value,
    }));

    let errorMessage = "";

    // Real-time validation for phone_number
    if (field === "phone_number") {
      const phoneValidationRegex = /^\+\d{1,4}\s?\d{1,}$/;
      errorMessage = !phoneValidationRegex.test(value.trim())
        ? "Invalid phone number format"
        : "";
    }

    // Real-time validation for email
    if (field === "email") {
      const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      errorMessage = !emailValidationRegex.test(value.trim())
        ? "Invalid email format"
        : "";
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorMessage,
    }));
  };

  const validate = () => {
    const errors = {};

    // Example validation checks, customize based on your requirements
    if (!contactInfo.user_name.trim()) {
      errors.user_name = "Name is required";
    }

    if (!contactInfo.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactInfo.email)) {
      errors.email = "Invalid email format";
    }

    if (!contactInfo.phone_number.trim()) {
      errors.phone_number = "Phone Number is required";
    } else if (!/^\+\d{1,4}\s?\d{1,}$/.test(contactInfo.phone_number)) {
      errors.phone_number = "Invalid phone number format";
    }

    if (!contactInfo.terms_and_condition) {
      errors.marketing_permission = "You must agree to Marketing Permission";
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (
      userData &&
      userData?.data &&
      Array.isArray(userData.data) &&
      userData?.data.length > 0
    ) {
      const { user_name, email, phone_number, terms_and_condition, y_tunnus } =
        userData.data[0];

      // Extracting first name from the full name

      setContactInfo((prevContactInfo) => ({
        ...prevContactInfo,
        user_name: user_name || "",
        email: email || "",
        phone_number: phone_number || "", // Set to empty string if undefined
        terms_and_condition: terms_and_condition || "",
        y_tunnus: y_tunnus || "",
      }));
    } else {
      setContactInfo({
        user_name: "",
        email: "",
        phone_number: "",
        y_tunnus: "",
        terms_and_condition: true,
      });
    }
  }, [userData]);

  const handleSave =()=>{
    const isValid = validate();
    if (isValid){
   
      dispatch(companyInfoEdit(contactInfo, token, userData.data[0]._id));
    }
 
  }

  return (
    <Box>
      <Box>
        <Box>
          <Typography
            sx={{
              fontSize: "18px",
              color: "#212121",
              fontWeight: 600,
            }}
          >
            {t("navbar.contacts")}
          </Typography>
        </Box>
        <Box>
          <Box my="16px">
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap="8px"
            >
              <Box>
                <Typography sx={txt}>{t("sidebarView.CompanyName")}</Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    maxWidth: { sm: "240px" },
                    width: "100%",
                    background: "#F8F8F8",
                    "& fieldset": { border: "1px solid #4254ea33" },
                    borderRadius: "8px",
                  }}
                  placeholder="Enter Company name"
                  value={contactInfo.user_name}
                  onChange={(e) => handleChange("user_name", e.target.value)}
                />
                {validationErrors.user_name && (
                  <Typography
                    sx={{ color: "red", fontSize: "12px", ml: "12px" }}
                  >
                    {validationErrors.user_name}
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography sx={txt}>{t("sidebarView.ytunnus")}</Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    maxWidth: { sm: "240px" },
                    width: "100%",
                    background: "#F8F8F8",
                    "& fieldset": { border: "1px solid #4254ea33" },
                    borderRadius: "8px",
                  }}
                  placeholder="Enter Y-tunnus"
                  value={contactInfo.y_tunnus}
                  onChange={(e) => handleChange("y_tunnus", e.target.value)}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap="8px"
              mt="8px"
            >
              <Box>
                <Typography sx={txt}>{t("formLable.email")}</Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    maxWidth: { sm: "240px" },
                    width: "100%",
                    background: "#F8F8F8",
                    "& fieldset": { border: "1px solid #4254ea33" },
                    borderRadius: "8px",
                  }}
                  placeholder="Enter your Email"
                  value={contactInfo.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
                {validationErrors.email && (
                  <Typography
                    sx={{ color: "red", fontSize: "12px", ml: "12px" }}
                  >
                    {validationErrors.email}
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography sx={txt}>{t("sidebarView.ContactPhone")}</Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    maxWidth: { sm: "240px" },
                    width: "100%",
                    background: "#F8F8F8",
                    "& fieldset": { border: "1px solid #4254ea33" },
                    borderRadius: "8px",
                  }}
                  placeholder="Enter your phone number"
                  value={contactInfo.phone_number}
                  onChange={(e) => handleChange("phone_number", e.target.value)}
                />
                {validationErrors.phone_number && (
                  <Typography
                    sx={{ color: "red", fontSize: "12px", ml: "12px" }}
                  >
                    {validationErrors.phone_number}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Box my="16px">
            <Box
              display="flex"
              alignItems="center"
              justifyItems="self-start"
              gap="5px"
              mt="20px"
            >
              <Checkbox
                sx={{
                  padding: "0px",
                  color: "#4254EA",
                  "&.Mui-checked": {
                    color: "#4254EA",
                  },
                }}
                defaultChecked={contactInfo.terms_and_condition}
                onChange={(e) =>
                  handleChange("terms_and_condition", e.target.checked)
                }
              />
              <Typography
                sx={{ color: "#212121", fontSize: "14px", fontWeight: 500 }}
              >
                {t("partnercardpage.agreewith")}{" "}
                <span style={{ textDecoration: "underline" }}>
                {t("partnercardpage.tandc")}
                </span>
              </Typography>
            </Box>
            {validationErrors.terms_and_condition && (
              <Typography sx={{ color: "red", fontSize: "12px", ml: "12px" }}>
                {validationErrors.terms_and_condition}
              </Typography>
            )}
            <Box mt="35px" sx={{ maxWidth: { sm: "240px" } }}>
              <CustomButton
                onClick={handleSave}
                fs="18px"
                width={{ xs: "100%" }}
              >
                {t("sidebarView.save")}
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyAccount;
