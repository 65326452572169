import { Box, OutlinedInput, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import CustomButton from "../global/CustomButton";
import PwdReset from "./Company/PwdReset";
import DigitalSignature from "./Company/DigitalSignature";
import ProgressStepper from "../Checkout/ProgressStepper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkEmail } from "../../store/actions/ExistEmailCheckAction";

const CompanyRegister = ({ setViewtab }) => {
  const txt = {
    color: "#64646D",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    ml: "12px",
  };
  const [openAccordion, setOpenAccordion] = useState([1]);
  const [nextphase, setNextPhase] = useState({
    step1: true,
    step2: false,
    step3: false,
  });
  const [companyDetails, setCompanyDetails] = useState({
    company_name: "",
    phone_number: "",
    email: "",
    password: "",
    y_tunnus: "",
    contract_sign: "",
    user_type: "company",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const EmailCheck = useSelector((state) => state.checkEmailStatus.data);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Basic validation check
    let error = "";
    if (name === "email") {
      if (!isValidEmail(value)) {
        error = "Invalid email address";
      }
    }

    setCompanyDetails({
      ...companyDetails,
      [name]: value,
    });

    setValidationErrors({
      ...validationErrors,
      [name]: error,
    });
  };

  const isValidEmail = (email) => {
    // Add your email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleChangephone = (e) => {
    const phoneNumber = e.trim(); // Trim any leading or trailing spaces

    setCompanyDetails({
      ...companyDetails,
      phone_number: `+${phoneNumber}`,
    });

    // Basic validation check for phone number
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      phone_number: isValidPhoneNumber(phoneNumber)
        ? ""
        : "Invalid phone number",
    }));
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // Use a regular expression to validate the phone number format
    // Adjust the regular expression based on the required format for your application
    const phoneRegex = /^[0-9+]*$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleNext = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const emailCheck = {
        email: companyDetails.email,
      };
      dispatch(checkEmail(emailCheck));
    } else {
      setValidationErrors(errors);
    }
  };

  const handleCheck = () => {
    setViewtab(false);
    setNextPhase({ step1: false, step2: true, step3: false });
    setOpenAccordion([1, 2]);
  };

  useEffect(() => {
    if (EmailCheck && EmailCheck?.status === 200) {
      handleCheck();
    }
  }, [EmailCheck]);

  const validateForm = () => {
    const errors = {};

    // Basic validation checks
    if (!companyDetails.company_name.trim()) {
      errors.company_name = "Company name is required";
    }

    if (!companyDetails.phone_number.trim()) {
      errors.phone_number = "Phone number is required";
    }

    if (!isValidEmail(companyDetails.email)) {
      errors.email = "Invalid email address";
    }

    if (!companyDetails.y_tunnus.trim()) {
      errors.y_tunnus = "Y-tunnus is required";
    }

    // Add more validation checks for other fields as needed

    return errors;
  };

  return (
    <Box mt="23.5px">
      <ProgressStepper openAccordion={openAccordion} />
      {nextphase.step1 === true && (
        <Box>
          <Box my="16px">
            <Typography sx={txt}>Your Name*</Typography>
            <OutlinedInput
              fullWidth
              sx={{
                width: { xs: "100%", tab: "355px" },
                background: "#F8F8F8",
                "& fieldset": {
                  border: `1px solid ${
                    validationErrors.company_name ? "red" : "#4254ea33"
                  }`,
                },
                borderRadius: "8px",
              }}
              placeholder="Enter your Name"
              name="company_name"
              value={companyDetails.company_name}
              onChange={handleChange}
              error={!!validationErrors.company_name}
            />
            <Box sx={{ fontSize: "12px", color: "red" }}>
              {validationErrors.company_name}
            </Box>
          </Box>

          <Box my="16px">
            <Typography sx={txt}>Enter your phone number*</Typography>
            <PhoneInput
              inputStyle={{
                width: "100%",
                borderRadius: "8px",
                border: validationErrors.phone_number
                  ? "1px solid red"
                  : "1px solid #4254ea33",
                background: "#F8F8F8",
              }}
              country={"fi"}
              enableSearch={true}
              name="phone_number"
              value={companyDetails.phone_number}
              onChange={handleChangephone}
            />
            <Box sx={{ fontSize: "12px", color: "red" }}>
              {validationErrors.phone_number}
            </Box>
          </Box>
          <Box my="16px">
            <Typography sx={txt}>Email*</Typography>
            <OutlinedInput
              fullWidth
              sx={{
                width: { xs: "100%", tab: "355px" },
                background: "#F8F8F8",
                "& fieldset": {
                  border: `1px solid ${
                    validationErrors.email ? "red" : "#4254ea33"
                  }`,
                },
                borderRadius: "8px",
              }}
              placeholder="Enter your Email"
              name="email"
              value={companyDetails.email}
              onChange={handleChange}
            />
            <Box sx={{ fontSize: "12px", color: "red" }}>
              {validationErrors.email}
            </Box>
          </Box>
          <Box my="16px">
            <Typography sx={txt}>Y-tunnus*</Typography>
            <OutlinedInput
              fullWidth
              sx={{
                width: { xs: "100%", tab: "355px" },
                background: "#F8F8F8",
                "& fieldset": {
                  border: `1px solid ${
                    validationErrors.y_tunnus ? "red" : "#4254ea33"
                  }`,
                },
                borderRadius: "8px",
              }}
              placeholder="Enter your Email"
              name="y_tunnus"
              value={companyDetails.y_tunnus}
              onChange={handleChange}
            />
            <Box sx={{ fontSize: "12px", color: "red" }}>
              {validationErrors.y_tunnus}
            </Box>
          </Box>

          <Box my="33.5px">
            <CustomButton onClick={handleNext} width="100%" fs="18px">
              Continue
            </CustomButton>
            <Typography
              sx={{
                color: "#212121",
                fontFamily: "Montserrat",
                fontSize: "18px",
                fontWeight: 700,
                my: "27.5px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => navigation("/login")}
            >
              Login
            </Typography>
          </Box>
        </Box>
      )}
      {nextphase.step2 === true && (
        <PwdReset
          setNextPhase={setNextPhase}
          setViewtab={setViewtab}
          setOpenAccordion={setOpenAccordion}
          handleChange={handleChange}
          companyDetails={companyDetails}
          setCompanyDetails={setCompanyDetails}
        />
      )}
      {nextphase.step3 === true && (
        <DigitalSignature
          setNextPhase={setNextPhase}
          setOpenAccordion={setOpenAccordion}
          handleChange={handleChange}
          companyDetails={companyDetails}
          setCompanyDetails={setCompanyDetails}
        />
      )}
    </Box>
  );
};

export default CompanyRegister;
