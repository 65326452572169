// src/sagas/giftCardSaga.js
import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import {
  searchGiphyStickersFailure,
  searchGiphyStickersSuccess,
} from "../actions/GifiSearchAction";
import {
  BIRTHDAY_STICKERS_FAILURE,
  BIRTHDAY_STICKERS_REQUEST,
  BIRTHDAY_STICKERS_SUCCESS,
  NEW_YEAR_STICKERS_FAILURE,
  NEW_YEAR_STICKERS_REQUEST,
  NEW_YEAR_STICKERS_SUCCESS,
  SEARCH_GIPHY_STICKERS_REQUEST,
  WEDDING_STICKERS_FAILURE,
  WEDDING_STICKERS_REQUEST,
  WEDDING_STICKERS_SUCCESS,
} from "../types/GifiSearchType";

function* searchGiphyStickersSaga(action) {
  try {
    // Construct the URL with a default value if action.payload is not present
    const response = yield call(() =>
      baseURL.get(`/gift-card/giphy-stickers?search=${action.payload}&limit=50`)
    );
    yield put(searchGiphyStickersSuccess(response.data));
  } catch (error) {
    yield put(searchGiphyStickersFailure(error));
  }
}

function* BirthdaySaga(action) {
  try {
    // Construct the URL with a default value if action.payload is not present
    const { query, limit } = action.payload;
    const response = yield call(() =>
      baseURL.get(`/gift-card/giphy-stickers?search=${query}&limit=${limit}`)
    );
    yield put({ type: BIRTHDAY_STICKERS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({
      type: BIRTHDAY_STICKERS_FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
}

function* WeddingSaga(action) {
  try {
    // Construct the URL with a default value if action.payload is not present
    const { query, limit } = action.payload;
    const response = yield call(() =>
      baseURL.get(`/gift-card/giphy-stickers?search=${query}&limit=${limit}`)
    );
    yield put({ type: WEDDING_STICKERS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({
      type: WEDDING_STICKERS_FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
}

function* NewYearSaga(action) {
  try {
    // Construct the URL with a default value if action.payload is not present
    const { query, limit } = action.payload;
    const response = yield call(() =>
      baseURL.get(`/gift-card/giphy-stickers?search=${query}&limit=${limit}`)
    );;
    yield put({ type: NEW_YEAR_STICKERS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({
      type: NEW_YEAR_STICKERS_FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
}

export default function* watchgiftCardSaga() {
  yield takeLatest(SEARCH_GIPHY_STICKERS_REQUEST, searchGiphyStickersSaga);
  yield takeLatest(BIRTHDAY_STICKERS_REQUEST, BirthdaySaga);
  yield takeLatest(NEW_YEAR_STICKERS_REQUEST, NewYearSaga);
  yield takeLatest(WEDDING_STICKERS_REQUEST, WeddingSaga);
}
