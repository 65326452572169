import { Box, Typography } from "@mui/material";
import React from "react";
import CustomButton from "../global/CustomButton";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import whatsp from "../../assets/contactus/whatspp.svg";
import mess from "../../assets/contactus/message.svg";
import FeedbackModal from "./FeedbackModal";
import { useNavigate } from "react-router-dom";
const ContactBlock = () => {
  const heading = {
    color: "#212121",
    fontFamily: "Montserrat",
    fontSize: { xs: "25px", sm: "28px", lg: "32px" },
    fontWeight: 700,
    lineHeight: "30px",
  };

  const subheading = {
    color: " #212121",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: { xs: "14px", sm: "16px" },
  };

  const navigation = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={{ xs: "column", tab: "row" }}
      gap="32px"
      my={"80px"}
      mx="12px"
    >
      <Box
        sx={{
          background: "#D8F1FD",
          padding: { xs: "25px", sm: "40px", md: "60px" },
          borderRadius: "30px",
          maxWidth: "592px",
          width: "100%",
          height: { xs: "auto", sm: "350px", md: "393px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography sx={heading}>Have a question?</Typography>
          <Typography sx={subheading} mt={"24px"} style={{ maxWidth: "428px" }}>
            Check out our detailed guide on how the service works.
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "flex-end" }} mt="30px">
          <CustomButton
            onClick={() => {
              navigation("/how-it-works");
            }}
            pd="10px 80px"
          >
            How it works
          </CustomButton>
        </Box>
      </Box>
      <Box
        sx={{
          background: "#D8F1FD",
          padding: { xs: "25px", sm: "40px", md: "60px" },
          borderRadius: "30px",
          maxWidth: "592px",
          width: "100%",
          height: { xs: "auto", sm: "350px", md: "393px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography sx={heading}>Contacts</Typography>
          <Box mt={"24px"} display="flex" alignItems="center" gap="10px">
            <CallOutlinedIcon />{" "}
            <Typography sx={subheading}>
              +358 7 789 123 (from 9 am to 6 pm)
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap="10px" my={"12px"}>
            <MailOutlineIcon />
            <Typography sx={subheading} style={{ wordBreak: "break-all" }}>
              lahjakamucontacts@mail.com
            </Typography>
          </Box>

          <Box display="flex" gap="12px">
            <img src={whatsp} alt="social media" />
            <img src={mess} alt="social media" />
          </Box>
        </Box>

        <Box mt="30px">
          <FeedbackModal />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactBlock;
