import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import GiftItems from "../../components/Home/GiftItems";
import CardBanner from "../../components/Home/CardBanner";
import Header from "../../components/Home/Header";
import ItemsCategories from "../../components/Home/ItemsCategories";
import HomeFAQ from "../../components/Home/HomeFAQ";
import { jwtDecode } from "jwt-decode";
import { setTokenToLocalStorage } from "../../utils/authService";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleUserRequest } from "../../store/actions/GetUserAction";

const Home = () => {
  const [decodedToken, setDecodedToken] = useState(null);
  const dispatch = useDispatch();

  // const userData = useSelector((state) => state.getUser.user);

  useEffect(() => {
    // Get the token from localStorage
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (token) {
      // localStorage.setItem("authToken", token);
      setTokenToLocalStorage(token);
    }

    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      try {
        // Decode the token
        const decoded = jwtDecode(storedToken);
        // Set the decoded token in state
        setDecodedToken(decoded);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken && decodedToken !== "" && decodedToken?.user_id) {
      const userId = decodedToken?.user_id;
      const token = storedToken;
      dispatch(fetchSingleUserRequest(userId, token));
    }
  }, [decodedToken]);

  return (
    <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Header />
      <GiftItems />
      <ItemsCategories />
      <CardBanner />
      <HomeFAQ />
    </Container>
  );
};

export default Home;
