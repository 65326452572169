import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../global/CustomButton";
import { MuiOtpInput } from "mui-one-time-password-input";
import { styled } from "@mui/system";
import CommonBreadcrumb from "../../global/CommonBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  checkregisterotp,
  registerPerson,
  registerotp,
  resendRegisterOtp,
} from "../../../store/actions/RegisterPersonAction";
import Loadingbar from "../../../utils/Loadingbar";

const MuiOtpInputStyled = styled(MuiOtpInput)({
  "& .MuiOtpInput-TextField": {
    background: "#F2F2F7",
    border: "none",
    width: "48px",
    height: "48px",
    textAlign: "center",
  },
  "& fieldset": { border: "none" },
});

const path = [
  { path: "/", label: "Main" },
  { path: "/person-register/otp", label: "Register person " },
];
const RegisterOTP = () => {
  const [otp, setOtp] = React.useState("");
  const [timer, setTimer] = useState(60); // Initial timer value in seconds
  const [resendText, setResendText] = useState("Resend Code");
  const [otpError, setOtpError] = useState(""); // State for OTP error message
  const [flagstatus, setFlagStatus] = useState(false); // State for OTP error message
  const dispatch = useDispatch();
  const success = useSelector(
    (state) => state.personOtpRegister.registerdataOTP
  );
  const resendSuccess = useSelector((state) => state.resendOtpRegPer.data);
  const personInfo = useSelector((state) => state.personOtpRegister.data);
  const registersuccess = useSelector((state) => state.personRegister.success);
  const otpsuccess = useSelector((state) => state.checkOptPerReg);
  const navigation = useNavigate();

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setResendText("Resend Code");
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);
  const handleNext = () => {
    if (otp === "") {
      setOtpError("OTP cannot be empty");
    } else if (otp.length !== 4) {
      setOtpError("OTP should be 4 characters long");
    } else {
      setOtpError("");
      const otpnum = parseInt(otp);
      const payload = {
        phone_number: personInfo.phone_number,
        otp: otpnum,
      };
      dispatch(checkregisterotp(payload));
    }
  };
  const handleResendClick = () => {
    setTimer(10); // Reset the timer
    setResendText("Resending..."); // Update text while resending
    const payload = {
      phone_number: personInfo.phone_number,
    };
    dispatch(resendRegisterOtp(payload));
  };

  useEffect(() => {
    if (otpsuccess?.data?.status  === 200) {
      setTimer(0);
      dispatch(registerPerson(personInfo));
    }
  }, [otpsuccess]);

  useEffect(() => {
    if (Object.keys(success).length === 0 && success.constructor === Object) {
      navigation("/register");
    }
  }, [success]);

  if(registersuccess){
    navigation("/person-register/success-register");
  }
  
  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: 0, paddingRight: 0, background: "#F2F2F7" }}
    >
      {Object.keys(success).length !== 0 ? (
        <Box mx={"7%"}>
          <Box>
            <CommonBreadcrumb path={path} />
          </Box>
          <Box py={{ xs: "30px", tab: "60px" }}>
            <Box
              sx={{
                bgcolor: "#fff",
                maxWidth: "592px",
                width: "100%",
                padding: { xs: "30px 20px", tab: "40px 116px" },
                borderRadius: "16px",
                mx: "auto",
                mb: "24px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 700,
                  color: "#212121",
                  textAlign: "center",
                  mb: "24px",
                }}
              >
                Welcome to Lahjakamu 👋🏻
              </Typography>
              <Box>
                <Box>
                  <Box mb="24px">
                    <Typography
                      sx={{
                        color: "#212121",
                        textAlign: "center",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Enter the 4 digital code we send. Confirm registration!
                    </Typography>
                  </Box>
                  <Box my="16px">
                    <Box display="flex" justifyContent="center">
                      <MuiOtpInputStyled value={otp} onChange={handleChange} autoFocus/>
                    </Box>
                    <Typography
                      color="red"
                      sx={{ fontSize: "12px", textAlign: "center", mt: "10px" }}
                    >
                      {otpError}
                    </Typography>
                    <Typography
                      mt="32px"
                      sx={{
                        color: timer > 0 ? "#BABABA" : "#212121",
                        fontSize: "12px",
                        fontWeight: 500,
                        fontFamily: "Montserrat",
                        textAlign: "center",
                        textDecoration: !timer > 0 && "underline",
                      }}
                    >
                      {timer > 0 ? (
                        `Resend SMS Code in ${timer} seconds`
                      ) : (
                        <span onClick={handleResendClick}>{resendText}</span>
                      )}
                    </Typography>
                  </Box>
                  <Box mt="32px">
                    <CustomButton onClick={handleNext} width="100%" fs="18px">
                      Continue
                    </CustomButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Loadingbar />
        </Box>
      )}
    </Container>
  );
};

export default RegisterOTP;
