import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import ActivePage from "./GiftCardComponent/ActivePage";
import CheckPage from "./GiftCardComponent/CheckPage";
import { useTranslation } from "react-i18next";

const GiftCardAccount = () => {
  const [logintype, setLogintype] = useState("Activate");
  const { t } = useTranslation();

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            color: "#212121",
            fontWeight: 600,
            maxWidth: { xs: "220px", sm: "354px" },
            width: "100%",
          }}
        >
          {t("sidebarView.gfHead")}
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            mt: "18px",
          }}
        >
          <Box
            sx={{
              background: "#7676801f",
              maxWidth: "385px",
              width: "100%",
              padding: "2px",
              borderRadius: "8px",
            }}
            display="flex"
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                background: logintype === "Activate" ? "#FFF" : "transparent",
                maxWidth: "192.5px",
                width: "100%",
                textAlign: "center",
                padding: "6px",
                borderRadius: "7px",
              }}
              onClick={() => setLogintype("Activate")}
            >
              {t("sidebarView.Activate")}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                background: logintype === "Check" ? "#FFF" : "transparent",
                maxWidth: "192.5px",
                width: "100%",
                textAlign: "center",
                padding: "6px",
                borderRadius: "7px",
              }}
              onClick={() => setLogintype("Check")}
            >
              {t("sidebarView.Check")}
            </Typography>
          </Box>
          <Box mt="18px">
            {logintype === "Activate" ? <ActivePage /> : <CheckPage setLogintype={setLogintype} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GiftCardAccount;
