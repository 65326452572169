import {
  LOGIN_COMPANY,
  LOGIN_COMPANY_FAILED,
  LOGIN_COMPANY_SUCCESS,
  LOGOUT_COMPANY,
} from "../types/LoginCompanyType";

const INITIAL_STATE = {
  loginData: {},
  islogindata: false,
};

export function LoginCompanyReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_COMPANY:
      return {
        ...state,
        islogindata: false,
      };

    case LOGIN_COMPANY_SUCCESS:
      return {
        ...state,
        islogindata: true,
        loginData: action.payload,
      };

    case LOGIN_COMPANY_FAILED:
      return {
        ...state,
        islogindata: false,
      };

    // logout
    case LOGOUT_COMPANY:
      return {
        ...state,
        isLoggedIn: false,
      };

    default:
      return state;
  }
}
