import { LOGIN_NUMBER_VARIFICATION, LOGIN_NUMBER_VARIFICATION_ERROR, LOGIN_NUMBER_VARIFICATION_SUCCESS } from "../types/NumberVarification";

  const initialState = {
    loading: false,
    error: null,
    data: {},
  };
  
  // The reducer function
  const NumberVarificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN_NUMBER_VARIFICATION:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case LOGIN_NUMBER_VARIFICATION_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload, // Update the customer data with the payload
          error: null,
        };
      case LOGIN_NUMBER_VARIFICATION_ERROR:
        return {
          ...state,
          loading: false,
          data: null,
          error: action.payload, // Set the error with the payload
        };
      default:
        return state;
    }
  };
  
  export default NumberVarificationReducer;
  