import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, MenuList } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { styled } from "@mui/system";
import { filterRequest } from "../../store/actions/PartnerFilterAction";
import { useDispatch } from "react-redux";
import {
  FilterCategories,
  FilterCity,
  FilterNominal,
  FilterWhom,
} from "../../utils/Constant";
import { useTranslation } from "react-i18next";
const SelectWithoutPadding = styled(Select)({
  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
    {
      paddingRight: "10px !important",
    },
  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
    width: "auto",
  },
});

const FilterSelect = ({ id, label, value, onChange, items }) => {

  const formControlStyle = {
    margin: "8px",
    minWidth: "120px",
    maxWidth: "300px",
  };
  const StyledMenuList = styled(MenuList)({
    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",    
  });
  return (
    <FormControl style={formControlStyle}>
      <SelectWithoutPadding
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
              marginTop: 14,
              boxShadow: "none",
              overflow: "hidden",
              overflowY: "scroll",
            },
          },
          MenuListProps: {
            component: StyledMenuList,
          },
        }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          paddingRight: 0,
          color: "#212121",
          fontWeight: 600,
        }}
        labelId="category-select-label"
        id="category-select"
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        multiple
        value={value}
        IconComponent={() => (
          <ExpandMoreIcon
            sx={{
              color: "#212121",
              fontWeight: 900,
            }}
          />
        )}
        onChange={onChange}
        renderValue={(selected) =>
          `${label} ${selected.length > 0 ? `(${selected.length})` : ""}`
        }
      >
        {items.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox
              checked={value.includes(item)}
              sx={{
                "&.Mui-checked": {
                  color: "#4254EA",
                },
              }}
              icon={
                <CheckBoxOutlineBlankIcon
                  fontSize="small"
                  sx={{
                    background: "#00000012",
                    fill: "#00000003",
                    cursor: "pointer",
                  }}
                />
              }
            />
            <ListItemText
              primary={item}
              primaryTypographyProps={{
                style: { fontSize: "13px", color: "#212121", fontWeight: 500 },
              }}
            />
          </MenuItem>
        ))}
      </SelectWithoutPadding>
    </FormControl>
  );
};
const PartnersFilter = ({
  selectedCategories,
  setSelectedCategories,
  selectedValue,
  setSelectedValue,
  selectedWhom,
  setSelectedWhom,
  selectedCity,
  setSelectedCity,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const handleCategoryChange = (event) => {
    setSelectedCategories(event.target.value);
  };

  const handleWhomChange = (event) => {
    setSelectedWhom(event.target.value);
  };
  const handleValueChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleReset = () => {
    setSelectedCategories([]);
    setSelectedValue([]);
    setSelectedWhom([]);
    setSelectedCity([]);
    dispatch(filterRequest(''));
  };

  const handleApplyFilter = () => {
    const payload = {
      category: selectedCategories,
      whom: selectedWhom,
      nominal: selectedValue,
      city: selectedCity,
    };
    dispatch(filterRequest(payload));
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection={{ xs: "column-reverse", tab: "row" }}
    >
      <Box
        display="flex"
        gap={{ md: "10px", lg: "60px" }}
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          maxWidth:
            selectedCategories.length > 0 ||
            selectedValue.length > 0 ||
            selectedWhom.length > 0 ||
            selectedCity.length
              ? { xs: "200px", sm: "none", tab: "400px", md: "none" }
              : { xs: "250px", sm: "none" },
          overflow: "hidden",
          overflowX: { xs: "scroll", md: "auto" },
        }}
      >
        <FilterSelect
          id="category-select"
          label={t("partnersFilter.Category")}
          value={selectedCategories}
          onChange={handleCategoryChange}
          items={FilterCategories}
        />
        <FilterSelect
          id="whom-select"
          label={t("partnersFilter.Whom")}
          value={selectedWhom}
          onChange={handleWhomChange}
          items={FilterWhom}
        />
        <FilterSelect
          id="value-select"
          label={t("partnersFilter.Value")}
          value={selectedValue}
          onChange={handleValueChange}
          items={FilterNominal}
        />
        <FilterSelect
          id="city-select"
          label={t("partnersFilter.City")}
          value={selectedCity}
          onChange={handleCityChange}
          items={FilterCity}
        />
      </Box>
      <Box>
        {selectedCategories.length > 0 ||
        selectedValue.length > 0 ||
        selectedWhom.length > 0 ||
        selectedCity.length ? (
          <Box
            display="flex"
            //  flexDirection={{xs:"column",tab:"row"}}
            gap="8px"
          >
            <Button
              onClick={handleReset}
              sx={{
                background: "#4254ea33",
                padding: {
                  lg: "16px 40px",
                  md: "10px 8px",
                  tab: "8px 8px",
                  xs: "8px 12px",
                },
                borderRadius: "16px",
                fontSize: "14px",
                fontWeight: "600",
                color: "#4254EA",
                textTransform: "none",
              }}
            >
              Reset filter (
              {selectedCategories.length +
                selectedCity.length +
                selectedValue.length +
                selectedWhom.length}
              )
            </Button>
            <Button
              sx={{
                background: "#4254EA",
                padding: { lg: "16px 40px", md: "10px 20px", xs: "8px 15px" },
                borderRadius: "16px",
                fontSize: "14px",
                fontWeight: "600",
                color: "#fff",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#4254EA",
                },
                "&:active": {
                  backgroundColor: "#4254EA",
                },
              }}
              onClick={handleApplyFilter}
            >
              {t("checkoutpage.Apply")}
            </Button>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default PartnersFilter;
