import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { faqArray } from "../Home/Data";
const StyledAccordion = styled(Accordion)({
  borderBottom: "none",
  background: "#FFF",
  padding: "10rem 0rem",
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
  "&.MuiAccordion-root:first-of-type": {
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
  },
  "&.MuiAccordion-root:last-of-type": {
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
  },
  // Add border when expanded
  border: (props) => (props.expanded ? "1px solid red" : "none"),
});

const StyledAccordionSummary = styled(AccordionSummary)({
  boxShadow: "none",
});

const FaqAccordion = ({title}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      <Typography
        sx={{
          color: "#212121",
          fontSize: { xs: "28px", sm: "32px" },
          fontWeight: 700,
        }}
        my={'20px'}
      >
       {title}
      </Typography>
      <Box>
        {faqArray.map((item, index) => (
          <StyledAccordion
            key={index}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
            disableGutters={true}
            sx={{
              maxWidth: "850px",
              borderRadius: expanded === `panel${index + 1}` ? "16px" : "none",
              border:
                expanded === `panel${index + 1}` ? "1px solid #4254EA" : "none",
              padding:
                expanded === `panel${index + 1}`
                  ? { tab: "20px", xs: "10px 5px" }
                  : { tab: "10px 20px 10px 20px", xs: "10px 5px 10px 5px" },
            }}
          >
            <StyledAccordionSummary
              expandIcon={
                expanded === `panel${index + 1}` ? (
                  <RemoveIcon
                    sx={{
                      color: "#FFF",
                      background: "#4254EA",
                      borderRadius: "30px",
                    }}
                  />
                ) : (
                  <AddIcon
                    sx={{
                      color: "#FFF",
                      background: "#4254EA",
                      borderRadius: "30px",
                    }}
                  />
                )
              }
              aria-controls={`panel${index + 1}a-content`}
              id={`panel${index + 1}a-header`}
            >
              <Typography
                sx={{
                  color: "#212121",
                  fontWeight: "bold",
                  marginRight: { xs: ".8rem", tab: "3rem" },
                }}
              >
                {item.question}
              </Typography>
            </StyledAccordionSummary>
            <AccordionDetails sx={{ marginRight: { tab: "7rem" } }}>
              <Typography sx={{ fontSize: ".9rem" }}>{item.answer}</Typography>
            </AccordionDetails>
          </StyledAccordion>
        ))}
      </Box>
    </Box>
  );
};

export default FaqAccordion;
