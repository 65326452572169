import { Link, useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Breadcrumbs, Typography } from "@mui/material";

const CommonBreadcrumb = ({ path }) => {
  const location = useLocation();
  return (
    <Box>
      <Breadcrumbs
        separator={<ArrowForwardIosIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ color: "#BABABA" }}
        py={2}
      >
        {path.map((item, index) => (
          <Typography key={index}>
            <Link
              to={item.path}
              style={{
                color: location.pathname === item.path ? "black" : "#BABABA",
              }}
            >
              {item.label}
            </Link>
          </Typography>
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default CommonBreadcrumb;
