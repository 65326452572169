import React from "react";
import { Typography, Box } from "@mui/material";
import bg from "../../assets/cards/pricebg.svg";

const GiftCardView = ({ cardData, results, resultsPaid }) => {
  let dummy = cardData.type_of_card === "free" ? results[0]?.url : resultsPaid[0]?.url;

  return (
    <Box
      sx={{
        maxWidth: "450px",
        width: "auto",
        height: "auto",
        background: "#fff",
        borderRadius: "16px",
        textAlign: "center",
        py: { sm: "20px", xs: "15px" },
        px: { sm: "30px", xs: "15px" },
        mx: "auto",
      }}
    >
      <Box>
        <img
          src={cardData.image ? cardData.image : dummy}
          alt="card-img"
          style={{
            width: "100%",
            height: "auto",
            aspectRatio: "4/4",
            objectFit: "contain",
          }}
        />
      </Box>
      <Box
        sx={{
          borderRadius: "6px",
          background: "#D9DCF9",
          maxWidth: "408px",
          height: "auto",
          padding: "1.5rem",
          mx: "auto",
        }}
        mb={1}
      >
        <Typography textAlign="center" sx={{ wordBreak: "break-all" }}>
          {cardData.greeting_text ? cardData.greeting_text : "Happy New Year ✨"}
        </Typography>
      </Box>
      <Box>
        <Box sx={{ position: "relative" }}>
          <img
            src={bg}
            alt="card-img"
            style={{ maxWidth: "100%", height: "auto" }}
          />
          <Typography
            sx={{
              textAlign: "center",
              position: "absolute",
              width: "80%",
              background: "#fff",
              bottom: "17%",
              left: "10%",
              right: "10%",
              p: ".5rem",
              borderRadius: "6px",
              color: "#4254EA",
              fontWeight: "700",
            }}
          >
            ${cardData.nominal ? cardData.nominal : "1000"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GiftCardView;
