import { call, put, takeLatest } from "redux-saga/effects";
import {
  LOGIN_COMPANY,
  LOGIN_COMPANY_FAILED,
  LOGIN_COMPANY_SUCCESS,
} from "../types/LoginCompanyType";
import { baseURL } from "../../utils/baseURL";
import { setTokenToLocalStorage } from "../../utils/authService";
import { toast } from "react-toastify";

function* loginsagaCompany(action) {
  try {
    const response = yield call(() =>
      baseURL.post(`/auth/login`, action.payload)
    );
    const token = response.data.token;
    // localStorage.setItem("authToken", token);
    setTokenToLocalStorage(token)
    yield put({ type: LOGIN_COMPANY_SUCCESS, payload: response });
  } catch (error) {
    toast.error('something went wrong, Please Try again!')
    yield put({ type: LOGIN_COMPANY_FAILED, payload: error });
  }
}

export default function* watchaLoginCompany() {
  yield takeLatest(LOGIN_COMPANY, loginsagaCompany);
}
