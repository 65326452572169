import {
  ADD_TO_CART_REQUEST,
  SEND_TO_CHECKOUT,
} from "../types/AddToCart";

const initialState = {
  cartItems: [],
  updatedItem: {}, // Change this to null or an appropriate initial value
};

const AddToCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART_REQUEST:
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };
    case SEND_TO_CHECKOUT:
      return {
        ...state,
        updatedItem: action.payload, // Change this to assign the payload directly
      };
    default:
      return state;
  }
};

export default AddToCartReducer;
