import { ACTIVE_CARD_CLEAN, ACTIVE_CARD_REQUEST, CHECK_CARD_CLEAN, CHECK_CARD_REQUEST } from "../types/ActiveCheckCard";

  export const activeCard = (data,token,id) => {
      return {
        type: ACTIVE_CARD_REQUEST,
        payload: {data, token,id},
      };
    };

    export const CheckCard = (data,token) => {
        return {
          type: CHECK_CARD_REQUEST,
          payload: {data, token},
        };
      };
  
      export const cleanActiveModal =()=>{
        return{
          type:ACTIVE_CARD_CLEAN
        }
      }

      export const cleanCheckModal =()=>{
        return{
          type: CHECK_CARD_CLEAN
        }
      }