import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomButton from "../global/CustomButton";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useDispatch, useSelector } from "react-redux";
import fb from "../../assets/fbIcon.svg";
import google from "../../assets/google.svg";
import apple from "../../assets/apple.svg";
import LoginOTP from "./LoginOTP/LoginOtp";
import {
  SaveNumloginOtpPer,
  loginOtpPer,
} from "../../store/actions/LoginOtpPersonAction";
import { loginNumberVarify } from "../../store/actions/NumberVarification";
import { toast } from "react-toastify";
import { socialLoginUrl } from "../../utils/Constant";
import { useTranslation } from "react-i18next";
import { LOGIN_CLEANUP } from "../../store/types/LoginOTP";

const PersonLogin = ({ setShow, show }) => {
  const txt = {
    color: "#64646D",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  };

  const [formData, setFormData] = useState({
    phoneNumber: "",
  });

  const [phoneError, setPhoneError] = useState("");

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { t } = useTranslation();

  // const googleResposne = useSelector((state) => state.perGoogleLogin.data);
  const otpsendsuccess = useSelector(
    (state) => state.SendOtpLoginPerson.data.status
  );
  const payload = {
    phone_number: formData.phoneNumber,
  };

  const NumberCheck = useSelector((state) => state.NumberCheck.data);

  const handleFieldChange = (fieldName, value) => {
    if (fieldName === "phoneNumber") {
      value = `+${value}`;
    }
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleCheck = () => {
    if (NumberCheck && NumberCheck?.status === 200) {
      let isValid = true;

      if (!formData.phoneNumber) {
        setPhoneError("Phone number cannot be empty");
        isValid = false;
      } else if (formData.phoneNumber.trim().length <= 8) {
        setPhoneError("Invalid Phone Number");
        isValid = false;
      } else {
        setPhoneError("");
      }

      if (isValid) {
        dispatch(SaveNumloginOtpPer(payload));
        dispatch(loginOtpPer(payload));
      }
    }
  };
  const handleLogin = () => {
    let isValid = true;

    if (!formData.phoneNumber) {
      setPhoneError("Phone number cannot be empty");
      isValid = false;
    } else if (formData.phoneNumber.trim().length <= 8) {
      setPhoneError("Invalid Phone Number");
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (isValid) {
      dispatch(loginNumberVarify(payload));
    }
  };

  useEffect(() => {
    if (NumberCheck && NumberCheck?.status === 200) {
      handleCheck();
    }
  }, [NumberCheck]);

  useEffect(() => {
    if (otpsendsuccess === 200) {
      toast.success("OTP send Successfully");
      setShow(true);
    }

    // Cleanup function
    return () => {
      // Set setShow to false when the component unmounts
      dispatch({ type: LOGIN_CLEANUP });
    };
  }, [otpsendsuccess]);

  const handleGoogle = async () => {
    window.open(`${socialLoginUrl}/api/v1/auth/google`, "_self");
    // dispatch(personGoogleLogin());
  };

  const handleFacebook = async () => {
    window.open(`${socialLoginUrl}/api/v1/auth/facebook`, "_self");
    // dispatch(personGoogleLogin());
  };
  const handleApple = async () => {
    window.open(`${socialLoginUrl}/api/v1/auth/apple`, "_self");
    // dispatch(personGoogleLogin());
  };
  return (
    <>
      {show ? (
        <LoginOTP />
      ) : (
        <Box>
          <Box my="16px">
            <Typography sx={txt}>
              {t("loginRegister.enterPhnNumber")}
            </Typography>
            <Box display="flex" gap="8px">
              <PhoneInput
                country={"fi"}
                enableSearch={true}
                value={formData.phoneNumber}
                inputStyle={{
                  width: "100%",
                  borderRadius: "8px",
                  border: phoneError ? "1px solid red" : "1px solid #4254ea33",
                  background: "#F8F8F8",
                }}
                onChange={(value) => handleFieldChange("phoneNumber", value)}
              />
            </Box>
            <Box>
              <Typography color="error" sx={{ fontSize: "12px" }}>
                {phoneError}
              </Typography>
            </Box>
          </Box>
          <Box mt="38px">
            <CustomButton onClick={handleLogin} width="100%">
              {t("loginRegister.Login")}
            </CustomButton>
          </Box>
          <Box
            mt="32px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                width: { xs: "50px", sm: "100px" },
                color: "#EEEEEF",
                height: "1px",
                background: "#EEEEEF",
              }}
            />
            <Typography
              sx={{
                fontSize: "14px",
                color: "#212121",
                fontWeight: 600,
                mx: 1,
              }}
            >
              {t("loginRegister.orWith")}
            </Typography>{" "}
            <Box
              sx={{
                width: { xs: "50px", sm: "100px" },
                color: "#EEEEEF",
                height: "1px",
                background: "#EEEEEF",
              }}
            />
          </Box>
          <Box
            my="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="20px"
          >
            {/* <GoogleLogin
        // clientId="YOUR_GOOGLE_CLIENT_ID"
        // buttonText="Login with Google"
        // onSuccess={responseGoogle}
        // onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
      /> */}
            <img src={google} alt="google" onClick={handleGoogle} />
            <img src={fb} alt="facebook" onClick={handleFacebook} />
            <img src={apple} alt="apple" onClick={handleApple} />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              sx={{
                fontSize: "14px",
                color: "#212121",
                fontWeight: 600,
                fontFamily: "Montserrat",
              }}
            >
              {t("loginRegister.dontHaveAccount")}
              <span> </span>
              <span
                style={{
                  color: "#4254EA",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => navigation("/register")}
              >
                {t("loginRegister.registerNow")}
              </span>
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PersonLogin;
