export const SEARCH_GIPHY_STICKERS_REQUEST = "SEARCH_GIPHY_STICKERS_REQUEST";
export const SEARCH_GIPHY_STICKERS_SUCCESS = "SEARCH_GIPHY_STICKERS_SUCCESS";
export const SEARCH_GIPHY_STICKERS_FAILURE = "SEARCH_GIPHY_STICKERS_FAILURE";

export const BIRTHDAY_STICKERS_REQUEST = "BIRTHDAY_STICKERS_REQUEST";
export const BIRTHDAY_STICKERS_SUCCESS = "BIRTHDAY_STICKERS_SUCCESS";
export const BIRTHDAY_STICKERS_FAILURE = "BIRTHDAY_STICKERS_FAILURE";

export const NEW_YEAR_STICKERS_REQUEST = "NEW_YEAR_STICKERS_REQUEST";
export const NEW_YEAR_STICKERS_SUCCESS = "NEW_YEAR_STICKERS_SUCCESS";
export const NEW_YEAR_STICKERS_FAILURE = "NEW_YEAR_STICKERS_FAILURE";

export const WEDDING_STICKERS_REQUEST = "WEDDING_STICKERS_REQUEST";
export const WEDDING_STICKERS_SUCCESS = "WEDDING_STICKERS_SUCCESS";
export const WEDDING_STICKERS_FAILURE = "WEDDING_STICKERS_FAILURE";