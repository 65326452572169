// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import {
  GENERATE_PROMOCODE_FAILURE,
  GENERATE_PROMOCODE_REQUEST,
  GENERATE_PROMOCODE_SUCCESS,
} from "../types/GeneratePromoCode";
import { BALANCE_REQUEST } from "../types/GetBalance";

function* GeneratePromoCodeSaga(action) {
  try {
    const { data, token, id } = action.payload;
    const userId = id;

    const url = `/gift-card/promo-code/${id}`;
    const response = yield call(baseURL.post, url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({
      type: GENERATE_PROMOCODE_SUCCESS,
      payload: { data: response.data, status: response.status },
    });
    yield put({ type: BALANCE_REQUEST, payload: { userId, token } });
  } catch (error) {
    yield put({ type: GENERATE_PROMOCODE_FAILURE, error });
  }
}

export default function* watchGeneratePromoCode() {
  yield takeLatest(GENERATE_PROMOCODE_REQUEST, GeneratePromoCodeSaga);
}
