import { Box, Checkbox, Grid, OutlinedInput, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../global/CustomButton";
import user from "../../assets/user.svg";
import { useTranslation } from "react-i18next";
import { isAuthenticated } from "../../utils/authService";

const ContackDetails = ({ onNextStep, userData }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    agreeTerms: true,
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    agreeTerms: false,
  });

  const errorMessages = {
    firstName: t("validation.firstNameT"),
    lastName: t("validation.lastNameT"),
    email: t("validation.emailT"),
    phone: t("validation.phoneT"),
    agreeTerms: t("validation.agreeTermsT"),
  };

  const text = {
    fontSize: "12px",
    color: "#64646D",
    fontWeight: "500",
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[0-9]+$/;

    const newErrors = {
      firstName: formData.firstName.trim() === "",
      lastName: formData.lastName.trim() === "",
      email: !emailRegex.test(formData.email.trim()),
      phone: !phoneRegex.test(formData.phone.trim()),
      agreeTerms: !formData.agreeTerms,
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some(Boolean);
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      agreeTerms: event.target.checked,
    }));
  };

  const handleContinueClick = () => {
    if (validateForm()) {
      onNextStep();
    }
  };

  useEffect(() => {
    if (
      userData &&
      userData?.data &&
      Array.isArray(userData.data) &&
      userData?.data.length > 0
    ) {
      setFormData({
        firstName: userData.data[0].first_name || "",
        lastName: userData.data[0].last_name || "",
        email: userData.data[0].email || "",
        phone: userData.data[0].phone_number || "",
        agreeTerms: userData.data[0].terms_and_condition || "",
      });
    } else {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        agreeTerms: true,
      });
    }
  }, [userData]);

  return (
    <Box>
      {!isAuthenticated() && <Box display="flex" gap="5px" alignItems="center" mb="24px">
        <img src={user} alt="user" />

        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#4254EA",
            textDecoration: "underline",
          }}
        >
          {t("checkoutpage.LoginRegistration")}{" "}
        </Typography>
      </Box>}
      <Box >
        <Grid container width={{ xs: "100%", tab: "69%" }} spacing={"8px"}>
          <Grid item xs={12} sm={6}>
            <Typography style={text} mb={"4px"}>
              {t("formLable.firstName")}
            </Typography>
            <OutlinedInput
              placeholder="First Name"
              value={formData.firstName}
              onChange={(e) => handleInputChange("firstName", e.target.value)}
              error={errors.firstName}
              sx={{
                width: { lg: "230px", xs: "100%" },
                background: "#F8F8F8",
                "& fieldset": { border: "1px solid #4254ea33" },
                borderRadius: "8px",
              }}
              size="small"
              inputProps={{
                style: { fontSize: "0.8rem" },
              }}
            />
            {errors.firstName && (
              <Typography color="error" variant="caption">
                {errorMessages.firstName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <Typography style={text} mb={"4px"}>
              {t("formLable.lastName")}
            </Typography>
            <OutlinedInput
              placeholder="Last Name"
              value={formData.lastName}
              onChange={(e) => handleInputChange("lastName", e.target.value)}
              error={errors.lastName}
              sx={{
                width: { lg: "230px", xs: "100%" },
                background: "#F8F8F8",
                "& fieldset": { border: "1px solid #4254ea33" },
                borderRadius: "8px",
              }}
              size="small"
              inputProps={{
                style: { fontSize: "0.8rem" },
              }}
            />
            {errors.lastName && (
              <Typography color="error" variant="caption">
                {errorMessages.lastName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <Typography style={text} mb={"4px"}>
              {t("formLable.email")}
            </Typography>
            <OutlinedInput
              placeholder="Email"
              value={formData.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
              error={errors.email}
              sx={{
                width: { xs: "100%", lg: "230px" },
                background: "#F8F8F8",
                "& fieldset": { border: "1px solid #4254ea33" },
                borderRadius: "8px",
              }}
              size="small"
              inputProps={{
                style: { fontSize: "0.8rem" },
              }}
            />
            {errors.email && (
              <Typography color="error" variant="caption">
                {errorMessages.email}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography style={text} mb={"4px"}>
              {t("formLable.Phone")}
            </Typography>
            <OutlinedInput
              placeholder="Phone"
              value={formData.phone}
              onChange={(e) => handleInputChange("phone", e.target.value)}
              error={errors.phone}
              sx={{
                width: { lg: "230px", xs: "100%" },
                background: "#F8F8F8",
                "& fieldset": { border: "1px solid #4254ea33" },
                borderRadius: "8px",
              }}
              size="small"
              inputProps={{
                style: { fontSize: "0.8rem" },
              }}
            />
            {errors.phone && (
              <Typography color="error" variant="caption">
                {errorMessages.phone}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyItems="self-start"
              gap="5px"
            >
              <Checkbox
                checked={formData.agreeTerms}
                onChange={handleCheckboxChange}
                sx={{
                  padding: "0px",
                  color: "#4254EA",
                  "&.Mui-checked": {
                    color: "#4254EA",
                  },
                }}
              />
              <Typography
                sx={{
                  color: "#212121",
                  fontSize: { sm: "14px", xs: "10px" },
                  fontWeight: 500,
                }}
              >
                {t("partnercardpage.agreewith")}
                <span style={{ textDecoration: "underline" }}>
                  {t("partnercardpage.tandc")}
                </span>
              </Typography>
            </Box>
            {errors.agreeTerms && (
              <Typography color="error" variant="caption">
                {errorMessages.agreeTerms}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} mt={"1.5rem"}>
            {" "}
            <CustomButton
              onClick={handleContinueClick}
              width={{ xs: "100%", md: "80%" }}
            >
              {t("formLable.continue")}
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ContackDetails;
