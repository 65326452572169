import { combineReducers } from "redux";
import RegisterCompanyReducer from "./RegisterCompanyReducer";
import { LoginCompanyReducer } from "./LoginCompanyReducer";
import RegisterPersonReducer from "./RegisterPersonReducer";
import GifiSearchReducer from "./GifiSearchReducer";
import PaidGiftCardReducer from "./PaidGiftCardReducer";
import RegisterPersonOTPReducer from "./RegisterPersonReducerOTP";
import CheckRegPerOTPReducer from "./CheckRegPerOTPReducer";
import ResendRegPerOTPReducer from "./ResendRegPerOTPReducer";
import GooglePersonLoginReducer from "./GooglePersonLoginReducer";
import SendOtpLoginPerson from "./SendOtpLoginPerson";
import CheckOtpLoginPerReducer from "./CheckOtpLoginPerReducer";
import LoginResendOtpPerson from "./LoginResendOtpPerson";
import { LoginOtpPersonReducer } from "./LoginOtpPersonReducer";
import ForgetPasswordReducer from "./ForgetPasswordReducer";
import ChangePasswordReducer from "./ChangePasswordReducer";
import AddToCartReducer from "./AddToCartReducer";
import FeedbackReducer from "./FeedbackReducer";
import userReducer from "./GetUserReducer";
import PartnerFilterReducer from "./PartnerFilterReducer";
import GeneratePromoCodeReducer from "./GeneratePromoCodeReducer";
import AddToGiftCartReducer from "./AddToGiftCartReducer";
import GetGiftCartReducer from "./GetGiftCartReducer";
import EditUserInfoReducer from "./EditUserInfoReducer";
import PersonPromocodeReducer from "./PersonPromocodeReducer";
import ActiveCardReducer from "./ActiveCardReducer";
import CheckCardReducer from "./CheckCardReducer";
import GIFDataReducer from "./GIFDataReducer";
import HistoryPurchaseReducer from "./HistoryPurchaseReducer";
import AfterLoginChangePassword from "./AfterLoginChangePassword";
import AfterLoginPartnerInfoReducer from "./AfterLoginPartnerInfoReducer";
import EditAddToCartReducer from "./EditAddToCartReducer";
import PaymentReducer from "./PaymentReducer";
import GetBalanceReducer from "./GetBalanceReducer";
import ExistEmailCheckReducer from "./ExistEmailCheckReducer";
import NumberVarificationReducer from "./NumberVarificationReducer";
// import AuthReducer from "./auth";

const rootReducer = combineReducers({
  authCompany: LoginCompanyReducer,
  companyRegister: RegisterCompanyReducer,
  personRegister: RegisterPersonReducer,
  giftCard: GifiSearchReducer,
  PaidCard: PaidGiftCardReducer,
  personOtpRegister:RegisterPersonOTPReducer,
  checkOptPerReg:CheckRegPerOTPReducer,
  resendOtpRegPer:ResendRegPerOTPReducer,
  perGoogleLogin:GooglePersonLoginReducer,
  SendOtpLoginPerson:SendOtpLoginPerson,
  CheckOtpLoginPerReducer:CheckOtpLoginPerReducer,
  LoginResendOtpPerson:LoginResendOtpPerson,
  LoginOtpPersonReducer:LoginOtpPersonReducer,
  ForgetPasswordReducer:ForgetPasswordReducer,
  ChangePasswordReducer:ChangePasswordReducer,
  AddToCart:AddToCartReducer,
  UserFeedback:FeedbackReducer,
  getUser:userReducer,
  filterData:PartnerFilterReducer,
  GeneratePromo : GeneratePromoCodeReducer,
  AddToGiftCartReducer:AddToGiftCartReducer,
  GetGiftCartReducer:GetGiftCartReducer,
  EditUser:EditUserInfoReducer,
  promocode:PersonPromocodeReducer,
  activeCard:ActiveCardReducer,
  checkCardData:CheckCardReducer,
  GIFDataReducer:GIFDataReducer,
  purchaseHistory:HistoryPurchaseReducer,
  AfterLoginChangePassword:AfterLoginChangePassword,
  AfterLoginPartnerInfoReducer:AfterLoginPartnerInfoReducer,
  EditAddToCartReducer:EditAddToCartReducer,
  PaymentReducer:PaymentReducer,
  balance:GetBalanceReducer,
  checkEmailStatus :ExistEmailCheckReducer,
  NumberCheck :NumberVarificationReducer
});

export default rootReducer;
