export const mapPath = [
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7939.465520930137!2d24.92203532582211!3d60.16639087535309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34d032778f%3A0x2600b5523c1977b1!2sKamppi%2C%20Helsinki%2C%20Finland!5e0!3m2!1sen!2sin!4v1702959844961!5m2!1sen!2sin",
    name: "Shop Inter Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.6905376163481!2d24.930440889368796!3d60.16930180000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34b9cd6bc1%3A0x193cb610881bef14!2sMango!5e0!3m2!1sen!2sin!4v1702961095827!5m2!1sen!2sin",
    name: "Shop Mango in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.7303260655344!2d24.93514287674507!3d60.16864314355762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc90e20b57%3A0x698b6e77b3f2068c!2zSCZN!5e0!3m2!1sen!2sin!4v1702961173218!5m2!1sen!2sin",
    name: "Shop H&M Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63511.173318767585!2d24.85531770228575!3d60.168745113826326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a491e327d1d%3A0x2dddde8986870fb9!2sJYSK%20Ruoholahti%2C%20Helsinki!5e0!3m2!1sen!2sin!4v1702961270546!5m2!1sen!2sin",
    name: "Shop Jysk in Forum Shopping Centre ",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31741.440426133056!2d24.898099308201388!3d60.18338008773008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920b82cbb9b401%3A0x6715b67c84bc89fb!2sGateau%20Citycenter!5e0!3m2!1sen!2sin!4v1702961360738!5m2!1sen!2sin",
    name: "Cafe Gateau in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16258181.614509078!2d-10.917554699999965!3d60.17011670000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc59b09483%3A0x2553ff3073615367!2sCitycenter%20Mall%20Helsinki!5e0!3m2!1sen!2sin!4v1702961460178!5m2!1sen!2sin",
    name: "Shop Vero Moda in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7939.465520930137!2d24.92203532582211!3d60.16639087535309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34d032778f%3A0x2600b5523c1977b1!2sKamppi%2C%20Helsinki%2C%20Finland!5e0!3m2!1sen!2sin!4v1702959844961!5m2!1sen!2sin",
    name: "Shop Inter Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.6905376163481!2d24.930440889368796!3d60.16930180000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34b9cd6bc1%3A0x193cb610881bef14!2sMango!5e0!3m2!1sen!2sin!4v1702961095827!5m2!1sen!2sin",
    name: "Shop Mango in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.7303260655344!2d24.93514287674507!3d60.16864314355762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc90e20b57%3A0x698b6e77b3f2068c!2zSCZN!5e0!3m2!1sen!2sin!4v1702961173218!5m2!1sen!2sin",
    name: "Shop H&M Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63511.173318767585!2d24.85531770228575!3d60.168745113826326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a491e327d1d%3A0x2dddde8986870fb9!2sJYSK%20Ruoholahti%2C%20Helsinki!5e0!3m2!1sen!2sin!4v1702961270546!5m2!1sen!2sin",
    name: "Shop Jysk in Forum Shopping Centre ",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31741.440426133056!2d24.898099308201388!3d60.18338008773008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920b82cbb9b401%3A0x6715b67c84bc89fb!2sGateau%20Citycenter!5e0!3m2!1sen!2sin!4v1702961360738!5m2!1sen!2sin",
    name: "Cafe Gateau in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16258181.614509078!2d-10.917554699999965!3d60.17011670000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc59b09483%3A0x2553ff3073615367!2sCitycenter%20Mall%20Helsinki!5e0!3m2!1sen!2sin!4v1702961460178!5m2!1sen!2sin",
    name: "Shop Vero Moda in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7939.465520930137!2d24.92203532582211!3d60.16639087535309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34d032778f%3A0x2600b5523c1977b1!2sKamppi%2C%20Helsinki%2C%20Finland!5e0!3m2!1sen!2sin!4v1702959844961!5m2!1sen!2sin",
    name: "Shop Inter Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.6905376163481!2d24.930440889368796!3d60.16930180000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34b9cd6bc1%3A0x193cb610881bef14!2sMango!5e0!3m2!1sen!2sin!4v1702961095827!5m2!1sen!2sin",
    name: "Shop Mango in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.7303260655344!2d24.93514287674507!3d60.16864314355762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc90e20b57%3A0x698b6e77b3f2068c!2zSCZN!5e0!3m2!1sen!2sin!4v1702961173218!5m2!1sen!2sin",
    name: "Shop H&M Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63511.173318767585!2d24.85531770228575!3d60.168745113826326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a491e327d1d%3A0x2dddde8986870fb9!2sJYSK%20Ruoholahti%2C%20Helsinki!5e0!3m2!1sen!2sin!4v1702961270546!5m2!1sen!2sin",
    name: "Shop Jysk in Forum Shopping Centre ",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31741.440426133056!2d24.898099308201388!3d60.18338008773008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920b82cbb9b401%3A0x6715b67c84bc89fb!2sGateau%20Citycenter!5e0!3m2!1sen!2sin!4v1702961360738!5m2!1sen!2sin",
    name: "Cafe Gateau in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16258181.614509078!2d-10.917554699999965!3d60.17011670000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc59b09483%3A0x2553ff3073615367!2sCitycenter%20Mall%20Helsinki!5e0!3m2!1sen!2sin!4v1702961460178!5m2!1sen!2sin",
    name: "Shop Vero Moda in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7939.465520930137!2d24.92203532582211!3d60.16639087535309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34d032778f%3A0x2600b5523c1977b1!2sKamppi%2C%20Helsinki%2C%20Finland!5e0!3m2!1sen!2sin!4v1702959844961!5m2!1sen!2sin",
    name: "Shop Inter Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.6905376163481!2d24.930440889368796!3d60.16930180000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34b9cd6bc1%3A0x193cb610881bef14!2sMango!5e0!3m2!1sen!2sin!4v1702961095827!5m2!1sen!2sin",
    name: "Shop Mango in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.7303260655344!2d24.93514287674507!3d60.16864314355762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc90e20b57%3A0x698b6e77b3f2068c!2zSCZN!5e0!3m2!1sen!2sin!4v1702961173218!5m2!1sen!2sin",
    name: "Shop H&M Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63511.173318767585!2d24.85531770228575!3d60.168745113826326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a491e327d1d%3A0x2dddde8986870fb9!2sJYSK%20Ruoholahti%2C%20Helsinki!5e0!3m2!1sen!2sin!4v1702961270546!5m2!1sen!2sin",
    name: "Shop Jysk in Forum Shopping Centre ",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31741.440426133056!2d24.898099308201388!3d60.18338008773008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920b82cbb9b401%3A0x6715b67c84bc89fb!2sGateau%20Citycenter!5e0!3m2!1sen!2sin!4v1702961360738!5m2!1sen!2sin",
    name: "Cafe Gateau in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16258181.614509078!2d-10.917554699999965!3d60.17011670000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc59b09483%3A0x2553ff3073615367!2sCitycenter%20Mall%20Helsinki!5e0!3m2!1sen!2sin!4v1702961460178!5m2!1sen!2sin",
    name: "Shop Vero Moda in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7939.465520930137!2d24.92203532582211!3d60.16639087535309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34d032778f%3A0x2600b5523c1977b1!2sKamppi%2C%20Helsinki%2C%20Finland!5e0!3m2!1sen!2sin!4v1702959844961!5m2!1sen!2sin",
    name: "Shop Inter Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.6905376163481!2d24.930440889368796!3d60.16930180000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34b9cd6bc1%3A0x193cb610881bef14!2sMango!5e0!3m2!1sen!2sin!4v1702961095827!5m2!1sen!2sin",
    name: "Shop Mango in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.7303260655344!2d24.93514287674507!3d60.16864314355762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc90e20b57%3A0x698b6e77b3f2068c!2zSCZN!5e0!3m2!1sen!2sin!4v1702961173218!5m2!1sen!2sin",
    name: "Shop H&M Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63511.173318767585!2d24.85531770228575!3d60.168745113826326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a491e327d1d%3A0x2dddde8986870fb9!2sJYSK%20Ruoholahti%2C%20Helsinki!5e0!3m2!1sen!2sin!4v1702961270546!5m2!1sen!2sin",
    name: "Shop Jysk in Forum Shopping Centre ",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31741.440426133056!2d24.898099308201388!3d60.18338008773008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920b82cbb9b401%3A0x6715b67c84bc89fb!2sGateau%20Citycenter!5e0!3m2!1sen!2sin!4v1702961360738!5m2!1sen!2sin",
    name: "Cafe Gateau in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16258181.614509078!2d-10.917554699999965!3d60.17011670000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc59b09483%3A0x2553ff3073615367!2sCitycenter%20Mall%20Helsinki!5e0!3m2!1sen!2sin!4v1702961460178!5m2!1sen!2sin",
    name: "Shop Vero Moda in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7939.465520930137!2d24.92203532582211!3d60.16639087535309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34d032778f%3A0x2600b5523c1977b1!2sKamppi%2C%20Helsinki%2C%20Finland!5e0!3m2!1sen!2sin!4v1702959844961!5m2!1sen!2sin",
    name: "Shop Inter Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.6905376163481!2d24.930440889368796!3d60.16930180000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34b9cd6bc1%3A0x193cb610881bef14!2sMango!5e0!3m2!1sen!2sin!4v1702961095827!5m2!1sen!2sin",
    name: "Shop Mango in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.7303260655344!2d24.93514287674507!3d60.16864314355762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc90e20b57%3A0x698b6e77b3f2068c!2zSCZN!5e0!3m2!1sen!2sin!4v1702961173218!5m2!1sen!2sin",
    name: "Shop H&M Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63511.173318767585!2d24.85531770228575!3d60.168745113826326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a491e327d1d%3A0x2dddde8986870fb9!2sJYSK%20Ruoholahti%2C%20Helsinki!5e0!3m2!1sen!2sin!4v1702961270546!5m2!1sen!2sin",
    name: "Shop Jysk in Forum Shopping Centre ",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31741.440426133056!2d24.898099308201388!3d60.18338008773008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920b82cbb9b401%3A0x6715b67c84bc89fb!2sGateau%20Citycenter!5e0!3m2!1sen!2sin!4v1702961360738!5m2!1sen!2sin",
    name: "Cafe Gateau in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16258181.614509078!2d-10.917554699999965!3d60.17011670000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc59b09483%3A0x2553ff3073615367!2sCitycenter%20Mall%20Helsinki!5e0!3m2!1sen!2sin!4v1702961460178!5m2!1sen!2sin",
    name: "Shop Vero Moda in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7939.465520930137!2d24.92203532582211!3d60.16639087535309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34d032778f%3A0x2600b5523c1977b1!2sKamppi%2C%20Helsinki%2C%20Finland!5e0!3m2!1sen!2sin!4v1702959844961!5m2!1sen!2sin",
    name: "Shop Inter Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.6905376163481!2d24.930440889368796!3d60.16930180000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34b9cd6bc1%3A0x193cb610881bef14!2sMango!5e0!3m2!1sen!2sin!4v1702961095827!5m2!1sen!2sin",
    name: "Shop Mango in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.7303260655344!2d24.93514287674507!3d60.16864314355762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc90e20b57%3A0x698b6e77b3f2068c!2zSCZN!5e0!3m2!1sen!2sin!4v1702961173218!5m2!1sen!2sin",
    name: "Shop H&M Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63511.173318767585!2d24.85531770228575!3d60.168745113826326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a491e327d1d%3A0x2dddde8986870fb9!2sJYSK%20Ruoholahti%2C%20Helsinki!5e0!3m2!1sen!2sin!4v1702961270546!5m2!1sen!2sin",
    name: "Shop Jysk in Forum Shopping Centre ",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31741.440426133056!2d24.898099308201388!3d60.18338008773008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920b82cbb9b401%3A0x6715b67c84bc89fb!2sGateau%20Citycenter!5e0!3m2!1sen!2sin!4v1702961360738!5m2!1sen!2sin",
    name: "Cafe Gateau in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16258181.614509078!2d-10.917554699999965!3d60.17011670000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc59b09483%3A0x2553ff3073615367!2sCitycenter%20Mall%20Helsinki!5e0!3m2!1sen!2sin!4v1702961460178!5m2!1sen!2sin",
    name: "Shop Vero Moda in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7939.465520930137!2d24.92203532582211!3d60.16639087535309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34d032778f%3A0x2600b5523c1977b1!2sKamppi%2C%20Helsinki%2C%20Finland!5e0!3m2!1sen!2sin!4v1702959844961!5m2!1sen!2sin",
    name: "Shop Inter Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.6905376163481!2d24.930440889368796!3d60.16930180000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a34b9cd6bc1%3A0x193cb610881bef14!2sMango!5e0!3m2!1sen!2sin!4v1702961095827!5m2!1sen!2sin",
    name: "Shop Mango in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.7303260655344!2d24.93514287674507!3d60.16864314355762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc90e20b57%3A0x698b6e77b3f2068c!2zSCZN!5e0!3m2!1sen!2sin!4v1702961173218!5m2!1sen!2sin",
    name: "Shop H&M Sport in Kamppi Helsinki",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63511.173318767585!2d24.85531770228575!3d60.168745113826326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a491e327d1d%3A0x2dddde8986870fb9!2sJYSK%20Ruoholahti%2C%20Helsinki!5e0!3m2!1sen!2sin!4v1702961270546!5m2!1sen!2sin",
    name: "Shop Jysk in Forum Shopping Centre ",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31741.440426133056!2d24.898099308201388!3d60.18338008773008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920b82cbb9b401%3A0x6715b67c84bc89fb!2sGateau%20Citycenter!5e0!3m2!1sen!2sin!4v1702961360738!5m2!1sen!2sin",
    name: "Cafe Gateau in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
  {
    path: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16258181.614509078!2d-10.917554699999965!3d60.17011670000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bcc59b09483%3A0x2553ff3073615367!2sCitycenter%20Mall%20Helsinki!5e0!3m2!1sen!2sin!4v1702961460178!5m2!1sen!2sin",
    name: "Shop Vero Moda in Citycenter Mall",
    description:
      "Urho Kekkosen katu 1. We work every day from 9:00 a.m. to 8:00 p.m. We accept payment in cash and VISA/Mastercard.",
  },
];
