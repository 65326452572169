import { all } from "redux-saga/effects";
import watchRegisterCompany from "./RegisterCompanySaga";
import watchaLoginCompany from "./LoginCompanySaga";
import watchRegisterPerson from "./RegisterPerson";
import watchgiftCardSaga from "./GifiSearchSaga";
import watchPaidCardSaga from "./PaidGiftCardSaga";
import watchRegisterPersonOTP from "./RegisterPersonOTP";
import watchCheckRegisterPersonOTP from "./CheckRegisterOTPSaga";
import watchMediaPersonLogin from "./PersonMediaLoginSaga";
import watchCheckLoginPersonOTP from "./LoginOtpPersonSaga";
import watchForgetPasswordCompany from "./ForgetPasswordSaga";
import watchUserFeedbackSaga from "./FeedbackSaga";
import watchFetchSingleUser from "./GetUserSaga";
import watchFilterPartnerSaga from "./PartnerFilterSaga";
import watchGeneratePromoCode from "./GeneratePromoCodeSaga";
import watchAddtoGiftCardSaga from "./AddGritCartSaga";
import watchUserInfoEditSaga from "./EditUserInfoSaga";
import watchpromocodesSaga from "./PersonPromocodeSaga";
import watchActiveCheckSaga from "./ActiveCheckCardSaga";
import watchHistoryPurchaseSaga from "./HistoryPurchaseSaga";
import watchuserInfoAfterLoginSaga from "./AfterLoginPartnerInfoSaga";
import watchChangePasswordSaga from "./ChangePasswordSaga";
import watchEditGiftCardSaga from "./EditGiftCardSaga";
import watchpaymentCardSaga from "./PaymentSaga";
import watchfetchBalance from "./GetBalanceSaga";
import watchEmailCheckStatus from "./ExistEmailCheckSaga";
import watchNumberVarificationSaga from "./NumberVarificationSaga";

export default function* rootSaga() {
  yield all([
    watchRegisterCompany(),
    watchaLoginCompany(),
    watchRegisterPerson(),
    watchgiftCardSaga(),
    watchPaidCardSaga(),
    watchRegisterPersonOTP(),
    watchCheckRegisterPersonOTP(),
    watchMediaPersonLogin(),
    watchCheckLoginPersonOTP(),
    watchForgetPasswordCompany(),
    watchUserFeedbackSaga(),
    watchFetchSingleUser(),
    watchFilterPartnerSaga(),
    watchGeneratePromoCode(),
    watchAddtoGiftCardSaga(),
    watchUserInfoEditSaga(),
    watchpromocodesSaga(),
    watchActiveCheckSaga(),
    watchHistoryPurchaseSaga(),
    watchuserInfoAfterLoginSaga(),
    watchChangePasswordSaga(),
    watchEditGiftCardSaga(),
    watchpaymentCardSaga(),
    watchfetchBalance(),
    watchEmailCheckStatus(),
    watchNumberVarificationSaga()
  ]);
}
