import { GENERATE_PROMOCODE_CLEAN, GENERATE_PROMOCODE_REQUEST } from "../types/GeneratePromoCode";

  
  export const generatePromocode = (data,token,id) => {
      return {
        type: GENERATE_PROMOCODE_REQUEST,
        payload: {data,token,id}
      };
    };

    export const generatePromocodeClean = () => {
      return {
        type: GENERATE_PROMOCODE_CLEAN,
      };
    };
  