// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/border.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basic-modal {
  border-style: solid;
  border-image-width: 50px;
  border-image-source: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  border-image-slice: 40;
  border-image-repeat: repeat;
}

.class-center {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/global/basicModal.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,wBAAwB;EACxB,4DAAmD;EACnD,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".basic-modal {\n  border-style: solid;\n  border-image-width: 50px;\n  border-image-source: url(\"../../assets/border.svg\");\n  border-image-slice: 40;\n  border-image-repeat: repeat;\n}\n\n.class-center {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
