import { UPDATE_CART_REQUEST } from "../types/AddToCart";
import {
  ADD_GIFT_CART_REQUEST,
  CLEAN_ADDED_GIFT_CART,
  GET_GIFT_CART_REQUEST,
} from "../types/AddToCartGift";

export const addGiftCart = (data, token) => {
  return {
    type: ADD_GIFT_CART_REQUEST,
    payload: { data, token },
  };
};

export const getGiftCart = (id, token) => {
  return {
    type: GET_GIFT_CART_REQUEST,
    payload: { id, token },
  };
};

export const EditAddTOGiftCart = (data, token, id) => {
  return {
    type: UPDATE_CART_REQUEST,
    payload: { data, token, id },
  };
};


export const CleanGiftCart = () => {
  return {
    type: CLEAN_ADDED_GIFT_CART,
  };
};