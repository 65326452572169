import React from "react";
import AddCart from "../../components/Cart/AddCart";
import { Container } from "@mui/material";

const CartItem = () => {
  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: 0, paddingRight: 0, background: "#F2F2F7" }}
    >
      <AddCart />
    </Container>
  );
};

export default CartItem;
