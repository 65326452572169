// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import {
  HISTORY_PURCHASE_INFO,
  HISTORY_PURCHASE_INFO_ERROR,
  HISTORY_PURCHASE_INFO_SUCCESS,
} from "../types/HistoryPurchase";

function* HistoryPurchaseSaga(action) {
  try {
    const { userId, token } = action.payload;
    const url = `/payment/purchase-history/${userId}`;

    const response = yield call(baseURL.get, url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({ type: HISTORY_PURCHASE_INFO_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: HISTORY_PURCHASE_INFO_ERROR, error });
  }
}

export default function* watchHistoryPurchaseSaga() {
  yield takeLatest(HISTORY_PURCHASE_INFO, HistoryPurchaseSaga);
}
