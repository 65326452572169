import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import {
  searchPaidGIFIFailure,
  searchPaidGIFISuccess,
} from "../actions/PaidGiftCardAction";
import { SEARCH_PAID_GIFTCARD_REQUEST } from "../types/PaidGIftCard";

function* searchhPaidGIFISaga(action) {
  try {
    // Construct the URL with a default value if action.payload is not present

    const response = yield call(() =>
      baseURL.get(`/gift-card/giphy-gifs?search=${action.payload}&limit=50`)
    );
    yield put(searchPaidGIFISuccess(response.data));
  } catch (error) {
    yield put(searchPaidGIFIFailure(error));
  }
}

export default function* watchPaidCardSaga() {
  yield takeLatest(SEARCH_PAID_GIFTCARD_REQUEST, searchhPaidGIFISaga);
}
