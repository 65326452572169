import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Grid,
  styled,
} from "@mui/material";
import { mapPath } from "./mapdata";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./MapComponent.css"; // Import the CSS file for styling

const ContactMap = () => {
  const [expanded, setExpanded] = useState(0); // State to track expanded Accordion panel index
  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : -1);
  };
  const StyledAccordion = styled(Accordion)({
    borderBottom: "none",
    background: "#fff",
    padding: ".5rem 0rem",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
  });

  const StyledAccordionSummary = styled(AccordionSummary)({
    boxShadow: "none",
  });

  const heading = {
    color: "#212121",
    fontFamily: "Montserrat",
    fontSize: { xs: "25px", sm: "32px", lg: "40px" },
    fontWeight: 700,
  };

  const boxStyle = {
    width: " 100%",
    height: "489px",
    borderRadius: "30px",
  };

  return (
    <Box mx={"7%"} mb={"100px"}>
      <Typography sx={heading}>
        Our Partners in{" "}
        <span style={{ color: "#4254EA" }}>
          Helsinki <KeyboardArrowDownIcon />
        </span>
      </Typography>
      <Grid container mt={"40px"} gap="32px">
        <Grid item xs={12} md={5}>
          <Box>
            <Box
              sx={{
                height: "489px",
                overflow: "hidden",
                overflowY: "auto",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                "&::-webkit-scrollbar": {
                  width: "5px",
                  background: "#F2F2F7",
                  padding: "2rem",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#4254EA",
                  borderRadius: "4px",
                },
              }}
            >
              {mapPath.map((item, index) => (
                <StyledAccordion
                  key={index}
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                >
                  <StyledAccordionSummary>
                    <Typography
                      sx={{
                        color: expanded === index ? "#4254EA" : "#212121",
                        fontFamily: "Montserrat",
                        fontSize: {
                          xs: "14px",
                          sm: "16px",
                          lg: "18px",
                          textDecoration: "underline",
                        },
                        fontWeight: 500,
                      }}
                    >
                      {item.name}
                    </Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails sx={{ maxWidth: { md: "412px" } }}>
                    <Typography sx={{ color: "#64646D", fontSize: "14px" }}>
                      {item.description}
                    </Typography>
                  </AccordionDetails>
                </StyledAccordion>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6.5}>
          <Box sx={boxStyle} className="iframe-container">
            {mapPath[expanded] && (
              <iframe
                title="Map"
                width="100%"
                height="489"
                className={`map-iframe ${expanded !== -1 ? "fade-in" : ""}`}
                src={mapPath[expanded].path}
                style={{ border: "0", borderRadius: "30px" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactMap;
