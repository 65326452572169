import {
  Box,
  FormControl,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React from "react";
import CustomButton from "../global/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { paymentAction } from "../../store/actions/PaymentCardAction";
const PaymetDetails = () => {
  const text = {
    fontSize: "12px",
    color: "#64646D",
    fontWeight: "500",
  };
  const dispatch = useDispatch();
  const cartData1 = useSelector((state) => state.GetGiftCartReducer?.data);
  const token = localStorage.getItem("authToken");
  const results = useSelector((state) => state?.PaymentReducer?.data);
  const userData = useSelector((state) => state.getUser?.user);
  const id_user = userData?.data[0]?._id;  if (Object.keys(results).length !== 0) {
    window.open(results.url, "_self");
  }
  window.open(results.url, "_self");
  const filterData =
  cartData1 &&
  cartData1.length > 0 &&
  cartData1?.map((item, ind) => ({
    name: item.recipient_name,
    unit_amount:
      item.type_of_card === 'paid'
        ? parseInt(item.nominal) + 2
        : parseInt(item.nominal),
    currency: "usd",
    delivery_type: item.delivery_type,
    quantity: item.amount,
  }));


  const sumTotal =
    cartData1 &&
    cartData1.length > 0 &&
    cartData1?.reduce((total, item) => {
      if (item.type_of_card === "free") {
        total += parseInt(item.nominal, 10);
      } else if (item.type_of_card === "paid") {
        total += 2 + parseInt(item.nominal, 10);
      }
      return total;
    }, 0);

  const handleButtonClick = () => {
    const data = {
      items: filterData,
      userId: id_user,
      unit_amount: sumTotal,
      quantity: 1,
    };
    dispatch(paymentAction(data, token));
  };
 
  return (
    <Box>
      <Box>
        <Grid container>
          <Grid item xs={12} tab={6}>
            <FormControl variant="outlined">
              <Typography style={text} mb={"4px"}>
                Comment on the order{" "}
              </Typography>
              <OutlinedInput
                fullWidth
                sx={{
                  width: { xs: "100%", tab: "355px" },
                  background: "#F8F8F8",
                  "& fieldset": { border: "1px solid #4254ea33" },
                  borderRadius: "8px",
                }}
                rows={4}
                multiline
                placeholder="Your comment"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} mt={"1.5rem"}>
            <Box width={{ xs: "100%", tab: "69%" }}>
              <CustomButton
                onClick={handleButtonClick}
                width={{ xs: "100%", md: "80%" }}
              >
                Pay the order
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PaymetDetails;
