import { CUSTOMER_FEEDBACK_FAILURE, CUSTOMER_FEEDBACK_REQUEST, CUSTOMER_FEEDBACK_SUCCESS } from "../types/Feedback";

  const initialState = {
    loading: false,
    error: null,
    data: {},
  };
  
  // The reducer function
  const FeedbackReducer = (state = initialState, action) => {
    switch (action.type) {
      case CUSTOMER_FEEDBACK_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case CUSTOMER_FEEDBACK_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload, // Update the customer data with the payload
          error: null,
        };
      case CUSTOMER_FEEDBACK_FAILURE:
        return {
          ...state,
          loading: false,
          data: null,
          error: action.payload, // Set the error with the payload
        };
      default:
        return state;
    }
  };
  
  export default FeedbackReducer;
  