import {
  REGISTER_PERSON,
  REGISTER_PERSON_ERROR,
  REGISTER_PERSON_SUCCESS,
} from "../types/RegisterPerson";
const initialState = {
  registerdata: {},
  loading: false,
  error: null,
  success: false,
};

// The reducer function
const RegisterPersonReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_PERSON:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REGISTER_PERSON_SUCCESS:
      return {
        ...state,
        loading: false,
        registerdata: action.payload.data, // Update the customer data with the payload
        success: true,
        error: null,
      };
    case REGISTER_PERSON_ERROR:
      return {
        ...state,
        loading: false,
        registerdata: null,
        error: action.payload, // Set the error with the payload
      };
    default:
      return state;
  }
};

export default RegisterPersonReducer;
