import { Container } from "@mui/material";
import React from "react";
import CardView from "../../components/partnerCard/CardView";
import PartnerUses from "../../components/partnerCard/PartnerUses";
import PartnerTerms from "../../components/partnerCard/PartnerTerms";

const PartnerCard = () => {
  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: 0, paddingRight: 0, background: "#F2F2F7" }}
    >
      <CardView />
      <PartnerUses />
      <PartnerTerms />
    </Container>
  );
};

export default PartnerCard;
