import { Box, Typography } from "@mui/material";
import React from "react";
import card from "../../assets/bannerCard.png";
import CustomButton from "../global/CustomButton";
import "./CardBanner.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isAuthenticated } from "../../utils/authService";
const CardBanner = () => {
  const {t} = useTranslation();
  const navigation = useNavigate();

  const handleButtonClick = () => {
    isAuthenticated() ? navigation('/gift-card') : toast.warn('Need to login, for access this page!'); // Added parentheses
    window.scrollTo(0, 0);

  };  return (
    <Box>
      <Box
        display="flex"
        flexDirection={{ tab: "row", xs: "column" }}
        justifyItems="space-between"
        alignItems="center"
        backgroundColor="#D9DCF9"
        padding={{ lg: "64px 104px", tab: "44px 44px", xs: "24px 44px" }}
        mx={{ lg: "7%", xs: "2%" }}
        borderRadius="1rem"
        my={{sm:'80px',xs:"40px"}}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "30px", md: "40px" },
              maxWidth: "384px",
              lineHeight: { xs: "34px", sm: "36px", md: "56px" },
            }}
          >
            {t('home.banner.headtxt')}
          </Typography>
          <Typography
            sx={{ color: "#64646D", maxWidth: "394px" }}
            pt={{ xs: "18px", md: "24px" }}
            pb={{ xs: "20px", md: "48px" }}
          >
            {t('home.banner.subtxt')}
          </Typography>
          <CustomButton onClick={handleButtonClick}>{t('home.header.btntxt')}</CustomButton>{" "}
        </Box>
        <Box mx={"auto"} mt={{xs:5,tab:0}}>
          <img src={card} alt="cards" className="card-img" />
        </Box>
      </Box>
    </Box>
  );
};

export default CardBanner;
