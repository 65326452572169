import { INFO_DETAILS_COMPANY, INFO_DETAILS_COMPANY_ERROR, INFO_DETAILS_COMPANY_SUCCESS } from "../types/AfterLoginPartnerInfo";

  const initialState = {
    loading: false,
    error: null,
    data: {},
  };
  
  // The reducer function
  const AfterLoginPartnerInfoReducer = (state = initialState, action) => {
    switch (action.type) {
      case INFO_DETAILS_COMPANY:
        return {
          ...state,
          loading: true,
          error: null,
        };
    
      case INFO_DETAILS_COMPANY_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload, // Update the customer data with the payload
          error: null,
        };
      case INFO_DETAILS_COMPANY_ERROR:
        return {
          ...state,
          loading: false,
          data: null,
          error: action.payload, // Set the error with the payload
        };
      default:
        return state;
    }
  };
  
  
  
  export default AfterLoginPartnerInfoReducer;
  