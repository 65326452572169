import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import cart from "../../assets/cards/ps1.svg";
import truck from "../../assets/cards/ps2.svg";
import money from "../../assets/cards/ps3.svg";
import { useTranslation } from "react-i18next";
const PartnerUses = () => {
  const { t } = useTranslation();
  const content = [
    {
      tag: cart,
      title: `${t("howitworks.stepone")}`,
      text: `${t("partnercardpage.steponetxt")}`,
    },
    {
      tag: truck,
      title: `${t("howitworks.steptwo")}`,
      text: `${t("partnercardpage.steptwotxt")}`,
    },
    {
      tag: money,
      title: `${t("howitworks.stepthree")}`,
      text: `${t("partnercardpage.stepthreetxt")}`,
    },
  ];
  return (
    <Box
      sx={{ background: "#fff", padding: { md: "80px 0px", xs: "40px 0px" } }}
    >
      <Typography
        sx={{
          color: "#212121",
          fontWeight: "700",
          fontSize: { xs: "30px", md: "40px" },
          lineHeight: { xs: "36px", md: "56px" },
          textAlign: "center",
        }}
        pb={2}
      >
        {t("giftcardpage.howtouse.htouse")}
      </Typography>
      <Grid
        container
        display="flex"
        gap="28px"
        justifyContent="center"
        flexWrap="wrap"
      >
        {content.map((item,index) => (
          <Grid item sx={{ margin: "8px" }} key={index}>
            <Box
              sx={{
                width: "100%",
                maxWidth: "315px",
                background: "#D9DCF9",
                borderRadius: "16px",
                height: "320px",
              }}
              p={"30px"}
            >
              <Box
                sx={{
                  background: "#fff",
                  width: "80px",
                  height: "80px",
                  borderRadius: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={item.tag} alt="cart" />
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#212121",
                    fontSize: { xs: "28px", sm: "32px" },
                    fontWeight: 600,
                  }}
                  mt={"50px"}
                >
                  {item.title}
                </Typography>
                <Typography
                  sx={{
                    color: "#212121",
                    fontWeight: 600,
                    fontSize: "17px",
                  }}
                  mt={2}
                >
                  {item.text}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PartnerUses;
