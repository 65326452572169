import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import {
  PERSON_APPLE_lOGIN,
  PERSON_APPLE_lOGIN_FAILURE,
  PERSON_APPLE_lOGIN_SUCCESS,
  PERSON_FACEBOOK_lOGIN,
  PERSON_FACEBOOK_lOGIN_FAILURE,
  PERSON_FACEBOOK_lOGIN_SUCCESS,
  PERSON_GOOGLE_lOGIN,
  PERSON_GOOGLE_lOGIN_FAILURE,
  PERSON_GOOGLE_lOGIN_SUCCESS,
} from "../types/SocialMediaLoginPerson";

function* personGoogleLoginSaga() {
  try {
    const response = yield call(() => baseURL.get(`/auth/google`));
    yield put({ type: PERSON_GOOGLE_lOGIN_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: PERSON_GOOGLE_lOGIN_FAILURE, payload: error });
  }
}

function* personFacebookLoginSaga(action) {
  try {
    const response = yield call(() =>
      baseURL.post(`/auth/resend-otp`, action.payload)
    );
    yield put({ type: PERSON_FACEBOOK_lOGIN_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: PERSON_FACEBOOK_lOGIN_FAILURE, payload: error });
  }
}

function* personAppleLoginSaga(action) {
  try {
    const response = yield call(() =>
      baseURL.post(`/auth/resend-otp`, action.payload)
    );
    yield put({ type: PERSON_APPLE_lOGIN_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: PERSON_APPLE_lOGIN_FAILURE, payload: error });
  }
}

export default function* watchMediaPersonLogin() {
  yield takeLatest(PERSON_GOOGLE_lOGIN, personGoogleLoginSaga);
  yield takeLatest(PERSON_FACEBOOK_lOGIN, personFacebookLoginSaga);
  yield takeLatest(PERSON_APPLE_lOGIN, personAppleLoginSaga);
}
