import { UPDATE_CART_FAILURE, UPDATE_CART_REQUEST, UPDATE_CART_SUCCESS } from "../types/AddToCart";

  const initialState = {
    loading: false,
    error: null,
    data: {},
  };
  
  // The reducer function
  const EditAddToCartReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_CART_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case UPDATE_CART_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload, // Update the customer data with the payload
          error: null,
        };
      case UPDATE_CART_FAILURE:
        return {
          ...state,
          loading: false,
          data: null,
          error: action.payload, // Set the error with the payload
        };
      default:
        return state;
    }
  };
  
  export default EditAddToCartReducer;
  