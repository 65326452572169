import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  birthdayStickers,
  weddingStickers,
  NewYearStickers,
} from "../../../src/store/actions/GifiSearchAction";
import Loadingbar from "../../utils/Loadingbar";
import { isAuthenticated } from "../../utils/authService";

const Section = ({ title, items, data,loading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToPartnersCard = (url) => {
    if (isAuthenticated()) {
      window.scrollTo({ top: 0, behavior: "smooth" });

      navigate(`/gift-card/`, { state: { url } });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });

      navigate("/login");
    }
  };
  
  const handleClick = (data) => {
    if (isAuthenticated()) {
      window.scrollTo({ top: 0, behavior: "smooth" });

      navigate(`/gif-show/`, { state: { data } });

    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });

      navigate("/login");

    }
  };
  

  return (
    <Box ml={"7%"} pb={5}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        pb={"2rem"}
      >
        <Typography
          sx={{ fontSize: { xs: "1.4rem", sm: "2rem" }, fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <Button
          variant="text"
          endIcon={<ArrowRightAltIcon />}
          sx={{ color: "#4254EA" }}
          size="small"
          onClick={() => handleClick(data)}
        >
          {t("seemore")}
        </Button>
      </Box>
      {loading ? (
        <Loadingbar />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          gap="1.8rem"
          sx={{
            overflowX: "auto",
            cursor: "pointer",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {items?.map((item, index) => (
            <Box key={index} onClick={() =>  navigateToPartnersCard(item.url)}>
              <Box>
                <img
                  src={item.url}
                  alt="hello"
                  style={{
                    width: "260px",
                    height: "320px",
                    objectFit: "contain",
                    background: "#f2f2f2",
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

const GiftItems = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const birthday = useSelector((state) => state.GIFDataReducer?.data_bir);
  const wedding = useSelector((state) => state.GIFDataReducer?.data_wed);
  const newYear = useSelector((state) => state.GIFDataReducer?.data_Year);
  const loading = useSelector((state) => state.GIFDataReducer.loading);

  useEffect(() => {
    dispatch(birthdayStickers("Happy Birthday", 10));
    dispatch(weddingStickers("Wedding Love", 10));
    dispatch(NewYearStickers("Christmas and NewYear", 10));
  }, [dispatch]);

  const sections = [
    { title: t("home.category.bd"), items: birthday, data: "Happy Birthday" },
    {
      title: t("home.category.chrisny"),
      items: newYear,
      data: "Christmas And New Year",
    },
    {
      title: t("home.category.weddings"),
      items: wedding,
      data: "Weddings",
    },
  ];

  return (
    <Box mt={"80px"}>
      {sections?.map((section, index) => (
        <Section
          key={index}
          title={section.title}
          items={section.items}
          data={section.data}
          loading={loading}
        />
      ))}
    </Box>
  );
};

export default GiftItems;
