export const REGISTER_PERSON = "REGISTER_PERSON";
export const REGISTER_PERSON_SUCCESS = "REGISTER_PERSON_SUCCESS";
export const REGISTER_PERSON_ERROR = "REGISTER_PERSON_ERROR";
export const REGISTER_PERSON_INFO = "REGISTER_PERSON_INFO";

export const REGISTER_OTP = "REGISTER_OTP";
export const REGISTER_OTP_SUCCESS = "REGISTER_OTP_SUCCESS";
export const REGISTER_OTP_ERROR = "REGISTER_PERSON_ERROR";

export const CHECK_REGISTER_OTP = "CHECK_REGISTER_OTP";
export const CHECK_REGISTER_OTP_SUCCESS = "CHECK_REGISTER_OTP_SUCCESS";
export const CHECK_REGISTER_OTP_ERROR = "CHECK_REGISTER_PERSON_ERROR";

export const RESEND_REGISTER_OTP = "RESEND_REGISTER_OTP";
export const RESEND_REGISTER_OTP_SUCCESS = "RESEND_REGISTER_OTP_SUCCESS";
export const RESEND_REGISTER_OTP_ERROR = "RESEND_REGISTER_PERSON_ERROR";