import React from "react";
import { Container } from "@mui/material";
import TabRegister from "../../components/Register/TabRegister";

const RegisterPage = () => {
  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: 0, paddingRight: 0, background: "#F2F2F7" }}
    >
      <TabRegister />
    </Container>
  );
};

export default RegisterPage;
