import { Box, Breadcrumbs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useLocation } from "react-router-dom";
import PersonLogin from "./PersonLogin";
import { CompanyLogin } from "./CompanyLogin";
import { useTranslation } from "react-i18next";
const TabsLogin = () => {
  const [logintype, setLogintype] = useState("person");
  const [show, setShow] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    if (logintype === "person") {
      localStorage.setItem("userType", "PR");
    } else {
      localStorage.setItem("userType", "CM");
    }
  }, [logintype]);
  return (
    <Box mx={"7%"}>
      <Box>
        <Breadcrumbs
          separator={<ArrowForwardIosIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ color: "#BABABA" }}
          py={2}
        >
          <Typography
            style={{
              color: logintype === "person" ? "black" : "#BABABA",
            }}
          >
            <Link
              to="/"
              style={{
                color: location.pathname === "/" ? "black" : "#BABABA",
              }}
            >
              {t("giftcardpage.main")}
            </Link>
          </Typography>
          <Typography
            style={{
              color:
                logintype === "person" || logintype === "company"
                  ? "black"
                  : "#BABABA",
            }}
          >
            {logintype === "person" ? "Login person" : "Login partner"}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box py={{ xs: "30px", tab: "60px" }}>
        <Box
          sx={{
            bgcolor: "#fff",
            maxWidth: "592px",
            width: "100%",
            padding: { xs: "30px 20px", tab: "40px 116px" },
            borderRadius: "16px",
            mx: "auto",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              color: "#212121",
              textAlign: "center",
              mb: "24px",
            }}
          >
            {t("loginRegister.welcomeBack")}
          </Typography>
          {!show && (
            <Box
              sx={{
                background: "#7676801f",
                maxWidth: "385px",
                width: "100%",
                padding: "2px",
                borderRadius: "8px",
                mx: "auto",
              }}
              display="flex"
              justifyContent="center"
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  background: logintype === "person" ? "#FFF" : "transparent",
                  maxWidth: "192.5px",
                  width: "100%",
                  textAlign: "center",
                  padding: "6px",
                  borderRadius: "7px",
                  cursor: "pointer",
                }}
                onClick={() => setLogintype("person")}
              >
                {t("loginRegister.Person")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  background: logintype === "company" ? "#FFF" : "transparent",
                  maxWidth: "192.5px",
                  width: "100%",
                  textAlign: "center",
                  padding: "6px",
                  borderRadius: "7px",
                  cursor: "pointer",
                }}
                onClick={() => setLogintype("company")}
              >
                {t("loginRegister.Company")}
              </Typography>
            </Box>
          )}
          <Box>
            {logintype !== "person" ? (
              <CompanyLogin setShow={setShow} show={show} />
            ) : (
              <PersonLogin setShow={setShow} show={show} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TabsLogin;
