import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { styled } from "@mui/system";
import CustomButton from "../../global/CustomButton";
import CommonBreadcrumb from "../../global/CommonBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginPersonOtp,
  checkLoginOtpPer,
  resendLoginOtpPer,
} from "../../../store/actions/LoginOtpPersonAction";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const MuiOtpInputStyled = styled(MuiOtpInput)({
  "& .MuiOtpInput-TextField": {
    background: "#F2F2F7",
    border: "none",
    width: "48px",
    height: "48px",
    textAlign: "center",
  },
  "& fieldset": { border: "none" },
});

const CompanyOtpLogin = () => {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [resendText, setResendText] = useState("Resend Code");
  const [otpError, setOtpError] = useState("");
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const otpNumb = useSelector(
    (state) => state.SendOtpLoginPerson.info.phone_number
  );
  const otpchecksuccess = useSelector(
    (state) => state?.CheckOtpLoginPerReducer?.data?.status
  );
  const otpcheckerror = useSelector(
    (state) => state.CheckOtpLoginPerReducer.error
  );
  const resendSucess = useSelector(
    (state) => state.LoginResendOtpPerson.data.status
  );
  const loginOtpSuccess = useSelector(
    (state) => state.LoginOtpPersonReducer.loginData.status
  );

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleNext = () => {
    if (otp === "") {
      setOtpError("OTP cannot be empty");
    } else if (otp.length !== 4) {
      setOtpError("OTP should be 4 characters long");
    } else {
      setOtpError("");
      const otpnum = parseInt(otp);
      const payload = {
        phone_number: otpNumb,
        otp: otpnum,
      };
      dispatch(checkLoginOtpPer(payload));
    }
  };

  const handleResendClick = () => {
    setTimer(60);
    setResendText("Resending...");
    const payload = {
      phone_number: otpNumb,
    };
    dispatch(resendLoginOtpPer(payload));
  };

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setResendText("Resend Code");
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (timer === 0) {
      setResendText("Resend Code");
    }
  }, [timer]);

  useEffect(() => {
    if (otpchecksuccess === 200) {
      setTimer(0);
      const payload = {
        phone_number: otpNumb,
      };
      dispatch(LoginPersonOtp(payload));
    }
  }, [otpchecksuccess]);

  useEffect(() => {
    if (resendSucess === 200) {
      toast.success("OTP Resend Successfully");
      setTimer(60);
    }
  }, [resendSucess]);

  useEffect(() => {
    if (loginOtpSuccess === 200) {
      setTimer(0);
      navigation("/");
    }
  }, [loginOtpSuccess]);

  return (
    <Box>
      <Box>
        <Box>
          <Box mb="24px">
            <Typography
              sx={{
                color: "#212121",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {t("loginRegister.enterFourDigitOtpLogin")}
            </Typography>
          </Box>
          <Box my="16px">
            <Box display="flex" justifyContent="center">
              <MuiOtpInputStyled
                value={otp}
                onChange={handleChange}
                autoFocus
              />
            </Box>
            <Typography
              color="error"
              sx={{ fontSize: "14px", textAlign: "center", mt: "10px" }}
            >
              {otpError}
            </Typography>
            <Typography
              mt="32px"
              sx={{
                color: timer > 0 ? "#BABABA" : "#212121",
                fontSize: "12px",
                fontWeight: 500,
                fontFamily: "Montserrat",
                textAlign: "center",
                textDecoration: !timer > 0 && "underline",
              }}
            >
              {timer > 0 ? (
                `Resend SMS Code in ${timer} seconds`
              ) : (
                <span onClick={handleResendClick}>{resendText}</span>
              )}
            </Typography>
          </Box>
          <Box mt="60px">
            <CustomButton onClick={handleNext} width="100%" fs="18px">
              {t("formLable.continue")}
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyOtpLogin;
