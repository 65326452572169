import React, { useState } from "react";
import PartnersCards from "./PartnersCards";
import { Box, Button } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useTranslation } from "react-i18next";
import Loadingbar from "../../utils/Loadingbar";

const PartnersBlocks = ({ allData, loading, onCardClick, errorCheck }) => {
  const { t } = useTranslation();
  const [visibleItems, setVisibleItems] = useState(12);
  const handleShowMore = () => {
    setVisibleItems((prev) => prev + 12);
  };

  return (
    <div>
      {loading ? (
        <Box>
          <Loadingbar />
        </Box>
      ) : (
        <Box
          display="flex"
          flexWrap="wrap"
          gap="2rem"
          justifyContent="center"
          flexDirection={"column"}
        >
          {errorCheck === 404 ? (
            <Box>No Data Found</Box>
          ) : (
            <Box
              display="flex"
              flexWrap="wrap"
              gap="2rem"
              justifyContent="center"
            >
              {allData &&
                allData?.length > 0 &&
                allData
                  .slice(0, visibleItems)
                  .map((item, index) => (
                    <PartnersCards
                      key={index}
                      title={item.company_name}
                      display={item.product_images[0]}
                      describe={item.about_company}
                      onCardClick={onCardClick}
                      id={item._id}
                    />
                  ))}
            </Box>
          )}

          {allData && allData.length > visibleItems && (
            <Box sx={{ textAlign: "center" }} mt={{ sm: 5, xs: 3 }}>
              <Button
                onClick={handleShowMore}
                sx={{
                  color: "#4254EA",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
                endIcon={<KeyboardDoubleArrowRightIcon />}
              >
                {t("showmore")}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};

export default PartnersBlocks;
