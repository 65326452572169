// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iframe-container {
  position: relative;

}

.map-iframe {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease; /* Transition property for opacity */
}

.map-iframe.fade-in {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/ContactUs/MapComponent.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;;AAEpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,UAAU;EACV,6BAA6B,EAAE,oCAAoC;AACrE;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".iframe-container {\n  position: relative;\n\n}\n\n.map-iframe {\n  position: absolute;\n  top: 0;\n  left: 0;\n  opacity: 0;\n  transition: opacity 0.5s ease; /* Transition property for opacity */\n}\n\n.map-iframe.fade-in {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
