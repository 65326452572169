import {
  ADD_TO_CART_REQUEST, SEND_TO_CHECKOUT,
} from "../types/AddToCart";

export const addToCart = (data) => {
    return {
      type: ADD_TO_CART_REQUEST,
      payload: data,
    };
  };

  export const sendCheckout = (data) => {
    return {
      type: SEND_TO_CHECKOUT,
      payload: data,
    };
  };
  
  

