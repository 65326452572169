import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import clip from "../../assets/copyboard.svg";
import "./basicModal.css";
import CustomButton from "./CustomButton";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { generatePromocodeClean } from "../../store/actions/GeneratePromoCodeAction";
import { Button } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 680,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  testAlign: "center",
  mx: "auto",
  p: 4,
};

//   backgroundImage: `url(${borderImage})`, // Apply the border image

export default function BasicModal({
  handleCodeGenerate,
  open,
  setOpen,
  codeSuccess,
  siteLink,
  codeLoader,
}) {
  const clipboardText = codeSuccess?.promo_code;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpen = () => {
    handleCodeGenerate();
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(generatePromocodeClean());
  };
  const handleButtonClick = () => {
    window.open(siteLink, "_blank");
  };
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard:", text);
        // You can add further logic or state updates here after successful copy
      })
      .catch((error) => {
        toast.error("Error copying to clipboard:", error);
      });
  };

  return (
    <div>
      <Button
        sx={{
          padding: { tab: "10px 40px", xs: "10px 20px" },
          borderRadius: "0px 30px",
          color: "#fff",
          fontWeight: 700,
          backgroundColor: "#4254EA",
          width: "100%",
          fontSize: { sm: "16px", xs: "14px" },
          fontFamily: "Montserrat",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "#4254EA",
          },
          "&:active": {
            backgroundColor: "#4254EA",
          },
          textTransform: "none",
        }}
        onClick={handleOpen}
        disabled={codeLoader}
      >
        {codeLoader ? "loading..." : t("partnercardpage.getcode")}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="basic-modal">
          <CloseIcon onClick={handleClose} sx={{ float: "right" }} />

          <Box
            className="class-center"
            sx={{ padding: { tab: "40px 80px", xs: "40px 10px" } }}
          >
            <Typography
              sx={{
                fontSize: { sm: "30px", xs: "24px" },
                fontWeight: "700",
                color: "#212121",
              }}
            >
              {t("partnercardpage.yourcode")}
            </Typography>
            <Typography
              sx={{
                fontWeight: "500",
                color: "#212121",
                fontSize: "16px",
                my: "12px",
              }}
            >
              {t("partnercardpage.giftcardone")} {codeSuccess?.nominal}{" "}
              {t("partnercardpage.giftcardtwo")}
            </Typography>
            <Box
              maxWidth="304px"
              borderRadius="16px"
              width="100%"
              bgcolor="#F2F2F7"
              p={5}
              textAlign="center"
              mx={"auto"}
              mb={"8px"}
            >
              <Box
                bgcolor="#FFF"
                borderRadius="16px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ padding: "12px 36px" }}
              >
                <p>{clipboardText}</p>
                <p>
                  <img
                    src={clip}
                    alt="copy"
                    onClick={() => copyToClipboard(clipboardText)}
                  />
                </p>
              </Box>
            </Box>
            <Typography
              sx={{
                fontWeight: "500",
                color: "#212121",
                fontSize: "13px",
                maxWidth: "384px",
                width: "100%",
                mx: "auto",
              }}
            >
              {t("partnercardpage.subtext")}
            </Typography>
            <Typography
              sx={{
                fontWeight: "700",
                color: "#212121",
                fontSize: "17px",
                mt: "12px",
                mb: "28px",
              }}
            >
              {t("partnercardpage.happyshopping")}
              <span>
                {" "}
                <LocalMallIcon fontSize="12px" sx={{ color: "pink" }} />
              </span>
            </Typography>
            <CustomButton onClick={handleButtonClick}>
              {t("partnercardpage.btn")}
            </CustomButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
