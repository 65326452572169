import { CHECK_LOGIN_OTP_PERSON, LOGIN_OTP_PERSON, LOGIN_OTP_PERSON_SAVE, LOGIN_WITH_OTP_PERSON, RESEND_LOGIN_OTP_PERSON } from "../types/LoginOTP"

export const loginOtpPer =(data)=>({
    type: LOGIN_OTP_PERSON,
    payload: data
})

export const SaveNumloginOtpPer =(data)=>({
    type: LOGIN_OTP_PERSON_SAVE,
    payload: data
})


export const checkLoginOtpPer =(data)=>({
    type: CHECK_LOGIN_OTP_PERSON,
    payload: data
})

export const resendLoginOtpPer =(data)=>({
    type: RESEND_LOGIN_OTP_PERSON,
    payload: data
})

export const LoginPersonOtp =(data)=>({
    type: LOGIN_WITH_OTP_PERSON,
    payload: data
})