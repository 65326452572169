import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import card from "../../assets/purchasecard.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchHistoryPurchase } from "../../store/actions/HistoryPurchaseAction";
import Loadingbar from "../../utils/Loadingbar";
import { useTranslation } from "react-i18next";

const headtxt = {
  color: "#BABABA",
  fontSize: { xs: "13px", sm: "14px", tab: "12px", md: "14px" },
};
const subtxt = {
  fontSize: { xs: "13px", sm: "14px", tab: "12px", md: "14px" },
  color: "#212121",
  fontWeight: 500,
};

const Purchases = () => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const token = localStorage.getItem("authToken");
  const userData = useSelector((state) => state.getUser.user);
  const loadingData = useSelector((state) => state?.purchaseHistory?.loading);
  const dispatch = useDispatch();
  const historyData = useSelector(
    (state) => state?.purchaseHistory?.history?.getPurchases
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (token && userData && userData.data && userData.data.length > 0) {
      const userId = userData.data[0]._id;
      dispatch(fetchHistoryPurchase(userId, token));
    }
  }, [token, userData]);
  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            color: "#212121",
            fontWeight: 600,
            mb: "24px",
          }}
        >
          {t("sidebarView.yourPurchase")}
        </Typography>
      </Box>
      {loadingData ? (
        <Loadingbar />
      ) : (
        <Box>
          {historyData && historyData.length > 0 ? (
            historyData?.map((data, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                sx={{
                  marginBottom: "8px",
                  borderBottom: "none",
                  boxShadow: "none",
                  padding: "20px 10px",
                  borderRadius: "8px",
                  border: "none",
                  "&:before": {
                    display: "none",
                  },
                  "&.MuiAccordion-root:first-of-type": {
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  },
                  "&.MuiAccordion-root:last-of-type": {
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{
                    "& .css-i4bv87-MuiSvgIcon-root": {
                      marginTop: {
                        xs: expanded === `panel${index}` ? "30px" : "-30px",
                        sm: "auto",
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      maxWidth: "700px",
                      width: "100%",
                      alignItems: { sm: "center" },
                      justifyContent: "space-between",
                      flexDirection: { sm: "row", xs: "column" },
                      gap: "10px",
                    }}
                  >
                    <Box>
                      <Typography sx={subtxt}>
                        {t("sidebarView.Order")} {data.order_no}
                      </Typography>
                    </Box>
                    <Typography sx={subtxt}>${data.amount_subtotal}</Typography>
                    <Typography sx={subtxt}>{data.createdAt}</Typography>
                    <Box
                      sx={{
                        maxWidth: "110px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px 12px",
                        borderRadius: "8px",
                        fontSize: "14px",
                        background:
                          data.delivery_status === "complete"
                            ? "#34C759"
                            : "#34c7591a",
                        color:
                          data.delivery_status === "complete"
                            ? "#fff"
                            : "#34C759",
                      }}
                    >
                      {data.delivery_status}
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Divider />
                    <Box
                      mt="24px"
                      display="flex"
                      alignItems={{ lg: "center" }}
                      justifyContent="center"
                      flexDirection={{ sm: "row", xs: "column" }}
                      gap={{ md: "32px", xs: "20px" }}
                    >
                      <Box
                        sx={{
                          width: "152px",
                          height: "100px",
                          background: "#D8F1FD",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "16px",
                        }}
                      >
                        <img src={card} alt="card" />
                      </Box>
                      <Box
                        display="flex"
                        alignItems={{ lg: "center" }}
                        justifyContent="space-between"
                        flexDirection={{ sm: "row", xs: "column" }}
                        gap="16px"
                        width="100%"
                      >
                        <Box>
                          <Typography sx={subtxt}>
                            Lahjakamu {t("giftcardpage.giftcard")}
                          </Typography>
                          <Box
                            display="flex"
                            alignItems={{ lg: "center" }}
                            justifyContent={{ lg: "space-between" }}
                            mt="10px"
                            flexWrap="wrap"
                            flexDirection={{
                              xs: "row",
                              sm: "column",
                              lg: "row",
                            }}
                            gap={{ xs: "9px", lg: "40px" }}
                          >
                            <Box display="flex" alignItems="center" gap="12px">
                              <Typography sx={headtxt}>
                                {t("sidebarView.devType")}
                              </Typography>
                              <Typography sx={subtxt}>
                                {data.delivery_type || ""}{" "}
                              </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" gap="12px">
                              <Typography sx={headtxt}>
                                {t("sidebarView.Nom")}
                              </Typography>
                              <Typography sx={subtxt}>
                                ${data.amount_subtotal || ""}
                              </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" gap="12px">
                              <Typography sx={headtxt}>
                                {t("sidebarView.amt")}
                              </Typography>
                              <Typography sx={subtxt}>
                                {data.quantity || ""}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <Typography sx={subtxt}>
                            {t("sidebarView.subtotal")}: $
                            {data.amount_subtotal || ""}{" "}
                          </Typography>
                          <Typography sx={subtxt} my="12px">
                            {t("checkoutpage.Delivery")}: ${data.delivery || ""}
                          </Typography>
                          <Typography sx={{ ...subtxt, fontWeight: "bold" }}>
                            {t("checkoutpage.total")}: $
                            {data.amount_total || ""}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Box sx={{ color: "gray", fontSize: "16px", textAlign: "left" }}>
              {t("sidebarView.purHistory")}{" "}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Purchases;
