import { RESEND_LOGIN_OTP_PERSON, RESEND_LOGIN_OTP_PERSON_ERROR, RESEND_LOGIN_OTP_PERSON_SUCCESS } from "../types/LoginOTP";

  const initialState = {
    loading: false,
    error: null,
    data: {},
  };
  
  // The reducer function
  const LoginResendOtpPerson = (state = initialState, action) => {
    switch (action.type) {
      case RESEND_LOGIN_OTP_PERSON:
        return {
          ...state,
          loading: true,
          error: null,
        };
    
      case RESEND_LOGIN_OTP_PERSON_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload, // Update the customer data with the payload
          error: null,
        };
      case RESEND_LOGIN_OTP_PERSON_ERROR:
        return {
          ...state,
          loading: false,
          data: null,
          error: action.payload, // Set the error with the payload
        };
      default:
        return state;
    }
  };
  
  export default LoginResendOtpPerson;
  