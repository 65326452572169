import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Navbar from "./components/global/Navbar";
import Footer from "./components/global/Footer";
import GiftCard from "./pages/giftCard/GiftCard";
import PartnerCard from "./pages/partnerCard/PartnerCard";
import CartItem from "./pages/cartItem/CartItem";
import SuccessPage from "./components/acknowledgement/SuccessPage";
import FaiurePage from "./components/acknowledgement/FaiurePage";
import AboutUs from "./pages/aboutUs/AboutUs";
import CheckOut from "./pages/cartItem/CheckOut";
import FAQPage from "./pages/faqPage/FAQPage";
import ContactUs from "./pages/contactUs/ContactUs";
import HowItWorks from "./pages/howItWorks/HowItWorks";
import { Suspense, useEffect, useState } from "react";
import LocaleContext from "./LocaleContext";
import i18n from "./i18n";
import AllPartners from "./pages/allPartners/AllPartners";
import LoginPage from "./pages/Login/LoginPage";
import PhoneValidation from "./components/Login/ForgetPassword/PhoneValidation";
import OTPValidation from "./components/Login/ForgetPassword/OTPValidation";
import ResetPassword from "./components/Login/ForgetPassword/ResetPassword";
import SuccessValidation from "./components/Login/ForgetPassword/SuccessValidation";
import SidebarPerson from "./components/PersonAccount/SidebarPerson";
import MyAccount from "./components/PersonAccount/MyAccount";
import GiftCardAccount from "./components/PersonAccount/GiftCardAccount";
import Promocodes from "./components/PersonAccount/Promocodes";
import Purchases from "./components/PersonAccount/Purchases";
import Security from "./components/PersonAccount/Security";
import RegisterPage from "./pages/Login/RegisterPage";
import CompanyAccount from "./components/CompanyProfile/CompanyAccount";
import CompanyProduct from "./components/CompanyProfile/CompanyProduct";
import CompanySecurity from "./components/CompanyProfile/CompanySecurity";
import SideBarCompany from "./components/CompanyProfile/SideBarCompany";
import RegisterOTP from "./components/Register/Person/RegisterOTP";
import SuccessfullRegister from "./components/Register/Person/SuccessfullRegister";
import RegisterCompanySuccess from "./components/Register/Company/RegisterCompanySuccess";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./utils/ProtectedRoute";
import { fetchSingleUserRequest } from "./store/actions/GetUserAction";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { setTokenToLocalStorage } from "./utils/authService";
import GIFCardView from "./pages/gifCardView/GIFCardView";
function App() {
  const [locale, setLocale] = useState(i18n.language);
  const userType = localStorage.getItem("userType");
  const Loading = () => {
    return <>Loading.....</>;
  };
  const handleChange = async (event) => {
    await i18n.changeLanguage(event.target.value);
  };
  useEffect(() => {
    i18n.on("languageChanged", (lng) => setLocale(i18n.language));
  }, [locale]);
  const [decodedToken, setDecodedToken] = useState(null);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.getUser.user);
  const userData1 = useSelector((state) => state.getUser.error);
  useEffect(() => {
    // Get the token from localStorage
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      try {
        // Decode the token
        const decoded = jwtDecode(storedToken);
        // Set the decoded token in state
        setDecodedToken(decoded);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken && decodedToken !== "" && decodedToken?.user_id) {
      const userId = decodedToken?.user_id;
      const token = storedToken;
      dispatch(fetchSingleUserRequest(userId, token));
    }
  }, [decodedToken]);

  return (
    <div className="app-css">
      <LocaleContext.Provider value={{ locale, setLocale }}>
        <Suspense fallback={<Loading />}>
          <BrowserRouter>
            <ToastContainer />
            <Navbar locale={locale} handleChange={handleChange} />
            <Routes>
              <Route path="/" element={<Home />} />

              <Route path="/thank-you" element={<SuccessPage />} />
              <Route path="/failure" element={<FaiurePage />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/gif-show" element={<GIFCardView />} />
                <Route path="/all-partners" element={<AllPartners />} />
                <Route path="/gift-card" element={<GiftCard />} />
                <Route path="/partners-card/:id" element={<PartnerCard />} />
                <Route path="/add-cart" element={<CartItem />} />
                <Route path="/checkout" element={<CheckOut />} />

                {userType === "CM" ? (
                  <Route path="company-account" element={<SideBarCompany />}>
                    <Route index element={<CompanyAccount />} />
                    <Route
                      path="company-Profile"
                      element={<CompanyAccount />}
                    />
                    <Route path="add-product" element={<CompanyProduct />} />
                    <Route
                      path="partner-security"
                      element={<CompanySecurity />}
                    />
                  </Route>
                ) : (
                  <Route path="person-account" element={<SidebarPerson />}>
                    <Route index element={<MyAccount />} />
                    <Route path="my-account" element={<MyAccount />} />
                    <Route path="gift-card" element={<GiftCardAccount />} />
                    <Route path="promocodes" element={<Promocodes />} />
                    <Route path="purchases" element={<Purchases />} />
                    <Route path="security" element={<Security />} />
                  </Route>
                )}
              </Route>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/forgot-pwd" element={<PhoneValidation />} />
              <Route
                path="/forgot-pwd/successfully-reset"
                element={<SuccessValidation />}
              />

              <Route path="/person-register/otp" element={<RegisterOTP />} />
              <Route
                path="/person-register/success-register"
                element={<SuccessfullRegister />}
              />
              <Route
                path="/company-register/success-register"
                element={<RegisterCompanySuccess />}
              />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
              <Route path="/faq-section" element={<FAQPage />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="*" element={<Home />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </Suspense>
      </LocaleContext.Provider>
    </div>
  );
}

export default App;
