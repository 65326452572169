import {
    CHECK_REGISTER_OTP, CHECK_REGISTER_OTP_ERROR, CHECK_REGISTER_OTP_SUCCESS,
    
  } from "../types/RegisterPerson";
  const initialState = {
    loading: false,
    error: null,
    data: {},
  };
  
  // The reducer function
  const CheckRegPerOTPReducer = (state = initialState, action) => {
    switch (action.type) {
      case CHECK_REGISTER_OTP:
        return {
          ...state,
          loading: true,
          error: null,
        };
    
      case CHECK_REGISTER_OTP_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload, // Update the customer data with the payload
          error: null,
        };
      case CHECK_REGISTER_OTP_ERROR:
        return {
          ...state,
          loading: false,
          data: null,
          error: action.payload, // Set the error with the payload
        };
      default:
        return state;
    }
  };
  
  export default CheckRegPerOTPReducer;
  