import { Box, Breadcrumbs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PartnersFilter from "./PartnersFilter";
import PartnersBlocks from "./PartnersBlocks";
import { filterRequest } from "../../store/actions/PartnerFilterAction";
import { useDispatch, useSelector } from "react-redux";

const AllPartnersPage = () => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedWhom, setSelectedWhom] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allData = useSelector((state) => state?.filterData?.data?.data);
  const loading = useSelector((state) => state?.filterData?.loading);
  const errorCheck = useSelector((state) => state?.filterData?.error?.status);
  const path = [
    { path: "/", label: `${t("giftcardpage.main")}` },
    { path: "/all-partners", label: `Partners` },
  ];

  const handleCardClick = (id) => {
    navigate(`/partners-card/${id}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(filterRequest(""));
  }, []);

  return (
    <Box mx={"7%"} pb="120px">
      <Box>
        <Breadcrumbs
          separator={<ArrowForwardIosIcon fontSize="8px" />}
          aria-label="breadcrumb"
          sx={{ color: "#BABABA" }}
          py={2}
        >
          {path.map((item, index) => (
            <Typography key={index}>
              <Link
                to={item.path}
                style={{
                  color: location.pathname === item.path ? "black" : "#BABABA",
                }}
              >
                {item.label}
              </Link>
            </Typography>
          ))}
        </Breadcrumbs>
      </Box>
      <Box
        display="flex"
        gap="10px"
        alignItems="center"
        justifyContent="flex-start"
        my={{ xs: "20px", sm: "20px" }}
      >
        <Typography
          sx={{
            color: "#212121",
            fontWeight: "700",
            fontSize: { xs: "28px", md: "40px" },
            lineHeight: { xs: "32px", md: "56px" },
          }}
        >
          {t("navbar.partners")}
        </Typography>
        <Typography
          sx={{
            color: "#BABABA",
            fontWeight: "700",
            fontSize: { xs: "25px", md: "28px", lg: "32px" },
          }}
        >
          ({allData?.length})
        </Typography>
      </Box>
      <Box
        sx={{ background: "#fff", borderRadius: "8px", padding: "16px 30px" }}
      >
        <PartnersFilter
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          selectedWhom={selectedWhom}
          setSelectedWhom={setSelectedWhom}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
        />
      </Box>
      <Box mt="32px">
        <PartnersBlocks
          allData={allData}
          loading={loading}
          onCardClick={handleCardClick}
          errorCheck={errorCheck}
        />
      </Box>
    </Box>
  );
};

export default AllPartnersPage;
