import React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import logo from "../../assets/logo.svg";
import ipay from "../../assets/ipay.svg";
import paypal from "../../assets/paypal.svg";
import gpay from "../../assets/gpay.svg";
import mastercard from "../../assets/mastercard.svg";
import visa from "../../assets/visa.svg";
import fb from "../../assets/facebook.svg";
import insta from "../../assets/instagram.svg";
import music from "../../assets/muisc.svg";
import empat from "../../assets/empat.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Box sx={{ background: "#3634A3" }}>
      <Container maxWidth="xl">
        <Box px={{ xs: "16px", lg: "120px" }} py={"40px"}>
          <Box paddingBottom={{ sm: "110px", xs: "40px" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item xs={12} sm={6} tab={3}>
                <Box mb={2}>
                  <img src={logo} alt="logo" />
                </Box>
                <Box display="flex" gap="8px">
                  <img src={fb} alt="social media logo" />
                  <img src={insta} alt="social media logo" />
                  <img src={music} alt="social media logo" />
                </Box>
              </Grid>
              <Grid item sm={6} tab={3}>
                <Link to="/gift-card" onClick={handleClick}>
                  <Typography fontSize="14px" color="#fff" mb={2}>
                    Lahjakamu {t("navbar.card")}
                  </Typography>
                </Link>
                <Link to="/how-it-works" onClick={handleClick}>
                  <Typography fontSize="14px" color="#fff" mb={2}>
                    {t("navbar.hws")}
                  </Typography>
                </Link>
                <Link to="/about-us" onClick={handleClick}>
                  {" "}
                  <Typography fontSize="14px" color="#fff" mb={2}>
                    {t("navbar.aboutUs")}
                  </Typography>
                </Link>
              </Grid>
              <Grid item sm={6} tab={3}>
                <Link to="/all-partners" onClick={handleClick}>
                  {" "}
                  <Typography fontSize="14px" color="#fff" mb={2}>
                    {t("navbar.partners")}
                  </Typography>
                </Link>
                <Link to="/faq-section" onClick={handleClick}>
                  <Typography fontSize="14px" color="#fff" mb={2}>
                    FAQ
                  </Typography>
                </Link>
                <Link to="/contact-us" onClick={handleClick}>
                  {" "}
                  <Typography fontSize="14px" color="#fff" mb={2}>
                    {t("navbar.contacts")}
                  </Typography>
                </Link>
              </Grid>
              <Grid item sm={6} tab={3}>
                <Link to="/how-it-works" onClick={handleClick}>
                  {" "}
                  <Typography fontSize="14px" color="#fff" mb={2}>
                    {t("footer.payment")}
                  </Typography>
                </Link>
                <Link to="/how-it-works" onClick={handleClick}>
                  {" "}
                  <Typography fontSize="14px" color="#fff" mb={2}>
                    {t("footer.term")}
                  </Typography>
                </Link>
                <Link to="/how-it-works" onClick={handleClick}>
                  {" "}
                  <Typography fontSize="14px" color="#fff" mb={2}>
                    {t("footer.privacy")}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={{ tab: "row", xs: "column" }}
            alignItems="center"
            borderTop="1px solid rgba(255, 255, 255, 0.5)"
            padding="40px 0px 0px 0px"
            gap="1rem"
          >
            <Box>
              <Typography
                sx={{ opacity: "0.5", color: "#fff", fontSize: "14px" }}
              >
                © 2022 {t("footer.copyright")}
              </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap" gap="12px">
              <img src={ipay} alt="payment partner logo" />
              <img src={gpay} alt="payment partner logo" />
              <img src={paypal} alt="payment partner logo" />
              <img src={visa} alt="payment partner logo" />
              <img src={mastercard} alt="payment partner logo" />
            </Box>
            <Box>
              <img src={empat} alt="payment partner logo" />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
