// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-img{
    width: 500px;
}

@media (max-width: 1050px) {
    .card-img{
        width: 400px;
    }
}

@media (max-width: 768px) {
    .card-img{
        max-width:100%;
        height: auto;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Home/CardBanner.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,cAAc;QACd,YAAY;IAChB;AACJ","sourcesContent":[".card-img{\n    width: 500px;\n}\n\n@media (max-width: 1050px) {\n    .card-img{\n        width: 400px;\n    }\n}\n\n@media (max-width: 768px) {\n    .card-img{\n        max-width:100%;\n        height: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
