import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useTranslation } from "react-i18next";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#4254EA",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#4254EA",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#fff",
    borderRadius: "50%",
    fontWeight: "bold",
    zIndex: 1,
    fontSize: "15px",
    width: 16,
    height: 16,
    background: "#4254EA",
    padding: "2px",
  },
  "& .QontoStepIcon-circle": {
    width: 16,
    height: 16,
    borderRadius: "50%",
    backgroundColor: "#fff",
    border: "1px solid #BABABA",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

// const steps = [
//   t("howitworks.stepone"),
//   t("howitworks.steptwo"),
//   t("howitworks.stepthree"),
// ];

export default function ProgressStepper({ openAccordion, width }) {
  const { t } = useTranslation();
  const steps = [
    t("howitworks.stepone"),
    t("howitworks.steptwo"),
    t("howitworks.stepthree"),
  ];
  let activetab = openAccordion.length;
  return (
    <Stack
      sx={{
        width: width ? width : "100%",
        minWidth: "auto",
        mx: "auto",
        mt: { tab: "0px", xs: "1rem" },
      }}
      spacing={4}
    >
      <Stepper
        alternativeLabel
        activeStep={activetab}
        connector={<QontoConnector />}
        sx={{
          "& .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel ": {
            marginTop: "3px !important",
          },
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              sx={{ marginTop: 0 }} // This line removes the top margin
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
