import React, { useEffect, useState } from "react";
import { Box, IconButton, OutlinedInput, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomButton from "../global/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { loginCompany } from "../../store/actions/LoginCompanyTypeAction";
import { useNavigate } from "react-router-dom";
import fb from "../../assets/fbIcon.svg";
import google from "../../assets/google.svg";
import apple from "../../assets/apple.svg";
import PhoneInput from "react-phone-input-2";
import {
  SaveNumloginOtpPer,
  loginOtpPer,
} from "../../store/actions/LoginOtpPersonAction";
import { toast } from "react-toastify";
import CompanyOtpLogin from "./LoginOTP/CompanyOtpLogin";
import { socialLoginUrl } from "../../utils/Constant";
import { useTranslation } from "react-i18next";
import { loginNumberVarify } from "../../store/actions/NumberVarification";

export const CompanyLogin = ({ setShow, show }) => {
  const txt = {
    color: "#64646D",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    ml: "14px",
  };
  const navigation = useNavigate();
  const [formData, setFormData] = useState({
    idvalue: "",
    pwd: "",
  });
  const companyLoginData = useSelector((state) => state.authCompany.loginData);
  useEffect(() => {
    if (companyLoginData && companyLoginData.status === 200) {
      navigation("/");
    }
  }, [companyLoginData, navigation]);

  const [showPassword, setShowPassword] = useState(false);
  const [idError, setIdError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [mobileLogin, setMobileLogin] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [formDataMobile, setFormDataMoble] = useState({
    phoneNumber: "",
  });
  const NumberCheck = useSelector((state) => state.NumberCheck.data);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const otpsendsuccess = useSelector((state) => state.SendOtpLoginPerson.data);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleInputChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const handleCheck = () => {
    if (NumberCheck && NumberCheck?.status === 200) {
      let isValid = true;
      if (!formDataMobile.phoneNumber) {
        setPhoneError("Phone number cannot be empty");
        isValid = false;
      } else if (formDataMobile.phoneNumber.trim().length <= 8) {
        setPhoneError("Invalid Phone Number");
        isValid = false;
      } else {
        setPhoneError("");
      }

      if (isValid) {
        const payload1 = {
          phone_number: formDataMobile.phoneNumber,
        };
        dispatch(SaveNumloginOtpPer(payload1));
        dispatch(loginOtpPer(payload1));
      }
    }
  };

  const handleNumberLogin = () => {
    let isValid = true;
    if (!formDataMobile.phoneNumber) {
      setPhoneError("Phone number cannot be empty");
      isValid = false;
    } else if (formDataMobile.phoneNumber.trim().length <= 8) {
      setPhoneError("Invalid Phone Number");
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (isValid) {
      const payload1 = {
        phone_number: formDataMobile.phoneNumber,
      };
      dispatch(loginNumberVarify(payload1));
    }
  };

  useEffect(() => {
    if (otpsendsuccess.status === 200) {
      toast.success("OTP send Successfully");
      setShow(true);
    }
  }, [otpsendsuccess]);

  const handleLogin = () => {
    let isValid = true;

    if (!formData.idvalue) {
      setIdError("Value cannot be empty");
      isValid = false;
    } else {
      setIdError("");
    }
    if (!formData.pwd) {
      setPasswordError("Password cannot be empty");
      isValid = false;
    } else {
      setPasswordError("");
    }
    if (isValid) {
      // Perform login logic using phoneNumber, countryCode, and password
      const payload = {
        email: formData.idvalue,
        password: formData.pwd,
      };
      dispatch(loginCompany(payload));

      // Example: You might want to send this data to an API for authentication
    }
  };
  const handleFieldChange = (fieldName, value) => {
    if (fieldName === "phoneNumber") {
      value = `+${value}`;
    }
    setFormDataMoble({
      ...formDataMobile,
      [fieldName]: value,
    });
  };

  const handleGoogle = async () => {
    window.open(`${socialLoginUrl}/api/v1/auth/google`, "_self");
    // dispatch(personGoogleLogin());
  };

  const handleFacebook = async () => {
    window.open(`${socialLoginUrl}/api/v1/auth/facebook`, "_self");
    // dispatch(personGoogleLogin());
  };

  const handleApple = async () => {
    window.open(`${socialLoginUrl}/api/v1/auth/apple`, "_self");
    // dispatch(personGoogleLogin());
  };
  const changePasswordhandler = () => {
    navigation("/forgot-pwd");
  };

  useEffect(() => {
    if (NumberCheck && NumberCheck?.status === 200) {
      handleCheck();
    }
  }, [NumberCheck]);
  return (
    <>
      {show ? (
        <CompanyOtpLogin />
      ) : (
        <Box>
          {mobileLogin ? (
            <>
              <Box my="16px">
                <Typography sx={txt}>
                  {t("loginRegister.enterPhnNumber")}
                </Typography>
                <Box display="flex" gap="8px">
                  <PhoneInput
                    country={"fi"}
                    enableSearch={true}
                    value={formDataMobile.phoneNumber}
                    inputStyle={{
                      width: "100%",
                      borderRadius: "8px",
                      border: phoneError
                        ? "1px solid red"
                        : "1px solid #4254ea33",
                      background: "#F8F8F8",
                    }}
                    onChange={(value) =>
                      handleFieldChange("phoneNumber", value)
                    }
                  />
                </Box>
                <Box>
                  <Typography color="error" sx={{ fontSize: "12px" }}>
                    {phoneError}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box my="16px">
                <Typography sx={txt}>{t("formLable.email")}</Typography>
                <OutlinedInput
                  fullWidth
                  value={formData.idvalue}
                  onChange={handleInputChange("idvalue")}
                  sx={{
                    width: { xs: "100%", tab: "355px" },
                    background: "#F8F8F8",
                    "& fieldset": {
                      border:
                        idError !== ""
                          ? "1px solid red"
                          : "1px solid #4254ea33",
                    },
                    borderRadius: "8px",
                  }}
                  placeholder="Enter Input"
                />
                <Typography color="error" sx={{ fontSize: "12px" }}>
                  {idError}
                </Typography>
              </Box>
              <Box my="16px">
                <Typography sx={txt}>
                  {t("loginRegister.enterPassword")}
                </Typography>
                <OutlinedInput
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  value={formData.pwd}
                  onChange={handleInputChange("pwd")}
                  sx={{
                    width: { xs: "100%", tab: "355px" },
                    background: "#F8F8F8",
                    "& fieldset": {
                      border:
                        passwordError !== ""
                          ? "1px solid red"
                          : "1px solid #4254ea33",
                    },
                    borderRadius: "8px",
                  }}
                  placeholder="password"
                  endAdornment={
                    <IconButton
                      onClick={handleTogglePassword}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                />
                <Typography color="error" sx={{ fontSize: "12px" }}>
                  {passwordError}
                </Typography>
              </Box>

              <Box mt="16px" mb="27px">
                <Typography
                  sx={{
                    color: "#4254EA",
                    fontSize: "14px",
                    fontWeight: 600,
                    textAlign: "center",
                    textDecoration: "underline",
                    fontFamily: "Montserrat",
                  }}
                  onClick={changePasswordhandler}
                >
                  {t("loginRegister.forgetPwd")}
                </Typography>
              </Box>
            </>
          )}
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              sx={{
                fontSize: "14px",
                color: "#212121",
                fontWeight: 600,
                fontFamily: "Montserrat",
                cursor: "pointer",
              }}
            >
              {t("loginRegister.loginUsing")}
              {mobileLogin ? (
                <span
                  style={{ color: "#4254EA", textDecoration: "underline" }}
                  onClick={() => setMobileLogin(false)}
                >
                  {t("loginRegister.emailAndPwd")}
                </span>
              ) : (
                <span
                  style={{ color: "#4254EA", textDecoration: "underline" }}
                  onClick={() => setMobileLogin(true)}
                >
                  {t("loginRegister.mobileNumber")}
                </span>
              )}
            </Typography>
          </Box>
          <Box my="35px">
            <CustomButton
              onClick={mobileLogin ? handleNumberLogin : handleLogin}
              width="100%"
            >
              {t("loginRegister.Login")}
            </CustomButton>
          </Box>

          <Box
            mt="32px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                width: { xs: "50px", sm: "100px" },
                color: "#EEEEEF",
                height: "1px",
                background: "#EEEEEF",
              }}
            />
            <Typography
              sx={{
                fontSize: "14px",
                color: "#212121",
                fontWeight: 600,
                mx: 1,
                fontFamily: "Montserrat",
              }}
            >
              {t("loginRegister.orWith")}
            </Typography>{" "}
            <Box
              sx={{
                width: { xs: "50px", sm: "100px" },
                color: "#EEEEEF",
                height: "1px",
                background: "#EEEEEF",
              }}
            />
          </Box>
          <Box
            my={{ xs: "25px", sm: "40px" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="20px"
          >
            <img src={google} alt="google" onClick={handleGoogle} />
            <img src={fb} alt="facebook" onClick={handleFacebook} />
            <img src={apple} alt="apple" onClick={handleApple} />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mx={2}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "#212121",
                fontWeight: 600,
                fontFamily: "Montserrat",
                textAlign: "center",
              }}
            >
              {t("loginRegister.dontHaveAccount")} <span> </span>
              <span
                style={{
                  color: "#4254EA",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => navigation("/register")}
              >
                {t("loginRegister.registerNow")}
              </span>
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
