import {
  LOGIN_CLEANUP,
  LOGIN_OTP_PERSON,
  LOGIN_OTP_PERSON_ERROR,
  LOGIN_OTP_PERSON_SAVE,
  LOGIN_OTP_PERSON_SUCCESS,
} from "../types/LoginOTP";

const initialState = {
  loading: false,
  error: null,
  data: {},
  info: {},
};

// The reducer function
const SendOtpLoginPerson = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_OTP_PERSON_SAVE:
      return {
        ...state,
        info: action.payload,
      };
    case LOGIN_OTP_PERSON:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_OTP_PERSON_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload, // Update the customer data with the payload
        error: null,
      };
    case LOGIN_OTP_PERSON_ERROR:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload, // Set the error with the payload
      };
      case LOGIN_CLEANUP:
        return {
          ...state,
          loading: false,
          data: {},
          error: action.payload, // Set the error with the payload
        };
    default:
      return state;
  }
};

export default SendOtpLoginPerson;
