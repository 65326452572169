import {
  REGISTER_COMPANY,
  REGISTER_COMPANY_ERROR,
  REGISTER_COMPANY_SUCCESS,
} from "../types/RegisterCompanyType";

const initialState = {
  registerdata: {},
  loading: false,
  error: null,
};

// The reducer function
const RegisterCompanyReducer = (state = initialState, action) => {
  switch (action.type){
    case REGISTER_COMPANY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REGISTER_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        registerdata: action.payload, // Update the customer data with the payload
        error: null,
      };
    case REGISTER_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        registerdata: null,
        error: action.payload, // Set the error with the payload
      };
    default:
      return state;
  }
};

export default RegisterCompanyReducer;
