import {
  Box,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import chain from "../../../assets/link.svg";
import CategorySection from "./CategorySection";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../../global/CustomButton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const categories = {
  category: {
    label: "category",
    options: [
      "Home goods",
      "Children's goods",
      "Clothes & Shoes",
      "Cosmetics & Perfumery",
      "Jewelry",
      "Impression",
      "Alcoholic drinks",
      "Pharmacy & Medicine",
      "Household appliances & Electronics",
      "Animal products",
      "Food",
      "Restaurants",
      "Books",
      "Goods for sports",
      "Tourist services",
    ],
  },
  whom: {
    label: "Whom",
    options: [
      "For all",
      "Man",
      "Woman",
      "For two",
      "For children",
      "For beloved",
      "Girl",
      "Guy",
    ],
  },
  city: {
    label: "City",
    options: [
      "Helsinki",
      "Espoo",
      "Tampere",
      "Vantaa",
      "Oulu",
      "Turku",
      "Lapua",
      "Loimaa",
      "Nivala",
    ],
  },
};

const txt = {
  color: "#64646D",
  fontSize: "12px",
  fontWeight: 500,
  fontFamily: "Montserrat",
  opacity: ".8",
  ml: "12px",
};

const SelectCategory = ({
  setProductInfo,
  productInfo,
  onCompanyNameChange,
  onSiteLinkChange,
  onAboutCompanyChange,
  validationErrors,
  handleSave,
  filteredData,
  productAdded,
}) => {
  const [selectedValues, setSelectedValues] = useState({});
  const { t } = useTranslation();

  const handleAddNominal = () => {
    const lastNominal = productInfo.nominals[productInfo.nominals.length - 1];
    if (lastNominal.nominal !== "" && lastNominal.promocode !== "") {
      const newNominal = {
        nominal: "",
        promocode: "",
      };
      setProductInfo((prev) => ({
        ...prev,
        nominals: [...prev.nominals, newNominal],
      }));
    } else {
      toast.error("Please fill in the previous nominal and promo code fields");
    }
  };

  const handleNominalChange = (index, field, value) => {
    const updatedNominals = [...productInfo.nominals]; // Use productInfo.nominals here
    if (field === "promocode" && value.includes(",")) {
      updatedNominals[index][field] = value
        .split(",")
        .map((item) => item.trim());
    } else {
      updatedNominals[index][field] = value;
    }
    setProductInfo((prev) => ({
      ...prev,
      nominals: updatedNominals,
    }));
  };

  const handleCategoryChange = (value) => {
    setProductInfo((prev) => ({
      ...prev,
      category: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleWhomChange = (value) => {
    setProductInfo((prev) => ({
      ...prev,
      whom: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleCityChange = (value) => {
    setProductInfo((prev) => ({
      ...prev,
      city: typeof value === "string" ? value.split(",") : value,
    }));
  };

  return (
    <Box>
      {/* 1st row */}
      <Grid container display="flex" alignItems="center" gap="12px">
        <Grid item mt="16px" xs={12} md={5.5}>
          <Typography sx={txt}>{t("sidebarView.CompanyName")}</Typography>
          <OutlinedInput
            sx={{
              width: "100%",
              background: "#F8F8F8",
              "& fieldset": { border: "1px solid #4254ea33" },
              borderRadius: "8px",
            }}
            inputProps={{
              style: { fontSize: "0.8rem" },
            }}
            value={productInfo.company_name}
            onChange={onCompanyNameChange}
          />
          {validationErrors.company_name && (
            <Typography sx={{ color: "red", fontSize: "12px" }}>
              {validationErrors.company_name}
            </Typography>
          )}
        </Grid>
        <Grid item my="16px" xs={12} md={5.5}>
          <Typography sx={txt}>{t("sidebarView.linkToSite")}</Typography>
          <OutlinedInput
            sx={{
              width: "100%",
              background: "#F8F8F8",
              "& fieldset": { border: "1px solid #4254ea33" },
              borderRadius: "8px",
            }}
            inputProps={{
              style: { fontSize: "0.8rem" },
            }}
            startAdornment={
              <InputAdornment position="end">
                <img src={chain} alt="link icon" />
              </InputAdornment>
            }
            value={productInfo.site_link}
            onChange={onSiteLinkChange}
          />
          {validationErrors.site_link && (
            <Typography sx={{ color: "red", fontSize: "12px" }}>
              {validationErrors.site_link}
            </Typography>
          )}
        </Grid>
      </Grid>
      {/* 2nd row */}
      <Grid container display="flex" gap="12px">
        {/* 3 category come */}
        <Grid
          item
          xs={12}
          md={5.5}
          display="flex"
          flexDirection="column"
          gap="12px"
          flexWrap="wrap"
          sx={{ maxWidth: "440px" }}
        >
          {Object.keys(categories).map((category) => (
            <CategorySection
              key={category}
              category={category}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              categories={categories}
              onCategoryChange={handleCategoryChange}
              onWhomChange={handleWhomChange}
              onCityChange={handleCityChange}
              validationErrors={validationErrors}
              filteredData={filteredData}
            />
          ))}
        </Grid>
        <Grid item xs={12} md={5.5}>
          <Box>
            <Typography sx={txt}>{t("sidebarView.aboutCompany")}</Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={9}
              sx={{
                background: "#F8F8F8",
                width: "100%",
                border: "none",
                wordBreak: "break-all",
                "& fieldset": { border: "1px solid #4254ea33" },
              }}
              inputProps={{
                "aria-label": "Without label",
                maxLength: 1000,
                disableUnderline: true,
              }}
              value={productInfo.about_company}
              onChange={onAboutCompanyChange}
            />
            {validationErrors.about_company && (
              <Typography sx={{ color: "red", fontSize: "12px" }}>
                {validationErrors.about_company}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      {/* 3re row */}
      <Box mt={"24px"}>
        <Typography
          sx={{
            fontSize: "18px",
            color: "#212121",
            fontWeight: 600,
          }}
        >
          {t("sidebarView.Nominals")}
        </Typography>
        {productInfo?.nominals?.map((nominal, index) => (
          <Grid
            container
            display="flex"
            alignItems="center"
            gap={{ sm: "6px", tab: "12px" }}
            key={index}
          >
            <Grid item mt="16px" xs={12} sm={3} md={3}>
              <Typography sx={txt}>{t("checkoutpage.Nominal")}*</Typography>
              <OutlinedInput
                value={nominal.nominal}
                onChange={(e) =>
                  handleNominalChange(index, "nominal", e.target.value)
                }
                sx={{
                  width: "100%",
                  background: "#F8F8F8",
                  "& fieldset": { border: "1px solid #4254ea33" },
                  borderRadius: "8px",
                }}
                inputProps={{
                  style: { fontSize: "0.8rem" },
                }}
              />
            </Grid>
            <Grid item my="16px" display="block" sm={8.7} xs={12} md={8}>
              <Typography sx={txt}>{t("sidebarView.Promocodes")}</Typography>
              <OutlinedInput
                value={nominal.promocode}
                onChange={(e) =>
                  handleNominalChange(index, "promocode", e.target.value)
                }
                sx={{
                  width: "100%",
                  background: "#F8F8F8",
                  "& fieldset": { border: "1px solid #4254ea33" },
                  borderRadius: "8px",
                }}
                inputProps={{
                  style: { fontSize: "0.8rem" },
                }}
              />
            </Grid>
          </Grid>
        ))}
        {validationErrors.nominals && (
          <Typography sx={{ color: "red", fontSize: "12px" }}>
            {validationErrors.nominals}
          </Typography>
        )}
        <Box>
          <Button
            sx={{
              color: "#4254EA",
              fontSize: "14px",
              fontWeight: "600",
              fontFamily: "Montserrat",
            }}
            size="small"
            onClick={handleAddNominal}
            startIcon={<AddIcon fontSize="small" />}
          >
            {t("sidebarView.AddNominal")}
          </Button>
        </Box>
      </Box>
      <Box mt="33px">
        <CustomButton
          onClick={handleSave}
          width={{ sm: "50%", md: "25%", xs: "100%" }}
          fs="18px"
        >
          {t("sidebarView.save")}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default SelectCategory;
