import { Box, Typography } from "@mui/material";
import React from "react";
import set1 from "../../assets/aboutUs/set1.svg";
import set2 from "../../assets/aboutUs/set2.svg";
import set3 from "../../assets/aboutUs/set3.svg";
import set4 from "../../assets/aboutUs/set4.svg";
import set5 from "../../assets/aboutUs/set5.svg";
import set6 from "../../assets/aboutUs/set6.svg";
import set7 from "../../assets/aboutUs/set7.svg";
import { useTranslation } from "react-i18next";

const Gallery = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={{ md: "row", xs: "column" }}
        sx={{
          padding: { lg: "120px 112px", tab: "30px 30px" },
          sm: "50px 50px",
          xs: "50px 20px",
        }}
      >
        <Box
          width={{ md: "45%", xs: "100%" }}
          sx={{
            backgroundColor: "#FFF",
          }}
          p={2}
        >
          <Typography
            sx={{
              color: "#212121",
              fontSize: { xs: "25px", md: "30px", lg: "32px" },
              fontWeight: 700,
              maxWidth: "438px",
              width: "100%",
            }}
          >
            {t("about.galleryheading")}{" "}
          </Typography>
        </Box>
        <Box width={{ md: "55%", xs: "100%" }} px={2} mb={3}>
          <Typography
            sx={{
              fontSize: { xs: "16px", sm: "18px" },
              fontWeight: 700,
              color: "#212121",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu dictum
            phasellus volutpat velit tempus magna felis ornare. Nunc morbi
            sollicitudin ut platea pellentesque suspendisse tellus luctus
            convallis.
          </Typography>
          <Typography
            mt={"24px"}
            sx={{ fontSize: "14px", fontWeight: 500, color: "#212121" }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo netus
            laoreet volutpat, ullamcorper nunc consequat mauris. Nisl non, enim
            malesuada aenean eget risus potenti etiam tortor. At iaculis enim
            velit ultricies sed. Sagittis in arcu maecenas nulla. Id amet tortor
            venenatis fermentum nunc. Fringilla amet pellentesque tortor sit
            lorem. Odio arcu, dictum diam urna velit ipsum, id ac diam.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "auto" },
          overflow: "hidden",
          overflowX: "scroll",
          mx: "auto",
          scrollbarWidth: "none" /* Firefox */,
          "::-webkit-scrollbar": {
            display: "none" /* Chrome, Safari */,
          },
        }}
      >
        <Box
          display="flex"
          gap={{ xs: "1rem", md: "2rem" }}
          justifyContent={{ tab: "center" }}
        >
          <Box display="flex" alignItems="center" ml={"3px"}>
            <Box
              component="img"
              src={set1}
              sx={{ maxWidth: { tab: "100%" }, height: { tab: "auto" } }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap="2rem"
            flexDirection="column"
          >
            <Box
              component="img"
              src={set2}
              alt="set"
              sx={{ maxWidth: { tab: "100%" }, height: { tab: "auto" } }}
            />
            <Box
              component="img"
              src={set3}
              alt="set"
              sx={{ maxWidth: { tab: "100%" }, height: { tab: "auto" } }}
            />
          </Box>
          <Box>
            {" "}
            <Box
              component="img"
              src={set4}
              alt="set"
              sx={{ maxWidth: { tab: "100%" }, height: { tab: "auto" } }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap="2rem"
            flexDirection="column"
          >
            {" "}
            <Box
              component="img"
              src={set5}
              alt="set"
              sx={{ maxWidth: { tab: "100%" }, height: { tab: "auto" } }}
            />
            <Box
              component="img"
              src={set6}
              alt="set"
              sx={{ maxWidth: { tab: "100%" }, height: { tab: "auto" } }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{ maxWidth: { tab: "100%" }, height: { tab: "auto" } }}
          >
            {" "}
            <Box
              component="img"
              src={set7}
              alt="set"
              sx={{ maxWidth: { tab: "100%" }, height: { tab: "auto" } }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Gallery;
