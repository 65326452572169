import { Container } from "@mui/material";
import React from "react";
import Header from "../../components/HowItWorks/Header";
import TabsButton from "../../components/HowItWorks/TabsButton";
import WorkaFaq from "../../components/HowItWorks/WorksFaq";

const HowItWorks = () => {
  return (
    <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Header />
      <TabsButton />
      <WorkaFaq />
    </Container>
  );
};

export default HowItWorks;
