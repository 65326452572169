import { Box, Typography } from "@mui/material";
import React from "react";
import CustomButton from "../global/CustomButton";
import cat from "../../assets/aboutUs/CAT.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const Banner = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  
  const handleButtonClick = (route) => {
    navigation(route);
    window.scrollTo(0, 0);
  };
  return (
    <Box
      sx={{ background: "#4254EA", color: "#fff" }}
      display="flex"
      justifyContent="space-between"
      flexDirection={{ tab: "row", xs: "column" }}
      alignItems="center"
    >
      <Box
        sx={{
          px: { xs: "20px", lg: "112px" },
          py: "30px",
          width: { xs: "100%", tab: "60%" },
        }}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: { xs: "28px", md: "40px" },
          }}
        >
          {t("about.bannerHeading")}
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            maxWidth: "451px",
          }}
          mt={"24px"}
          mb={"54px"}
        >
          {t("about.bannersubtxt")}
        </Typography>
        <Box
          display="flex"
          flexDirection={{ md: "row", xs: "column" }}
          gap={{ xs: "15px", lg: "32px" }}
        >
          <CustomButton
            width={{ xs: "280px" }}
            fs={{ lg: "18px", xs: "14px" }}
            bg="#fff"
            color="#4254EA"
            onClick={() => handleButtonClick("/gift-card")}
          >
            {t("about.bannerbtn")}
          </CustomButton>
          <CustomButton
            onClick={() => handleButtonClick("/all-partners")}
            width="280px"
            fs={{ lg: "18px", xs: "14px" }}
            bg="#fff"
            color="#4254EA"
          >
            {t("about.bannerbtnpartner")}
          </CustomButton>
        </Box>
      </Box>
      <Box sx={{ width: { tab: "40%", xs: "100%" } }} py={"50px"}>
        <img
          src={cat}
          alt="banner"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </Box>
    </Box>
  );
};

export default Banner;
