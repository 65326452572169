import { Container } from "@mui/material";
import React from "react";
import Header from "../../components/FrequentAsk/Header";
import FAQsection from "../../components/FrequentAsk/FAQsection";

const FAQPage = () => {
  return (
    <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Header />
      <FAQsection/>
    </Container>
  );
};

export default FAQPage;
