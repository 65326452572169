import { PERSON_PROMOCODE, PERSON_PROMOCODE_ERROR, PERSON_PROMOCODE_SUCCESS } from "../types/PersonPromocode";

  const initialState = {
    loading: false,
    error: null,
    data: [],
  };
  
  // The reducer function
  const PersonPromocodeReducer = (state = initialState, action) => {
    switch (action.type) {
      case PERSON_PROMOCODE:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case PERSON_PROMOCODE_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload, // Update the customer data with the payload
          error: null,
        };
      case PERSON_PROMOCODE_ERROR:
        return {
          ...state,
          loading: false,
          data: null,
          error: action.payload, // Set the error with the payload
        };
      default:
        return state;
    }
  };
  
  export default PersonPromocodeReducer;
  