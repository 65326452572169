import { Box, Typography } from "@mui/material";
import React from "react";
import happy from "../../assets/aboutUs/happy.svg";
import thumb from "../../assets/aboutUs/thumb.svg";
import bag from "../../assets/aboutUs/bag.svg";
import gift from "../../assets/aboutUs/gift.svg";
import { useTranslation } from "react-i18next";
const Block = () => {
  const { t } = useTranslation();

  const blockText = [
    {
      logo: happy,
      head: t('about.blockOneHeading'),
      text: t('about.subtextService'),
    },
    {
      logo: thumb,
      head:  t('about.blockTwoHeading'),
      text: t('about.subtextEasy'),
    },
    {
      logo: bag,
      head: t('about.blockThreeHeading'),
      text:  t('about.subtextPartners'),
    },
    {
      logo: gift,
      head: t('about.blockFourHeading'),
      text: t('about.subtextGreet'),
    },
  ];
  return (
    <Box sx={{ padding: {xs:"30px 30px",md:"80px 112px" }}}  mt={3}>
      <Box
        sx={{
          textAlign: "center",
          color: "#212121",
          fontWeight: 700,
          fontSize: { xs: "28px", md: "40px" },
          lineHeight: { xs: "36px", md: "56px" },
        }}
       
      >
        {t('about.blockheading')}
      </Box>
      <Box display='flex' flexWrap='wrap' gap="2rem" mt='40px' justifyContent='center'>
        {blockText.map(({ logo, head, text },index) => (
          <Box
            sx={{
              background: "#D9DCF9",
              width: "280px",
              borderRadius: "16px",
              padding: "30px",
            }}
            key={index}
          >
            <Box
              sx={{
                background: "#fff",
                width: { xs: "80px", tab: "80px" },
                height: { xs: "80px", tab: "80px" },
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={logo}
                alt="blog"
                style={{ width: "32px", height: "32px" }}
              />
            </Box>

            <Typography
              sx={{
                color: "#212121",
                fontSize: { xs: "25px", md: "30px", lg: "32px" },
                fontWeight: 700,
                mt: "34px",
                mb: "12px",
                lineHeight: "43px",
              }}
            >
              {head}
            </Typography>
            <Typography sx={{ color: "#212121", fontWeight: 600 }}>
              {text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Block;
