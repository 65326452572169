import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import {
  CHANGE_PASSWORD_COMPANY,
  CHANGE_PASSWORD_COMPANY_ERROR,
  CHANGE_PASSWORD_COMPANY_SUCCESS,
  FORGOT_PASSWORD_COMPANY,
  FORGOT_PASSWORD_COMPANY_ERROR,
  FORGOT_PASSWORD_COMPANY_SUCCESS,
} from "../types/ForgetPassword";

function* ForgetPasswordSaga(action) {
  try {
    const response = yield call(() =>
      baseURL.post(`/auth/forgot-password`, action.payload)
    );
    yield put({
      type: FORGOT_PASSWORD_COMPANY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: FORGOT_PASSWORD_COMPANY_ERROR, payload: error });
  }
}

function* ChangePasswordSaga(action) {
  try {
    const response = yield call(() =>
      baseURL.post(`/auth/change-password`, action.payload)
    );
    yield put({
      type: CHANGE_PASSWORD_COMPANY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({ type: CHANGE_PASSWORD_COMPANY_ERROR, payload: error });
  }
}

export default function* watchForgetPasswordCompany() {
  yield takeLatest(FORGOT_PASSWORD_COMPANY, ForgetPasswordSaga);
  yield takeLatest(CHANGE_PASSWORD_COMPANY, ChangePasswordSaga);
}
