import { EMAIL_EXIST_STATUS } from "../types/ExistEmailCheck";

  
  export const checkEmail = (data,token) => {
      return {
        type: EMAIL_EXIST_STATUS,
        payload: {data},
      };
    };

  