import {
  PARTNER_FILTER_FAILURE,
  PARTNER_FILTER_REQUEST,
  PARTNER_FILTER_SUCCESS,
} from "../types/PartnerFilter";

// src/reducers/giftCardReducer.js
const initialState = {
  data: [],
  loading: false,
  error: null,
};

const PartnerFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARTNER_FILTER_REQUEST:
      return { ...state, loading: true, error: null };
    case PARTNER_FILTER_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case PARTNER_FILTER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default PartnerFilterReducer;
