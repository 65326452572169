// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-tel-input .form-control {
    width: 355px; 
    border: 1px solid #4254ea33;
    background: #F8F8F8;
  }

  @media (max-width: 768px) {
    .react-tel-input .form-control {
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Register/Register.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,2BAA2B;IAC3B,mBAAmB;EACrB;;EAEA;IACE;QACI,WAAW;IACf;AACJ","sourcesContent":[".react-tel-input .form-control {\n    width: 355px; \n    border: 1px solid #4254ea33;\n    background: #F8F8F8;\n  }\n\n  @media (max-width: 768px) {\n    .react-tel-input .form-control {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
