import React, { useState } from "react";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useLocation } from "react-router-dom";
import PersonRegister from "./PersonRegister";
import CompanyRegister from "./CompanyRegister";
const TabRegister = () => {
  const [logintype, setLogintype] = useState("person");
  const [viewtab, setViewtab] = useState(true);
  const location = useLocation();
  return (
    <Box mx={"7%"}>
      <Box>
        <Breadcrumbs
          separator={<ArrowForwardIosIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ color: "#BABABA" }}
          py={2}
        >
          <Typography
            style={{
              color: logintype === "person" ? "black" : "#BABABA",
            }}
          >
            <Link
              to="/"
              style={{
                color: location.pathname === "/" ? "black" : "#BABABA",
              }}
            >
              Main
            </Link>
          </Typography>
          <Typography
            style={{
              color:
                logintype === "person" || logintype === "company"
                  ? "black"
                  : "#BABABA",
            }}
          >
            {logintype === "person" ? "Register person" : "Register company"}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box py={{ xs: "30px", tab: "60px" }}>
        <Box
          sx={{
            bgcolor: "#fff",
            maxWidth: "592px",
            width: "100%",
            padding: { xs: "30px 20px", tab: "40px 116px" },
            borderRadius: "16px",
            mx: "auto",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              color: "#212121",
              textAlign: "center",
              mb: "24px",
            }}
          >
            Welcome to Lahjakamu 👋🏻
          </Typography>
          {viewtab === true && (
            <Box
              sx={{
                background: "#7676801f",
                maxWidth: "385px",
                width: "100%",
                padding: "2px",
                borderRadius: "8px",
                mx: "auto",
              }}
              display="flex"
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  background: logintype === "person" ? "#FFF" : "transparent",
                  maxWidth: "192.5px",
                  width: "100%",
                  textAlign: "center",
                  padding: "6px",
                  borderRadius: "7px",
                  cursor: "pointer"
                }}
                onClick={() => setLogintype("person")}
              >
                Person
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  background: logintype === "company" ? "#FFF" : "transparent",
                  maxWidth: "192.5px",
                  width: "100%",
                  textAlign: "center",
                  padding: "6px",
                  borderRadius: "7px",
                  cursor: "pointer"
                }}
                onClick={() => setLogintype("company")}
              >
                Company
              </Typography>
            </Box>
          )}
          <Box>
            {logintype === "person" ? (
              <PersonRegister />
            ) : (
              <CompanyRegister setViewtab={setViewtab} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TabRegister;
