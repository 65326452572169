// authService.js
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";

const TOKEN_KEY = 'auth-token'; // Replace with a unique key for your application

// Store token in cookie and remove from localStorage
export function setTokenFromLocalStorage() {
  const token = localStorage.getItem('authToken');
  if (token) {
    Cookies.set(TOKEN_KEY, token, { expires: 1 }); // Set the cookie to expire in 1 day
    // localStorage.removeItem('authToken');
  }
}

// Store token in localStorage and remove from cookie
export function setTokenToLocalStorage(token) {
//   Cookies.remove(TOKEN_KEY);
  localStorage.setItem('authToken', token);
  setTokenFromLocalStorage()
}

export function getToken() {
  const tokenFromCookie = Cookies.get(TOKEN_KEY);
  const tokenFromLocalStorage = localStorage.getItem('authToken');

  // If the token is present in the cookie, return it; otherwise, return the token from localStorage
  return tokenFromCookie || tokenFromLocalStorage;
}

export function removeToken() {
  Cookies.remove(TOKEN_KEY);
  localStorage.removeItem('authToken');
}

export function isAuthenticated() {
  const token = getToken();

  // Check if the token is present and not expired
  return !!token && !isTokenExpired(token);
}

// Check if the token is expired
export function isTokenExpired(token) {
    try {
      const decoded = jwtDecode(token);
      const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
      return decoded.exp < currentTimestampInSeconds;
    } catch (error) {
      // Handle decoding errors (e.g., invalid token format)
      console.error('Error decoding token:', error);
      return true; // Treat decoding errors as expired tokens
    }
  }
  


// how to handle logout

//   // HomeComponent.js
// import React from 'react';
// import { isAuthenticated, removeToken } from './path-to-authService/authService';

// const HomeComponent = () => {
//   const handleLogout = () => {
//     // Remove the token and navigate to the login page
//     removeToken();
//     // Replace '/login' with your actual login page route
//     window.location.href = '/login';
//   };

//   return (
//     <div>
//       <h1>Welcome to the Home Page!</h1>
//       <button onClick={handleLogout}>Logout</button>
//     </div>
//   );
// };

// export default HomeComponent;
