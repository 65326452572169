import { BALANCE_FAILURE, BALANCE_REQUEST, BALANCE_SUCCESS } from "../types/GetBalance";

// reducers.js
const initialState = {
    balance: null,
    loading: false,
    error: null,
  };
  
  const GetBalanceReducer = (state = initialState, action) => {
    switch (action.type) {
      case BALANCE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case BALANCE_SUCCESS:
        return {
          ...state,
          loading: false,
          balance: action.payload,
          error: null,
        };
  
      case BALANCE_FAILURE:
        return {
          ...state,
          loading: false,
          balance: null,
          error: action.error,
        };
  
      default:
        return state;
    }
  };
  
  export default GetBalanceReducer;
  