import { Container } from '@mui/material'
import React from 'react'
import AllPartnersPage from '../../components/PartnersDisplay/AllPartnersPage'

const AllPartners = () => {
  return (
    <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 ,background:"#F2F2F7",pb:"128px"}}>
      <AllPartnersPage/>
    </Container>
  )
}

export default AllPartners