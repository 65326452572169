import React from "react";
import { Box, Typography } from "@mui/material";

const PartnersCards = ({ title, display, describe,onCardClick,id }) => {
  return (
    <Box
      sx={{
        width: { xs: "300px", md: "384px" },
        hight: "auto",
        padding: { sm: "16px 16px", xs: "16px 16px" },
        background: "#fff",
        borderRadius: "16px",
      }}
      onClick={() => onCardClick(id)}

    >
      <img
        src={display}
        alt={title}
        style={{
          maxWidth: "100%",
          width: "100%",
          height: "auto",
          objectFit: "cover",
          aspectRatio: "5/3",
        }}
      />
      <Box>
        <Typography
          sx={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}
          py={1}
        >
          {title}
        </Typography>
        <Typography
          sx={{ textAlign: "center", fontSize: "14px", color: "#64646D" }}
        >
          {`${describe.slice(0, 150)}...`}
        </Typography>
      </Box>
    </Box>
  );
};

export default PartnersCards;
