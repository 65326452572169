import {
  BIRTHDAY_STICKERS_FAILURE,
  BIRTHDAY_STICKERS_REQUEST,
  BIRTHDAY_STICKERS_SUCCESS,
  NEW_YEAR_STICKERS_FAILURE,
  NEW_YEAR_STICKERS_REQUEST,
  NEW_YEAR_STICKERS_SUCCESS,
  WEDDING_STICKERS_FAILURE,
  WEDDING_STICKERS_REQUEST,
  WEDDING_STICKERS_SUCCESS,
} from "../types/GifiSearchType";

// src/reducers/giftCardReducer.js
const initialState = {
  data_bir: [],
  data_wed: [],
  data_Year: [],
  loading: false,
  loading1: false,
  loading2: false,
  error: null,
  error1: null,
  error2: null,
};

const GIFDataReducer = (state = initialState, action) => {

  switch (action.type) {

    case BIRTHDAY_STICKERS_REQUEST:
      return { ...state, loading: true, error: null };
    case BIRTHDAY_STICKERS_SUCCESS:
      return { ...state, loading: false, data_bir: action.payload };
    case BIRTHDAY_STICKERS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    //   wed
    case WEDDING_STICKERS_REQUEST:
      return { ...state, loading1: true, error: null };
    case WEDDING_STICKERS_SUCCESS:
      return { ...state, loading1: false, data_wed: action.payload };
    case WEDDING_STICKERS_FAILURE:
      return { ...state, loading1: false, error1: action.payload };
    // new year
    case NEW_YEAR_STICKERS_REQUEST:
      return { ...state, loading2: true, error: null };
    case NEW_YEAR_STICKERS_SUCCESS:
      return { ...state, loading2: false, data_Year: action.payload };
    case NEW_YEAR_STICKERS_FAILURE:
      return { ...state, loading2: false, error2: action.payload };
    default:
      return state;
      
  }
};

export default GIFDataReducer;
