import { call, put, takeLatest } from "redux-saga/effects";
import {
  REGISTER_COMPANY,
  REGISTER_COMPANY_ERROR,
  REGISTER_COMPANY_SUCCESS,
} from "../types/RegisterCompanyType";
import { baseURL } from "../../utils/baseURL";

function* registerCompany(action) {
  try {
    const response = yield call(() =>
      baseURL.post(`/auth/sign-up`, action.payload)
    );
    yield put({ type: REGISTER_COMPANY_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: REGISTER_COMPANY_ERROR, payload: error });
  }
}

export default function* watchRegisterCompany() {
  yield takeLatest(REGISTER_COMPANY, registerCompany);
}
