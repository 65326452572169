import { SEARCH_PAID_GIFTCARD_FAILURE, SEARCH_PAID_GIFTCARD_REQUEST, SEARCH_PAID_GIFTCARD_SUCCESS } from "../types/PaidGIftCard";

export const searchPaidGIFI = (query) => ({
    type: SEARCH_PAID_GIFTCARD_REQUEST,
    payload: query,
  });
  
  export const searchPaidGIFISuccess = (data) => ({
    type: SEARCH_PAID_GIFTCARD_SUCCESS,
    payload: data,
  });
  
  export const searchPaidGIFIFailure = (error) => ({
    type: SEARCH_PAID_GIFTCARD_FAILURE,
    payload: error,
  });
  