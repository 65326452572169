// on PhoneValidation on otp send successfully
export const forgetpasswordsuccess = "SMS sent successfully";

// amount array

export const valueAry = [
  "100",
  "200",
  "300",
  "400",
  "500",
  "600",
  "700",
  "800",
  "900",
  "1000",
  "15000",
  "2000",
  "3000",
];

// for partner filter

export const FilterCategories = [
  "Home goods",
  "Children's goods",
  "Clothes & Shoes",
  "Cosmetics & Perfumery",
  "Jewelry",
  "Impression",
  "Alcoholic drinks",
  "Pharmacy & Medicine",
  "Household appliances & Electronics",
  "Animal products",
  "Food",
  "Restaurants",
  "Books",
  "Goods for sports",
  "Tourist services",
];

export const FilterWhom = [
  "For all",
  "Man",
  "Woman",
  "For two",
  "For children",
  "For beloved",
  "Girl",
  "Guy",
];

export const FilterNominal = [
  "$100",
  "$200",
  "$300",
  "$400",
  "$500",
  "$600",
  "$700",
  "$800",
  "$900",
  "$1000",
  "$15000",
  "$2000",
  "$3000",
  "more",
];

export const FilterCity = [
  "Helsinki",
  "Espoo",
  "Tampere",
  "Vantaa",
  "Oulu",
  "Turku",
  "Lapua",
  "Loimaa",
  "Nivala",
];


export const socialLoginUrl = 'https://dev.lahjakamu.com'