import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import {
  CHECK_REGISTER_OTP,
  CHECK_REGISTER_OTP_ERROR,
  CHECK_REGISTER_OTP_SUCCESS,
  RESEND_REGISTER_OTP,
  RESEND_REGISTER_OTP_ERROR,
  RESEND_REGISTER_OTP_SUCCESS,
} from "../types/RegisterPerson";

function* checkregisterPersonOTPSaga(action) {
  try {
    const response = yield call(() =>
      baseURL.post(`/auth/check-otp`, action.payload)
    );
    const { data, status } = response;
    yield put({ type: CHECK_REGISTER_OTP_SUCCESS, payload: { data, status } });
  } catch (error) {
    yield put({ type: CHECK_REGISTER_OTP_ERROR, payload: error });
  }
}

function* resendRegisterPersonOTPSaga(action) {
  try {
    const response = yield call(() =>
      baseURL.post(`/auth/resend-otp`, action.payload)
    );
    yield put({ type: RESEND_REGISTER_OTP_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: RESEND_REGISTER_OTP_ERROR, payload: error });
  }
}

export default function* watchCheckRegisterPersonOTP() {
  yield takeLatest(CHECK_REGISTER_OTP, checkregisterPersonOTPSaga);
  yield takeLatest(RESEND_REGISTER_OTP, resendRegisterPersonOTPSaga);
}
