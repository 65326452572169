import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // Extra small devices (portrait phones)
      sm: 600, // Small devices (landscape phones)
      tab:768,
      md: 1024, // Medium devices (tablets)
      lg: 1280, // Large devices (desktops)
      xl: 1600, // Extra large devices (large desktops)
    },
  },
});

export default theme;
