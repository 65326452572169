import React, { useEffect, useState } from "react";
import { Box, OutlinedInput, Typography } from "@mui/material";
import TabViewModal from "./TabViewModal";
import { useDispatch, useSelector } from "react-redux";
import { activeCard, cleanActiveModal } from "../../../store/actions/ActiveCheckAction";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const txt = {
  color: "#64646D",
  fontSize: "12px",
  fontWeight: 500,
  fontFamily: "Montserrat",
  opacity: ".8",
};

const ActivePage = () => {
  const [formData, setFormData] = useState({
    giftCardNumber: "",
    secretCode: "",
    email: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const activeStatus = useSelector((state) => state?.activeCard?.data);
  const [open, setOpen] = React.useState(false);
  const [modalInfo, setModalInfo] = useState("");
  const navigation = useNavigate();
  const userData = useSelector((state) => state.getUser.user);
  const { t } = useTranslation();

  const handleChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));

    // Real-time validation for email
    if (field === "email") {
      const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailValidationRegex.test(value.trim());
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        email: isValidEmail ? "" : "Invalid email format",
      }));
    }
  };

  const validate = () => {
    const errors = {};

    // Example validation checks, customize based on your requirements
    if (!formData.giftCardNumber.trim()) {
      errors.giftCardNumber = "Gift card number is required";
    }

    if (!formData.secretCode.trim()) {
      errors.secretCode = "Secret code is required";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else {
      const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailValidationRegex.test(formData.email.trim())) {
        errors.email = "Invalid email format";
      }
    }

    setValidationErrors(errors);

    // Return true if there are no errors, false otherwise
    return Object.keys(errors).length === 0;
  };

  const handleActivate = () => {
    // Perform activation logic here
    if (validate()) {
      // If validation passes, proceed with activation logic
      if (token) {
        const payload = {
          promo_code: formData.giftCardNumber,
          secret_code:formData.secretCode,
          email: formData.email
        };
        const userId = userData.data[0]._id;
        dispatch(activeCard(payload, token,userId));
      }
    } else {
      // If validation fails, display an error message or take appropriate action
      console.log("Activation failed. Please check the form.");
    }
  };

  
  useEffect(() => {
    if (
      activeStatus &&
      activeStatus?.status === 200
    ) {
      setOpen(true);
      setModalInfo(activeStatus?.data?.checkCard);
    }
  }, [activeStatus]);

  const handleCloseModal =()=>{
    setOpen(false);
    dispatch(cleanActiveModal());
  }
  const handleNavigate = () =>{
    dispatch(cleanActiveModal());
     navigation("/all-partners");
  }

  return (
    <Box>
      <Box my="16px">
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          gap="8px"
        >
          <Box>
            <Typography sx={txt}>{t("sidebarView.gfNum")}</Typography>
            <OutlinedInput
              fullWidth
              sx={{
                maxWidth: { sm: "240px" },
                width: "100%",
                background: "#F8F8F8",
                "& fieldset": { border: "1px solid #4254ea33" },
                borderRadius: "8px",
              }}
              placeholder="Enter Gift card number"
              value={formData.giftCardNumber}
              onChange={(e) => handleChange("giftCardNumber", e.target.value)}
            />
            {validationErrors.giftCardNumber && (
              <Typography sx={{ color: "red", fontSize: "12px" }}>
                {validationErrors.giftCardNumber}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography sx={txt}>{t("sidebarView.secretCode")}</Typography>
            <OutlinedInput
              fullWidth
              sx={{
                maxWidth: { sm: "240px" },
                width: "100%",
                background: "#F8F8F8",
                "& fieldset": { border: "1px solid #4254ea33" },
                borderRadius: "8px",
              }}
              placeholder="Enter Secret code"
              value={formData.secretCode}
              onChange={(e) => handleChange("secretCode", e.target.value)}
            />
            {validationErrors.secretCode && (
              <Typography sx={{ color: "red", fontSize: "12px" }}>
                {validationErrors.secretCode}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          gap="8px"
          mt="8px"
        >
          <Box>
            <Typography sx={txt}>{t("formLable.email")}</Typography>
            <OutlinedInput
              fullWidth
              sx={{
                maxWidth: { sm: "240px" },
                width: "100%",
                background: "#F8F8F8",
                "& fieldset": { border: "1px solid #4254ea33" },
                borderRadius: "8px",
              }}
              placeholder="Enter your Email"
              value={formData.email}
              onChange={(e) => handleChange("email", e.target.value)}
            />
            {validationErrors.email && (
              <Typography sx={{ color: "red", fontSize: "12px" }}>
                {validationErrors.email}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Box mt="35px" sx={{ maxWidth: { sm: "240px" } }}>
        <TabViewModal
          btntxt={t("sidebarView.activeCard")}
          headingtxt={t("sidebarView.activeGiftCard")}
          subtxt={t("sidebarView.succAct")}
          messagetxt={t("sidebarView.Congratulations")}
          balanceamt="Balance: $1000"
          modalbtn={t("sidebarView.goPrt")}
          flag={true}
          fs="18px"
          width={{ xs: "100%" }}
          setOpen={setOpen}
          open={open}
          handleStatus={handleActivate}
          modalInfo={modalInfo}
          handleCloseModal={handleCloseModal}
          handleNavigate={handleNavigate}
        />
      </Box>
    </Box>
  );
};

export default ActivePage;
