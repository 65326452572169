// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Importing Google Font */
/* Applying styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Montserrat', sans-serif; /* Using Montserrat font with a fallback */
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: none;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,0BAA0B;AAE1B,oBAAoB;AACpB;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,qCAAqC,EAAE,0CAA0C;EACjF,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["/* Importing Google Font */\n  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');\n/* Applying styles */\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n  font-family: 'Montserrat', sans-serif; /* Using Montserrat font with a fallback */\n  margin-left: auto;\n  margin-right: auto;\n}\n\na {\n  text-decoration: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
