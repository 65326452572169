import React, { useState } from "react";
import { Box, IconButton, OutlinedInput, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomButton from "../global/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { ChangePasswordInfo } from "../../store/actions/ChangePassworldAction";
import { useTranslation } from "react-i18next";

const CompanySecurity = () => {
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const token = localStorage.getItem("authToken");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.getUser?.user);
  const { t } = useTranslation();

  const [errorMessages, setErrorMessages] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handlePasswordChange = (field, value) => {
    setPasswordData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleTogglePasswordVisibility = (field) => {
    switch (field) {
      case "currentPassword":
        setShowCurrentPassword((prevShow) => !prevShow);
        break;
      case "newPassword":
        setShowNewPassword((prevShow) => !prevShow);
        break;
      case "confirmNewPassword":
        setShowConfirmPassword((prevShow) => !prevShow);
        break;
      default:
        break;
    }
  };

  const handleValidation = () => {
    let isValid = true;
    const newErrorMessages = {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };

    // Validation for Current Password
    if (passwordData.currentPassword.trim() === "") {
      newErrorMessages.currentPassword = "Current Password is required";
      isValid = false;
    }

    // Validation for New Password
    if (passwordData.newPassword.trim() === "") {
      newErrorMessages.newPassword = "New Password is required";
      isValid = false;
    }

    // Validation for Confirm New Password
    if (passwordData.confirmNewPassword.trim() === "") {
      newErrorMessages.confirmNewPassword = "Confirm New Password is required";
      isValid = false;
    } else if (passwordData.confirmNewPassword !== passwordData.newPassword) {
      newErrorMessages.confirmNewPassword = "Passwords do not match";
      isValid = false;
    }

    setErrorMessages(newErrorMessages);
    return isValid;
  };

  const handleSubmit = () => {
    if (handleValidation()) {
      const userEmail = userData.data[0].email;

      const payload = {
        email: userEmail,
        password: passwordData.newPassword,
        old_password: passwordData.currentPassword,
      };
      dispatch(ChangePasswordInfo(payload, token));
    }
  };

  const txt = {
    color: "#64646D",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  };

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            color: "#212121",
            fontWeight: 600,
          }}
        >
          {t("sidebarView.ChangePassword")}
        </Typography>
      </Box>
      <Box>
        <Box mt="16px">
          <Typography sx={txt}>{t("sidebarView.CurrentPassword")}</Typography>
          <OutlinedInput
            type={showCurrentPassword ? "text" : "password"}
            value={passwordData.currentPassword}
            onChange={(e) =>
              handlePasswordChange("currentPassword", e.target.value)
            }
            fullWidth
            sx={{
              maxWidth: { sm: "240px" },
              width: "100%",
              background: "#F8F8F8",
              "& fieldset": { border: "1px solid #4254ea33" },
              borderRadius: "8px",
            }}
            placeholder="Current Password"
            endAdornment={
              <IconButton
                onClick={() =>
                  handleTogglePasswordVisibility("currentPassword")
                }
                edge="end"
                aria-label="toggle password visibility"
              >
                {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            }
          />
          <Typography sx={{ fontSize: "12px", color: "red", ml: "8px" }}>
            {errorMessages.currentPassword}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          gap="8px"
        >
          <Box my="16px">
            <Typography sx={txt}>{t("sidebarView.NewPassword")}</Typography>
            <OutlinedInput
              type={showNewPassword ? "text" : "password"}
              value={passwordData.newPassword}
              onChange={(e) =>
                handlePasswordChange("newPassword", e.target.value)
              }
              fullWidth
              sx={{
                maxWidth: { sm: "240px" },
                width: "100%",
                background: "#F8F8F8",
                "& fieldset": { border: "1px solid #4254ea33" },
                borderRadius: "8px",
              }}
              placeholder="New Password"
              endAdornment={
                <IconButton
                  onClick={() => handleTogglePasswordVisibility("newPassword")}
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
            />
            <Typography sx={{ fontSize: "12px", color: "red", ml: "8px" }}>
              {errorMessages.newPassword}
            </Typography>
          </Box>
          <Box my="16px">
            <Typography sx={txt}>
              {t("sidebarView.ConfirmNewPassword")}
            </Typography>
            <OutlinedInput
              type={showConfirmPassword ? "text" : "password"}
              value={passwordData.confirmNewPassword}
              onChange={(e) =>
                handlePasswordChange("confirmNewPassword", e.target.value)
              }
              fullWidth
              sx={{
                maxWidth: { sm: "240px" },
                width: "100%",
                background: "#F8F8F8",
                "& fieldset": { border: "1px solid #4254ea33" },
                borderRadius: "8px",
              }}
              placeholder="Confirm New Password"
              endAdornment={
                <IconButton
                  onClick={() =>
                    handleTogglePasswordVisibility("confirmNewPassword")
                  }
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
            />
            <Typography sx={{ fontSize: "12px", color: "red", ml: "8px" }}>
              {errorMessages.confirmNewPassword}
            </Typography>
          </Box>
        </Box>
        <Box mt="20px" sx={{ maxWidth: { sm: "250px" } }}>
          <CustomButton onClick={handleSubmit} fs="16px" width={{ xs: "100%" }}>
            {t("sidebarView.SaveChange")}
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanySecurity;
