import React from "react";
import { Container } from "@mui/material";
import CardLayout from "../../components/GiftCard/CardLayout";
import UseBlock from "../../components/GiftCard/UseBlock";
import Terms from "../../components/GiftCard/Terms";

const GiftCard = () => {
  return (
    <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 ,background:"#F2F2F7"}}>
      <CardLayout />
      <UseBlock/>
      <Terms/>
    </Container>
  );
};

export default GiftCard;
