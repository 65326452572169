import React from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Drawer,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, Outlet, useLocation } from "react-router-dom";
import account from "../../assets/account/user-circle.svg";
import card from "../../assets/account/credit-card.svg";
import Security from "../../assets/account/lock-closed.svg";
import Logout from "../../assets/account/logout.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { removeToken } from "../../utils/authService";
import { useTranslation } from "react-i18next";

const SideBarCompany = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleLogout = () => {
    // Remove the token and navigate to the login page
    removeToken();
    // Replace '/login' with your actual login page route
    window.location.href = "/login";
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          background: "#FFF",
          maxWidth: { md: "280px", tab: "180px" },
          width: "100%",
          boxShadow: "0px 0px 20px 0px rgba(5, 17, 118, 0.04)",
          pt: "30px",
        }}
      >
        <Link to={"/company-account/company-Profile"}>
          <Typography
            sx={{
              padding: "16px 20px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              color: "#212121",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              background:
                location.pathname === "/company-account/company-Profile" ||
                location.pathname === "/company-account"
                  ? "#D9DCF9"
                  : "transparent",
            }}
          >
            <img src={account} alt="My Account" />
           {t("sidebarView.MyAccount")}
          </Typography>
        </Link>
        <Divider sx={{ background: "#0511760a" }} />

        <Link to={"/company-account/add-product"}>
          <Typography
            sx={{
              padding: "16px 20px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              color: "#212121",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              background:
                location.pathname === "/company-account/add-product"
                  ? "#D9DCF9"
                  : "transparent",
            }}
          >
            <img src={card} alt="Gift Card" />
          {t("sidebarView.productPage")}
          </Typography>
        </Link>
        <Divider sx={{ background: "#0511760a" }} />
        <Link to={"/company-account/partner-security"}>
          <Typography
            sx={{
              padding: "16px 20px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              color: "#212121",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              background:
                location.pathname === "/company-account/partner-security"
                  ? "#D9DCF9"
                  : "transparent",
            }}
          >
            <img src={Security} alt="Security" />
            {t("sidebarView.Security")}
          </Typography>
        </Link>
        <Divider sx={{ background: "#0511760a" }} />
        <Typography
          sx={{
            padding: "16px 20px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: "#212121",
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            background:
              location.pathname === "/login" ? "#D9DCF9" : "transparent",
          }}
          onClick={handleLogout}
        >
          <img src={Logout} alt="Logout" />
          {t("sidebarView.Logout")}
        </Typography>
        <Divider sx={{ background: "#0511760a" }} />
      </Box>
    </Box>
  );

  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: 0, paddingRight: 0, background: "#F2F2F7" }}
    >
      <Box mx={"7%"}>
        <Box>
          <Breadcrumbs
            separator={<ArrowForwardIosIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{ color: "#BABABA" }}
            py={2}
          >
            <Typography>
              <Link
                to="/"
                style={{
                  color: location.pathname === "/" ? "black" : "#BABABA",
                }}
              >
                Main
              </Link>
            </Typography>

            {(location.pathname === "/company-account/company-Profile" ||
              location.pathname === "/company-account") && (
              <Typography
                style={{
                  color:
                    location.pathname === "/company-account/company-Profile" ||
                    location.pathname === "/company-account"
                      ? "black"
                      : "#BABABA",
                }}
              >
               {t("sidebarView.MyAccount")}
              </Typography>
            )}
            {location.pathname === "/company-account/add-product" && (
              <Typography
                style={{
                  color:
                    location.pathname === "/company-account/add-product"
                      ? "black"
                      : "#BABABA",
                }}
              >
                {t("sidebarView.productPage")}
              </Typography>
            )}
            {location.pathname === "/company-account/partner-security" && (
              <Typography
                style={{
                  color:
                    location.pathname === "/company-account/partner-security"
                      ? "black"
                      : "#BABABA",
                }}
              >
                {t("sidebarView.Security")}
              </Typography>
            )}
          </Breadcrumbs>
        </Box>
        <Box my="24px">
          <Typography
            sx={{
              color: "#212121",
              fontWeight: 700,
              fontSize: { xs: "28px", md: "40px" },
            }}
          >
            Profile
          </Typography>
        </Box>
        <Box
          pb="100px"
          display="flex"
          gap="32px"
          sx={{
            flexDirection: { tab: "row", xs: "column" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              background: "#FFF",
              maxWidth: { md: "280px", tab: "180px" },
              borderRadius: "16px",
              width: "100%",
              height: "230px",
              boxShadow: "0px 0px 20px 0px rgba(5, 17, 118, 0.04)",
              display: { tab: "block", xs: "none" },
            }}
          >
            <Link to={"/company-account/company-Profile"}>
              <Typography
                sx={{
                  padding: "16px 20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#212121",
                  fontFamily: "Montserrat",
                  fontSize: { tab: "14px", md: "16px" },
                  fontStyle: "normal",
                  fontWeight: 500,
                  background:
                    location.pathname === "/company-account/company-Profile" ||
                    location.pathname === "/company-account"
                      ? "#D9DCF9"
                      : "transparent",
                  borderRadius:
                    location.pathname === "/company-account/company-Profile" ||
                    location.pathname === "/company-account"
                      ? "16px 16px 0px 0px"
                      : "transparent",
                }}
              >
                <img src={account} alt="My Account" />
                {t("sidebarView.MyAccount")}
              </Typography>
            </Link>
            <Divider sx={{ background: "#0511760a" }} />

            <Link to={"/company-account/add-product"}>
              <Typography
                sx={{
                  padding: "16px 20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#212121",
                  fontFamily: "Montserrat",
                  fontSize: { tab: "14px", md: "16px" },
                  fontStyle: "normal",
                  fontWeight: 500,
                  background:
                    location.pathname === "/company-account/add-product"
                      ? "#D9DCF9"
                      : "transparent",
                }}
              >
                <img src={card} alt="Gift Card" />
                {t("sidebarView.productPage")}
              </Typography>
            </Link>
            <Divider sx={{ background: "#0511760a" }} />

            <Link to={"/company-account/partner-security"}>
              <Typography
                sx={{
                  padding: "16px 20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#212121",
                  fontFamily: "Montserrat",
                  fontSize: { tab: "14px", md: "16px" },
                  fontStyle: "normal",
                  fontWeight: 500,
                  background:
                    location.pathname === "/company-account/partner-security"
                      ? "#D9DCF9"
                      : "transparent",
                }}
              >
                <img src={Security} alt="Security" />
                {t("sidebarView.Security")}
              </Typography>
            </Link>
            <Divider sx={{ background: "#0511760a" }} />
            <Typography
              sx={{
                padding: "16px 20px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                color: "#212121",
                fontFamily: "Montserrat",
                fontSize: { tab: "14px", md: "16px" },
                fontStyle: "normal",
                fontWeight: 500,
                background:
                  location.pathname === "/login" ? "#D9DCF9" : "transparent",
              }}
              onClick={handleLogout}
            >
              <img src={Logout} alt="Logout" />
              {t("sidebarView.Logout")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: { tab: "none", xs: "block" },
              position: "absolute",
              right: 0,
            }}
          >
            {["left"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Button onClick={toggleDrawer(anchor, true)}>
                  <MenuIcon />
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Box>
          <Box width="100%">
            {" "}
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default SideBarCompany;
