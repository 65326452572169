import { ADD_GIFT_CART_FAILURE, ADD_GIFT_CART_REQUEST, ADD_GIFT_CART_SUCCESS, CLEAN_ADDED_GIFT_CART } from "../types/AddToCartGift";

  const initialState = {
    loading: false,
    error: null,
    data: {},
  };
  
  // The reducer function
  const AddToGiftCartReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_GIFT_CART_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
    
      case ADD_GIFT_CART_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload, // Update the customer data with the payload
          error: null,
        };
      case ADD_GIFT_CART_FAILURE:
        return {
          ...state,
          loading: false,
          data: null,
          error: action.payload, // Set the error with the payload
        };
        case CLEAN_ADDED_GIFT_CART:
        return {
          ...state,
          loading: false,
          data: null,
          error: null, // Set the error with the payload
        };
      default:
        return state;
    }
  };
  
  
  
  export default AddToGiftCartReducer;
  