import { GET_GIFT_CART_FAILURE, GET_GIFT_CART_REQUEST, GET_GIFT_CART_SUCCESS } from "../types/AddToCartGift";

  const initialState = {
    loading: false,
    error: null,
    data: {},
  };
  
  // The reducer function
  const GetGiftCartReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_GIFT_CART_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case GET_GIFT_CART_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload.data, // Update the customer data with the payload
          error: null,
        };
      case GET_GIFT_CART_FAILURE:
        return {
          ...state,
          loading: false,
          data: null,
          error: action.payload, // Set the error with the payload
        };
      default:
        return state;
    }
  };
  
  
  
  export default GetGiftCartReducer;
  