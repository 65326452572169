import { CHECK_REGISTER_OTP, REGISTER_OTP, REGISTER_PERSON, REGISTER_PERSON_INFO, RESEND_REGISTER_OTP } from "../types/RegisterPerson";


export const registerPersonInfo = (data) => {
    return {
        type: REGISTER_PERSON_INFO,
        payload: data
    };
};

export const registerPerson = (data) => {
    return {
        type: REGISTER_PERSON,
        payload: data
    };
};

export const registerotp =(data)=>({
    type: REGISTER_OTP,
    payload: data
})

export const checkregisterotp =(data)=>({
    type: CHECK_REGISTER_OTP,
    payload: data
})

export const resendRegisterOtp =(data)=>({
    type: RESEND_REGISTER_OTP,
    payload: data
})