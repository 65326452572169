import React, { useEffect, useRef, useState } from "react";
import {
  Breadcrumbs,
  Typography,
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GiftCardView from "../global/GiftCardView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchBar from "./SearchBar";
import ImgList from "./ImgList";
import TextList from "./TextList";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../global/CustomButton";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import info from "../../assets/information-circle.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { searchGiphyStickers } from "../../store/actions/GifiSearchAction";
import { searchPaidGIFI } from "../../store/actions/PaidGiftCardAction";
import { toast } from "react-toastify";
import { addToCart } from "../../store/actions/AddToCartAction";
import { valueAry } from "../../utils/Constant";
import { CleanGiftCart, addGiftCart } from "../../store/actions/AddToGiftCartAction";
import { useHistory } from 'react-router-dom';

const CardLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigation = useNavigate();
  const path = [
    { path: "/", label: `${t("giftcardpage.main")}` },
    { path: "/gift-card", label: ` Lahjakamu ${t("navbar.card")}` },
  ];
  const [selectedImg, setSelectedImg] = useState("");
  // const [selectedOption, setSelectedOption] = useState("free"); // State to track selected option
  const [cardDataInfo, setCardDataInfo] = useState([
    {
      nominal: "",
      amount: "1",
      type_of_card: "free",
      image: "",
      greeting_text: "",
      total: "",
    },
  ]);
  const dispatch = useDispatch();
  const results = useSelector((state) => state.giftCard.data);
  const loading = useSelector((state) => state.giftCard.loading);
  const resultsPaid = useSelector((state) => state.PaidCard.data);
  const loadingPaid = useSelector((state) => state.PaidCard.loading);
  const userData = useSelector((state) => state.getUser.user);
  const addcartSuccess = useSelector(
    (state) => state?.AddToGiftCartReducer?.data
  );
  const { url } = location.state || {};

  const handleSearch = (query) => {
    dispatch(searchGiphyStickers(query));
  };
  const handleSearchPaid = (query) => {
    dispatch(searchPaidGIFI(query));
  };
  useEffect(() => {
    dispatch(searchGiphyStickers("gift"));
    dispatch(searchPaidGIFI("gift"));
  }, []);

  const handleOptionClick = (option, index) => {
    // setSelectedOption(option);
    const updatedCardDataInfo = [...cardDataInfo];
    updatedCardDataInfo[index].type_of_card = option;
    setCardDataInfo(updatedCardDataInfo);
    setCardDataInfo((prevData) => [
      ...prevData.map((item, i) =>
        i === index ? { ...item, image: "" } : item
      ),
    ]);
  };

  const handleIncrease = (index) => {
    const updatedCardDataInfo = [...cardDataInfo];
    updatedCardDataInfo[index].amount = Math.max(
      0,
      parseInt(updatedCardDataInfo[index].amount) + 1
    );
    updatedCardDataInfo[index].total =
      updatedCardDataInfo[index].nominal * updatedCardDataInfo[index].amount;
    setCardDataInfo(updatedCardDataInfo);
  };

  const handleDecrease = (index) => {
    const updatedCardDataInfo = [...cardDataInfo];
    updatedCardDataInfo[index].amount = Math.max(
      1,
      updatedCardDataInfo[index].amount - 1
    );
    updatedCardDataInfo[index].total =
      updatedCardDataInfo[index].nominal * updatedCardDataInfo[index].amount;
    setCardDataInfo(updatedCardDataInfo);
  };

  const handleInputChange = (event, index) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value)) {
      const updatedCardDataInfo = [...cardDataInfo];
      updatedCardDataInfo[index].amount = value;
      updatedCardDataInfo[index].total =
        updatedCardDataInfo[index].nominal * value;
      setCardDataInfo(updatedCardDataInfo);
    }
  };

  const handleCardChange = (index, field, value) => {
    const updatedata = [...cardDataInfo];
    updatedata[index][field] = value;
    updatedata[index].total =
      updatedata[index].nominal * updatedata[index].amount;
    setCardDataInfo(updatedata);
  };

  const handleTextSelection = (text, index) => {
    updateGreetingText(text, index);
  };

  const handleInputChangetext = (event, index) => {
    const customText = event.target.value;
    updateGreetingText(customText, index);
  };

  const updateGreetingText = (text, index) => {
    const updatedCardDataInfo = [...cardDataInfo];
    updatedCardDataInfo[index].greeting_text = text;
    setCardDataInfo(updatedCardDataInfo);
  };

  const fileInputRef = useRef(null);
  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    const uploadedImageUrl = URL.createObjectURL(file);
    setSelectedImg(uploadedImageUrl);
    fileInputRef.current.value = null;
    updateImageInCardData(uploadedImageUrl, index);
  };

  const handleImgUpload = (index) => {
    fileInputRef.current.click();
    updateImageInCardData(null, index);
  };

  const updateImageInCardData = (imageUrl, index) => {
    const updatedCardDataInfo = [...cardDataInfo];

    if (index !== null && imageUrl !== null) {
      updatedCardDataInfo[index].image = imageUrl;
    }
    setCardDataInfo(updatedCardDataInfo);
  };

  const updateTotal = (index) => {
    const updatedCardData = [...cardDataInfo];
    const nominal = parseFloat(updatedCardData[index].nominal) || 0;
    const amount = parseFloat(updatedCardData[index].amount) || 1;
    updatedCardData[index].total = nominal * amount;
    setCardDataInfo(updatedCardData);
  };

  const handledataCard = () => {
    const data = cardDataInfo[cardDataInfo.length - 1];
    if (
      data.nominal !== "" &&
      data.amount !== "" &&
      data.type_of_card !== "" &&
      data.image !== "" &&
      data.greeting_text !== ""
    ) {
      const newCardDataInfo = {
        nominal: "",
        amount: "1",
        type_of_card: "free",
        image: "",
        greeting_text: "",
        total: "",
      };
      setCardDataInfo([...cardDataInfo, newCardDataInfo]);
    } else {
      toast.warn("Please fill in the fields");
    }
  };

  // const handleButtonClick = () => {
  //   const data = cardDataInfo[cardDataInfo.length - 1];
  //   if (
  //     data.nominal !== "" &&
  //     data.amount !== "" &&
  //     data.type_of_card !== "" &&
  //     data.image !== "" &&
  //     data.greeting_text !== "" &&
  //     cardDataInfo.length !== 0
  //   ) {
  //     dispatch(addToCart(cardDataInfo));
  //     const token = localStorage.getItem("authToken");

  //     const payload = cardDataInfo.map((data) => ({
  //       user_id: userData.data[0]._id,
  //       nominal: data.nominal,
  //       amount: data.amount,
  //       image: data.image,
  //       greeting_text: data.greeting_text,
  //       type_of_card: data.type_of_card,
  //       delivery_type: "email",
  //     }));

  //     dispatch(addGiftCart(payload, token));
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //   } else {
  //     toast.error("Please fill in the fields");
  //   }
  // };


  const handleButtonClick = () => {
    const data = cardDataInfo[cardDataInfo.length - 1];
  
    if (cardDataInfo.length === 0) {
      toast.error("Please add at least one card to the cart");
      return;
    }
  
    const requiredFields = ["nominal", "amount", "type_of_card", "image", "greeting_text"];
  
    for (const field of requiredFields) {
      if (!data[field]) {
        toast.error(`Please fill in the ${field.replace('_', ' ')}`);
        return;
      }
    }
  
    dispatch(addToCart(cardDataInfo));
  
    const token = localStorage.getItem("authToken");
  
    const payload = cardDataInfo.map((data) => ({
      user_id: userData.data[0]._id,
      nominal: data.nominal,
      amount: data.amount,
      image: data.image,
      greeting_text: data.greeting_text,
      type_of_card: data.type_of_card,
      delivery_type: "email",
    }));
  
    dispatch(addGiftCart(payload, token));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  

  useEffect(() => {
    if (addcartSuccess?.status === 200) {
      navigation("/add-cart");
    }
  }, [addcartSuccess]);

 

  useEffect(() => {
    setCardDataInfo((prevData) => [
      {
        ...prevData[0], // Copy the existing object properties
        image: url, // Update the 'image' property with the new URL
      },
    ]);
  }, []);
  
  
  return (
    <Box mx={"7%"} mb={"40px"}>
      <Box>
        <Breadcrumbs
          separator={<ArrowForwardIosIcon fontSize="8px" />}
          aria-label="breadcrumb"
          sx={{ color: "#BABABA" }}
          py={2}
        >
          {path.map((item, index) => (
            <Typography key={index}>
              <Link
                to={item.path}
                style={{
                  color: location.pathname === item.path ? "black" : "#BABABA",
                }}
              >
                {item.label}
              </Link>
            </Typography>
          ))}
        </Breadcrumbs>
      </Box>
      <Box>
        <Typography
          sx={{
            color: "#212121",
            fontWeight: "700",
            fontSize: { xs: "30px", md: "40px" },
            lineHeight: { xs: "36px", md: "56px" },
          }}
          py={2}
        >
          Lahjakamu {t("giftcardpage.giftcard")}
        </Typography>
        <Grid container spacing={"30px"}>
          {cardDataInfo.map((card, index) => (
             <React.Fragment key={index}>
              <Grid item xs={12} md={4}>
                <GiftCardView
                  cardData={cardDataInfo[index]}
                  // key={index}
                  results={results}
                  resultsPaid={resultsPaid}
                />
              </Grid>
              <Grid item xs={12} md={8} px={{ md: "2rem" }}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: { xs: "1rem", sm: "2rem" },
                      flexWrap: { xs: "wrap", md: "nowrap" },
                    }}
                  >
                    <FormControl
                      sx={{ maxWidth: { xs: "100%", md: "450px" } }}
                      fullWidth
                    >
                      <Typography
                        sx={{
                          color: "#212121",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        py={1}
                      >
                        {t("giftcardpage.typeofnominal")}
                      </Typography>
                      <Select
                        sx={{
                          background: "#fff",
                          borderRadius: "8px",
                          border: "1px solid rgba(66, 84, 234, 0.20)",
                          fontWeight: "600",
                          "& .MuiSelect-select": {
                            padding: "10px 14px", // Adjust padding here
                          },
                        }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        IconComponent={ExpandMoreIcon}
                        placeholder="enter amount"
                        name="nominal"
                        value={card.nominal}
                        onChange={(e) =>
                          handleCardChange(index, "nominal", e.target.value)
                        }
                      >
                        {valueAry.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            ${item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box>
                      <Typography
                        sx={{
                          color: "#212121",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        py={1}
                      >
                        {t("giftcardpage.enteramt")}
                      </Typography>
                      <Stack
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: ".5rem",
                        }}
                        variant="outlined"
                      >
                        <button
                          onClick={() => handleIncrease(index)}
                          style={{
                            background: "#fff",
                            border: "none",
                            fontWeight: "bold",
                            fontSize: "20px",
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          +
                        </button>
                        <OutlinedInput
                          inputProps={{
                            "aria-label": "Without label",
                            min: "0",
                          }}
                          sx={{
                            background: "#fff",
                            borderRadius: "8px",
                            border: "none",
                            fontWeight: "600",
                            "& input": {
                              padding: "10px 14px", // Adjust input padding here
                            },
                          }}
                          value={card.amount}
                          onChange={(event) => handleInputChange(event, index)}
                        />
                        <button
                          onClick={() => handleDecrease(index)}
                          style={{
                            background: "#fff",
                            border: "none",
                            fontWeight: "bold",
                            fontSize: "20px",
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          -
                        </button>
                      </Stack>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#212121",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      pt={3}
                      pb={2}
                    >
                      {t("giftcardpage.addgreeting")}
                    </Typography>
                    <Box>
                      <Box
                        display="flex"
                        justifyContent={
                          card.type_of_card === "free" && "space-between"
                        }
                        alignItems="center"
                        flexWrap="wrap"
                        gap="1rem"
                        mb={1}
                      >
                        <Box
                          display="flex"
                          gap="1rem"
                          p={"3px"}
                          sx={{
                            background: "#e3e3e8",
                            maxWidth: "450px",
                            borderRadius: "8px",
                            width: "100%",
                          }}
                        >
                          <Typography
                            onClick={() => handleOptionClick("free", index)}
                            sx={{
                              background:
                                card.type_of_card === "free"
                                  ? "#fff"
                                  : "#e3e3e8",
                              borderRadius:
                                card.type_of_card === "free" ? "8px" : "none",
                              color: "#212121",
                              fontFamily: "Montserrat",
                              fontSize: "14px",
                              fontWeight: "600",
                              cursor: "pointer",
                            }}
                            width="50%"
                            textAlign="center"
                            py={"2px"}
                          >
                            {t("giftcardpage.free")}
                          </Typography>
                          <Typography
                            onClick={() => handleOptionClick("paid", index)}
                            sx={{
                              color: "#212121",
                              borderRadius:
                                card.type_of_card === "paid" ? "8px" : "none",
                              fontFamily: "Montserrat",
                              fontSize: "14px",
                              fontWeight: "600",
                              cursor: "pointer",
                              background:
                                card.type_of_card === "paid"
                                  ? "#fff"
                                  : "#e3e3e8",
                            }}
                            width="50%"
                            textAlign="center"
                            py={"2px"}
                          >
                            {t("giftcardpage.paid")}
                          </Typography>
                        </Box>
                        {card.type_of_card === "free" && (
                          <Box>
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={(event) =>
                                handleFileChange(event, index)
                              }
                            />
                            <Button
                              sx={{ color: "#4254EA", background: "#D9DCF9" }}
                              variant="outlined"
                              onClick={() => handleImgUpload(index)}
                              startIcon={<UpgradeIcon />}
                              size="small"
                            >
                              {t("giftcardpage.updimg")}
                            </Button>
                          </Box>
                        )}
                        {card.type_of_card === "paid" && (
                          <Box
                            sx={{
                              background: "#D8F1FD",
                              padding: "5px",
                              borderRadius: "8px",
                              fontWeight: 500,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "12px", color: "#212121" }}
                              pr={"10px"}
                            >
                              <span
                                style={{
                                  color: "#5AC8FA",
                                  fontWeight: "bold",
                                  padding: "0px 10px",
                                  fontSize: "15px",
                                }}
                              >
                                <em>i</em>
                              </span>
                              {t("giftcardpage.totalcost")} $2
                            </Typography>
                          </Box>
                        )}
                      </Box>

                      <Box>
                        {card.type_of_card === "free" && (
                          <>
                            <SearchBar handleSearch={handleSearch} />

                            <ImgList
                              setSelectedImg={setSelectedImg}
                              itemData={results}
                              load={loading}
                              updateImageInCardData={updateImageInCardData}
                              index={index}
                            />
                          </>
                        )}
                        {card.type_of_card === "paid" && (
                          <>
                            <SearchBar handleSearch={handleSearchPaid} />

                            <ImgList
                              setSelectedImg={setSelectedImg}
                              itemData={resultsPaid}
                              load={loadingPaid}
                              updateImageInCardData={updateImageInCardData}
                              index={index}
                            />
                          </>
                        )}
                        <Box>
                          <Grid container display="flex" flexWrap="wrap">
                            <Grid item xs={12} lg={5.8}>
                              <TextList
                                handleTextSelection={handleTextSelection}
                                index={index}
                                cardDataInfo={cardDataInfo}
                              />
                            </Grid>
                            <Grid item xs={12} lg={6} mt={4}>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "#64646D",
                                  fontWeight: "500",
                                }}
                                pb={1}
                              >
                                {t("giftcardpage.yourtxt")}
                              </Typography>
                              <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={3}
                                sx={{
                                  background: "#fff",
                                  width: "90%",
                                  border: "none",
                                  wordBreak: "break-all",
                                  "& fieldset": { border: "none" },
                                }}
                                inputProps={{
                                  "aria-label": "Without label",
                                  maxLength: 1000,
                                  // disableUnderline: true,
                                }}
                                onChange={(event) =>
                                  handleInputChangetext(event, index)
                                }
                                variant="outlined"
                              />
                              <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={0.4}
                                mt={"5px"}
                              >
                                <img src={info} alt="info" />
                                <Typography
                                  sx={{ fontSize: "12px", color: "#BABABA" }}
                                >
                                  {t("giftcardpage.maxchar")}
                                </Typography>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={8} px={{ md: "2rem" }}>
            <Box>
              <Button
                sx={{
                  color: "#4254EA",
                  fontSize: "12px",
                  fontWeight: "600",
                  margin: "-2rem 0rem 2rem 0rem",
                }}
                size="small"
                startIcon={<AddIcon />}
                onClick={handledataCard}
              >
                {t("giftcardpage.addmore")}
              </Button>
              <Box>
                {" "}
                <CustomButton
                  onClick={handleButtonClick}
                  width={{ sm: "50%", xs: "100%" }}
                >
                  {t("giftcardpage.addtocart")}{" "}
                </CustomButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CardLayout;
