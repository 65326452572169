import { call, put, takeLatest } from "redux-saga/effects";
import {
  REGISTER_PERSON,
  REGISTER_PERSON_ERROR,
  REGISTER_PERSON_SUCCESS,
} from "../types/RegisterPerson";
import { baseURL } from "../../utils/baseURL";
import { toast } from "react-toastify";

function* registerPersonSaga(action) {
  try {
    const response = yield call(() =>
      baseURL.post(`/auth/sign-up`, action.payload)
    );
    yield put({ type: REGISTER_PERSON_SUCCESS, payload: response.data });
  } catch (error) {
    toast.error('something went wrong, Please try again')
    const errorMessage = error.response?.data?.message || 'Unknown error occurred';
    const statusCode = error.response?.status || 'Unknown status';
    yield put({ type: REGISTER_PERSON_ERROR, payload: { message: errorMessage, status: statusCode } });
  }
}

export default function* watchRegisterPerson() {
  yield takeLatest(REGISTER_PERSON, registerPersonSaga);
}
