
export const LOGIN_OTP_PERSON = "LOGIN_OTP_PERSON";
export const LOGIN_OTP_PERSON_SUCCESS = "LOGIN_OTP_PERSON_SUCCESS";
export const LOGIN_OTP_PERSON_ERROR = "LOGIN_PER_PERSONS_ERROR";
export const LOGIN_CLEANUP = "LOGIN_CLEANUP";

export const CHECK_LOGIN_OTP_PERSON = "CHECK_LOGIN_OTP_PERSON";
export const CHECK_LOGIN_OTP_PERSON_SUCCESS = "CHECK_LOGIN_OTP_PERSON_SUCCESS";
export const CHECK_LOGIN_OTP_PERSON_ERROR = "CHECK_LOGIN_PERSONS_ERROR";

export const RESEND_LOGIN_OTP_PERSON = "RESEND_LOGIN_OTP_PERSON";
export const RESEND_LOGIN_OTP_PERSON_SUCCESS = "RESEND_LOGIN_OTP_PERSON_SUCCESS";
export const RESEND_LOGIN_OTP_PERSON_ERROR = "RESEND_LOGIN_PERSONSON_ERROR";

export const LOGIN_OTP_PERSON_SAVE = "LOGIN_OTP_PERSON_SAVE";

export const LOGIN_WITH_OTP_PERSON = "LOGIN_WITH_OTP_PERSON";
export const LOGIN_WITH_OTP_PERSON_SUCCESS = "LOGIN_WITH_OTP_PERSON_SUCCESS";
export const LOGIN_WITH_OTP_PERSON_ERROR = "LOGIN_WITH_OTP_PERSON_ERROR";

