import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import { toast } from "react-toastify";
import { PAYMENT_FAILURE, PAYMENT_REQUEST, PAYMENT_SUCCESS } from "../types/PaymentCard";

function* paymentCardSaga(action) {
  try {
    const { data, token, id } = action.payload;
    const url = `/payment/create-checkout-session`;
    const response = yield call(baseURL.post, url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({
      type: PAYMENT_SUCCESS,
      payload: response.data,
    });
    toast.success("add successfully!");
  } catch (error) {
    yield put({ type: PAYMENT_FAILURE, payload: error });
    toast.error("Please try again!");
  }
}



export default function* watchpaymentCardSaga() {
  yield takeLatest(PAYMENT_REQUEST, paymentCardSaga);
}
