import {
  GENERATE_PROMOCODE_CLEAN,
  GENERATE_PROMOCODE_FAILURE,
  GENERATE_PROMOCODE_REQUEST,
  GENERATE_PROMOCODE_SUCCESS,
} from "../types/GeneratePromoCode";

// reducers.js
const initialState = {
  code: {},
  loading: false,
  error: null,
  status:null
};

const GeneratePromoCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_PROMOCODE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GENERATE_PROMOCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.data,
        error: null,
        status:action.payload.status
      };

    case GENERATE_PROMOCODE_FAILURE:
      return {
        ...state,
        loading: false,
        code: null,
        status:null,
        error: action.error,
      };
    case GENERATE_PROMOCODE_CLEAN:
      return {
        ...state,
        loading: false,
        code: null,
        error: null,
        status:null
      };

    default:
      return state;
  }
};

export default GeneratePromoCodeReducer;
