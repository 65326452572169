import {
  LOGIN_COMPANY,
} from "../types/LoginCompanyType";

export function loginCompany(payload) {
  return {
    type: LOGIN_COMPANY,
    payload: payload,
  };
}

// export function loginComapnySuccess(payload) {
//   return {
//     type: LOGIN_COMPANY_SUCCESS,
//     payload: payload,
//   };
// }

// export function loginCompanyError(error) {
//   return {
//     type: LOGIN_COMPANY_FAILED,
//     payload: error,
//   };
// }
