import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();
  const TC = [
    {
      step: "1",
      data: t("giftcardpage.termconditon.stepone"),
    },
    {
      step: "2",
      data: t("giftcardpage.termconditon.steptwo"),
    },
    {
      step: "3",
      data: t("giftcardpage.termconditon.stepthree"),
    },
    {
      step: "4",
      data: t("giftcardpage.termconditon.stepfour"),
    },
    {
      step: "5",
      data: t("giftcardpage.termconditon.stepfive"),
    },
    {
      step: "6",
      data: t("giftcardpage.termconditon.stepsix"),
    },
  ];
  return (
    <Box sx={{ padding: { md: "80px 0px", xs: "40px 0px" } }}>
      <Typography
        sx={{
          color: "#212121",
          fontWeight: "700",
          fontSize: { xs: "30px", md: "40px" },
          lineHeight: { xs: "36px", md: "56px" },
          textAlign: "center",
        }}
        pb={2}
      >
        {t("giftcardpage.termconditon.heading")}
      </Typography>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent={{ xs: "start", md: "center" }}
        gap="25px"
        mb={3}
        sx={{ maxWidth: { xs: "700px", md: "none" }, mx: "auto" }}
      >
        {TC.map(({ step, data }, index) => ( // Updated this line
          <Grid item md={4} sx={{ margin: "10px" }} key={index}>
            <Box display="flex" alignItems="center" gap="24px">
              <Typography
                sx={{
                  color: "#fff",
                  background: "#5AC8FA",
                  padding: "20px 0px 19px 0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "0px 24px",
                  width: "64px",
                }}
              >
                {step}
              </Typography>
              <Typography sx={{ maxWidth: { md: "400px" }, width: "100%" }}>
                {data}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Terms;
