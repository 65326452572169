import { Box, Checkbox, OutlinedInput, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../global/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { UserInfoEdit } from "../../store/actions/EditUserInfoAction";
import { useTranslation } from "react-i18next";
const txt = {
  color: "#64646D",
  fontSize: "12px",
  fontWeight: 500,
  fontFamily: "Montserrat",
  opacity: ".8",
};

const MyAccount = () => {
  const [contactInfo, setContactInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    city: "",
    street: "",
    house: "",
    flat: "",
    marketing_permission: true,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const userData = useSelector((state) => state?.getUser?.user);
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const { t } = useTranslation();

  const handleChange = (field, value) => {
    setContactInfo((prevContactInfo) => ({
      ...prevContactInfo,
      [field]: value,
    }));

    let errorMessage = "";

    // Real-time validation for phone_number
    if (field === "phone_number") {
      const phoneValidationRegex = /^\+\d{1,4}\s?\d{1,}$/;
      errorMessage = !phoneValidationRegex.test(value.trim())
        ? "Invalid phone number format"
        : "";
    }

    // Real-time validation for email
    if (field === "email") {
      const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      errorMessage = !emailValidationRegex.test(value.trim())
        ? "Invalid email format"
        : "";
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorMessage,
    }));
  };

  const validate = () => {
    const errors = {};

    // Example validation checks, customize based on your requirements
    if (!contactInfo.first_name.trim()) {
      errors.first_name = "Name is required";
    }

    if (!contactInfo.last_name.trim()) {
      errors.last_name = "Name is required";
    }

    if (!contactInfo.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactInfo.email)) {
      errors.email = "Invalid email format";
    }

    if (!contactInfo.phone_number.trim()) {
      errors.phone_number = "Phone Number is required";
    } else if (!/^\+\d{1,4}\s?\d{1,}$/.test(contactInfo.phone_number)) {
      errors.phone_number = "Invalid phone number format";
    }

    if (!contactInfo.marketing_permission) {
      errors.marketing_permission = "You must agree to Marketing Permission";
    }

    setValidationErrors(errors);

    // Return true if there are no errors, false otherwise
    return Object.keys(errors).length === 0;
  };

  const handleSave = () => {
    const isValid = validate();

    if (isValid) {
      // Perform save action here
      const fullName = `${contactInfo.first_name} ${contactInfo.last_name}`;

      const payload = {
        user_name: fullName,
        first_name: contactInfo.first_name,
        last_name: contactInfo.last_name,
        email: contactInfo.email,
        phone_number: contactInfo.phone_number,
        city: contactInfo.city,
        street: contactInfo.street,
        house: contactInfo.house,
        flat: contactInfo.flat,
        marketing_permission: contactInfo.marketing_permission,
      };
      dispatch(UserInfoEdit(payload, token, userData.data[0]._id));
    }
  };

  useEffect(() => {
    if (
      userData &&
      userData?.data &&
      Array.isArray(userData.data) &&
      userData?.data.length > 0
    ) {
      const {
        user_name,
        email,
        phone_number,
        marketing_permission,
        city,
        street,
        house,
        flat,
        first_name,
        last_name,
      } = userData.data[0];

      // Extracting first name from the full name

      setContactInfo((prevContactInfo) => ({
        ...prevContactInfo,
        // user_name: user_name || "",
        first_name: first_name || "",
        last_name: last_name || "",
        email: email || "",
        phone_number: phone_number || "", // Set to empty string if undefined
        marketing_permission: marketing_permission || "",
        city: city || "",
        street: street || "",
        house: house || "",
        flat: flat || "",
      }));
    } else {
      setContactInfo({
        // user_name: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        city: "",
        street: "",
        house: "",
        flat: "",
        marketing_permission: "",
      });
    }
  }, [userData]);

  return (
    <Box>
      <Box>
        <Box>
          <Typography
            sx={{
              fontSize: "18px",
              color: "#212121",
              fontWeight: 600,
            }}
          >
            {t("navbar.contacts")}
          </Typography>
        </Box>
        <Box>
          <Box my="16px">
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap="8px"
            >
              {/* <Box>
                <Typography sx={txt}>User Name</Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    maxWidth: { sm: "240px" },
                    width: "100%",
                    background: "#F8F8F8",
                    "& fieldset": { border: "1px solid #4254ea33" },
                    borderRadius: "8px",
                  }}
                  placeholder="Enter your Name"
                  value={contactInfo.user_name}
                  onChange={(e) => handleChange("User_name", e.target.value)}
                />
                {validationErrors.User_name && (
                  <Typography
                    sx={{ color: "red", fontSize: "12px", ml: "12px" }}
                  >
                    {validationErrors.user_name}
                  </Typography>
                )}
              </Box> */}
              <Box>
                <Typography sx={txt}>{t("formLable.firstName")}</Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    maxWidth: { sm: "240px" },
                    width: "100%",
                    background: "#F8F8F8",
                    "& fieldset": { border: "1px solid #4254ea33" },
                    borderRadius: "8px",
                  }}
                  placeholder="Enter your First Name"
                  value={contactInfo.first_name}
                  onChange={(e) => handleChange("first_name", e.target.value)}
                />
                {validationErrors.first_name && (
                  <Typography
                    sx={{ color: "red", fontSize: "12px", ml: "12px" }}
                  >
                    {validationErrors.first_name}
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography sx={txt}>{t("formLable.lastName")}</Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    maxWidth: { sm: "240px" },
                    width: "100%",
                    background: "#F8F8F8",
                    "& fieldset": { border: "1px solid #4254ea33" },
                    borderRadius: "8px",
                  }}
                  placeholder="Enter your Last Name"
                  value={contactInfo.last_name}
                  onChange={(e) => handleChange("last_name", e.target.value)}
                />
                {validationErrors.last_name && (
                  <Typography
                    sx={{ color: "red", fontSize: "12px", ml: "12px" }}
                  >
                    {validationErrors.last_name}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap="8px"
              mt="8px"
            >
              <Box>
                <Typography sx={txt}>{t("formLable.email")}</Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    maxWidth: { sm: "240px" },
                    width: "100%",
                    background: "#F8F8F8",
                    "& fieldset": { border: "1px solid #4254ea33" },
                    borderRadius: "8px",
                  }}
                  placeholder="Enter your Email"
                  value={contactInfo.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
                {validationErrors.email && (
                  <Typography
                    sx={{ color: "red", fontSize: "12px", ml: "12px" }}
                  >
                    {validationErrors.email}
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography sx={txt}>{t("formLable.Phone")}</Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    maxWidth: { sm: "240px" },
                    width: "100%",
                    background: "#F8F8F8",
                    "& fieldset": { border: "1px solid #4254ea33" },
                    borderRadius: "8px",
                  }}
                  placeholder="+358"
                  value={contactInfo.phone_number}
                  onChange={(e) => handleChange("phone_number", e.target.value)}
                />
                {validationErrors.phone_number && (
                  <Typography
                    sx={{ color: "red", fontSize: "12px", ml: "12px" }}
                  >
                    {validationErrors.phone_number}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography
            sx={{
              fontSize: "18px",
              color: "#212121",
              fontWeight: 600,
            }}
          >
            {t("formLable.DeliveryDateN")}
          </Typography>
        </Box>
        <Box>
          <Box my="16px">
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap="8px"
            >
              <Box>
                <Typography sx={txt}> {t("partnersFilter.City")}</Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    maxWidth: { sm: "240px" },
                    width: "100%",
                    background: "#F8F8F8",
                    "& fieldset": { border: "1px solid #4254ea33" },
                    borderRadius: "8px",
                  }}
                  placeholder="City Name"
                  value={contactInfo.city}
                  onChange={(e) => handleChange("city", e.target.value)}
                />
              </Box>
              <Box>
                <Typography sx={txt}>{t("sidebarView.Street")}</Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    maxWidth: { sm: "240px" },
                    width: "100%",
                    background: "#F8F8F8",
                    "& fieldset": { border: "1px solid #4254ea33" },
                    borderRadius: "8px",
                  }}
                  placeholder="Street Name"
                  value={contactInfo.street}
                  onChange={(e) => handleChange("street", e.target.value)}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap="8px"
              mt="8px"
            >
              <Box>
                <Typography sx={txt}>{t("sidebarView.House")}</Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    maxWidth: { sm: "240px" },
                    width: "100%",
                    background: "#F8F8F8",
                    "& fieldset": { border: "1px solid #4254ea33" },
                    borderRadius: "8px",
                  }}
                  placeholder="House Name"
                  value={contactInfo.house}
                  onChange={(e) => handleChange("house", e.target.value)}
                />
              </Box>
              <Box>
                <Typography sx={txt}>{t("sidebarView.Flat")}</Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    maxWidth: { sm: "240px" },
                    width: "100%",
                    background: "#F8F8F8",
                    "& fieldset": { border: "1px solid #4254ea33" },
                    borderRadius: "8px",
                  }}
                  placeholder="Flat number"
                  value={contactInfo.flat}
                  onChange={(e) => handleChange("flat", e.target.value)}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyItems="self-start"
              gap="5px"
              mt="20px"
            >
              <Checkbox
                defaultChecked={contactInfo.marketing_permission}
                onChange={(e) =>
                  handleChange("marketing_permission", e.target.checked)
                }
                sx={{
                  padding: "0px",
                  color: "#4254EA",
                  "&.Mui-checked": {
                    color: "#4254EA",
                  },
                }}
              />
              <Typography
                sx={{ color: "#212121", fontSize: "14px", fontWeight: 500 }}
              >
                {t("sidebarView.MarkPer")}
              </Typography>
            </Box>
            {validationErrors.marketing_permission && (
              <Typography sx={{ color: "red", fontSize: "12px", ml: "12px" }}>
                {validationErrors.marketing_permission}
              </Typography>
            )}
            <Box mt="35px" sx={{ maxWidth: { sm: "240px" } }}>
              <CustomButton
                onClick={handleSave}
                fs="18px"
                width={{ xs: "100%" }}
              >
                {t("sidebarView.save")}
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyAccount;
