// src/sagas/giftCardSaga.js
import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import { PARTNER_FILTER_REQUEST } from "../types/PartnerFilter";
import {
  filterRequestFailure,
  filterRequestSuccess,
} from "../actions/PartnerFilterAction";

function* FilterPartnerSaga(action) {
  try {
    let url = "/users/company-product";

    // Helper function to remove spaces and single quotes from array elements
    // const cleanArray = (arr) => arr.map(item => item.replace(/\s+/g, '').replace(/'/g, ''));

    // Check if category is present in action.payload
    if (action.payload.category && action.payload.category.length > 0) {
      url += `?category[]=${action.payload.category.join("&category[]=")}`;
    }

    // Check if whom is present in action.payload
    if (action.payload.whom && action.payload.whom.length > 0) {
      url += `${url.includes("?") ? "&" : "?"}whom[]=${action.payload.whom.join(
        "&whom[]="
      )}`;
    }

    // Check if city is present in action.payload
    if (action.payload.city && action.payload.city.length > 0) {
      url += `${url.includes("?") ? "&" : "?"}city[]=${action.payload.city.join(
        "&city[]="
      )}`;
    }

    // Check if nominal is present in action.payload
    if (action.payload.nominal && action.payload.nominal.length > 0) {
      url += `${
        url.includes("?") ? "&" : "?"
      }nominal[]=${action.payload.nominal.join("&nominal[]=")}`;
    }

    const response = yield call(() => baseURL.get(url));
    yield put(filterRequestSuccess(response.data));
  } catch (error) {
    const serializedError = {
      status: error.response.status,
      data: error.response.data,
    };
    yield put(filterRequestFailure(serializedError));
  }
}

export default function* watchFilterPartnerSaga() {
  yield takeLatest(PARTNER_FILTER_REQUEST, FilterPartnerSaga);
}
