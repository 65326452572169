import {
  PARTNER_FILTER_FAILURE,
  PARTNER_FILTER_REQUEST,
  PARTNER_FILTER_SUCCESS,
} from "../types/PartnerFilter";

export const filterRequest = (query) => {
  return {
    type: PARTNER_FILTER_REQUEST,
    payload: query,
  };
};

export const filterRequestSuccess = (data) => ({
  type: PARTNER_FILTER_SUCCESS,
  payload: data,
});

export const filterRequestFailure = (error) => {
  return {
    type: PARTNER_FILTER_FAILURE,
    payload: error,
  };
};
