import { Box, Container, Typography } from "@mui/material";
import React from "react";
import success from "../../../assets/sucessspass.svg";
import CustomButton from "../../global/CustomButton";
import { useNavigate } from "react-router-dom";
import CommonBreadcrumb from "../../global/CommonBreadcrumbs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const SuccessValidation = () => {
  const { t } = useTranslation();

  const path = [
    { path: "/login", label: t("giftcardpage.main") },
    {
      path: "/forgot-pwd/successfully-reset",
      label: t("forgotPassword.fgPwd"),
    },
  ];

  const navigation = useNavigate();

  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: 0, paddingRight: 0, background: "#F2F2F7" }}
    >
      <Box mx={"7%"}>
        <Box>
          <CommonBreadcrumb path={path} />
        </Box>
        <Box py={{ xs: "30px", tab: "60px" }}>
          <Box
            sx={{
              bgcolor: "#fff",
              maxWidth: "592px",
              width: "100%",
              padding: { xs: "30px 20px", tab: "40px 116px" },
              borderRadius: "16px",
              mx: "auto",
              mb: "24px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                color: "#212121",
                textAlign: "center",
                mb: "24px",
              }}
            >
              {t("forgotPassword.headingforgetpwd")}
            </Typography>
            <Box>
              <Box>
                <Box mb="24px">
                  <Typography
                    sx={{
                      color: "#212121",
                      textAlign: "center",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {t("forgotPassword.pwdChangeSuccessfully")}
                  </Typography>
                </Box>
                <Box my="16px">
                  <Box display="flex" gap="8px" justifyContent="center">
                    <img src={success} alt="img-success" />
                  </Box>
                </Box>
                <Box mt="60px">
                  <CustomButton
                    onClick={() => {
                      navigation("/login");
                    }}
                    width="100%"
                    fs="18px"
                  >
                    {t("loginRegister.Login")}
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default SuccessValidation;
