import {
  Box,
  Button,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

const txt = {
  color: "#64646D",
  fontSize: "12px",
  fontWeight: 500,
  fontFamily: "Montserrat",
  ml: "12px",
};

const PwdReset = ({
  setNextPhase,
  setViewtab,
  setOpenAccordion,
  companyDetails,
  setCompanyDetails,
}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);

    // Password validation
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      password:
        newPassword.trim() !== ""
          ? newPassword.length >= 8 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/.test(
              newPassword
            )
            ? ""
            : "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
          : "Field is required",
    }));
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);

    // Confirm password validation
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      confirmPassword:
        newConfirmPassword.trim() !== ""
          ? newConfirmPassword === password
            ? ""
            : "Passwords do not match"
          : "Field is required",
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleBack = () => {
    setNextPhase({ step1: true, step2: false, step3: false });
    setViewtab(true);
    setOpenAccordion([1]);
  };

  const handleContinue = () => {
    // Check for empty fields
    if (!password.trim() || !confirmPassword.trim()) {
      setValidationErrors({
        password: !password.trim() ? "Field is required" : "",
        confirmPassword: !confirmPassword.trim() ? "Field is required" : "",
      });
      return;
    }
  
    // Continue only if there are no validation errors
    if (!validationErrors.password && !validationErrors.confirmPassword) {
      setNextPhase({ step1: false, step2: false, step3: true });
      setOpenAccordion([1, 2, 3]);
      setCompanyDetails({ ...companyDetails, password: password });
    }
  };
  
  return (
    <Box>
      <Box>
        <Box my="16px">
          <Typography sx={txt}>Password*</Typography>
          <OutlinedInput
            fullWidth
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            sx={{
              width: { xs: "100%", tab: "355px" },
              background: "#F8F8F8",
              "& fieldset": {
                border: `1px solid ${
                  validationErrors.password ? "red" : "#4254ea33"
                }`,
              },
              borderRadius: "8px",
              transition: "border-color 0.3s ease-in-out",
            }}
            placeholder="New Password"
            endAdornment={
              <IconButton
                edge="end"
                aria-label="toggle password visibility"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            }
          />
          {validationErrors.password && (
            <Typography sx={{ color: "red", fontSize: "12px" }}>
              {validationErrors.password}
            </Typography>
          )}
        </Box>
        <Box my="16px">
          <Typography sx={txt}>Confirm password*</Typography>
          <OutlinedInput
            fullWidth
            type={showConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            sx={{
              width: { xs: "100%", tab: "355px" },
              background: "#F8F8F8",
              "& fieldset": {
                border: `1px solid ${
                  validationErrors.confirmPassword ? "red" : "#4254ea33"
                }`,
              },
              borderRadius: "8px",
              transition: "border-color 0.3s ease-in-out",
            }}
            placeholder="Confirm New Password"
            endAdornment={
              <IconButton
                edge="end"
                aria-label="toggle confirm password visibility"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            }
          />
          {validationErrors.confirmPassword && (
            <Typography sx={{ color: "red", fontSize: "12px" }}>
              {validationErrors.confirmPassword}
            </Typography>
          )}
        </Box>
        <Box my="32px" display="flex" gap="8px">
          <Button
            sx={{
              width: "50%",
              borderRadius: "0px 30px",
              background: "#D9DCF9",
              color: "#212121",
              fontSize: { xs: "14px", sm: "18px" },
              fontWeight: 700,
              py: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#D9DCF9",
              },
              "&:active": {
                backgroundColor: "#D9DCF9",
              },
              textTransform: "none",
            }}
            variant="contained"
            startIcon={<KeyboardDoubleArrowLeftIcon />}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            sx={{
              width: "50%",
              borderRadius: "0px 30px",
              background: "#4254EA",
              color: "#fff",
              fontSize: { xs: "14px", sm: "18px" },
              fontWeight: 700,
              py: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#4254EA",
              },
              "&:active": {
                backgroundColor: "#4254EA",
              },
              textTransform: "none",
            }}
            variant="contained"
            onClick={handleContinue}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PwdReset;
