export const PERSON_GOOGLE_lOGIN = "PERSON_GOOGLE_lOGIN";
export const PERSON_GOOGLE_lOGIN_SUCCESS = "PERSON_GOOGLE_lOGIN_SUCCESS";
export const PERSON_GOOGLE_lOGIN_FAILURE = "PERSON_GOOGLE_lOGIN_FAILURE";

export const PERSON_FACEBOOK_lOGIN = "PERSON_FACEBOOK_lOGIN";
export const PERSON_FACEBOOK_lOGIN_SUCCESS = "PERSON_FACEBOOK_lOGIN_SUCCESS";
export const PERSON_FACEBOOK_lOGIN_FAILURE = "PERSON_FACEBOOK_lOGIN_FAILURE";

export const PERSON_APPLE_lOGIN = "PERSON_APPLE_lOGIN";
export const PERSON_APPLE_lOGIN_SUCCESS = "PERSON_APPLE_lOGIN_SUCCESS";
export const PERSON_APPLE_lOGIN_FAILURE = "PERSON_APPLE_lOGIN_FAILURE";