import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import { toast } from "react-toastify";
import {
  AFTERlOGIN_CHANGE_PASSWORD_INFO,
  AFTERlOGIN_CHANGE_PASSWORD_INFO_ERROR,
  AFTERlOGIN_CHANGE_PASSWORD_INFO_SUCCESS,
} from "../types/ChangePassword";

function* ChangePasswordSaga(action) {
  try {
    const { data, token } = action.payload;
    const url = `/auth/change-password`;
    const response = yield call(baseURL.post, url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({
      type: AFTERlOGIN_CHANGE_PASSWORD_INFO_SUCCESS,
      payload: response.data,
    });
    toast.success("Password Change successfully!");
  } catch (error) {
    yield put({ type: AFTERlOGIN_CHANGE_PASSWORD_INFO_ERROR, payload: error });
    toast.error("Failed to submit. Please try again!");
  }
}

export default function* watchChangePasswordSaga() {
  yield takeLatest(AFTERlOGIN_CHANGE_PASSWORD_INFO, ChangePasswordSaga);
}
