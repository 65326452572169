import { Container } from "@mui/material";
import React from "react";
import Header from "../../components/ContactUs/Header";
import ContactBlock from "../../components/ContactUs/ContactBlock";
import ContactMap from "../../components/ContactUs/ContactMap";
const ContactUs = () => {
  return (
    <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Header />
      <ContactBlock />
      <ContactMap />
    </Container>
  );
};

export default ContactUs;
