import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import {
  ADD_GIFT_CART_FAILURE,
  ADD_GIFT_CART_REQUEST,
  ADD_GIFT_CART_SUCCESS,
  GET_GIFT_CART_FAILURE,
  GET_GIFT_CART_REQUEST,
  GET_GIFT_CART_SUCCESS,
} from "../types/AddToCartGift";

function* AddtoGiftCardSaga(action) {
  try {
    const { data, token } = action.payload;
    const url = `/gift-card`;
    const requestData = { cards: data }; // Make sure baseURL.post is a function that works as intended
    const response = yield call(baseURL.post, url, requestData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data: responseData, status } = response; 
    // Check if the response has the expected structure before dispatching success
    yield put({
      type: ADD_GIFT_CART_SUCCESS,
      payload: {data,status},
    });
  } catch (error) {
    // Handle network errors or other exceptions
    yield put({ type: ADD_GIFT_CART_FAILURE, payload: error.message || error });
  }
}

function* getGiftCardSaga(action) {
  try {
    const { id, token } = action.payload;
    const url = `/gift-card/${id}`;
    const response = yield call(baseURL.get, url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // Check if the response has the expected structure before dispatching success
    yield put({
      type: GET_GIFT_CART_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    // Handle network errors or other exceptions
    yield put({ type: GET_GIFT_CART_FAILURE, payload: error.message || error });
  }
}

  

export default function* watchAddtoGiftCardSaga() {
  yield takeLatest(ADD_GIFT_CART_REQUEST, AddtoGiftCardSaga);
  yield takeLatest(GET_GIFT_CART_REQUEST, getGiftCardSaga);
}
