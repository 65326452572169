import {
  ACTIVE_CARD_CLEAN,
  ACTIVE_CARD_FAILURE,
  ACTIVE_CARD_REQUEST,
  ACTIVE_CARD_SUCCESS,
} from "../types/ActiveCheckCard";

const initialState = {
  loading: false,
  error: null,
  data: {},
};

// The reducer function
const ActiveCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_CARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ACTIVE_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload, // Update the customer data with the payload
        error: null,
      };
    case ACTIVE_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload, // Set the error with the payload
      };
    case ACTIVE_CARD_CLEAN:
      return {
        ...state,
        data: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default ActiveCardReducer;
