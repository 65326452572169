// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_SINGLE_USER_FAILURE,
  FETCH_SINGLE_USER_REQUEST,
  FETCH_SINGLE_USER_SUCCESS,
} from "../types/GetUser";
import { baseURL } from "../../utils/baseURL";

function* fetchSingleUser(action) {
  try {
    const { userId, token } = action.payload;
    const url = `/users/single-user/${userId}`;

    const response = yield call(baseURL.get, url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({ type: FETCH_SINGLE_USER_SUCCESS, payload: response.data });
  } catch (error) {
    const errorPayload = {
      message: error.error.message,
      name: error.name,
      code: error.code,
    };
    yield put({ type: FETCH_SINGLE_USER_FAILURE,error: error.response });
  }
}

export default function* watchFetchSingleUser() {
  yield takeLatest(FETCH_SINGLE_USER_REQUEST, fetchSingleUser);
}
