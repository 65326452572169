import { Box, Grid, OutlinedInput, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../global/CustomButton";
import info from "../../assets/Info.svg";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import CartCard from "../Cart/CartCard";
import DummyImg from "../../assets/Gift-dummy-image.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  EditAddTOGiftCart,
  getGiftCart,
} from "../../store/actions/AddToGiftCartAction";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
const text = {
  fontSize: "12px",
  color: "#64646D",
  fontWeight: "500",
};
const DeliveryDetails = ({ onNextStep }) => {
  const [formData, setFormData] = useState([
    {
      recipient_name: "",
      recipient_email: "",
      delivery_date: "",
      nominal: "",
      greeting_text: "",
    },
  ]);
  const [typeOfDelivery, setTypeOfDelivery] = useState("email"); // Fix typo here
  const [inputData, setInputData] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const userData = useSelector((state) => state.getUser?.user);
  const id_user = userData?.data[0]?._id;
  const token = localStorage.getItem("authToken");
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.GetGiftCartReducer?.data);
  const { t } = useTranslation();

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    // Validate file format
    if (
      file.name.endsWith(".csv") ||
      file.name.endsWith(".xls") ||
      file.name.endsWith(".xlsx")
    ) {
      setError(null);

      // Read the file and extract data
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Validate data structure based on your template
        const [header, ...rows] = jsonData;
        const expectedHeader = [
          "recipient_name",
          "recipient_email",
          "delivery_date",
          "nominal",
          "greeting_text",
        ];

        if (JSON.stringify(header) === JSON.stringify(expectedHeader)) {
          // Convert rows to array of objects
          const formattedData = rows.map((row) => {
            const obj = {};
            header.forEach((col, index) => {
              obj[col] = row[index];
            });

            // Convert Excel date to JavaScript Date object
            if (obj.delivery_date) {
              const excelDate = parseFloat(obj.delivery_date);
              const jsDate = new Date((excelDate - 25569) * 86400 * 1000);
              const formattedDate = jsDate.toISOString().split("T")[0];
              obj.delivery_date = formattedDate;
            }

            return obj;
          });

          // Update state only after successfully processing the data
          setFormData((prevData) => [...prevData, ...formattedData]);

          const convertedData = formattedData.map((data) => ({
            amount: "1",
            delivery_date: data.delivery_date,
            delivery_type: "email",
            greeting_text: data.greeting_text,
            image: DummyImg,
            isDelete: false,
            nominal: data.nominal,
            recipient_email: data.recipient_email,
            recipient_name: data.recipient_name,
            type_of_card: "free",
            user_id: id_user,
          }));

          setInputData((prevInputData) => [...prevInputData, ...convertedData]);
        } else {
          setError("Invalid file format. Please follow the template.");
        }
      };

      reader.readAsBinaryString(file);
    } else {
      setError("Invalid file format. Please upload a CSV or Excel file.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const downloadTemplate = () => {
    const templateData = [
      {
        recipient_name: "John Doe",
        recipient_email: "john.doe@example.com",
        delivery_date: "DD-MM-YYYY",
        nominal: "100",
        greeting_text: "Add Your Message",
      },
    ];

    const ws = XLSX.utils.json_to_sheet(templateData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Template");
    XLSX.writeFile(wb, "template.xlsx");
  };

  // const handleInputChange = (index, field, value) => {
  //   const newData = [...inputData];
  //   newData[index][field] = value;
  //   setInputData(newData);

  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [`${field}_${index}`]: "",
  //   }));
  // };
  const handleInputChange = (index, field, value) => {
    const newData = [...inputData];
    newData[index][field] = value;

    // Validate delivery date if the field being updated is 'delivery_date'
    if (field === "delivery_date") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Reset time part to midnight

      // Check if the selected date is before the current date
      if (selectedDate < currentDate) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`delivery_date_${index}`]: "Delivery date cannot be in the past",
        }));
      } else {
        // Clear the error if the selected date is valid
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`delivery_date_${index}`]: "",
        }));
      }
    }

    setInputData(newData);
  };

  const validateInput = (item, index) => {
    const itemErrors = {};

    if (!item?.recipient_name || !item.recipient_name.trim()) {
      itemErrors.recipient_name = "Recipient's name is required";
    }

    if (item?.delivery_type === "email") {
      if (!item?.recipient_email || !item.recipient_email.trim()) {
        itemErrors.recipient_email = "Recipient's Email is required";
      } else if (
        !item?.recipient_email.match(
          /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
        )
      ) {
        itemErrors.recipient_email = "Invalid email address";
      }

      if (!item?.delivery_date) {
        itemErrors.delivery_date = "Delivery date is required";
      } else {
        const currentDate = new Date();
        const selectedDate = new Date(item.delivery_date);
        currentDate.setHours(0, 0, 0, 0); // Reset time part to midnight

        if (selectedDate < currentDate) {
          itemErrors.delivery_date = "Delivery date cannot be in the past";
        }
      }
    } else if (item?.delivery_type === "sms") {
      if (!item?.recipient_number || !item.recipient_number.trim()) {
        itemErrors.recipient_number = "Recipient's Phone is required";
      } else if (!item?.recipient_number.match(/^(\+\d{1,4})?\d{10}$/)) {
        itemErrors.recipient_number = "Invalid phone number";
      }

      if (!item?.delivery_date) {
        itemErrors.delivery_date = "Delivery date is required";
      }
    }

    // Add more validation checks as needed for other fields

    return itemErrors;
  };

  const handleTypeChange = (type) => {
    setTypeOfDelivery(type);
  };

  const uniqueIds = new Set();

  const transformedCards = inputData.map((obj) => {
    if (uniqueIds.has(obj._id)) {
      delete obj._id;
    } else {
      uniqueIds.add(obj._id);
    }
    return obj;
  });

  const handleContinueClick = () => {
    const validationErrors = inputData.map((item, index) => ({
      index,
      errors: validateInput(item, index),
    }));

    const hasErrors = validationErrors.some(
      (validation) => Object.keys(validation.errors).length > 0
    );

    if (hasErrors) {
      // Handle errors, e.g., display error messages or prevent continuation
      const newErrors = {};
      validationErrors.forEach((validation) => {
        Object.entries(validation.errors).forEach(([field, errorMessage]) => {
          newErrors[`${field}_${validation.index}`] = errorMessage;
        });
      });
      toast.error("Please Fill all Data For SMS/Email");

      setErrors(newErrors);
    } else {
      // Continue logic
      onNextStep();
      const payloadEdit = {
        cards: transformedCards,
      };
      dispatch(EditAddTOGiftCart(payloadEdit, token, id_user));
    }
  };

  useEffect(() => {
    let newData = [];

    cartData &&
      cartData?.length > 0 &&
      cartData.forEach((item) => {
        const amount = parseInt(item.amount, 10);

        for (let i = 0; i < amount; i++) {
          // Create a copy of the current item
          const newItem = { ...item, amount: "1" };

          // Add extra keys based on delivery_type
          if (item.delivery_type === "email") {
            newItem.recipient_name = "" || item?.recipient_name;
            newItem.recipient_email = "" || item?.recipient_email;
            newItem.delivery_date = "" || item?.delivery_date;
          } else if (item.delivery_type === "sms") {
            newItem.recipient_name = "" || item?.recipient_name;
            newItem.recipient_number = "" || item?.recipient_number;
            newItem.delivery_date = "" || item?.delivery_date;
          }
          // Add the modified item to the newData array
          newData.push(newItem);
        }
      });

    setInputData(newData);
  }, [cartData, dispatch]);

  useEffect(() => {
    if (token && userData && userData.data && userData.data.length > 0) {
      const userId = userData.data[0]._id;
      dispatch(getGiftCart(userId, token));
    }
  }, []);

  return (
    <Box>
      <Box>
        <Typography style={text} mb={"4px"} ml={{ tab: "10px" }}>
          {t("checkoutpage.deliveryType")}
        </Typography>
        <Box display="flex" alignItems="center" gap="12px">
          <Box
            sx={{
              background: typeOfDelivery === "email" ? "#5AC8FA" : "#F2F2F7",
              padding: { xs: "10px 30px", sm: "12px 30px" },
              borderRadius: "8px",
              fontSize: "14px",
              color: typeOfDelivery === "email" ? "#fff" : "#212121",
              fontWeight: 500,
            }}
            onClick={() => handleTypeChange("email")}
          >
            {t("formLable.email")}
            <img src={info} alt="info" />
          </Box>
          <Box
            sx={{
              background: typeOfDelivery === "sms" ? "#5AC8FA" : "#F2F2F7",
              padding: { xs: "10px 30px", sm: "12px 30px" },
              borderRadius: "8px",
              fontSize: "14px",
              color: typeOfDelivery === "sms" ? "#fff" : "#212121",
              fontWeight: 500,
            }}
            onClick={() => handleTypeChange("sms")}
          >
            SMS <img src={info} alt="info" />
          </Box>
        </Box>
      </Box>

      <Box mt="24px">
        <Grid container spacing={"12px"}>
          {typeOfDelivery === "email" ? (
            <>
              <>
                {" "}
                {inputData &&
                  inputData.length > 0 &&
                  inputData.map((item, index) => (
                    <React.Fragment key={index}>
                      {item.delivery_type === "email" && (
                        <>
                          <Grid item xs={2}>
                            <CartCard
                              product={item?.image}
                              message={item?.greeting_text}
                              price={item?.nominal}
                            />
                          </Grid>
                          <Grid item xs={10} display="flex" flexWrap="wrap">
                            <Grid item xs={12} tab={6} md={6}>
                              <Typography
                                style={text}
                                mb={"4px"}
                                ml={{ tab: "10px" }}
                              >
                                {t("formLable.RecipientName")}
                              </Typography>
                              <OutlinedInput
                                placeholder="Recipient's name"
                                sx={{
                                  width: {
                                    lg: "228px",
                                    tab: "90%",
                                    xs: "100%",
                                  },
                                  background: "#F8F8F8",
                                  "& fieldset": {
                                    border: "1px solid #4254ea33",
                                  },
                                  borderRadius: "8px",
                                }}
                                size="small"
                                inputProps={{
                                  style: { fontSize: "0.8rem" },
                                }}
                                value={inputData[index].recipient_name}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "recipient_name",
                                    e.target.value
                                  )
                                }
                              />
                              {errors[`recipient_name_${index}`] && (
                                <Typography color="red" fontSize="0.8rem">
                                  {errors[`recipient_name_${index}`]}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12} tab={6} md={6}>
                              <Typography
                                style={text}
                                mb={"4px"}
                                ml={{ tab: "10px" }}
                              >
                                {t("formLable.RecipientEmail")}
                              </Typography>
                              <OutlinedInput
                                sx={{
                                  width: {
                                    lg: "228px",
                                    tab: "90%",
                                    xs: "100%",
                                  },
                                  background: "#F8F8F8",
                                  "& fieldset": {
                                    border: "1px solid #4254ea33",
                                  },
                                  borderRadius: "8px",
                                }}
                                size="small"
                                placeholder="Recipient's Email"
                                inputProps={{
                                  style: { fontSize: "0.8rem" },
                                }}
                                value={inputData[index].recipient_email}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "recipient_email",
                                    e.target.value
                                  )
                                }
                              />
                              {errors[`recipient_email_${index}`] && (
                                <Typography color="red" fontSize="0.8rem">
                                  {errors[`recipient_email_${index}`]}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12} tab={6} md={6}>
                              <Typography
                                style={text}
                                mb={"4px"}
                                ml={{ tab: "10px" }}
                              >
                                {t("formLable.DeliveryDate")}
                              </Typography>
                              <OutlinedInput
                                type="date"
                                sx={{
                                  width: {
                                    lg: "228px",
                                    tab: "90%",
                                    xs: "100%",
                                  },
                                  background: "#F8F8F8",
                                  borderRadius: "8px",
                                  "& fieldset": {
                                    border: "1px solid #4254ea33",
                                  },
                                }}
                                size="small"
                                placeholder="Delivery date"
                                inputProps={{
                                  style: { fontSize: "0.8rem" },
                                }}
                                defaultValue={inputData[index].delivery_date}
                                value={inputData[index].delivery_date || ""} // Add this line to bind the value
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "delivery_date",
                                    e.target.value
                                  )
                                }
                              />
                              {errors[`delivery_date_${index}`] && (
                                <Typography color="red" fontSize="0.8rem">
                                  {errors[`delivery_date_${index}`]}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </React.Fragment>
                  ))}
              </>
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: { lg: "239px", xs: "100%" },
                    borderRadius: "6px",
                    padding: "25px 50px",
                    cursor: "pointer",
                    border: "3px dashed #BABABA",
                    textAlign: "center",
                  }}
                  mt={".5rem"}
                  {...getRootProps()}
                >
                  <input type="file" style={{ display: "none" }} />
                  <input {...getInputProps()} />{" "}
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#212121",
                      fontWeight: "500",
                    }}
                  >
                    {t("formLable.importCVS")}
                  </Typography>
                </Box>
                {error && <p style={{ color: "red" }}>{error}</p>}
                <button onClick={downloadTemplate}>
                  {t("formLable.template")}
                </button>{" "}
              </Grid>
            </>
          ) : (
            <>
              {inputData &&
                inputData.length > 0 &&
                inputData.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.delivery_type === "sms" && (
                      <>
                        <Grid item xs={2}>
                          <CartCard
                            product={item?.image}
                            message={item?.greeting_text}
                            price={item?.nominal}
                          />
                        </Grid>
                        <Grid item xs={10} display="flex" flexWrap="wrap">
                          <Grid item xs={12} tab={6} md={6}>
                            <Typography
                              style={text}
                              mb={"4px"}
                              ml={{ tab: "10px" }}
                            >
                              {t("formLable.RecipientName")}
                            </Typography>
                            <OutlinedInput
                              placeholder="Recipient's name"
                              sx={{
                                width: { lg: "228px", tab: "90%", xs: "100%" },
                                background: "#F8F8F8",
                                "& fieldset": { border: "1px solid #4254ea33" },
                                borderRadius: "8px",
                              }}
                              size="small"
                              inputProps={{
                                style: { fontSize: "0.8rem" },
                              }}
                              value={inputData[index].recipient_name}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "recipient_name",
                                  e.target.value
                                )
                              }
                            />
                            {errors[`recipient_name_${index}`] && (
                              <Typography color="red" fontSize="0.8rem">
                                {errors[`recipient_name_${index}`]}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} tab={6} md={6}>
                            <Typography
                              style={text}
                              mb={"4px"}
                              ml={{ tab: "10px" }}
                            >
                              {t("formLable.RecipientPhone")}
                            </Typography>
                            <OutlinedInput
                              sx={{
                                width: { lg: "228px", tab: "90%", xs: "100%" },
                                background: "#F8F8F8",
                                "& fieldset": { border: "1px solid #4254ea33" },
                                borderRadius: "8px",
                              }}
                              size="small"
                              placeholder="Recipient's Phone"
                              inputProps={{
                                style: { fontSize: "0.8rem" },
                              }}
                              value={inputData[index].recipient_number} // Add this line to bind the value
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "recipient_number",
                                  e.target.value
                                )
                              }
                            />
                            {errors[`recipient_number_${index}`] && (
                              <Typography color="red" fontSize="0.8rem">
                                {errors[`recipient_number_${index}`]}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} tab={6} md={6}>
                            <Typography
                              style={text}
                              mb={"4px"}
                              ml={{ tab: "10px" }}
                            >
                              {t("formLable.DeliveryDate")}
                            </Typography>
                            <OutlinedInput
                              type="date"
                              sx={{
                                width: { lg: "228px", tab: "90%", xs: "100%" },
                                background: "#F8F8F8",
                                borderRadius: "8px",
                                "& fieldset": { border: "1px solid #4254ea33" },
                              }}
                              size="small"
                              placeholder="Delivery date"
                              inputProps={{
                                style: { fontSize: "0.8rem" },
                              }}
                              value={inputData[index].delivery_date} // Add this line to bind the value
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "delivery_date",
                                  e.target.value
                                )
                              }
                            />
                            {errors[`delivery_date_${index}`] && (
                              <Typography color="red" fontSize="0.8rem">
                                {errors[`delivery_date_${index}`]}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </React.Fragment>
                ))}
              {!inputData.some((obj) => obj.delivery_type === "sms") && (
                <>
                <Grid container xs={9} sm={11} display="flex">
                  <Grid item xs={12} tab={6} md={4}>
                    <Typography
                      style={text}
                      mb={"4px"}
                      ml={{ tab: "10px" }}
                      variant="body1"
                      color="textSecondary"
                    >
                      {t("formLable.RecipientName")}
                    </Typography>
                    <OutlinedInput
                      placeholder="Recipient's name"
                      sx={{
                        width: { lg: "200px", md: "80%", xs: "100%" },
                        background: "#F8F8F8",
                        "& fieldset": { border: "1px solid #4254ea33" },
                        borderRadius: "8px",
                      }}
                      size="small"
                      inputProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} tab={6} md={4}>
                    <Typography
                      style={text}
                      mb={"4px"}
                      ml={{ tab: "10px" }}
                      variant="body1"
                      color="textSecondary"
                    >
                      {t("formLable.RecipientPhone")}
                    </Typography>
                    <OutlinedInput
                      sx={{
                        width: { lg: "200px", md: "80%", xs: "100%" },
                        background: "#F8F8F8",
                        "& fieldset": { border: "1px solid #4254ea33" },
                        borderRadius: "8px",
                      }}
                      size="small"
                      placeholder="Recipient's Phone"
                      inputProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} tab={6} md={4}>
                    <Typography
                      style={text}
                      mb={"4px"}
                      ml={{ tab: "10px" }}
                      variant="body1"
                      color="textSecondary"
                    >
                      {t("formLable.DeliveryDate")}
                    </Typography>
                    <OutlinedInput
                      type="date"
                      sx={{
                        width: { lg: "200px", md: "80%", xs: "100%" },
                        background: "#F8F8F8",
                        borderRadius: "8px",
                        "& fieldset": { border: "1px solid #4254ea33" },
                      }}
                      size="small"
                      placeholder="Delivery date"
                      inputProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Box sx={{
                  fontSize:"10px",
                  color:"#C6C6C6"
                }} my={1}>
                  Note : The following fields are disabled because you have not selected 'Card' as the SMS delivery type.
                </Box>
                </>
                
              )}
            </>
          )}

          <Grid item xs={12} mt={"2rem"}>
            <Box width={{ xs: "100%", tab: "69%" }}>
              <CustomButton
                onClick={handleContinueClick}
                width={{ xs: "100%", md: "80%" }}
              >
                {t("formLable.continue")}
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DeliveryDetails;
