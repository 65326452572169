import { PAYMENT_FAILURE, PAYMENT_REQUEST, PAYMENT_SUCCESS } from "../types/PaymentCard";

  const initialState = {
    loading: false,
    error: null,
    data: {},
  };
  
  // The reducer function
  const PaymentReducer = (state = initialState, action) => {
    switch (action.type) {
      case PAYMENT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case PAYMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload, // Update the customer data with the payload
          error: null,
        };
      case PAYMENT_FAILURE:
        return {
          ...state,
          loading: false,
          data: null,
          error: action.payload, // Set the error with the payload
        };
      default:
        return state;
    }
  };
  
  export default PaymentReducer;
  