import { Box, Typography } from "@mui/material";
import React from "react";
import CustomButton from "../global/CustomButton";
import Carousel from "./Carousel/Carousel";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../utils/authService";
import { toast } from "react-toastify";

const Header = () => {
  const navigation = useNavigate();

  const handleButtonClick = () => {
    isAuthenticated() ? navigation('/gift-card'): toast.warn('Need to login, for access this page!'); // Added parentheses
    window.scrollTo(0, 0);

  };
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection={{ md: "row", xs: "column" }}>
      <Box
        width={{ md: "45%", xs: "100%" }}
        sx={{
          padding: { tab: "110px 110px", sm: "50px 50px", xs: "50px 20px" },
          backgroundColor: "#F2F2F7",
        }}
      >
        <Typography
          sx={{
            color: "#212121",
            fontWeight: "700",
            fontSize: { xs: "30px", md: "40px" },
            maxWidth: { sm: "354px" },
            lineHeight: { xs: "36px", md: "56px" },
          }}
        >
          LAHJAKAMU {t('home.header.giftcard')}
        </Typography>
        <Typography py={3} sx={{ color: "#212121" }}>
        {t('home.header.subtext')}
        </Typography>
        <CustomButton onClick={handleButtonClick}>{t('home.header.btntxt')}</CustomButton>
      </Box>
      <Box
        width={{ md: "55%", xs: "100%" }}
        sx={{ backgroundColor: "#3634A3" }}
      >
        <Carousel />
      </Box>
    </Box>
  );
};

export default Header;
