import { Box, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import add from "../../../assets/addcircle.svg";
import deleteIcon from "../../../assets/trash.svg";
import { useTranslation } from "react-i18next";

const AddImg = ({
  setProductInfo,
  productInfo,
  validationErrors,
  filteredData,
  productAdded,
}) => {
  const [images, setImages] = useState([null, null, null, null]);
  const { t } = useTranslation();

  const handleImageUpload = (index, event) => {
    const newImages = [...images];
    newImages[index] = event.target.files[0];
    setImages(newImages);
    setProductInfo((prev) => ({
      ...prev,
      product_images: newImages,
    }));
  };

  const handleDeleteImage = (index) => {
    const newImages = [...images];
    newImages[index] = null;
    setImages(newImages);
    setProductInfo((prev) => ({
      ...prev,
      product_images: newImages,
    }));
  };

  useEffect(() => {
    if (filteredData.length > 0) {
      const newImages = productInfo.product_images.map(
        (item, index) => item || images[index]
      );
      setImages(newImages);
    }
  }, [filteredData, productInfo.product_images,images]);
  return (
    <>
      <Box
        display="flex"
        gap={{ xs: "5px", sm: "12px" }}
        justifyContent={{ xs: "center", sm: "flex-start" }}
        alignItems={{ xs: "center", sm: "flex-start" }}
      >
        {/* Main Image Upload */}
        <Box
          sx={{
            position: "relative",
            maxWidth: "440px",
            width: "100%",
            height: { xs: "210px", sm: "340px" },
            background: "#fff",
            borderRadius: "16px",
            minWidth: "200px",
          }}
        >
          {/* Logic to display uploaded image or default add button */}
          {images[0] ? (
            <Box
              position="relative"
              width="100%"
              height="100%"
              borderRadius="16px"
              overflow="hidden"
            >
              <img
                src={
                  images[0]?.name
                    ? URL.createObjectURL(images[0])
                    : images[0]
                }
                alt="uploaded"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <IconButton
                sx={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  backgroundColor: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  padding: "4px 12px",
                  borderRadius: "8px",
                }}
                onClick={() => handleDeleteImage(0)}
              >
                <img src={deleteIcon} alt="delete" />
                <Typography
                  sx={{ fontSize: "14px", color: "#FF3B30", fontWeight: 500 }}
                >
                  Delete
                </Typography>
              </IconButton>
            </Box>
          ) : (
            <label htmlFor="main-image-upload">
              <input
                id="main-image-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => handleImageUpload(0, e)}
              />
              <Box
                display="flex"
                alignItems="center"
                gap="8px"
                justifyContent="center"
                sx={{
                  position: "absolute",
                  top: "0%",
                  left: "0%",
                  right: "0%",
                  bottom: "0%",
                }}
              >
                <img src={add} alt="add" />
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 600, color: "#4254EA" }}
                >
                  {t("sidebarView.AddPhoto")}
                </Typography>
              </Box>
            </label>
          )}
        </Box>
        <Box
          display="flex"
          gap={{ xs: "5px", sm: "12px" }}
          justifyContent="center"
          flexDirection={{ xs: "column" }}
        >
          {/* Other Image Upload Boxes */}
          {images.slice(1).map((image, index) => (
            <Box
              key={index + 1}
              sx={{
                position: "relative",
                width: { xs: "83px", sm: "140px" },
                height: { xs: "69px", sm: "105px" },
                background: "#fff",
                borderRadius: "16px",
              }}
            >
              {image ? (
                <Box
                  position="relative"
                  width="100%"
                  height="100%"
                  borderRadius="16px"
                  overflow="hidden"
                >
                  <img
                    src={
                      images[index + 1]?.name
                        ? URL.createObjectURL(image)
                        : image
                    }
                    alt={`uploaded-${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      aspectRatio: "5/4",
                      objectFit: "cover",
                    }}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "8px",
                      right: "8px",
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    }}
                    size="small"
                    onClick={() => handleDeleteImage(index + 1)}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </IconButton>
                </Box>
              ) : (
                <label htmlFor={`image-upload-${index + 1}`}>
                  <input
                    id={`image-upload-${index + 1}`}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => handleImageUpload(index + 1, e)}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      position: "absolute",
                      top: "0%",
                      left: "0%",
                      right: "0%",
                      bottom: "0%",
                    }}
                  >
                    <img src={add} alt="add" />
                  </Box>
                </label>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      {validationErrors.product_images && (
        <Typography sx={{ color: "red", fontSize: "12px" }}>
          {validationErrors.product_images}
        </Typography>
      )}
    </>
  );
};

export default AddImg;
