import { BIRTHDAY_STICKERS_REQUEST, NEW_YEAR_STICKERS_REQUEST, SEARCH_GIPHY_STICKERS_FAILURE, SEARCH_GIPHY_STICKERS_REQUEST, SEARCH_GIPHY_STICKERS_SUCCESS, WEDDING_STICKERS_REQUEST } from "../types/GifiSearchType";

export const searchGiphyStickers = (query) => ({
    type: SEARCH_GIPHY_STICKERS_REQUEST,
    payload: query,
  });
  
  export const searchGiphyStickersSuccess = (data) => ({
    type: SEARCH_GIPHY_STICKERS_SUCCESS,
    payload: data,
  });
  
  export const searchGiphyStickersFailure = (error) => ({
    type: SEARCH_GIPHY_STICKERS_FAILURE,
    payload: error,
  });

  
  // BIRTHDAY
  export const birthdayStickers = (query,limit) => ({
    type: BIRTHDAY_STICKERS_REQUEST,
    payload: {query,limit},
  });

  // WEDDING
  export const weddingStickers = (query,limit) => ({
    type: WEDDING_STICKERS_REQUEST,
    payload: {query,limit},
  });

  //NEW YEAR
  export const NewYearStickers = (query,limit) => ({
    type: NEW_YEAR_STICKERS_REQUEST,
    payload: {query,limit},
  });