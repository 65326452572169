import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import { toast } from "react-toastify";
import {
  EDIT_USERINFO,
  EDIT_USERINFO_ERROR,
  EDIT_USERINFO_SUCCESS,
} from "../types/EditUserInfo";
import {
  EDIT_COMPANY_INFO,
  EDIT_COMPANY_INFO_ERROR,
  EDIT_COMPANY_INFO_SUCCESS,
} from "../types/EditCompanyInfo";

function* UserInfoEditSaga(action) {
  try {
    const { data, token, id } = action.payload;
    const url = `/users/edit/${id}`;
    const response = yield call(baseURL.put, url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({
      type: EDIT_USERINFO_SUCCESS,
      payload: response.data,
    });
    toast.success("Contact Info submitted successfully!");
  } catch (error) {
    yield put({ type: EDIT_USERINFO_ERROR, payload: error });
    toast.error("Failed to submit. Please try again!");
  }
}

function* CompanyInfoEditSaga(action) {
  try {
    const { data, token, id } = action.payload;
    const url = `/users/edit/${id}`;
    const response = yield call(baseURL.put, url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({
      type: EDIT_COMPANY_INFO_SUCCESS,
      payload: response.data,
    });
    toast.success("Contact Info submitted successfully!");
  } catch (error) {
    yield put({ type: EDIT_COMPANY_INFO_ERROR, payload: error });
    toast.error("Failed to submit. Please try again!");
  }
}

export default function* watchUserInfoEditSaga() {
  yield takeLatest(EDIT_USERINFO, UserInfoEditSaga);
  yield takeLatest(EDIT_COMPANY_INFO, CompanyInfoEditSaga);
}
