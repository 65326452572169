
import { LOGIN_WITH_OTP_PERSON, LOGIN_WITH_OTP_PERSON_ERROR, LOGIN_WITH_OTP_PERSON_SUCCESS } from "../types/LoginOTP";
  
  const INITIAL_STATE = {
    loginData: {},
    islogindata: false,
  };
  
  export function LoginOtpPersonReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
      case LOGIN_WITH_OTP_PERSON:
        return {
          ...state,
          islogindata: false,
        };
  
      case LOGIN_WITH_OTP_PERSON_SUCCESS:
        return {
          ...state,
          islogindata: true,
          loginData: action.payload,
        };
  
      case LOGIN_WITH_OTP_PERSON_ERROR:
        return {
          ...state,
          islogindata: false,
        };
  
      // logout
    //   case LOGOUT_COMPANY:
    //     return {
    //       ...state,
    //       isLoggedIn: false,
    //     };
  
      default:
        return state;
    }
  }
  