import { CUSTOMER_FEEDBACK_REQUEST } from "../types/Feedback";

  
  export const Userfeedback = (data,token) => {
      return {
        type: CUSTOMER_FEEDBACK_REQUEST,
        payload: {data, token},
      };
    };

  