import React from "react";
import { Button } from "@mui/material";

const CustomButton = ({ children, onClick, bg, width, color, fs, pd }) => {
  const buttonStyles = {
    padding: pd
      ? { md: pd, tab: "10px 40px", xs: "10px 20px" }
      : { tab: "10px 40px", xs: "10px 20px" },
    borderRadius: "0px 30px",
    color: color ? color : "#fff",
    fontWeight: 700,
    backgroundColor: bg ? bg : "#4254EA",
    width: width,
    fontSize: { sm: fs ? fs : "16px", xs: "14px" },
    FontFamity: "Montserrat",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: bg ? bg : "#4254EA",
    },
    "&:active": {
      backgroundColor: bg ? bg : "#4254EA",
    },
    textTransform: "none",
  };

  return (
    <Button variant="contained" sx={buttonStyles} onClick={onClick}>
      {children}
    </Button>
  );
};

export default CustomButton;

// how to call

// import CustomButton from './CustomButton'; // Update the path based on your folder structure
// const handleButtonClick = () => {
//     Handle button click action
//     console.log('Button clicked!');
//   };
// <CustomButton onClick={handleButtonClick}>Design & Buy</CustomButton>
