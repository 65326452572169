import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import ReceiveTab from "./ReceiveTab";
import GiveTab from "./GiveTab";
import { useTranslation } from "react-i18next";

const TabsButton = () => {
  const [selectTab, setSelectTab] = useState(true);
  const { t } = useTranslation();
  const handleClick = () => {
    setSelectTab(!selectTab);
  };

  return (
    <Box mx={"7%"}>
      <Box
        display="flex"
        gap={{ xs: "20px",sm:"30px", md: "32px" }}
        justifyContent="center"
        mt={"50px"}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Button
          sx={{
            padding: { xs: "8px 30px", tab: "10px 50px", lg: "10px 40px" },
            borderRadius: "0px 30px",
            color: selectTab ? "#fff" : "#4254EA",
            background: selectTab ? "#4254EA" : "#D9DCF9",
            fontSize: { xs: "15px", md: "18px" },
            textTransform: "none",
            fontWeight: 700,
            "&:hover": {
              backgroundColor: selectTab ? "#4254EA" : "#D9DCF9",
            },
            "&:active": {
              backgroundColor: selectTab ? "#4254EA" : "#D9DCF9",
            },
          }}
          onClick={handleClick}
        >
          {t("howitworks.tabonebtn")}
        </Button>
        <Button
          sx={{
            padding: { xs: "8px 30px", tab: "10px 50px", lg: "10px 40px" },
            borderRadius: "0px 30px",
            color: !selectTab ? "#fff" : "#4254EA",
            background: !selectTab ? "#4254EA" : "#D9DCF9",
            fontSize: { xs: "15px", md: "18px" },
            fontWeight: 700,
            textTransform: "none",
            "&:hover": {
              backgroundColor: !selectTab ? "#4254EA" : "#D9DCF9",
            },
            "&:active": {
              backgroundColor: !selectTab ? "#4254EA" : "#D9DCF9",
            },
          }}
          onClick={handleClick}
        >
          {t("howitworks.tabtwobtn")}
        </Button>
      </Box>
      <Box>{selectTab === true ? <GiveTab /> : <ReceiveTab />}</Box>
    </Box>
  );
};

export default TabsButton;
