import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getGiftCart } from "../../store/actions/AddToGiftCartAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const styled = {
  fontSize: "18px",
  fontWeight: 700,
  color: "#212121",
};
const styled2 = {
  fontSize: "15px",
  fontWeight: 600,
  color: "#212121",
};
const small = {
  color: "#BABABA",
  fontSize: "14px",
};
const normal = {
  color: "#212121",
  fontSize: "14px",
  fontWeight: 600,
};
const normalText = {
  color: "#212121",
  fontSize: "14px",
  fontWeight: 500,
};
const OrderSummary = ({  cartData }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const token = localStorage.getItem("authToken");
  const userData = useSelector((state) => state.getUser.user);
  const isSmallScreen = useMediaQuery("(max-width:1024px)");
  const total = Array.isArray(cartData)
  ? cartData.reduce((acc, item) => {
      // Convert nominal and amount to numbers
      const nominal = parseFloat(item.nominal);
      const amount = parseFloat(item.amount);

      if (!isNaN(nominal) && !isNaN(amount)) {
        if (item.type_of_card === 'paid') {
          // Add 2 + nominal for each paid object
          acc += (nominal + 2) * amount;
        } else {
          // Add nominal * amount for non-paid objects
          acc += nominal * amount;
        }
      }

      return acc;
    }, 0)
  : 0;


  // Check if cartData is an array before using forEach
  let uniqueDeliveryTypes = Array.isArray(cartData) ? [] : null;

  // Iterate through the array to find unique delivery types
  if (Array.isArray(cartData)) {
    cartData.forEach((item) => {
      if (!uniqueDeliveryTypes.includes(item.delivery_type)) {
        uniqueDeliveryTypes.push(item.delivery_type);
      }
    });
  }

  // Determine the result based on the unique delivery types
  let result;
  if (uniqueDeliveryTypes?.length === 1) {
    result = uniqueDeliveryTypes[0]; // Only one unique delivery type
  } else {
    result = uniqueDeliveryTypes?.join(","); // Multiple unique delivery types
  }

  // useEffect(() => {
  //   if (Object.keys(cartData)?.length === 0) {
  //     navigation("/add-cart");
  //   }
  // }, [cartData]);

  useEffect(() => {
    if (token && userData && userData.data && userData.data.length > 0) {
      const userId = userData.data[0]._id;
      dispatch(getGiftCart(userId, token));
    }
  }, [token, userData]);

  return (
    <Box
      bgcolor="#FFF"
      borderRadius="1rem"
      padding="30px"
      width="auto"
      sx={{ boxShadow: "2px 2px 40px 0px rgba(66, 84, 234, 0.08)" }}
    >
      <Typography sx={styled} pb={"1.2rem"}>
      {t("checkoutpage.YourOrder")}
      </Typography>
      <Typography sx={styled2} pb={"1rem"}>
      Lahjakamu {t("giftcardpage.giftcard")}
      </Typography>
      <Box>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          spacing={"12px"}
        >
          <Grid item xs={6} sx={small}>
          {t("checkoutpage.Type")}
          </Grid>
          <Grid
            item
            xs={6}
            sx={normal}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {result}
          </Grid>
          <Grid item xs={6} sx={small}>
          {t("checkoutpage.Nominal")}
          </Grid>
          <Grid
            item
            xs={6}
            sx={normal}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {cartData.length > 0 ? (
              <span>
                {cartData.map((item, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && " + "}
                    {`$${item.nominal} ${
                      item.amount > 1 ? `*${item.amount}` : ""
                    }`}
                  </React.Fragment>
                ))}
              </span>
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={6} sx={small}>
          {t("checkoutpage.Amount")}
          </Grid>
          <Grid
            item
            xs={6}
            sx={normal}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {Array.isArray(cartData) && cartData.length > 0
              ? cartData.reduce((acc, item) => acc + parseInt(item.amount), 0)
              : 0}
          </Grid>
          <Grid item xs={6} sx={normalText}>
          {t("checkoutpage.cost")}:{" "}
          </Grid>
          <Grid
            item
            xs={6}
            sx={normal}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            ${total}
          </Grid>
          <Grid item xs={6} sx={normalText}>
          {t("checkoutpage.Delivery")}:{" "}
          </Grid>
          <Grid
            item
            xs={6}
            sx={normal}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            $0
          </Grid>
        </Grid>
        <Box>
          <Typography my={"12px"} sx={normalText}>
          {t("checkoutpage.PromoCode")}:{" "}
          </Typography>
          <Box style={{ position: "relative" }}>
            <input
              style={{
                border: "1px dashed #BABABA",
                width: isSmallScreen ? "100%" : "70%",
                padding: "12px 30% 12px 8px",
              }}
            />
            <Button
              sx={{
                borderRadius: "8px",
                background: "#64646D",
                color: "#fff",
                fontSize: "12px",
                p: { xs: "10px 13px", lg: "10px 39px" },
              }}
              style={{
                position: "absolute",
                right: "0%",
                zIndex: 2,
                transform: "translateX(2px)",
              }}
            >
              {t("checkoutpage.Apply")}
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={"72px"}
        >
          <Typography sx={styled}>{t("checkoutpage.total")}: </Typography>
          <Typography
            sx={{
              background: "#F2F2F7",
              padding: "12px 32px",
              fontSize: "18px",
              color: "#4254EA",
              fontWeight: 600,
            }}
          >
            ${total}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderSummary;
