import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonBreadcrumb from "../global/CommonBreadcrumbs";
import ProgressStepper from "./ProgressStepper";
import OrderSummary from "./OrderSummary";
import ContackDetails from "./ContackDetails";
import DeliveryDetails from "./DeliveryDetails";
import PaymetDetails from "./PaymetDetails";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Checkout = () => {
  const { t } = useTranslation();

  const path = [
    { path: "/", label: `${t("giftcardpage.main")}` },
    { path: "/gift-card", label: ` Lahjakamu ${t("navbar.card")}` },
    { path: "/add-cart", label: `${t("checkoutpage.cart")}` },
    { path: "/checkout", label: `${t("checkoutpage.checkout")}` },
  ];

  const [openAccordion, setOpenAccordion] = useState([1]);
  
  const userData = useSelector((state) => state?.getUser?.user);
  const cartData = useSelector((state) => state.GetGiftCartReducer?.data);

  const navigation = useNavigate();
  const styled = {
    fontSize: "18px",
    fontWeight: 700,
    color: "#212121",
  };

  const handleNextStep = () => {
    const nextAccordion = openAccordion[openAccordion.length - 1] + 1;
    setOpenAccordion([...openAccordion, nextAccordion]);
  };


  // useEffect(() => {
  //   if (cartData.length === 0) {
  //     navigation("/add-cart");
  //   }
  // }, [cartData]);

  // useEffect(() => {
  //   if (cartData && cartData?.length > 0) {
  //     setCart(cartData);
  //   }
  // }, [cartData]);
  
  return (
    <Box mx={"7%"} pb={"200px"}>
      <Box>
        <CommonBreadcrumb path={path} />
      </Box>
      <Box
        display={{ tab: "flex" }}
        alignItems="center"
        justifyContent="start"
        mb="1.4rem"
      >
        <Typography
          sx={{
            color: "#212121",
            fontWeight: "700",
            fontSize: { xs: "30px", md: "40px" },
            lineHeight: { xs: "36px", md: "56px" },
            display: "flex",
            justifyContent: "start",
          }}
        >
          {t("checkoutpage.checkout")}
        </Typography>
        <ProgressStepper
          openAccordion={openAccordion}
          width={{ tab: "340px", xs: "100%" }}
        />
      </Box>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Box>
            <Accordion
              expanded={openAccordion.includes(1)}
              sx={{
                background: "#fff",
                borderRadius: "16px",
                p: openAccordion.includes(2) ? "28px 28px" : "28px 23px",
                width: { md: "95%", xs: "100%" },
                "&.MuiAccordion-root:first-of-type": {
                  borderTopLeftRadius: "16px",
                  borderTopRightRadius: "16px",
                },
                position: "initial",
                boxShadow: "none",
              }}
            >
              <AccordionSummary>
                <Box width="100%">
                  <Typography style={styled}>{t("checkoutpage.contantDetails")}</Typography>
                  {openAccordion.includes(1) && (
                    <hr
                      style={{
                        color: "#F2F2F7",
                        opacity: ".2",
                        marginTop: "16px",
                      }}
                    />
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ContackDetails
                  onNextStep={handleNextStep}
                  userData={userData}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={openAccordion.includes(2)}
              sx={{
                background: "#fff",
                borderRadius: "16px",
                p: openAccordion.includes(2) ? "28px 28px" : "28px 23px",
                width: { md: "95%", xs: "100%" },
                borderBottom: "none",
                position: "initial",
                boxShadow: "none",
              }}
              style={{ margin: "30px 0px" }}
            >
              <AccordionSummary>
                <Box width="100%">
                  <Typography style={styled}>{t("checkoutpage.Delivery")}</Typography>
                  {openAccordion.includes(2) && (
                    <hr
                      style={{
                        color: "#F2F2F7",
                        opacity: ".2",
                        marginTop: "16px",
                      }}
                    />
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <DeliveryDetails onNextStep={handleNextStep} cartData={cartData}/>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={openAccordion.includes(3)}
              sx={{
                background: "#fff",
                borderRadius: "16px",
                p: openAccordion.includes(3) ? "28px 28px" : "28px 23px",
                width: { md: "95%", xs: "100%" },
                "&.MuiAccordion-root:last-of-type": {
                  borderBottomLeftRadius: "16px",
                  borderBottomRightRadius: "16px",
                },
                borderBottom: "none",
                position: "initial",
                boxShadow: "none",
              }}
            >
              <AccordionSummary>
                <Box width="100%">
                  <Typography style={styled}>{t("checkoutpage.Payment")} </Typography>
                  {openAccordion.includes(3) && (
                    <hr
                      style={{
                        color: "#F2F2F7",
                        opacity: ".2",
                        marginTop: "16px",
                      }}
                    />
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <PaymetDetails cartData={cartData} />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} mt={{ md: "0px", xs: "28px" }}>
          <OrderSummary cartData={cartData}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Checkout;
