import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import step1 from "../../assets/howItWorks/step1.svg";
import step2 from "../../assets/howItWorks/step2.svg";
import step3 from "../../assets/howItWorks/step3.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const GiveTab = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const handleNavigation = () => {
    navigation("/gift-card");
    window.scrollTo(0, 0);
  };
  return (
    <Box mt={"40px"}>
      <Grid
        container
        display="flex"
        alignItems="center"
        gap={{ tab: "30px", md: "42px", lg: "32px" }}
        justifyContent="center"
        maxWidth="1216px"
        width="100%"
        mx="auto"
      >
        <Grid item xs={12} tab={5.5} md={5.8} lg={6}>
          <Box>
            <img
              src={step1}
              alt="step1"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} tab={5.3} md={5.5} lg={5.5} mt={2}>
          <Box>
            <Typography
              sx={{
                display: "inline",
                color: "#212121",
                fontSize: { xs: "22px", md: "28px", lg: "32px" },
                lineHeight: "28px",
                fontWeight: 700,
                background: "#F2F2F7",
                borderRadius: "8px",
                padding: { xs: "12px 40px", tab: "10px 30px", md: "12px 40px" },
              }}
            >
              {t("howitworks.stepone")}
            </Typography>
            <Typography
              sx={{
                color: "#212121",
                fontSize: { xs: "22px", md: "28px", lg: "32px" },
                lineHeight: "30px",
                fontWeight: 700,
                padding: {
                  xs: "40px 0px 16px 0px",
                  tab: "20px 0px 16px 0px",
                  md: "40px 0px 16px 0px",
                },
              }}
            >
              {t("howitworks.createcard")}
            </Typography>
            <Typography
              sx={{
                color: "#212121",
                fontWeight: 600,
                maxWidth: "540px",
                fontFamily: "Montserrat",
                fontSize: { xs: "14px", md: "16px" },
              }}
            >
              {t("howitworks.tabonesubtxt")}{" "}
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={handleNavigation}
              >
                {" "}
                {t("howitworks.tabonesubtxturl")}{" "}
              </span>{" "}
              {t("howitworks.tabonesubtxtnml")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        display="flex"
        alignItems={{ xs: "self-start", tab: "center" }}
        gap={{ tab: "30px", md: "42px", lg: "32px" }}
        justifyContent="center"
        mt={"40px"}
        flexDirection={{ xs: "column-reverse", tab: "row" }}
        maxWidth="1216px"
        width="100%"
        mx="auto"
      >
        <Grid item xs={12} tab={5.5} md={5.8} lg={6} mt={2}>
          <Box>
            <Typography
              sx={{
                display: "inline",
                color: "#212121",
                fontSize: { xs: "22px", md: "28px", lg: "32px" },
                lineHeight: "28px",
                fontWeight: 700,
                background: "#F2F2F7",
                borderRadius: "8px",
                padding: { xs: "12px 40px", tab: "10px 30px", md: "12px 40px" },
              }}
            >
              {t("howitworks.steptwo")}
            </Typography>
            <Typography
              sx={{
                color: "#212121",
                fontSize: { xs: "22px", md: "28px", lg: "32px" },
                lineHeight: "30px",
                fontWeight: 700,
                padding: {
                  xs: "40px 0px 16px 0px",
                  tab: "20px 0px 16px 0px",
                  md: "40px 0px 16px 0px",
                },
              }}
            >
              {t("howitworks.contactdetails")}
            </Typography>
            <Typography
              sx={{
                color: "#212121",
                fontWeight: 600,
                maxWidth: "540px",
                fontFamily: "Montserrat",
                fontSize: { xs: "14px", md: "16px" },
              }}
            >
              {t("howitworks.tabtwosubtxt")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} tab={5.3} md={5.5} lg={5.5}>
          <Box>
            <img
              src={step2}
              alt="step2"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        display="flex"
        alignItems="center"
        gap={{ tab: "30px", md: "42px", lg: "32px" }}
        justifyContent="center"
        maxWidth="1216px"
        width="100%"
        mx="auto"
        mt={"40px"}
      >
        <Grid item xs={12} tab={5.5} md={5.8} lg={6}>
          <Box>
            <img
              src={step3}
              alt="step3"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} tab={5.3} md={5.5} lg={5.5} mt={2}>
          <Box>
            <Typography
              sx={{
                display: "inline",
                color: "#212121",
                fontSize: { xs: "22px", md: "28px", lg: "32px" },
                lineHeight: "28px",
                fontWeight: 700,
                background: "#F2F2F7",
                borderRadius: "8px",
                padding: { xs: "12px 40px", tab: "10px 30px", md: "12px 40px" },
              }}
            >
              {t("howitworks.stepthree")}
            </Typography>
            <Typography
              sx={{
                color: "#212121",
                fontSize: { xs: "22px", md: "28px", lg: "32px" },
                lineHeight: "30px",
                fontWeight: 700,
                padding: {
                  xs: "40px 0px 16px 0px",
                  tab: "20px 0px 16px 0px",
                  md: "40px 0px 16px 0px",
                },
              }}
            >
              {t("howitworks.payorder")}
            </Typography>
            <Typography
              sx={{
                color: "#212121",
                fontWeight: 600,
                maxWidth: "540px",
                fontFamily: "Montserrat",
                fontSize: { xs: "14px", md: "16px" },
              }}
            >
              {t("howitworks.tabthreesubtxt")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GiveTab;
