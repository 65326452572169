import React from "react";
import CommonAccordion from "../global/CommonAccordion";
import { Box, Grid, Typography } from "@mui/material";
import faq from "../../assets/faq-home.svg"
import { useTranslation } from "react-i18next";
const HomeFAQ = () => {
  const {t} = useTranslation();

  return (
    <Box sx={{ background: "#F2F2F7" }} py={5}>
      <Box mx={"7%"}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{textAlign:{xs:"center",md:"left"}}}>
            <Typography
              sx={{
                color: "#212121",
                fontWeight: "bold",
                fontSize: { xs: "30px", md: "40px" },
                lineHeight: { xs: "34px", sm: "36px", md: "56px" },
              }}
            >
             {t('home.faqsection.headtxt')}
            </Typography>
            <Typography py={1} sx={{ color: "#212121" }}>
            {t('home.faqsection.subtxt')}
            </Typography>
            <Box sx={{maxWidth:"500px",height:"auto",margin:"auto"}}>
              <img src={faq} alt='faq' style={{ maxWidth: "100%", height: "auto" }}/>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <CommonAccordion />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HomeFAQ;
