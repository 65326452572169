import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import { toast } from "react-toastify";
import { PERSON_PROMOCODE, PERSON_PROMOCODE_ERROR, PERSON_PROMOCODE_SUCCESS } from "../types/PersonPromocode";

function* promocodesSaga(action) {
  try {
    const { token,id } = action.payload;
    const url = `/gift-card/promo-code/${id}`;
    const response = yield call(baseURL.get, url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({
      type: PERSON_PROMOCODE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: PERSON_PROMOCODE_ERROR, payload: error });
    toast.error("Failed to submit. Please try again!");
  }
}

export default function* watchpromocodesSaga() {
  yield takeLatest(PERSON_PROMOCODE, promocodesSaga);
}
