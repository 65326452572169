import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import "../../global/basicModal.css";
import CustomButton from "../../global/CustomButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { cleanActiveModal } from "../../../store/actions/ActiveCheckAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  testAlign: "center",
  mx: "auto",
  p: 3,
};

const TabViewModal = ({
  btntxt,
  headingtxt,
  subtxt,
  messagetxt,
  balanceamt,
  modalbtn,
  fs,
  width,
  onClick,
  flag = false,
  open,
  setOpen,
  handleStatus,
  modalInfo,
  handleCloseModal,
  handleNavigate,
}) => {

  const { t } = useTranslation();

  const handleOpen = () => {
    handleStatus();
  };
  
  const handleClose = () => {
    handleCloseModal();
  };

  const handleChange = () => {
    handleNavigate();
  };
  
  return (
    <div>
      <CustomButton onClick={handleOpen} fs={fs} width={width}>
        {btntxt}
      </CustomButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="basic-modal">
          <CloseIcon onClick={handleClose} sx={{ float: "right" }} />

          <Box
            className="class-center"
            sx={{ padding: { tab: "50px 80px", xs: "20px 40px" } }}
          >
            <Typography
              sx={{
                fontSize: { sm: "30px", xs: "24px" },
                fontWeight: "700",
                color: "#212121",
                mt: "20px",
              }}
            >
              {headingtxt}
            </Typography>
            <Typography
              sx={{
                fontWeight: "600",
                color: "#212121",
                fontSize: "16px",
                maxWidth: { xs: "300px", sm: "384px" },
                width: "100%",
                mx: "auto",
                mt: "16px",
              }}
            >
              {messagetxt}
            </Typography>
            <Typography
              sx={{
                fontWeight: "500",
                color: "#212121",
                fontSize: "14px",
                mt: "8px",
                mb: "24px",
              }}
            >
              {subtxt}
            </Typography>
            <Box
              maxWidth={{ xs: "200px", sm: "384px" }}
              borderRadius="16px"
              width="100%"
              bgcolor="#F2F2F7"
              p={5}
              textAlign="center"
              mx={"auto"}
              mb={"8px"}
            >
              <Typography
                sx={{
                  fontSize: { xs: "16px", sm: "18px" },
                  fontWeight: 700,
                  color: "#4254EA",
                }}
              >
                ${modalInfo?.nominal}
              </Typography>
            </Box>

            {flag === true && (
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#212121",
                  fontSize: "17px",
                  mt: "12px",
                  mb: "50px",
                }}
              >
                {t("partnercardpage.happyshopping")}
                <span>
                  {" "}
                  <LocalMallIcon fontSize="12px" sx={{ color: "pink" }} />
                </span>
              </Typography>
            )}
            <Box mt={3}>
              <CustomButton onClick={handleChange} width="100%">
                {modalbtn}
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default TabViewModal;
