import { Container } from "@mui/material";
import React from "react";
import Header from "../../components/AboutUs/Header";
import Gallery from "../../components/AboutUs/Gallery";
import Block from "../../components/AboutUs/Block";
import Banner from "../../components/AboutUs/Banner";
import AboutFAQ from "../../components/AboutUs/AboutFAQ";

const AboutUs = () => {
  return (
    <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Header />
      <Gallery />
      <Block />
      <Banner />
      <AboutFAQ />
    </Container>
  );
};

export default AboutUs;
