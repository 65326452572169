import React, { useEffect, useState } from "react";
import { Box, IconButton, OutlinedInput, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomButton from "../../global/CustomButton";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordInfo } from "../../../store/actions/ForgotPasswordAction";
import { useTranslation } from "react-i18next";

const ResetPassword = ({ setStepChangePass }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const otpNumb = useSelector(
    (state) => state.ForgetPasswordReducer.info.phone_number
  );
  const navigation = useNavigate();
  const txt = {
    color: "#64646D",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  };
  const passwordChangeSuccess = useSelector(
    (state) => state.ChangePasswordReducer.data.status
  );

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character, and be at least 8 characters long"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const handleValidation = async () => {
    try {
      await validationSchema.validate(
        { password, confirmPassword },
        { abortEarly: false }
      );
      // If validation succeeds, navigate to '/'

      const payload = {
        phone_number: otpNumb,
        password: password,
      };
      dispatch(changePasswordInfo(payload));
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
      }
    }
  };

  const handleSubmit = () => {
    // Validate fields
    handleValidation();
    // Other submission logic if needed
  };

  useEffect(() => {
    if (passwordChangeSuccess === 200) {
      navigation("/forgot-pwd/successfully-reset");
      setStepChangePass({
        step1: true,
        step2: false,
        step3: false,
      });
    }
  }, [passwordChangeSuccess]);

  return (
    <Box>
      <Box mb="24px">
        <Typography
          sx={{
            color: "#212121",
            textAlign: "center",
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {t("forgotPassword.enterNewPwd")}{" "}
        </Typography>
      </Box>
      <Box my="16px">
        <Typography sx={txt}>{t("sidebarView.NewPassword")}</Typography>
        <OutlinedInput
          fullWidth
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={handlePasswordChange}
          sx={{
            width: { xs: "100%", tab: "355px" },
            background: "#F8F8F8",
            "& fieldset": {
              border:
                errors.password !== ""
                  ? "1px solid red"
                  : "1px solid #4254ea33",
            },
            borderRadius: "8px",
          }}
          placeholder="New Password"
          endAdornment={
            <IconButton
              edge="end"
              aria-label="toggle password visibility"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          }
        />{" "}
        {errors.password && (
          <Typography color="red" sx={{ fontSize: "12px" }}>
            {errors.password}
          </Typography>
        )}
      </Box>
      <Box my="16px">
        <Typography sx={txt}>{t("sidebarView.ConfirmNewPassword")}</Typography>
        <OutlinedInput
          fullWidth
          type={showConfirmPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          sx={{
            width: { xs: "100%", tab: "355px" },
            background: "#F8F8F8",
            "& fieldset": {
              border:
                errors.confirmPassword !== ""
                  ? "1px solid red"
                  : "1px solid #4254ea33",
            },
            borderRadius: "8px",
          }}
          placeholder="Confirm New Password"
          endAdornment={
            <IconButton
              edge="end"
              aria-label="toggle confirm password visibility"
              onClick={toggleConfirmPasswordVisibility}
            >
              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          }
        />
        {errors.confirmPassword && (
          <Typography color="red" sx={{ fontSize: "12px" }}>
            {errors.confirmPassword}
          </Typography>
        )}
      </Box>
      <Box my="35px">
        <CustomButton onClick={handleSubmit} width="100%" fs="18px">
          {t("sidebarView.SaveChange")}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default ResetPassword;
