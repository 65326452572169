import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import dummy from "../../assets/cards/DummyImg.svg";
import bg from "../../assets/cards/pricebg.svg";

const CartCard = ({ product, message, price }) => {
  return (
    <Box
      sx={{
        maxWidth: "84px",
        width: "auto",
        height: "110px",
        background: "#fff",
        textAlign: "center",
        mx: "auto",
        p:"5px",
      }}
    >
      <Box maxWidth="100px">
        <img
          src={product}
          alt="card-img"
          style={{
            width: "100%",
            aspectRatio:"5/4",
            objectFit: "contain",
          }}
        />
      </Box>
      <Box
        sx={{
          background: "#D9DCF9",
          height: "auto",
          mx: "auto",
          mt:"-5px",
          py:"3px"
        }}
      >
        <Typography
          textAlign="center"
          sx={{ wordBreak: "break-all", fontSize: "3px" }}
        >
          {message}
        </Typography>
      </Box>
      <Box>
        <Box sx={{ position: "relative" }}>
          <img
            src={bg}
            alt="card-img"
            style={{ maxWidth: "100%", height: "auto" }}
          />
          <Typography
            sx={{
              textAlign: "center",
              position: "absolute",
              width: "80%",
              background: "#fff",
              bottom: "30%",
              left: "10%",
              right: "10%",
              p: "1px",
              borderRadius: "6px",
              color: "#4254EA",
              fontWeight: "700",
              fontSize: "4px",
            }}
          >
            ${price}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CartCard;
