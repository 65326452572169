import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import { toast } from "react-toastify";
import {
  INFO_DETAILS_COMPANY,
  INFO_DETAILS_COMPANY_ERROR,
  INFO_DETAILS_COMPANY_SUCCESS,
} from "../types/AfterLoginPartnerInfo";

function appendToFormData(formData, key, value) {
  if (Array.isArray(value)) {
    value.forEach((item, index) => {
      if (typeof item === "object") {
        // Handle nested objects within arrays
        Object.entries(item).forEach(([nestedKey, nestedValue]) => {
          formData.append(`${key}[${index}][${nestedKey}]`, nestedValue);
        });
      } else {
        formData.append(`${key}[]`, item);
      }
    });
  } else {
    formData.append(key, value);
  }
}

function* userInfoAfterLogin(action) {
  try {
    const { data, token } = action.payload;
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "product_images") {
        value.forEach((file) => {
          formData.append("product_images", file);
        });
      } else {
        appendToFormData(formData, key, value);
      }
    });

    const url = `/users/add-product`;
    const response = yield call(baseURL.post, url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({
      type: INFO_DETAILS_COMPANY_SUCCESS,
      payload: response.data,
    });
    toast.success("Details submitted successfully!");
  } catch (error) {
    yield put({
      type: INFO_DETAILS_COMPANY_ERROR,
      payload: error.response.data,
    });
    toast.error(error.response?.data?.message || 'Something went wrong');
  }
}

export default function* watchuserInfoAfterLoginSaga() {
  yield takeLatest(INFO_DETAILS_COMPANY, userInfoAfterLogin);
}
