import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ItemCard from "../global/ItemCard";
import { tabProduct } from "./Data";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { filterRequest } from "../../store/actions/PartnerFilterAction";
import { useEffect } from "react";
import Loadingbar from "../../utils/Loadingbar";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../utils/authService";

function CustomTabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}


CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ItemsCategories = () => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const allData = useSelector((state) => state?.filterData?.data?.data);
  const loading = useSelector((state) => state?.filterData?.loading);
  const errorCheck = useSelector((state) => state?.filterData?.error?.status);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const labelTab = [
    `${t("home.category.all")}`,
    `${t("home.category.homegood")}`,
    `${t("home.category.child")}`,
    `${t("home.category.clothshoes")}`,
    `${t("home.category.restaurants")}`,
    `${t("home.category.skin")}`,
    `${t("home.category.jewelry")}`,
    `${t("home.category.books")}`,
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    switch (value) {
      case 0:
        dispatch(filterRequest(""));
        break;
      case 1:
        const payload1 = {
          category: ["Home goods"],
        };
        dispatch(filterRequest(payload1));
        break;
      case 2:
        const payload2 = {
          category: ["Childrens goods"],
        };
        dispatch(filterRequest(payload2));
        break;
      case 3:
        const payload3 = {
          category: ["Clothes & Shoes"],
        };
        dispatch(filterRequest(payload3));
        break;
      case 4:
        const payload4 = {
          category: ["Restaurants"],
        };
        dispatch(filterRequest(payload4));
        break;
      case 5:
        const payload5 = {
          category: ["Cosmetics & Perfumery"],
        };
        dispatch(filterRequest(payload5));
        break;
      case 6:
        const payload6 = {
          category: ["Jewelry"],
        };
        dispatch(filterRequest(payload6));
        break;
      case 7:
        const payload7 = {
          category: ["Books"],
        };
        dispatch(filterRequest(payload7));
        break;
      default:
        dispatch(filterRequest(""));
        break;
    }
  }, [value]);

  const renderTabPanels = () => {
    return tabProduct.map((item, index) => (
      <CustomTabPanel key={index} value={value} index={index}>
        {loading ? (
          <Box>
            <Loadingbar />
          </Box>
        ) : (
          <>
            {errorCheck === 404 ? (
              <Box
                sx={{ color: "#EBEBE4", fontSize: "18px", textAlign: "center" }}
              >
                No Data Found
              </Box>
            ) : (
              <Box
                display="flex"
                flexWrap="wrap"
                gap="1rem"
                justifyContent="center"
              >
                {allData &&
                  allData?.length > 0 &&
                  allData.map(
                    (
                      { company_name, product_images, about_company },
                      productIndex
                    ) => (
                      <ItemCard
                        key={productIndex}
                        title={company_name}
                        display={product_images[0]}
                        describe={about_company}
                      />
                    )
                  )}
              </Box>
            )}
          </>
        )}
        <Box sx={{ textAlign: "center" }} mt={{ sm: 5, xs: 3 }}>
          <Button
            sx={{ color: "#4254EA", textTransform: "none", fontWeight: "bold" }}
            endIcon={<KeyboardDoubleArrowRightIcon />}
            onClick={() => {
              isAuthenticated() ? navigate("/all-partners"):navigate("/login");;
            }}
          >
            {t("showmore")}
          </Button>
        </Box>
      </CustomTabPanel>
    ));
  };

  return (
    <Box mx={"7%"}>
      <Typography
        sx={{
          color: "#212121",
          fontWeight: "700",
          fontSize: { xs: "30px", md: "40px" },
          lineHeight: { xs: "36px", md: "56px" },
          textAlign: "center",
        }}
        pb={2}
      >
        {t("home.category.ptn")}
      </Typography>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="basic tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          {labelTab.map((item, index) => (
            <Tab
              key={index}
              label={item}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "rgba(66, 84, 234, 0.10)", // Change background color when selected
                },
              }}
            />
          ))}
        </Tabs>
      </Box>
      {renderTabPanels()}
    </Box>
  );
};

export default ItemsCategories;
