import {
  REGISTER_OTP,
  REGISTER_OTP_ERROR,
  REGISTER_OTP_SUCCESS,
  REGISTER_PERSON_INFO,
} from "../types/RegisterPerson";
const initialState = {
  registerdataOTP: {},
  loading: false,
  error: null,
  data: {},
};

// The reducer function
const RegisterPersonOTPReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_OTP:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REGISTER_PERSON_INFO:
     
      return {
        ...state,
        loading: false, // Set loading to false
        error: null, // Set error to null
        data: action.payload,
      };
    case REGISTER_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        registerdataOTP: action.payload, // Update the customer data with the payload
        error: null,
      };
    case REGISTER_OTP_ERROR:
      return {
        ...state,
        loading: false,
        registerdataOTP: null,
        error: action.payload, // Set the error with the payload
      };
    default:
      return state;
  }
};

export default RegisterPersonOTPReducer;
