import React, { useState } from "react";
import { Box, IconButton, OutlinedInput, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomButton from "../global/CustomButton";

const Security = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const handleToggleCurrentPassword = () => {
    setShowCurrentPassword((prevShow) => !prevShow);
  };

  const handleToggleNewPassword = () => {
    setShowNewPassword((prevShow) => !prevShow);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShow) => !prevShow);
  };

  const txt = {
    color: "#64646D",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  };

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            color: "#212121",
            fontWeight: 600,
          }}
        >
          Change Password
        </Typography>
      </Box>
      <Box>
        <Box mt="16px">
          <Typography sx={txt}>Current Password</Typography>
          <OutlinedInput
            type={showCurrentPassword ? "text" : "password"}
            value={currentPassword}
            onChange={handleCurrentPasswordChange}
            fullWidth
            sx={{
              maxWidth: { sm: "240px" },
              width: "100%",
              background: "#F8F8F8",
              "& fieldset": { border: "1px solid #4254ea33" },
              borderRadius: "8px",
            }}
            placeholder="Current Password"
            endAdornment={
              <IconButton
                onClick={handleToggleCurrentPassword}
                edge="end"
                aria-label="toggle password visibility"
              >
                {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            }
          />
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          gap="8px"
        >
          <Box my="16px">
            <Typography sx={txt}>New Password</Typography>
            <OutlinedInput
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={handleNewPasswordChange}
              fullWidth
              sx={{
                maxWidth: { sm: "240px" },
                width: "100%",
                background: "#F8F8F8",
                "& fieldset": { border: "1px solid #4254ea33" },
                borderRadius: "8px",
              }}
              placeholder="New Password"
              endAdornment={
                <IconButton
                  onClick={handleToggleNewPassword}
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
            />
          </Box>
          <Box my="16px">
            <Typography sx={txt}>Confirm New Password</Typography>
            <OutlinedInput
              type={showConfirmPassword ? "text" : "password"}
              value={confirmNewPassword}
              onChange={handleConfirmNewPasswordChange}
              fullWidth
              sx={{
                maxWidth: { sm: "240px" },
                width: "100%",
                background: "#F8F8F8",
                "& fieldset": { border: "1px solid #4254ea33" },
                borderRadius: "8px",
              }}
              placeholder="Confirm New Password"
              endAdornment={
                <IconButton
                  onClick={handleToggleConfirmPassword}
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
            />
          </Box>
        </Box>
        <Box mt="20px" sx={{maxWidth: {sm:"240px"}}}>
          <CustomButton
            onClick={() => {}}
            fs="18px"
            width={{ xs: "100%"}}
          >
            Save Change
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Security;
