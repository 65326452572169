import React from "react";
import TabsLogin from "../../components/Login/TabsLogin";
import { Container } from "@mui/material";

const LoginPage = () => {
  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: 0, paddingRight: 0, background: "#F2F2F7" }}
    >
      <TabsLogin />
    </Container>
  );
};

export default LoginPage;
