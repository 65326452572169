import React, { useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Drawer,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, Outlet, useLocation } from "react-router-dom";
import account from "../../assets/account/user-circle.svg";
import card from "../../assets/account/credit-card.svg";
import Promocodes from "../../assets/account/color-swatch.svg";
import purchase from "../../assets/account/shopping-bag.svg";
import Security from "../../assets/account/lock-closed.svg";
import Logout from "../../assets/account/logout.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { removeToken } from "../../utils/authService";
import { useDispatch, useSelector } from "react-redux";
import { balanceRequest } from "../../store/actions/GetBalanceAction";
import { useTranslation } from "react-i18next";

const SidebarPerson = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const userData = useSelector((state) => state.getUser.user);
  const { t } = useTranslation();
  const bal = useSelector((state) => state?.balance?.balance?.data?.balance);
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleLogout = () => {
    // Remove the token and navigate to the login page
    removeToken();
    // Replace '/login' with your actual login page route
    window.location.href = "/login";
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          background: "#FFF",
          maxWidth: { md: "280px", tab: "180px" },
          width: "100%",
          boxShadow: "0px 0px 20px 0px rgba(5, 17, 118, 0.04)",
          pt: "30px",
        }}
      >
        <Link to={"/person-account/my-account"}>
          <Typography
            sx={{
              padding: "16px 20px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              color: "#212121",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              background:
                location.pathname === "/person-account/my-account" ||
                location.pathname === "/person-account"
                  ? "#D9DCF9"
                  : "transparent",
            }}
          >
            <img src={account} alt="My Account" />
            {t("sidebarView.MyAccount")}
          </Typography>
        </Link>
        <Divider sx={{ background: "#0511760a" }} />

        <Link to={"/person-account/gift-card"}>
          <Typography
            sx={{
              padding: "16px 20px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              color: "#212121",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              background:
                location.pathname === "/person-account/gift-card"
                  ? "#D9DCF9"
                  : "transparent",
            }}
          >
            <img src={card} alt="Gift Card" />
            {t("sidebarView.gf")}
          </Typography>
        </Link>
        <Divider sx={{ background: "#0511760a" }} />

        <Link to={"/person-account/promocodes"}>
          <Typography
            sx={{
              padding: "16px 20px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              color: "#212121",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              background:
                location.pathname === "/person-account/promocodes"
                  ? "#D9DCF9"
                  : "transparent",
            }}
          >
            <img src={Promocodes} alt="Promocodes" />
            {t("sidebarView.Promocodes")}
          </Typography>
        </Link>
        <Divider sx={{ background: "#0511760a" }} />

        <Link to={"/person-account/purchases"}>
          <Typography
            sx={{
              padding: "16px 20px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              color: "#212121",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              background:
                location.pathname === "/person-account/purchases"
                  ? "#D9DCF9"
                  : "transparent",
            }}
          >
            <img src={purchase} alt="Purchases" />
            {t("sidebarView.Purchases")}
          </Typography>
        </Link>
        <Divider sx={{ background: "#0511760a" }} />

        {/* <Link to={"/person-account/security"}>
          <Typography
            sx={{
              padding: "16px 20px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              color: "#212121",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              background:
                location.pathname === "/person-account/security"
                  ? "#D9DCF9"
                  : "transparent",
            }}
          >
            <img src={Security} alt="Security" />
            Security
          </Typography>
        </Link>
        <Divider sx={{ background: "#0511760a" }} /> */}
        <Typography
          sx={{
            padding: "16px 20px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: "#212121",
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            background:
              location.pathname === "/login" ? "#D9DCF9" : "transparent",
            cursor: "pointer",
          }}
          onClick={handleLogout}
        >
          <img src={Logout} alt="Logout" />
          {t("sidebarView.Logout")}
        </Typography>

        <Divider sx={{ background: "#0511760a" }} />
      </Box>
    </Box>
  );

  useEffect(() => {
    if (token && userData) {
      const userId = userData.data[0]._id;
      dispatch(balanceRequest(userId, token));
    }
  }, []);

  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: 0, paddingRight: 0, background: "#F2F2F7" }}
    >
      <Box mx={"7%"}>
        <Box>
          <Breadcrumbs
            separator={<ArrowForwardIosIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{ color: "#BABABA" }}
            py={2}
          >
            <Typography>
              <Link
                to="/"
                style={{
                  color: location.pathname === "/" ? "black" : "#BABABA",
                }}
              >
                {t("giftcardpage.main")}
              </Link>
            </Typography>

            {(location.pathname === "/person-account/my-account" ||
              location.pathname === "/person-account") && (
              <Typography
                style={{
                  color:
                    location.pathname === "/person-account/my-account" ||
                    location.pathname === "/person-account"
                      ? "black"
                      : "#BABABA",
                }}
              >
                {t("sidebarView.MyAccount")}
              </Typography>
            )}
            {location.pathname === "/person-account/gift-card" && (
              <Typography
                style={{
                  color:
                    location.pathname === "/person-account/gift-card"
                      ? "black"
                      : "#BABABA",
                }}
              >
                {t("sidebarView.gf")}
              </Typography>
            )}
            {location.pathname === "/person-account/promocodes" && (
              <Typography
                style={{
                  color:
                    location.pathname === "/person-account/promocodes"
                      ? "black"
                      : "#BABABA",
                }}
              >
                {t("sidebarView.Promocodes")}
              </Typography>
            )}
            {location.pathname === "/person-account/purchases" && (
              <Typography
                style={{
                  color:
                    location.pathname === "/person-account/purchases"
                      ? "black"
                      : "#BABABA",
                }}
              >
                {t("sidebarView.Purchases")}
              </Typography>
            )}
            {/* {location.pathname === "/person-account/security" && (
              <Typography
                style={{
                  color:
                    location.pathname === "/person-account/security"
                      ? "black"
                      : "#BABABA",
                }}
              >
                Security
              </Typography>
            )} */}
          </Breadcrumbs>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my="24px"
        >
          <Typography
            sx={{
              color: "#212121",
              fontWeight: 700,
              fontSize: { xs: "28px", md: "40px" },
            }}
          >
            {t("sidebarView.Profile")}
          </Typography>
          <Typography
            sx={{
              padding: { xs: "8px 25px", sm: "8px 40px", tab: "10px 80px" },
              background: "#4254ea1a",
              borderRadius: "0px 30px",
              display: "inline",
              color: "#4254EA",
              fontSize: { sm: "18px", xs: "14px" },
              fontWeight: 700,
            }}
          >
            {t("partnercardpage.balance")}: ${bal}
          </Typography>
        </Box>
        <Box
          pb="100px"
          display="flex"
          gap="32px"
          sx={{
            flexDirection: { tab: "row", xs: "column" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              background: "#FFF",
              maxWidth: { md: "250px", tab: "180px" },
              width: "100%",
              height: "280px",
              boxShadow: "0px 0px 20px 0px rgba(5, 17, 118, 0.04)",
              display: { tab: "block", xs: "none" },
              borderRadius: "16px",
            }}
          >
            <Link to={"/person-account/my-account"}>
              <Typography
                sx={{
                  padding: "16px 20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#212121",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  background:
                    location.pathname === "/person-account/my-account" ||
                    location.pathname === "/person-account"
                      ? "#D9DCF9"
                      : "transparent",
                  borderRadius:
                    location.pathname === "/person-account/my-account" ||
                    location.pathname === "/person-account"
                      ? "16px 16px 0px 0px"
                      : "transparent",
                }}
              >
                <img src={account} alt="My Account" />
                {t("sidebarView.MyAccount")}
              </Typography>
            </Link>
            <Divider sx={{ background: "#0511760a" }} />

            <Link to={"/person-account/gift-card"}>
              <Typography
                sx={{
                  padding: "16px 20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#212121",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  background:
                    location.pathname === "/person-account/gift-card"
                      ? "#D9DCF9"
                      : "transparent",
                }}
              >
                <img src={card} alt="Gift Card" />
                {t("sidebarView.gf")}
              </Typography>
            </Link>
            <Divider sx={{ background: "#0511760a" }} />

            <Link to={"/person-account/promocodes"}>
              <Typography
                sx={{
                  padding: "16px 20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#212121",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  background:
                    location.pathname === "/person-account/promocodes"
                      ? "#D9DCF9"
                      : "transparent",
                }}
              >
                <img src={Promocodes} alt="Promocodes" />
                {t("sidebarView.Promocodes")}
              </Typography>
            </Link>
            <Divider sx={{ background: "#0511760a" }} />

            <Link to={"/person-account/purchases"}>
              <Typography
                sx={{
                  padding: "16px 20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#212121",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  background:
                    location.pathname === "/person-account/purchases"
                      ? "#D9DCF9"
                      : "transparent",
                }}
              >
                <img src={purchase} alt="Purchases" />
                {t("sidebarView.Purchases")}
              </Typography>
            </Link>
            <Divider sx={{ background: "#0511760a" }} />

            {/* <Link to={"/person-account/security"}>
              <Typography
                sx={{
                  padding: "16px 20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#212121",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  background:
                    location.pathname === "/person-account/security"
                      ? "#D9DCF9"
                      : "transparent",
                }}
              >
                <img src={Security} alt="Security" />
                Security
              </Typography>
            </Link>
            <Divider sx={{ background: "#0511760a" }} /> */}
            <Typography
              sx={{
                padding: "16px 20px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                color: "#212121",
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                background:
                  location.pathname === "/login" ? "#D9DCF9" : "transparent",
                cursor: "pointer",
              }}
              onClick={handleLogout}
            >
              <img src={Logout} alt="Logout" />
              {t("sidebarView.Logout")}{" "}
            </Typography>
          </Box>
          <Box
            sx={{
              display: { tab: "none", xs: "block" },
              position: "absolute",
              right: 0,
            }}
          >
            {["left"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Button onClick={toggleDrawer(anchor, true)}>
                  <MenuIcon />
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Box>
          <Box width="100%">
            {" "}
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default SidebarPerson;
