import React from "react";
import price from '../../..//assets/cards/price.svg'
const CarouselCard = ({product}) => {
  return (
    <div
      style={{
        width: "225.063px",
        height: "309px",
        background: "#fff",
        borderRadius: "7.379px",
        padding:"18px"
      }}
    >
      <div style={{ width: "188.167px", height: "162.34px",padding:".5rem 0rem 0rem 0rem"}}>
        <img src={product} alt="view-img" style={{width:"224px",height:"162px",objectFit:"cover"}}/>
      </div>
      <div style={{background:"#D9DCF9",padding:"5px",textAlign:"center",marginTop:"5px"}}>Happy New Year ✨</div>
      <div style={{ width: "138.358px", height: "70.563px",padding:".5rem 0rem"}}>
        <img src={price} alt='price' style={{ width: "225.358px", height: "auto"}}/>
      </div>
    </div>
  );
};

export default CarouselCard;
