import { Box, Container, Typography } from "@mui/material";
import React from "react";
import fail from "../../assets/failure.svg";
import CustomButton from "../global/CustomButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const FaiurePage = () => {
  const navigation = useNavigate();
  const {t} = useTranslation();
  const text = {
    color: "#FF3B30",
    fontSize: { xs: "30px", tab: "40px" },
    textAlign: "center",
    fontWeight: 700,
  };
  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: 0, paddingRight: 0, background: "#F2F2F7" }}
    >
      <Box
        mx={"7%"}
        sx={{
          padding: "120px 10px 280px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            background: "#fff",
            width: { xs: "100px", tab: "160px" },
            height: { xs: "100px", tab: "160px" },
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={fail}
            alt="cart"
            style={{ width: "48px", height: "48px" }}
          />
        </Box>
        <Box>
          <Typography sx={text} pt={"2rem"} pb={"1.5rem"}>
            {t('failurepage.message')}
          </Typography>
          <Typography
            sx={{ color: "#212121", fontWeight: 700, textAlign: "center" }}
          >
           {t('failurepage.status')}{" "}
          </Typography>
        </Box>
        <Box mt={"3rem"}>
          <CustomButton
            width={{ tab: " 384px", xs: "200px" }}
            pd="16px 40px"
            fs="18px"
            onClick={() => {
              navigation("/");
            }}
          >
            {t('failurepage.btn')}
          </CustomButton>
        </Box>
      </Box>
    </Container>
  );
};

export default FaiurePage;
