import { EDIT_USERINFO } from "../types/EditUserInfo";

  
  export const UserInfoEdit = (data,token,id) => {
      return {
        type: EDIT_USERINFO,
        payload: {data, token,id},
      };
    };

  