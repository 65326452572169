import { Box, Typography } from "@mui/material";
import React from "react";
import banner from "../../assets/contactus/faqBanner.svg";

const Header = () => {
  return (
    <Box display="flex" flexDirection={{ md: "row", xs: "column" }}>
      <Box
        width={{ md: "45%", xs: "100%" }}
        sx={{
          padding: { lg: "170px 110px", sm: "100px 80px", xs: "30px 20px" },
          backgroundColor: "#F2F2F7",
        }}
      >
        <Typography
          sx={{
            color: "#212121",
            fontWeight: "700",
            fontSize: { xs:"24px",sm: "28px", md: "40px" },
            maxWidth: { sm: "410px" },
            lineHeight: { xs: "32px", md: "56px" },
            textTransform:"uppercase"
          }}
        >
         Frequently asked questions
        </Typography>
        <Typography
          py={'16px'}
          sx={{
            color: "#212121",
            fontSize: {xs:"16px",lg:"18px"},
            fontWeight: 500,
            maxWidth: { xs:"auto",md: "359px" },
            fontFamily: "Montserrat",
          }}
        >
          Check, maybe there is an answer to your question.
        </Typography>
      </Box>
      <Box
        width={{ md: "55%", xs: "100%" }}
        sx={{
          backgroundImage: `url(${banner})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: { xs: '250px', tab: '300px',md:"auto" }, // Adjust minimum height for mobile view
        }}
      >
      </Box>
    </Box>
  );
};

export default Header;
