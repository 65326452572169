import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./Carousel.css";
import CarouselCard from "./CarouselCard";
import product from "../../../assets/cards/product.gif";
import frame3 from "../../../assets/cards/joey.gif";
import frame1 from "../../../assets/cards/frame1.svg";

const Carousel = () => {
  const [totalSlides, setTotalSlides] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const renderCustomPagination = (swiper, current, total) => {
    setCurrentSlide(current);
  };

  return (
    <div>
      <div className="container">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={{
            type: "progressbar",
            renderBullet: renderCustomPagination,
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          onSwiper={(swiper) => setTotalSlides(swiper.slides.length)}
          onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
        >
          <SwiperSlide>
            <CarouselCard product={frame1} />
          </SwiperSlide>
          <SwiperSlide>
            <CarouselCard product={product} />
          </SwiperSlide>
          <SwiperSlide>
            <CarouselCard product={frame3} />
          </SwiperSlide>
          <SwiperSlide>
            <CarouselCard product={frame1} />
          </SwiperSlide>
          <SwiperSlide>
            <CarouselCard product={product} />
          </SwiperSlide>
          <SwiperSlide>
            <CarouselCard product={frame3} />
          </SwiperSlide>
        </Swiper>
        
      </div>
      <div className="slider-controler">
        <div className="swiper-button-prev slider-arrow">
          <ArrowBackIosIcon
            sx={{ color: "#fff", width: "24px", height: "24px" }}
          />
        </div>
        <div className="swiper-button-next slider-arrow">
          <ArrowForwardIosIcon
            sx={{ color: "#fff", width: "24px", height: "24px" }}
          />
        </div>
      </div>
      <div>
      </div>
    </div>
  );
};

export default Carousel;
