import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  NewYearStickers,
  birthdayStickers,
  weddingStickers,
} from "../../store/actions/GifiSearchAction";
import { Box, Container, Grid, Typography } from "@mui/material";

// ... (imports and other code)

const GIFCardView = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const birthday = useSelector((state) => state.GIFDataReducer?.data_bir);
  const wedding = useSelector((state) => state.GIFDataReducer?.data_wed);
  const newYear = useSelector((state) => state.GIFDataReducer?.data_Year);
  const loading = useSelector((state) => state.GIFDataReducer.loading);

  useEffect(() => {
    if (data === "Happy Birthday") {
      dispatch(birthdayStickers("Happy Birthday", 32));
    } else if (data === "Weddings") {
      dispatch(weddingStickers("Wedding Love", 32));
    } else if (data === "Christmas And New Year") {
      dispatch(NewYearStickers("Christmas New Year", 32));
    }
  }, [dispatch, data]);

  const getDataArray = () => {
    switch (data) {
      case "Happy Birthday":
        return birthday;
      case "Christmas And New Year":
        return newYear;
      case "Weddings":
        return wedding;
      default:
        return [];
    }
  };

  const navigateToPartnersCard = (url) => {
    navigate(`/gift-card/`, { state: { url } });
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scrolls to the top of the page
  };

  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: 0, paddingRight: 0, background: "#F2F2F7" }}
    >
      <Box mx={"7%"} py={6}>
        <Typography
          sx={{ fontSize: { xs: "1.4rem", sm: "2rem" }, fontWeight: "bold" }}
        >
          {data} <span>({getDataArray().length})</span>
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap="1.5rem"
          mt={5}
        >
          {getDataArray().map((item, ind) => (
            <Grid item md={2.8} key={ind}>
              <Box
                sx={{
                  width: "280px",
                  height: "400px",
                  background: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => navigateToPartnersCard(item.url)}
              >
                <img
                  src={item.url}
                  alt="hello"
                  style={{
                    width: "260px",
                    aspectRatio: "16/18",
                    objectFit: "contain",
                    background:"#f2f2f2"
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default GIFCardView;
