import { FORGOT_PASSWORD_COMPANY, FORGOT_PASSWORD_COMPANY_ERROR, FORGOT_PASSWORD_COMPANY_SUCCESS, FORGOT_PASSWORD_SAVE_INFO } from "../types/ForgetPassword";

  
  const initialState = {
    loading: false,
    error: null,
    data: {},
    info: {},
  };
  
  // The reducer function
  const ForgetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
      case FORGOT_PASSWORD_SAVE_INFO:
        return {
          ...state,
          info: action.payload,
        };
      case FORGOT_PASSWORD_COMPANY:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FORGOT_PASSWORD_COMPANY_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload, // Update the customer data with the payload
          error: null,
        };
      case FORGOT_PASSWORD_COMPANY_ERROR:
        return {
          ...state,
          loading: false,
          data: null,
          error: action.payload, // Set the error with the payload
        };
      default:
        return state;
    }
  };
  
  export default ForgetPasswordReducer;
  