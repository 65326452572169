import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomButton from "../global/CustomButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  registerPersonInfo,
  registerotp,
} from "../../store/actions/RegisterPersonAction";
import { checkEmail } from "../../store/actions/ExistEmailCheckAction";

const PersonRegister = () => {
  const txt = {
    color: "#64646D",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    ml: "12px",
  };
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    // name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    marketingPermission: true,
    privacyPolicyAgreement: true,
    termsConditionsAgreement: true,
    showPassword: false,
  });

  const [errors, setErrors] = useState({});
  // const [refreshFlag,setRe]
  const navigation = useNavigate();
  // const loading = useSelector((state) => state.personOtpRegister.loading);
  const EmailCheck = useSelector((state) => state.checkEmailStatus.data);
  const success = useSelector(
    (state) => state.personOtpRegister.registerdataOTP
  );

  const LoginLoader = useSelector((state) => state.personOtpRegister.loading);

  const handleInputChange = (field, value) => {
    if (field === "phone") {
      value = `+${value}`;
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    // Clear error message for the current field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));

    // Specific validation for email field
    if (field === "email" && value.trim() !== "") {
      if (!/^\S+@\S+\.\S+$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Invalid email format",
        }));
      }
    }

    // Specific validation for password field
    if (field === "password" && value.trim() !== "") {
      if (
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(
          value
        )
      ) {
        // Password is valid, no error
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "", // Clear any existing error message
        }));
      } else {
        // Password is invalid, set error message
        setErrors((prevErrors) => ({
          ...prevErrors,
          password:
            "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character",
        }));
      }
    }

    // Check if passwords match
    if (
      field === "confirmPassword" &&
      value.trim() !== formData.password.trim()
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "", // Clear the error if passwords match
      }));
    }
  };

  const toggleCheckbox = (field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: !prevData[field],
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "", // Clear the error when the checkbox is toggled
    }));
  };

  const togglePasswordVisibility = () => {
    setFormData((prevData) => ({
      ...prevData,
      showPassword: !prevData.showPassword,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    // Check if formData is defined
    if (!formData) {
      return false; // or handle this case accordingly
    }

    // Validation logic for each field
    if (!formData.first_name || !formData.first_name.trim()) {
      newErrors.first_name = "First Name is required";
    }

    if (!formData.last_name || !formData.last_name.trim()) {
      newErrors.last_name = "Last Name is required";
    }

    if (!formData.email || !formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    if (!formData.phone || !formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (formData.phone.trim().length <= 8) {
      newErrors.phone = "Invalid Phone Number";
    }

    if (!formData.password || !formData.password.trim()) {
      newErrors.password = "Password is required";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(
        formData.password
      )
    ) {
      newErrors.password =
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character";
    }

    if (!formData.confirmPassword || !formData.confirmPassword.trim()) {
      newErrors.confirmPassword = "Password is required";
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (!formData.marketingPermission) {
      newErrors.marketingPermission = "You must agree to marketing permission";
    }

    if (!formData.privacyPolicyAgreement) {
      newErrors.privacyPolicyAgreement = "You must agree to the Privacy Policy";
    }

    if (!formData.termsConditionsAgreement) {
      newErrors.termsConditionsAgreement =
        "You must agree to the Terms and Conditions";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleCheck = (payload, payload1) => {
    if (EmailCheck && EmailCheck?.status === 200) {
      const fullName = `${formData.first_name} ${formData.last_name}`;
      const payload = {
        user_name: fullName,
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone_number: formData.phone,
        email: formData.email,
        password: formData.password,
        user_type: "person",
        marketing_permission: formData.marketingPermission,
        terms_and_condition: formData.termsConditionsAgreement,
        privacy_policy: formData.privacyPolicyAgreement,
      };
      const payload1 = {
        phone_number: formData.phone,
      };
      dispatch(registerPersonInfo(payload));
      dispatch(registerotp(payload1));
    }
  };

  const handleSubmit = () => {
    const isFormValid = validateForm();
    if (isFormValid) {
      const emailCheck = {
        email: formData.email,
      };
      dispatch(checkEmail(emailCheck));
    }
  };

  useEffect(() => {
    if (EmailCheck && EmailCheck?.status === 200) {
      handleCheck();
    }
  }, [EmailCheck]);

  if (success && success.message === "SMS sent successfully") {
    navigation("/person-register/otp");
  }

  return (
    <form>
      <Box>
        <Box my="16px">
          <Typography sx={txt}>First Name</Typography>
          <OutlinedInput
            fullWidth
            sx={{
              width: { xs: "100%", tab: "355px" },
              background: "#F8F8F8",
              "& fieldset": {
                border: errors.first_name
                  ? "1px solid red"
                  : "1px solid #4254ea33",
              },
              borderRadius: "8px",
            }}
            placeholder="Enter your Name"
            value={formData.first_name}
            onChange={(e) => handleInputChange("first_name", e.target.value)}
          />
          {errors.first_name && (
            <Typography color="red" fontSize="12px">
              {errors.first_name}
            </Typography>
          )}
        </Box>
        <Box my="16px">
          <Typography sx={txt}>Last Name</Typography>
          <OutlinedInput
            fullWidth
            sx={{
              width: { xs: "100%", tab: "355px" },
              background: "#F8F8F8",
              "& fieldset": {
                border: errors.last_name
                  ? "1px solid red"
                  : "1px solid #4254ea33",
              },
              borderRadius: "8px",
            }}
            placeholder="Enter your Name"
            value={formData.last_name}
            onChange={(e) => handleInputChange("last_name", e.target.value)}
          />
          {errors.last_name && (
            <Typography color="red" fontSize="12px">
              {errors.last_name}
            </Typography>
          )}
        </Box>
        <Box my="16px">
          <Typography sx={txt}>Email</Typography>
          <OutlinedInput
            fullWidth
            sx={{
              width: { xs: "100%", tab: "355px" },
              background: "#F8F8F8",
              "& fieldset": {
                border: errors.email ? "1px solid red" : "1px solid #4254ea33",
              },
              borderRadius: "8px",
            }}
            placeholder="Enter your Email"
            value={formData.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
          {errors.email && (
            <Typography color="red" fontSize="12px">
              {errors.email}
            </Typography>
          )}
        </Box>
        <Box my="16px">
          <Typography sx={txt}>Enter your phone number</Typography>
          <PhoneInput
            inputStyle={{
              width: "100%",
              borderRadius: "8px",
              border: errors.phone ? "1px solid red" : "1px solid #4254ea33",
              background: "#F8F8F8",
            }}
            country={"fi"}
            enableSearch={true}
            value={formData.phone} // Add '+' to the value
            onChange={(phone) => handleInputChange("phone", phone)}
          />
          {errors.phone && (
            <Typography color="red" fontSize="12px">
              {errors.phone}
            </Typography>
          )}
        </Box>
        <Box my="16px">
          <Typography sx={txt}>Enter password</Typography>
          <OutlinedInput
            fullWidth
            type={formData.showPassword ? "text" : "password"}
            value={formData.password}
            onChange={(e) => handleInputChange("password", e.target.value)}
            sx={{
              width: { xs: "100%", tab: "355px" },
              background: "#F8F8F8",
              "& fieldset": {
                border: errors.password
                  ? "1px solid red"
                  : "1px solid #4254ea33",
              },
              borderRadius: "8px",
            }}
            placeholder="New Password"
            endAdornment={
              <IconButton
                edge="end"
                aria-label="toggle password visibility"
                onClick={togglePasswordVisibility}
              >
                {formData.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            }
          />
          {errors.password && (
            <Typography color="red" fontSize="12px">
              {errors.password}
            </Typography>
          )}
        </Box>
        <Box my="16px">
          <Typography sx={txt}>Confirm password</Typography>
          <OutlinedInput
            fullWidth
            type={formData.showPassword ? "text" : "password"}
            value={formData.confirmPassword}
            onChange={(e) =>
              handleInputChange("confirmPassword", e.target.value)
            }
            sx={{
              width: { xs: "100%", tab: "355px" },
              background: "#F8F8F8",
              "& fieldset": {
                border: errors.confirmPassword
                  ? "1px solid red"
                  : "1px solid #4254ea33",
              },
              borderRadius: "8px",
            }}
            placeholder="Confirm New Password"
            endAdornment={
              <IconButton
                edge="end"
                aria-label="toggle confirm password visibility"
                onClick={togglePasswordVisibility}
              >
                {formData.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            }
          />
          {errors.confirmPassword && (
            <Typography color="red" fontSize="12px">
              {errors.confirmPassword}
            </Typography>
          )}
        </Box>
        <Box my="16px">
          <Box
            display="flex"
            alignItems="center"
            justifyItems="self-start"
            gap="5px"
            mt="11px"
          >
            <Checkbox
              checked={formData.termsConditionsAgreement}
              sx={{
                padding: "0px",
                color: "#4254EA",
                "&.Mui-checked": {
                  color: "#4254EA",
                },
              }}
              icon={
                <CheckBoxOutlineBlankIcon
                  fontSize="small"
                  sx={{
                    background: "#00000012",
                    fill: "#00000003",
                    cursor: "pointer",
                  }}
                />
              }
              onChange={() => toggleCheckbox("termsConditionsAgreement")}
            />
            <Typography
              sx={{
                color: "#212121",
                fontSize: { xs: "12px", sm: "14px" },
                fontWeight: 500,
              }}
            >
              Create account
            </Typography>
          </Box>
        </Box>
        <Box my="16px">
          <Box
            display="flex"
            alignItems="center"
            justifyItems="self-start"
            gap="5px"
            mt="11px"
          >
            <Checkbox
              checked={formData.marketingPermission}
              sx={{
                padding: "0px",
                color: "#4254EA",
                "&.Mui-checked": {
                  color: "#4254EA",
                },
              }}
              icon={
                <CheckBoxOutlineBlankIcon
                  fontSize="small"
                  sx={{
                    background: "#00000012",
                    fill: "#00000003",
                    cursor: "pointer",
                  }}
                />
              }
              onChange={() => toggleCheckbox("marketingPermission")}
            />
            <Typography
              sx={{
                color: "#212121",
                fontSize: { xs: "12px", sm: "14px" },
                fontWeight: 500,
              }}
            >
              Marketing Permission
            </Typography>
          </Box>
        </Box>
        <Box my="16px">
          <Box
            display="flex"
            alignItems="center"
            justifyItems="self-start"
            gap="5px"
            mt="11px"
          >
            <Checkbox
              checked={formData.privacyPolicyAgreement}
              sx={{
                padding: "0px",
                color: "#4254EA",
                "&.Mui-checked": {
                  color: "#4254EA",
                },
              }}
              icon={
                <CheckBoxOutlineBlankIcon
                  fontSize="small"
                  sx={{
                    background: "#00000012",
                    fill: "#00000003",
                    cursor: "pointer",
                  }}
                />
              }
              onChange={() => toggleCheckbox("privacyPolicyAgreement")}
            />
            <Typography
              sx={{
                color: "#212121",
                fontSize: { xs: "12px", sm: "14px" },
                fontWeight: 500,
              }}
            >
              I agree with the
              <span style={{ textDecoration: "underline" }}>
                Terms condition / privacy policy
              </span>
            </Typography>
          </Box>
          {formData.termsConditionsAgreement &&
          formData.privacyPolicyAgreement &&
          formData.marketingPermission ? null : (
            <Typography color="red" fontSize="12px">
              Please check all the checkboxes
            </Typography>
          )}
        </Box>

        <Box my="33.5px">
          <Button
            variant="contained"
            sx={{
              padding: { md: "10px 40px", xs: "10px 20px" },
              borderRadius: "0px 30px",
              color: "#fff",
              fontWeight: 700,
              backgroundColor: "#4254EA",
              width: "100%",
              fontSize: { md: "18px", xs: "14px" },
              fontFamily: "Montserrat", // Corrected the typo in "FontFamity" to "fontFamily"
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#1d2dbb", // Change to the desired hover color
              },
              "&:active": {
                backgroundColor: "#0c154f", // Change to the desired active color
              },
              textTransform: "none",
            }}
            onClick={handleSubmit}
            disabled={LoginLoader}
          >
            {LoginLoader ? "Loading..." : "Continue"}
          </Button>

          <Typography
            sx={{
              color: "#212121",
              fontFamily: "Montserrat",
              fontSize: "18px",
              fontWeight: 700,
              my: "27.5px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => navigation("/login")}
          >
            Login
          </Typography>
        </Box>
      </Box>
    </form>
  );
};

export default PersonRegister;
