import b1 from "../../assets/birthday/b1.svg";
import b2 from "../../assets/birthday/b2.svg";
import b3 from "../../assets/birthday/b3.svg";
import b4 from "../../assets/birthday/b4.svg";
import c1 from "../../assets/christmas/c1.svg";
import c2 from "../../assets/christmas/c2.svg";
import c3 from "../../assets/christmas/c3.svg";
import c4 from "../../assets/christmas/c4.svg";
import w1 from "../../assets/wedding/w1.svg";
import w2 from "../../assets/wedding/w2.svg";
import w3 from "../../assets/wedding/w3.svg";
import w4 from "../../assets/wedding/w4.svg";
import p1 from "../../assets/productCard/icon1.svg";
import p2 from "../../assets/productCard/icon2.svg";
import p3 from "../../assets/productCard/icon3.svg";
import p4 from "../../assets/productCard/icon4.svg";
import p5 from "../../assets/productCard/icon5.svg";
import p6 from "../../assets/productCard/icon6.svg";
import gif1 from "../../assets/cards/gif1.gif";
import gif2 from "../../assets/cards/gif2.gif";

export const data = [
  {
    card: b1,
    text: "demo img",
  },
  {
    card: b2,
    text: "demo img",
  },
  {
    card: b3,
    text: "demo img",
  },
  {
    card: b4,
    text: "demo img",
  },
  {
    card: b3,
    text: "demo img",
  },
];

export const data1 = [
  {
    card: c1,
    text: "demo img",
  },
  {
    card: c2,
    text: "demo img",
  },
  {
    card: c3,
    text: "demo img",
  },
  {
    card: c4,
    text: "demo img",
  },
  {
    card: c3,
    text: "demo img",
  },
];

export const data2 = [
  {
    card: w1,
    text: "demo img",
  },
  {
    card: w2,
    text: "demo img",
  },
  {
    card: w3,
    text: "demo img",
  },
  {
    card: w4,
    text: "demo img",
  },
  {
    card: w3,
    text: "demo img",
  },
];
export const tabProduct = [
  {
    label: "All",
    products: [
      {
        display: p1,
        title: "IKEA",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p2,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p3,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p4,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p5,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p6,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
    ],
  },
  {
    label: "Home Goods",
    products: [
      {
        display: p5,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p4,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p1,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p2,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p3,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p6,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
    ],
  },
  {
    label: "Children's goods",
    products: [
      {
        display: p1,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p2,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p3,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p4,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p5,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p6,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
    ],
  },
  {
    label: "Clothes & Shoes",
    products: [
      {
        display: p5,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p4,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p1,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p2,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p3,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p6,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
    ],
  },
  {
    label: "Restaurants",
    products: [
      {
        display: p1,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p2,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p3,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p4,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p5,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p6,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
    ],
  },
  {
    label: "Cosmetics & Perfumery",
    products: [
      {
        display: p5,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p4,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p1,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p2,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p3,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p6,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
    ],
  },
  {
    label: "Jewelry",
    products: [
      {
        display: p1,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p2,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p3,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p4,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p5,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p6,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
    ],
  },
  {
    label: "Books",
    products: [
      {
        display: p5,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p4,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p1,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p2,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p3,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
      {
        display: p6,
        title: "Adidas",
        describe:
          "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
      },
    ],
  },
];

export const faqArray = [
  {
    question: "Lorem ipsum dolor sit amet, consectetur elit?",
    answer:
      "Id ornare turpis vulputate enim sed magna sit. A id cursus dolor urna. Aliquam diam integer vitae eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim lorem hac a ultricies. ",
  },
  {
    question: "Lorem ipsum dolor sit amet,consectetur adipiscing elit?",
    answer:
      "Id ornare turpis vulputate enim sed magna sit. A id cursus dolor urna. Aliquam diam integer vitae eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim lorem hac a ultricies. ",
  },
  {
    question:
      "Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing elit?",
    answer:
      "Id ornare turpis vulputate enim sed magna sit. A id cursus dolor urna. Aliquam diam integer vitae eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim lorem hac a ultricies. ",
  },
  {
    question: "Lorem ipsum dolor sit amet, conse?",
    answer:
      "Id ornare turpis vulputate enim sed magna sit. A id cursus dolor urna. Aliquam diam integer vitae eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim lorem hac a ultricies. ",
  },
  {
    question: "Lorem ipsum dolor sit amet, conse?",
    answer:
      "Id ornare turpis vulputate enim sed magna sit. A id cursus dolor urna. Aliquam diam integer vitae eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim lorem hac a ultricies. ",
  },
  {
    question: "Lorem ipsum dolor sit amet, conse?",
    answer:
      "Id ornare turpis vulputate enim sed magna sit. A id cursus dolor urna. Aliquam diam integer vitae eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim lorem hac a ultricies. ",
  },
];

export const itemData = [
  {
    img: b1,
    title: "Breakfast",
    rows: 2,
    cols: 2,
  },
  {
    img: b2,
    title: "Burger",
  },
  {
    img: b3,
    title: "Camera",
  },
  {
    img: b4,
    title: "Coffee",
  },
  {
    img: c1,
    title: "Hats",
  },
  {
    img: c2,
    title: "Honey",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
  },
  {
    img: c3,
    title: "Basketball",
  },
  {
    img: c4,
    title: "Fern",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: "Mushrooms",
    rows: 2,
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: "Tomato basil",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: "Sea star",
  },
];

export const itemData2 = [
  {
    img: gif2,
    title: "Breakfast",
    rows: 2,
    cols: 2,
  },
  {
    img: gif1,
    title: "Burger",
  },
  {
    img: c1,
    title: "Hats",
  },

  {
    img: b4,
    title: "Coffee",
  },
  {
    img: b3,
    title: "Camera",
  },

  {
    img: c2,
    title: "Honey",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
  },
  {
    img: c3,
    title: "Basketball",
  },
  {
    img: c4,
    title: "Fern",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: "Mushrooms",
    rows: 2,
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: "Tomato basil",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: "Sea star",
  },
];

export const productPage = [
  {
    display: p1,
    title: "IKEA",
    describe:
      "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
  },
  {
    display: p2,
    title: "Adidas",
    describe:
      "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
  },
  {
    display: p3,
    title: "Adidas",
    describe:
      "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
  },
  {
    display: p4,
    title: "Adidas",
    describe:
      "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
  },
  {
    display: p5,
    title: "Adidas",
    describe:
      "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
  },
  {
    display: p6,
    title: "Adidas",
    describe:
      "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
  },
  {
    display: p1,
    title: "IKEA",
    describe:
      "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
  },
  {
    display: p2,
    title: "Adidas",
    describe:
      "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
  },
  {
    display: p3,
    title: "Adidas",
    describe:
      "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
  },
  {
    display: p4,
    title: "Adidas",
    describe:
      "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
  },
  {
    display: p5,
    title: "Adidas",
    describe:
      "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
  },
  {
    display: p6,
    title: "Adidas",
    describe:
      "Ipsum dolor sit amet, consectetur adipiscing. Purus ipsum viverra etiam. Dolor sit amet, consectetur elit viverra etiam purus.",
  },
]