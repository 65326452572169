import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import { toast } from "react-toastify";
import { UPDATE_CART_FAILURE, UPDATE_CART_REQUEST, UPDATE_CART_SUCCESS } from "../types/AddToCart";
import { GET_GIFT_CART_REQUEST } from "../types/AddToCartGift";

function* EditGiftCardSaga(action) {
  try {
    const { data, token, id } = action.payload;
    const url = `gift-card/update-card/${id}`;
    const response = yield call(baseURL.put, url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({
      type: UPDATE_CART_SUCCESS,
      payload: response.data,
    });
    yield put({ type: GET_GIFT_CART_REQUEST ,payload: { id, token }});
    // toast.success("submitted successfully!");
  } catch (error) {
    yield put({ type: UPDATE_CART_FAILURE, payload: error });
    // toast.error("Please try again!");
  }
}



export default function* watchEditGiftCardSaga() {
  yield takeLatest(UPDATE_CART_REQUEST, EditGiftCardSaga);
}
