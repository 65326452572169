import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import AddImg from "./product/AddImg";
import SelectCategory from "./product/SelectCategory";
import { useDispatch, useSelector } from "react-redux";
import { partnerDetailsInfo } from "../../store/actions/AfterLoginPartnerInfoAction";
import { filterRequest } from "../../store/actions/PartnerFilterAction";
import { useTranslation } from "react-i18next";
const CompanyProduct = () => {
  const dispatch = useDispatch();
  const allData = useSelector((state) => state?.filterData?.data?.data);
  const token = localStorage.getItem("authToken");
  const userData = useSelector((state) => state?.getUser?.user);
  // const dataError = useSelector((state) => state.AfterLoginPartnerInfoReducer.error);

  const [productInfo, setProductInfo] = useState({
    company_name: "",
    product_images: [],
    site_link: "",
    category: [],
    whom: [],
    city: [],
    about_company: "",
    nominals: [
      {
        nominal: "",
        promocode: "",
      },
    ],
  });
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState({
    company_name: "",
    site_link: "",
    category: "",
    whom: "",
    city: "",
    about_company: "",
    nominals: "",
    product_images: "",
  });
  const [productAdded, setProductAdded] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const handleCompanyNameChange = (e) => {
    setProductInfo((prev) => ({
      ...prev,
      company_name: e.target.value,
    }));

    setValidationErrors((prev) => ({ ...prev, company_name: "" }));
  };

  const handleSiteLinkChange = (e) => {
    setProductInfo((prev) => ({
      ...prev,
      site_link: e.target.value,
    }));
    setValidationErrors((prev) => ({ ...prev, site_link: "" }));
  };

  const handleAboutCompanyChange = (e) => {
    setProductInfo((prev) => ({
      ...prev,
      about_company: e.target.value,
    }));
    setValidationErrors((prev) => ({ ...prev, about_company: "" }));
  };

  const handleSave = () => {
    if (!validateForm()) {
      return;
    }
    dispatch(partnerDetailsInfo(productInfo, token));
  };
  useEffect(() => {
    dispatch(filterRequest(""));
  }, []);

  const validateForm = () => {
    const errors = {
      company_name: "",
      site_link: "",
      category: "",
      whom: "",
      city: "",
      about_company: "",
      nominals: "",
      product_images: "",
    };
    // (!formData.first_name || !formData.first_name.trim())
    // Validate company name
    if (!productInfo.company_name || !productInfo.company_name.trim()) {
      errors.company_name = "Company name is required";
    }

    // Validate site link
    if (productInfo.site_link.length === 0) {
      errors.site_link = "Site link is required";
    }

    // Validate category, whom, city (You may need specific conditions here)
    if (productInfo.category.length === 0) {
      errors.category = "Category is required";
    }
    if (productInfo.whom.length === 0) {
      errors.whom = "Whom is required";
    }
    if (productInfo.city.length === 0) {
      errors.city = "City is required";
    }

    // Validate about company
    if (!productInfo.about_company || !productInfo.about_company.trim()) {
      errors.about_company = "About company is required";
    }

    // Validate nominals (You may need specific conditions here)
    const nominalsValidation = productInfo.nominals.some(
      (nominal) => !nominal.nominal.trim() || !nominal.promocode.trim()
    );
    if (nominalsValidation) {
      errors.nominals = "Nominals are required";
    }

    // Validate product images
    if (productInfo.product_images.length === 0) {
      errors.product_images = "Product images are required";
    }

    if (productInfo.product_images.length < 4) {
      errors.product_images = "Please select exactly four product images";
    }
    // Set validation errors
    setValidationErrors(errors);

    // Check if any validation error exists
    return Object.values(errors).every((error) => !error);
  };

  useEffect(() => {
    if (allData && userData && userData.data && userData.data.length > 0) {
      const initialFilteredData = allData.filter(
        (item) => item.company_id === userData.data[0]._id
      );
      setFilteredData(initialFilteredData);
      setProductAdded(true);
    }
  }, [allData, userData]);

  useEffect(() => {
    if (productAdded && filteredData.length > 0) {
      const data = filteredData[0];

      setProductInfo({
        company_name: data.company_name,
        product_images: data.product_images,
        site_link: data.site_link,
        category: data.category, // Assume data.category is already an array
        whom: data.whom,
        city: data.city, // Assume data.city is already an array
        about_company: data.about_company,
        nominals: data.nominals.map((nominal) => ({
          nominal: nominal.nominal,
          promocode: nominal.promocode,
        })),
      });
    }
  }, [productAdded, filteredData]);

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            maxWidth: { xs: "250px", sm: "500px", tab: "520px" },
            width: "100%",
            fontSize: "18px",
            color: "#212121",
            fontWeight: 600,

            mb: "24px",
          }}
        >
          {t("sidebarView.companyHeading")}{" "}
        </Typography>
      </Box>
      <Box display="flex" gap="24px" flexDirection="column">
        <AddImg
          setProductInfo={setProductInfo}
          productInfo={productInfo}
          validationErrors={validationErrors}
          filteredData={filteredData}
          productAdded={productAdded}
        />
        <SelectCategory
          setProductInfo={setProductInfo}
          productInfo={productInfo}
          onCompanyNameChange={handleCompanyNameChange}
          onSiteLinkChange={handleSiteLinkChange}
          onAboutCompanyChange={handleAboutCompanyChange}
          validationErrors={validationErrors}
          handleSave={handleSave}
          filteredData={filteredData}
          productAdded={productAdded}
        />
      </Box>
    </Box>
  );
};

export default CompanyProduct;
