import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import {
  CHECK_LOGIN_OTP_PERSON,
  CHECK_LOGIN_OTP_PERSON_ERROR,
  CHECK_LOGIN_OTP_PERSON_SUCCESS,
  LOGIN_OTP_PERSON,
  LOGIN_OTP_PERSON_ERROR,
  LOGIN_OTP_PERSON_SUCCESS,
  LOGIN_WITH_OTP_PERSON,
  LOGIN_WITH_OTP_PERSON_ERROR,
  LOGIN_WITH_OTP_PERSON_SUCCESS,
  RESEND_LOGIN_OTP_PERSON,
  RESEND_LOGIN_OTP_PERSON_ERROR,
  RESEND_LOGIN_OTP_PERSON_SUCCESS,
} from "../types/LoginOTP";
import { setTokenToLocalStorage } from "../../utils/authService";
import { toast } from "react-toastify";

function* sendLoginPersonOTPSaga(action) {
  try {
    const response = yield call(() =>
      baseURL.post(`/auth/send-otp`, action.payload)
    );
    const { data, status, statusText } = response;

    yield put({ type: LOGIN_OTP_PERSON_SUCCESS, payload: { data, status, statusText } });
  } catch (error) {
    toast.error("Something went wrong. Please try again.");
    yield put({
      type: LOGIN_OTP_PERSON_ERROR,
      payload: error.response ? error.response.data : error.message,
    });
  }
}

function* checkLoginPersonOTPSaga(action) {
  try {
    const response = yield call(() =>
      baseURL.post(`/auth/check-otp`, action.payload)
    );
    const { data, status } = response;

    yield put({ type: CHECK_LOGIN_OTP_PERSON_SUCCESS, payload: { data, status } });
  } catch (error) {
    toast.error("Something went wrong. Please try again.");
    yield put({
      type: CHECK_LOGIN_OTP_PERSON_ERROR,
      payload: error.response ? error.response.data : error.message,
    });
  }
}

function* resendLoginPersonOTPSaga(action) {
  try {
    const response = yield call(() =>
      baseURL.post(`/auth/resend-otp`, action.payload)
    );
    const { data, status } = response;

    yield put({
      type: RESEND_LOGIN_OTP_PERSON_SUCCESS,
      payload: { data, status },
    });
  } catch (error) {
    toast.error("Something went wrong. Please try again.");
    yield put({
      type: RESEND_LOGIN_OTP_PERSON_ERROR,
      payload: error.response ? error.response.data : error.message,
    });
  }
}

function* LoginPersonSaga(action) {
  try {
    const response = yield call(() =>
      baseURL.post(`/auth/login-with-otp`, action.payload)
    );
    const token = response.data.token;
    // localStorage.setItem("authToken", token);
    setTokenToLocalStorage(token);

    yield put({
      type: LOGIN_WITH_OTP_PERSON_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: LOGIN_WITH_OTP_PERSON_ERROR,
      payload: error.response ? error.response.data : error.message,
    });
  }
}

export default function* watchCheckLoginPersonOTP() {
  yield takeLatest(LOGIN_OTP_PERSON, sendLoginPersonOTPSaga);
  yield takeLatest(CHECK_LOGIN_OTP_PERSON, checkLoginPersonOTPSaga);
  yield takeLatest(RESEND_LOGIN_OTP_PERSON, resendLoginPersonOTPSaga);
  yield takeLatest(LOGIN_WITH_OTP_PERSON, LoginPersonSaga);
}
