// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import { BALANCE_FAILURE, BALANCE_REQUEST, BALANCE_SUCCESS } from "../types/GetBalance";

function* fetchBalance(action) {
  try {
    const { userId, token } = action.payload;
    const url = `/users/get-balance/${userId}`;

    const response = yield call(baseURL.get, url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({ type: BALANCE_SUCCESS, payload:response?.data});
  } catch (error) {
    yield put({ type: BALANCE_FAILURE, error });
  }
}

export default function* watchfetchBalance() {
  yield takeLatest(BALANCE_REQUEST, fetchBalance);
}
