import { CHANGE_PASSWORD_COMPANY, CHANGE_PASSWORD_COMPANY_ERROR, CHANGE_PASSWORD_COMPANY_SUCCESS } from "../types/ForgetPassword";

  
  // src/reducers/giftCardReducer.js
  const initialState = {
    data: [],
    loading: false,
    error: null,
  };
  
  const ChangePasswordReducer = (state = initialState, action) => {
    switch (action.type) {
      case CHANGE_PASSWORD_COMPANY:
        return { ...state, loading: true, error: null };
      case CHANGE_PASSWORD_COMPANY_SUCCESS:
        return { ...state, loading: false, data: action.payload };
      case CHANGE_PASSWORD_COMPANY_ERROR:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default ChangePasswordReducer;
  