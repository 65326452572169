import { call, put, takeLatest } from "redux-saga/effects";
import { baseURL } from "../../utils/baseURL";
import {
  REGISTER_OTP,
  REGISTER_OTP_ERROR,
  REGISTER_OTP_SUCCESS,
} from "../types/RegisterPerson";
import { toast } from "react-toastify";

function* registerPersonOTPSaga(action) {
  try {
    const response = yield call(() =>
      baseURL.post(`/auth/send-otp`, action.payload)
    );
    yield put({ type: REGISTER_OTP_SUCCESS, payload: response.data });
  } catch (error) {
    toast.error("something went wrong, Please try again");
    yield put({ type: REGISTER_OTP_ERROR, payload: error });
  }
}

export default function* watchRegisterPersonOTP() {
  yield takeLatest(REGISTER_OTP, registerPersonOTPSaga);
}
